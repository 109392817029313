import moment from "moment";
import SummationsFlowUI from "./components/SummationsFlowUI";
import { useDispatch, useSelector } from "react-redux";
import {
  getLiquidationsHistoryThunk,
  getOrdersHistoryThunk,
  selectLiquidationsHistory,
  selectOrdersHistory,
} from "./SummationsSlice";
import { useEffect, useState } from "react";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { selectCountry } from "../login/UserSlice";

const Summations = () => {
  const today = moment.tz("America/El_Salvador");
  const ordersHistory = useSelector(selectOrdersHistory);
  const liquidationsHistory = useSelector(selectLiquidationsHistory);
  const country = useSelector(selectCountry);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [summations, setSummations] = useState([]);

  const transformData = () => {
    const drivers = {};
    const dataA = ordersHistory?.orders.map((item) => {
      const shopping = parseFloat(-item.repay) || 0.00;
      const change = parseFloat(-item.change) || 0.00;
      return {
        full_name: `${item.driver_name}_${item.driver_lastname}`,
        cash: item.cash,
        driver_payment: -item.driver_payment,
        repay: !!item.payment_type ? 0.00 : change,
        purchases: !!item.payment_type ? 0.00 : shopping,
      };
    });

    const dataB = liquidationsHistory?.settlements.map((item) => {
      return {
        full_name: `${item.id_driver.name}_${item.id_driver.lastname}`,
        opening: item.initial_cash_balance,
        floating: item.floating,
        bonus: item.bonus,
        paid_less: item.bonus,
        paid_more: parseFloat(-item.bonus) || 0.00,
        zone_adjustment: parseFloat(-item.bonus) || 0.00,
        remittance: parseFloat(-item.closing_cash_balance) || 0.00,
        orders: item.transactions
      };
    });

    dataA.map((driver) => {
      if (drivers[driver.full_name]) {
        drivers[driver.full_name] = [...drivers[driver.full_name], driver];
      } else {
        drivers[driver.full_name] = [driver];
      }
    });

    dataB.map((driver) => {
      if (drivers[driver.full_name]) {
        drivers[driver.full_name] = [...drivers[driver.full_name], driver];
      } else {
        drivers[driver.full_name] = [driver];
      }
    });

  //  console.log(dataA,dataB)

    const beforeResults = [];
    Object.keys(drivers).map((item) => {
      drivers[item].map((driver) => {
        if (driver.full_name !== "null_null") {
          beforeResults.push({
            full_name: driver.full_name,
            opening: driver.opening ?? 0.00,
            bonus: driver.bonus ?? 0.00,
            cash: driver.cash ?? 0.00,
            floating: driver.floating ?? 0.00,
            paid_less: driver.paid_less ?? 0.00,
            paid_more: driver.paid_more ?? 0.00,
            purchases: driver.purchases ?? 0.00,
            repay: driver.repay ?? 0.00,
            zone_adjustment: driver.zone_adjustment ?? 0.00,
            remittance: driver.remittance ?? 0.00,
            driver_payment: driver.driver_payment ?? 0.00,
            orders:driver.orders ?? null
          });
        }
      });
    });

    
    const driverTotals = {};

    // Itera sobre los registros y suma todas las propiedades numéricas por conductor
    beforeResults.forEach((record) => {
      const fullName = record.full_name;

      if (!driverTotals[fullName]) {
        driverTotals[fullName] = { ...record }; // Inicializa con el primer registro
      } else {
        // Suma todas las propiedades numéricas para el conductor actual
        for (const key in record) {
          if (typeof record[key] === "number") {
            driverTotals[fullName][key] += record[key];
          }
        }
      }
    });

    // Convierte el objeto de totales nuevamente en un array de objetos
    const result = Object.values(driverTotals);

    
    setSummations(result);

    setLoading(false);
  };
  useEffect(() => {
    const dates = {
      start_date: today.format("YYYY-MM-DD 00:00:00"),
      end_date: today.format("YYYY-MM-DD 23:59:59"),
    };

    Promise.all([
      dispatch(getOrdersHistoryThunk()),
      dispatch(getLiquidationsHistoryThunk(dates)),
    ]);
  }, []);

  useEffect(() => {
    if (
      liquidationsHistory?.settlements === undefined ||
      ordersHistory?.orders === undefined
    )
      return;
    transformData();
  }, [liquidationsHistory, ordersHistory]);

  
  useEffect(() => {
   //  console.log(ordersHistory?.orders);
   //  console.log(liquidationsHistory?.settlements);
  }, []);
  const exportSettlement = () => {
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Reporte Liquidaciones Drivers Get",
      Subject: "Reporte de liquidaciones drivers ",
      Author: "Get Technologies",
      CreatedDate: new Date(),
    };
    wb.SheetNames.push("Liquidaciones");
    const ws_data = [
      [
        "Driver",
        "Ordenes",
        "Apertura",
        "Bono",
        "Efectivo",
        "Flotante",
        "Pagó Menos",
        "Pagó Mas",
        "Compras",
        "Cambios",
        "Ajuste de zona",
        "Remesas",
        "Pago Driver",  
        "Balance",
      ],
    ];
    const s2ab = (s) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    summations.forEach((settlement) => {
      const positive = parseFloat(
        settlement.opening +
          settlement.bonus +
          settlement.cash +
          settlement.floating +
          settlement.paid_less
      ).toFixed(2);
      const negative = parseFloat(
        settlement.paid_more +
          settlement.purchases +
          settlement.repay +
          settlement.zone_adjustment +
          settlement.remittance +
          settlement.driver_payment
      ).toFixed(2);
      const newRegister = [
        settlement.full_name,
        settlement.orders,
        settlement.opening,
        settlement.bonus,
        settlement.cash,
        settlement.floating,
        settlement.paid_less,
        settlement.paid_more ,
        settlement.purchases ,
        settlement.repay,
        settlement.zone_adjustment ,
        settlement.remittance,
        settlement.driver_payment,
        (parseFloat(positive) + parseFloat(negative)).toFixed(2),
      ];
      ws_data.push(newRegister);
    });
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Liquidaciones"] = ws;
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "ReporteLiquidacionesDrivers.xlsx"
    );
  };

  return (
    <>
      <SummationsFlowUI
        today={today}
        loading={loading}
        dataSource={summations}
        exportSettlement={exportSettlement}
        country={country}
      />
    </>
  );
};

export default Summations;
