import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Modal} from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {selectCountry} from '../login/UserSlice';
import {getCashierCloseHistory, selectHistory} from '../cashflow/CashflowSlice';
import CashierCloseHistoryUI from './components/CashierCloseHistoryUI';

const CashierCloseHistory = () => {
  const country = useSelector(selectCountry);
  const today = moment.tz('America/El_Salvador');
  const [loading, handleLoading] = useState(true);
  const [beginDate, handleBeginDate] = useState(
    moment.tz('America/El_Salvador').subtract(7, 'days')
  );
  const [endDate, handleEndDate] = useState(today);
  const historyCashierClose = useSelector(selectHistory);
  const dispatch = useDispatch();

  const searchHistory = useCallback(async (newdBegin, newEnd) => {
    handleLoading(true);
    const begin = newdBegin || beginDate
    const end = newEnd || endDate;
    const config = {
      start_date: begin.format('YYYY-MM-DD 00:00:00'),
      end_date: end.format('YYYY-MM-DD 23:59:59'),
    };
    const response = await dispatch(getCashierCloseHistory(config));
    if (response.status !== 'success') {
      Modal.warning({
        title: 'Hubo un problema',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleLoading(false);
  }, [beginDate, dispatch, endDate]);

  const handleDates = (newDates) => {
    handleBeginDate(newDates[0]);
    handleEndDate(newDates[1]);
    searchHistory(newDates[0], newDates[1]);
  };

  useEffect(() => {
    searchHistory();
  }, [searchHistory]);

  return (
    <CashierCloseHistoryUI
      country={country}
      today={today}
      historyCashierClose={historyCashierClose}
      loading={loading}
      beginDate={beginDate}
      endDate={endDate}
      handleDates={handleDates}
    />
  );
};

export default CashierCloseHistory;
