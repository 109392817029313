import { createSlice } from '@reduxjs/toolkit';
import { getList, updateList } from './Api'

// Reducer
export const feedSortSlice = createSlice({
  name: 'feedsort',
  initialState: {
    dt_sort: [],
  },
  reducers: {
    setFeedSort: (state, action) => {
      state.dt_sort = action.payload;
    },
  },
});


//Actions

// export const { setList } = feedSortSlice.actions;

export const {
  setFeedSort
} = feedSortSlice.actions;

export const getSortList = () => async dispatch => {
  try {
    const response = await getList();
    if (!response.error && response.status === 200) {
      dispatch(setFeedSort(response.data.dt_sort));
      return {
        status: 'success',
        dt_sort: response.data.dt_sort
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const updateSortList = (list) => async dispatch => {
  try {
    const response = await updateList(list);
    if (!response.error && response.status === 200) {
      // dispatch(setFeedSort(response.data.dt_sort));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectList = state => state.feedsort.dt_sort;

export default feedSortSlice.reducer;
