import {Spin} from 'antd';
import {Link} from 'react-router-dom';
import {getCurrency} from '../../../utils/format';
import TableOrders from '../../../components/TableOrders';
import '../styles/SettlementDetailUI.scss';

const SettlementDetailUI = props => {
  const {
    settlement,
    loading,
    country,
    exportData,
    idPartner,
  } = props;
  const {partner_name, date, orders} = settlement;

  const getValueSummary = attribute => {
    let amount = 0;
    orders.map(order => {
      amount += order[attribute];
    });
    return getCurrency(country, amount);
  };

  const SummaryItem = ({label, value}) => (
    <div className="summary-item">
      <span className="label-summary">
        {label}
      </span>
      <span className="value-summary">
        {getValueSummary(value)}
      </span>
    </div>
  );

  if (loading) {
    return (
      <div className="loader-wrap">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="settlement-detail-wrap">
      <div className="main-titles">
        <div>
          <Link
            to={idPartner ? '/settlements' : '/settlements/history'}
            className="link"
          >
            <i className="bi bi-arrow-left-short" />
            {idPartner ? 'Pendientes' : 'Historial'}
          </Link>
          <h1 className="title">
            {partner_name}
          </h1>
          <h3 className="subtitle">
            {date.format('dddd D [de] MMMM')}
          </h3>
        </div>
        <div className="number-orders">
          <button
            onClick={exportData}
            className="action green"
          >
            Exportar
          </button>
          <div className="number-data">
            <h1 className="title">{orders.length}</h1>
            <h3 className="subtitle">Pedidos</h3>
          </div>
        </div>
      </div>
      <div className="orders-wrap-settlement">
        <div className="summary">
          <SummaryItem
            label="Delivery"
            value="delivery_fee"
          />
          <SummaryItem
            label="Pago Driver"
            value="driver_payment"
          />
          <SummaryItem
            label="Ingresos"
            value="order_total"
          />
          <SummaryItem
            label="Fee Get"
            value="get_fee"
          />
          <SummaryItem
            label="Comercio"
            value="repay"
          />
          <SummaryItem
            label="Utilidad"
            value="profits"
          />
        </div>
        <TableOrders
          settlement
          orders={orders}
          filterOrders={() => {}}
        />
      </div>
    </div>
  );
};

export default SettlementDetailUI;
