import { GoogleMap, LoadScript, OverlayView } from '@react-google-maps/api';
import {useSelector} from 'react-redux';
import {selectCountry} from '../../login/UserSlice';
import '../styles/Map.scss';

const containerStyle = {
  width: '100%',
  height: '500px'
};

const Map = ({drivers}) => {
  const country = useSelector(selectCountry);

  const centerSV = {
    lat: 13.701319108430834,
    lng: -89.22441469943618,
  };

  const centerNI = {
    lat: 12.139571,
    lng: -86.248850,
  };

  const getCoordinates = (latitude, longitude) => {
    return {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };
  };

  const Marker = ({photo, name}) => (
    <div className="marker driver">
      <div className="wrap">
        <div
          className="image"
          style={{
            backgroundImage: `url(${photo})`,
          }}
        />
      </div>
      <div className="point" />
      <div className="info">
        {name}
      </div>
    </div>
  );

  const GetMarker = ({ubication, photo, name}) => (
    <OverlayView
      position={ubication}
      getPixelPositionOffset={() => ({x: -20, y: -52})}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <Marker photo={photo} name={name}  />
    </OverlayView>
  );

  return (
    <div className="map-wrapper-list">
      <LoadScript
      googleMapsApiKey="AIzaSyBmPjCHkYIqkcSCagiDjBao3fJaBlpTDCg"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={country === 'sv' ? centerSV : centerNI}
          zoom={15}
          options={{
            disableDefaultUI: true,
          }}
        >
          {drivers.map(driver => (
            driver.latitude && driver.latitude.length > 0 && driver.longitude && driver.longitude.length > 0 ? (
              <GetMarker
                ubication={getCoordinates(driver.latitude, driver.longitude)}
                name={driver.name}
                photo={driver.photo}
              />
            ) : (
              <div />
            )
          ))}
        </GoogleMap>
      </LoadScript>
      <div className="filter" />
    </div>
  );
};

export default Map;