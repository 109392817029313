import {useState} from 'react';
import cx from 'classnames';
import G from '../../../assets/logo/g.svg';
import {getCurrency} from '../../../utils/format';
import '../styles/OrderDetail.scss';

const OrderDetail = ({partnerOrder, productsOrder, country, deleteProductOrder, editProduct}) => {
  const [edit, handleEdit] = useState(false);
  const isPartner = !!partnerOrder.id;

  const Product = ({product, index}) => {
    const {quantity, name, categories, comments, price, customProducts} = product;

    const isSelected = (extras) => {
      let status = false;
      extras.map((extra) => {
        if (extra.selected) {
          status = true;
        }
      });
      return status;
    };

    const deleteProduct = () => {
      deleteProductOrder(index);
    };

    const editProductOrder = () => {
      editProduct(product, index);
    };

    return (
      <div className="product-wrap">
        <div className={cx('product-header', {edit})}>
          <div className="actions">
            <button className="delete" onClick={deleteProduct}>
              <i className="bi bi-trash-fill" />
            </button>
            <button className="edit" onClick={editProductOrder}>
              <i className="bi bi-pencil-fill" />
            </button>
          </div>
          <span className="quantity">{quantity}</span>
          <span className="name">{name}</span>
          <span className="total">
            {getCurrency(country, price, partnerOrder.default_currency)}
          </span>
        </div>
        {(categories.length > 0 || comments.length > 0) && (
          <div className="categories">
            {customProducts.map((category) =>
              (
                <div key={category.id} className="category">
                  <span className="label-category">{category.name}</span>
                  {category.categories.map((customCategory) =>
                    customCategory.extras.map(extra => 
                      extra.selected && (
                        <div key={extra.id} className="extra-wrap">
                          <span>
                            {extra.name}
                          </span>
                          {extra.price > 0 && (
                            <span>
                              {getCurrency(country, extra.price, partnerOrder.default_currency)}
                            </span>
                          )}
                        </div>
                      )
                    )
                  )}
                </div>
              )
            )}
            {categories.map((category) =>
              (!product.id_setmenu ||
                (product.id_setmenu &&
                  category.id !== product.categories[0].id)) &&
                !category.custom &&
                category.extras.length > 0 &&
                isSelected(category.extras) && (
                <div key={category.id} className="category">
                  <span className="label-category">{category.name}</span>
                  {category.extras.map((extra) => 
                    extra.selected && (
                    <div key={extra.id} className="extra-wrap">
                      <span>
                        {`${extra.name}${extra.quantity !== undefined && extra.quantity > 0 ? ` (${extra.quantity})` : ''}`}
                      </span>
                      {extra.price > 0 && (
                        <span>
                          {getCurrency(country, extra.price, partnerOrder.default_currency)}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              )
            )}
            {comments.length > 0 && (
              <div className="category">
                <span className="label-category">Comentario</span>
                  <div className="extra-wrap">
                    <span>{comments}</span>
                  </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="order-detail-take-order">
      <div className="partner-info">
        <div
          className="partner-logo"
          style={{backgroundImage: `url(${isPartner ? partnerOrder.logo : ''})`}}
        >
          {!isPartner && <img src={G} alt="" />}
        </div>
        <div className="partner-name">
          <span className="name">{isPartner ? partnerOrder.name :  'Sin comercio'}</span>
          <span className="type">Pedido por llamada</span>
        </div>
        {isPartner && (
          <div onClick={() => handleEdit(!edit)} className="edit-btn">
            {edit ? 'Listo' : 'Editar'}
          </div>
        )}
      </div>
      <div className={cx('detail', {empty: productsOrder.length === 0})}>
        {productsOrder.length === 0 ? (
          <span>Aún no has agregado nada al carrito <i className="bi bi-cart-fill" /></span>
        ) : (
          <div className="detail-wrap">
            <span className="label-detail">Tu Pedido 🛵</span>
            <div className="details-list">
              {productsOrder.map((product, index) => (
                <Product key={product.id} product={product} index={index} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetail;
