import instance from '../../Api';

export const getList = async () => {
  const api = await instance();
  let data = await api
    .get('/dtsort/list')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const updateList = async (list) => {
  const api = await instance();
  let data = await api
    .post('/dtsort/list-update', {
      list: list
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
