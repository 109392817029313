import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Input, Button} from 'antd';
import {BlobProvider} from '@react-pdf/renderer';
import cx from 'classnames';
import {getCurrency} from '../../../utils/format';
import {selectUser} from '../../login/UserSlice';
import Print from './CloseCashierPrint';
import '../styles/CloseCashier.scss';

const {TextArea} = Input;

const CloseCashier = ({cashSummary, country, closeCashier, isAdmin, today}) => {
  const user = useSelector(selectUser);
  const [comments, handleComments] = useState('');
  const [sending, handleSending] = useState(false);
  const {
    initial_cash_balance,
    bill_coin_total,
    pos_app,
    pos_callcenter,
    total_income,
    total_expenses,
    to_deposit,
    missing,
    surplus,
    cash_available,
    cashier_cash,
    cash,
  } = cashSummary;

  const getCurrencyCountry = val => getCurrency(country, val);

  const Detail = ({label, value, gray, teal, red, bold, blue}) => (
    <div className="detail-summary">
      <span className={cx({bold})}>{label}</span>
      <span
        className={cx('value', {gray, teal, red, blue})}>
        {value}
      </span>
    </div>
  );

  const close = async () => {
    handleSending(true);
    await closeCashier(true, comments);
    handleSending(false);
  };

  const printSummary = url => {
    window.open(url, '_blank');
  };


  const PrintButton = () => (
    <BlobProvider
      document={
        <Print
          summary={cashSummary}
          country={country}
          comments={comments}
          name={user.username}
          date={today.format('dddd D [de] MMMM')}
        />
      }
    >
      {({ url }) => (
        <Button
          onClick={() => printSummary(url)}
          size="large"
          className="btn print">
          Imprimir
        </Button>
      )}
    </BlobProvider>
  );


  return (
    <div className="close-cashier-wrap">
      <div className="summary">
        <Detail
          label="Contado"
          gray
          value={getCurrencyCountry(bill_coin_total)}
        />
        <div className="horizontal" />
        <Detail
          label="Apertura de caja" 
          value={getCurrencyCountry(initial_cash_balance)}
        />
        <Detail
          label="Ingreso fondo de caja"
          gray
          value={getCurrencyCountry(cashier_cash)}
        />
        <Detail
          label="Ingresos efectivo"
          gray
          value={getCurrencyCountry(cash)}
        />
        <Detail
          label="Egresos"
          bold
          red
          value={`- ${getCurrencyCountry(total_expenses)}`}
        />
        {/* <Detail
          label="Efectivo según sistema"
          bold
          blue
          value={getCurrencyCountry(cash_available)}
        /> */}
        <div className="horizontal" />
        <Detail
          label="Valor a remesar"
          gray
          value={getCurrencyCountry(to_deposit)}
        />
        {/* <Detail
          label="(-) Faltante"
          gray
          value={getCurrencyCountry(missing)}
        />
        <Detail
          label="(+) Sobrante"
          gray
          value={getCurrencyCountry(surplus)}
        /> */}
        {isAdmin && (
          <>
            <div className="horizontal" />
            <Detail
              label="Cobros día (Cash In)"
              gray
              value={getCurrencyCountry(cash)}
            />
            <Detail
              label="Ingreso fondo de caja"
              gray
              value={getCurrencyCountry(cashier_cash)}
            />
            <Detail
              label="POS App" 
              gray
              value={getCurrencyCountry(pos_app)}
            />
            <Detail
              label="POS Callcenter"
              gray
              value={getCurrencyCountry(pos_callcenter)}
            />
            <Detail
              label="Ingresos"
              bold
              teal
              value={getCurrencyCountry(total_income)}
            />
          </>
        )}
      </div>
      {/* <div className="cash-wrap">
        <span className="label">Efectivo disponible:</span>
        <span className="cash">{getCurrencyCountry(cash_available)}</span>
      </div> */}
      <div className="commentary">
        <span>Comentarios</span>
        <TextArea
          onChange={e => handleComments(e.target.value)}
          value={comments}
          className="text"
          rows={4}
        />
      </div>
      <div className="actions">
        <PrintButton />
        <Button
          onClick={close}
          loading={sending}
          size="large"
          className="btn close"
        >
          Cerrar corte
        </Button>
      </div>
    </div>
  );
};

export default CloseCashier;
