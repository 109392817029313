import { createSlice } from '@reduxjs/toolkit';
import {
  getDawRecords,
  getCategories,
  createDawRecord,
  deleteDawRecord,
} from './Api'

// Reducer
export const dawRecordsSlice = createSlice({
  name: 'dawRecords',
  initialState: {
    records: [],
  },
  reducers: {
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    newRecord: (state, action) => {
      state.records.push(action.payload);
    },
    removeRecord: (state, action) => {
      const newRecords = state.records;
      newRecords.splice(action.payload, 1);
      state.records = newRecords;
    },
  },
});


//Actions

export const {
  setRecords,
  newRecord,
  removeRecord,
} = dawRecordsSlice.actions;

export const getRecords = config => async dispatch => {
  try {
    const response = await getDawRecords(config);
    if (!response.error && response.status === 200) {
      dispatch(setRecords(response.data.records));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getDawCategories = () => async () => {
  try {
    const response = await getCategories();
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        categories: response.data.categories,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const createRecord = record => async dispatch => {
  try {
    const response = await createDawRecord(record);
    if (!response.error && response.status === 200) {
      dispatch(newRecord(response.data.new_daw));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const deleteRecord = (id, index) => async dispatch => {
  try {
    const response = await deleteDawRecord(id);
    if (!response.error && response.status === 200) {
      dispatch(removeRecord(index));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectRecords = state => state.dawRecords.records;

export default dawRecordsSlice.reducer;
