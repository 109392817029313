import { Modal, Divider } from "antd";
import React from "react";
import { getCurrency } from "../../../utils/format";

const ModalDetail = ({ openModal, setOpenModal, record, country }) => {
  return (
    <Modal
      open={openModal}
      footer={null}
      onCancel={() => setOpenModal(false)}
      destroyOnClose
    >
      <div className="detail">
        <div className="header-detail">
          <span className="driver">
            <p>DRIVER</p>
            {record.full_name?.split("_").join(" ")}{" "}
          </span>

          <span className="driver">
            <p>ORDENES</p>
            {record.orders ?? "-"}
          </span>
        </div>
        <div className="container">
          <p style={{ fontSize: "20px" }}>Conceptos</p>
          <p style={{ fontSize: "20px" }}>Suma</p>
          <p style={{ fontSize: "20px" }}>Resta</p>
        </div>
        <Divider type="horizontal" className="divider" />

        <div className="container-details">
          <div className="detail-summary plus">
            <p>Apertura</p>
          </div>
          <div className="detail-summary plus">
            <p>{getCurrency(country, record.opening)}</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary plus">
            <p>Abono</p>
          </div>
          <div className="detail-summary plus">
            <p>{getCurrency(country, record.bonus)}</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary plus">
            <p>Efectivo</p>
          </div>
          <div className="detail-summary plus">
            <p>{getCurrency(country, record.cash)}</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary plus">
            <p>Flotante</p>
          </div>
          <div className="detail-summary plus">
            <p>{getCurrency(country, record.floating)}</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary plus">
            <p>Pagó Menos</p>
          </div>
          <div className="detail-summary plus">
            <p>{getCurrency(country, record.paid_less)}</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary less">
            <p>Pagó Mas</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary less">
            <p>{getCurrency(country, record.paid_more)}</p>
          </div>
          <div className="detail-summary less">
            <p>Compras</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary less">
            <p>{getCurrency(country, record.purchases)}</p>
          </div>
          <div className="detail-summary less">
            <p>Cambio</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary less">
            <p>{getCurrency(country, record.repay)}</p>
          </div>
          <div className="detail-summary less">
            <p>Ajuste de Zona</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary less">
            <p>{getCurrency(country, record.zone_adjustment)}</p>
          </div>
          <div className="detail-summary less">
            <p>Remesa</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary less">
            <p>{getCurrency(country, record.remittance)}</p>
          </div>
          <div className="detail-summary less">
            <p>Pago a Driver</p>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary less">
            <p>{getCurrency(country, record.driver_payment)}</p>
          </div>
        </div>
        <Divider type="horizontal" className="divider" />

        <div className="container">
          <div className="detail-summary">
            <b>Balance</b>
          </div>
          <div className="detail-summary">
            <p>-</p>
          </div>
          <div className="detail-summary">
            <span className="balance">
              {getCurrency(country, record.balance)}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDetail;
