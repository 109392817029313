import { createSlice } from '@reduxjs/toolkit';
import { getOrderProducts } from '../monitor/Api'
import { cancelOrder } from './Api'

// Reducer
export const userSlice = createSlice({
  name: 'order_detail',
  initialState: {
    order: {},
  },
  reducers: {
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setReverse: (state, action) => {
      state.order.serfinsa_payment_logs.push(action.payload);
    },
  },
});


//Actions

export const { setOrder, setReverse } = userSlice.actions;

export const getOrderDetail = (order) => async dispatch => {
  try {
    const response = await getOrderProducts(order);
    if (!response.error && response.status === 200) {
      dispatch(setOrder(response.data.order));
      return {
        status: 'success',
      };
    }
    if (response.error.response.status === 401) {
      return {
        status: 'error',
        type: 'not-found'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const revertOrderDetail = (user, order, code, date) => async dispatch => {
  try {
    const response = await cancelOrder(user, order, code, date);
    if (!response.error && response.status === 200) {
      if (response.data) {
        dispatch(setReverse(response.data.transaction));
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectOrder = state => state.order_detail.order;

export default userSlice.reducer;
