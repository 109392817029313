import { createSlice } from "@reduxjs/toolkit";
import { getLiquidations, getOrdersHistory } from "./Api";

const initialState = {
  ordersHistory: [],
  liquidationsHistory: [],
};

export const summationsFlowSlice = createSlice({
  name: "summations_flow",
  initialState,
  reducers: {
    setOrdersHistory: (state, action) => {
      state.ordersHistory = action.payload;
    },
    setLiquidationsHistory: (state, action) => {
      state.liquidationsHistory = action.payload;
    },
  },
});

export const { setOrdersHistory, setLiquidationsHistory } =
  summationsFlowSlice.actions;

export const getOrdersHistoryThunk = () => async (dispatch) => {

  try {
    const response = await getOrdersHistory();

    if (!response.error && response.status === 200) {
      const data = response.data;
      dispatch(setOrdersHistory(data));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (error) {

    return {
      status: "error",
      type: "unkown",
    };
  }
};

export const getLiquidationsHistoryThunk = (filters) => async (dispatch) => {
  try {
    const response = await getLiquidations(filters.start_date,filters.end_date);
    if (!response.error && response.status === 200) {
      const data = response.data;
      dispatch(setLiquidationsHistory(data));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (error) {
    return {
      status: "error",
      type: "unkown",
    };
  }
};

export const selectOrdersHistory = (state) =>
  state.summations_flow.ordersHistory;
export const selectLiquidationsHistory = (state) =>
  state.summations_flow.liquidationsHistory;

export default summationsFlowSlice.reducer;
