import {saveAs} from 'file-saver';
import XLSX from 'xlsx';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';

const s2ab = (s) => { 
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;    
};

const exportData = (records, begin, end, categories) => {
  const wb = XLSX.utils.book_new();
  const title = `Reporte de abonos_retiros Get ${begin.format('DD_MM_YYYY')}  ${end.format('DD_MM_YYYY')}`;
  wb.Props = {
    Title: title,
    Subject: 'Reporte de abonos_retiros Get',
    Author: 'Get Technologies',
    CreatedDate: new Date(),
  };
  wb.SheetNames.push('Ordenes');
  const ws_data = [
    [
      'Fecha',
      'Categoría',
      'Comentarios',
      'Tipo',
      'Monto',
    ]
  ];

  const getCategory = cat => {
    if (typeof(cat) !== 'object') {
      const index = categories.findIndex(el => el.id === cat);
      if (index > -1) {
        return categories[0].name;
      }
      return '-';
    }
    return cat.name;
  };

  records.forEach(record => {
    const newRegister = [
      moment.tz(record.date, 'America/El_Salvador').format('DD/MM/YYYY, HH:mm a'),
      getCategory(record.id_category),
      record.comment,
      record.id_type === 0 ? 'Retiro' : 'Abono',
      record.amount * (record.id_type === 0 ? -1 : 1),
    ];
    ws_data.push(newRegister);
  });
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets['Ordenes'] = ws;
  const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
  saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${title}.xlsx`);
};

export default exportData;
