import instance from '../../Api';

export const getDrivers = async () => {
  const api = await instance();
  let data = await api
    .get('/drivers')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

const getFormData = object => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key]);
  return formData;
}, new FormData());

export const getDriver = async (driver) => {
  const api = await instance();
  let data = await api
    .get(`/drivers/${driver}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newDriver = async (driver) => {
  const api = await instance();
  const newData = getFormData(driver);
  let data = await api
    .post(
      '/drivers/create',
      newData
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editDriver = async (driver) => {
  const api = await instance();
  const newData = getFormData(driver);
  let data = await api
    .post(
      '/drivers/update',
      newData
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const activeDriver = async (driver, value) => {
  const api = await instance();
  let data = await api
    .post(
      '/drivers/activate',
      {
        driverId: driver,
        active: value,
      },
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const enableDriver = async (driver) => {
  const api = await instance();
  let data = await api
    .post(
      '/drivers/enable',
      {
        driverId: driver,
      },
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const disableDriver = async (driver) => {
  const api = await instance();
  let data = await api
    .post(
      '/drivers/disable',
      {
        driverId: driver,
      },
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getPartnersAvailable = async () => {
  const api = await instance();
  let data = await api
    .get('/partners/delivery-active')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const assignBranch = async (driver, branch) => {
  const api = await instance();
  let data = await api
    .post('/drivers/branch', {
      id_driver: driver,
      id_branch: branch,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
