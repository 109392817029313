import { useSelector } from "react-redux";
import { Layout, Menu, Dropdown } from "antd";
import {
  TvFill,
  ArchiveFill,
  FilePost,
  BadgePersonFill,
  PersonPlusFill,
  PersonFill,
  Calendar2Fill,
} from "../../../components/Icon";
import { selectCountry } from "../../login/UserSlice";
import Routes from "../routes/Dashboard";
import Logo from "../../../assets/logo/logo.svg";
import "../styles/DashboardUI.scss";

const { Header, Sider, Content } = Layout;
const { Item } = Menu;

const DashboardUI = (props) => {
  const { user, logout, onClikMenu, active, isDispatch, isSuperadmin } = props;
  const country = useSelector(selectCountry);
  const tuNicaragua = user.role === 3 && country === "ni";

  const menu = () => (
    <Menu>
      <Menu.Item>
        <span onClick={logout}>Cerrar sesión</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="dashboard-wrapper">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        className="sider-wrap"
        theme="light"
        zeroWidthTriggerStyle={{ border: "0.5px solid #D3D3DE", top: "30px" }}
        width={280}
      >
        <div className="sider">
          <div className="logo-wrap">
            <img src={Logo} className="logo" alt="" />
          </div>
          <Menu
            className="nav"
            mode="inline"
            onClick={onClikMenu}
            selectedKeys={[active]}
            inlineIndent={30}
          >
            <Item key="monitor" className="item-nav" icon={<TvFill />}>
              Monitor
            </Item>
            <Item key="orders" className="item-nav" icon={<ArchiveFill />}>
              Historial
            </Item>
            {!tuNicaragua && (
              <>
                {isSuperadmin && (
                  <Item
                    key="charts"
                    className="item-nav"
                    icon={<i className="bi bi-pie-chart-fill" />}
                  >
                    Gráficas
                  </Item>
                )}
                {isSuperadmin && (
                  <Item
                    key="reports"
                    className="item-nav"
                    icon={<i className="bi bi-file-earmark-text" />}
                  >
                    Reportes
                  </Item>
                )}
                {(isSuperadmin || isDispatch) && (
                  <Item
                    key="cashflow"
                    className="item-nav cash-wrap"
                    icon={<i className="bi bi-cash-coin cash-coin-icon" />}
                  >
                    Caja
                  </Item>
                )}
                {(isSuperadmin || isDispatch) && (
                  <Item
                    key="getters-cashflow"
                    className="item-nav cash-wrap"
                    icon={<i className="bi bi-bicycle bicycle-icon" />}
                  >
                    Gestión Getters
                  </Item>
                )}
                {(isSuperadmin) && (
                  <Item
                    key="summations"
                    className="item-nav cash-wrap"
                    icon={<i className="bi bi-wallet"/>}
                  >
                    Liquidaciones
                  </Item>
                )}
                {isSuperadmin && (
                  <Item
                    key="settlements"
                    className="item-nav cash-wrap"
                    icon={<i className="bi bi-cash" />}
                  >
                    Acreditaciones
                  </Item>
                )}
                {!isDispatch && (
                  <Item key="partners" className="item-nav" icon={<FilePost />}>
                    Restaurantes
                  </Item>
                )}
                <Item
                  key="drivers"
                  className="item-nav"
                  icon={<BadgePersonFill />}
                >
                  Motoristas
                </Item>
                {!isDispatch && (
                  <Item
                    key="users"
                    className="item-nav"
                    icon={<PersonPlusFill />}
                  >
                    Usuarios
                  </Item>
                )}
                {!isDispatch && (
                  <Item
                    key="feed-sort"
                    className="item-nav"
                    icon={<Calendar2Fill />}
                  >
                    Ordenar comercios
                  </Item>
                )}
                {/* <Item
                  key="zones"
                  className="item-nav"
                  icon={<HexagonFill />}
                  disabled
                >
                  Zonas de cobertura
                </Item>
                <Item
                  key="promotions"
                  className="item-nav"
                  icon={<CartPlusFill />}
                  disabled
                >
                  Promociones
                </Item>
                <Item
                  key="push"
                  className="item-nav"
                  icon={<ChatLeftDotsFill />}
                  disabled
                >
                  Notificaciones Push
                </Item> */}
              </>
            )}
          </Menu>
          <div className="version-wrap">
            <span className="version">v 1.5.2</span>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header className="header">
          {country === "sv" ? (
            <span className="country">
              <span className="flag">🇸🇻</span> El Salvador
            </span>
          ) : (
            <span className="country">
              <span className="flag">🇳🇮</span> Nicaragua
            </span>
          )}
          <div className="account">
            <span className="username">{user.username}</span>
            <Dropdown overlay={menu} placement="bottomRight">
              <button className="button-profile">
                <PersonFill />
              </button>
            </Dropdown>
          </div>
        </Header>
        <Content className="content">
          <Routes isDispatch={isDispatch} isSuperadmin={isSuperadmin} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardUI;
