import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../pages/login/UserSlice";
import cashflowReducer from "../pages/cashflow/CashflowSlice";
import monitorReducer from "../pages/monitor/MonitorSlice";
import driversReducer from "../pages/drivers/DriversSlice";
import dawRecordsReducer from "../pages/daw-records/DawRecordsSlice";
import orderDetailReducer from "../pages/order-detail/OrderDetailSlice";
import ordersReducer from "../pages/orders/OrdersSlice";
import usersReducer from "../pages/users/UsersSlice";
import takeOrdersReducer from "../pages/take-orders/TakeOrdersSlice";
import reportsReducer from "../pages/reports/ReportsSlice";
import partnersReducer from "../pages/partners/PartnersSlice";
import settlementsReducer from "../pages/settlements/SettlementsSlice";
import feedsortReducer from "../pages/feed-sort/FeedSortUISlice";
import gettersCashflowReducer from "../pages/getters-cashflow/GettersCashflowSlice";
import chartsReducer from "../pages/charts/ChartsSlice";
import SummationsSlice from "../pages/summations/SummationsSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    cashflow: cashflowReducer,
    monitor: monitorReducer,
    drivers: driversReducer,
    dawRecords: dawRecordsReducer,
    users: usersReducer,
    take_orders: takeOrdersReducer,
    order_detail: orderDetailReducer,
    orders_history: ordersReducer,
    reports: reportsReducer,
    partners: partnersReducer,
    settlements: settlementsReducer,
    feedsort: feedsortReducer,
    getters_cashflow: gettersCashflowReducer,
    charts: chartsReducer,
    summations_flow:SummationsSlice
  },
});
