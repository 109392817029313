import { Row, Col, Form, Input, Button, Alert } from 'antd';
import { EyeFill, EyeSlashFill } from '../../../components/Icon';
import Logo from '../../../assets/logo/logo.svg';
import '../styles/LoginUI.scss';

// const { Option } = Select;

const LoginUI = (props) => {
  const {
    form,
    login,
    sending,
    error,
    handleError,
    country,
    handleCountry,
  } = props;
  return (
    <div className="login-wrap">
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
          <div className="login-form">
            <img src={Logo} alt="" />
            <Form
              form={form}
              name="login"
              onFinishFailed={login}
              requiredMark={false}
              layout="vertical"
              onFinish={login}
            >
              <span className="title">Login to Customer Success</span>
              <Form.Item
                label={<span className="label">USERNAME</span>}
                name="username"
                colon={false}
                rules={[{ required: true, message: 'Ingresa tu usuario' }]}
              >
                <Input size="large" className="input" placeholder="example@getlaapp.com" />
              </Form.Item>
              <Form.Item
                label={<span className="label">PASSWORD</span>}
                name="password"
                colon={false}
                rules={[{ required: true, message: 'Ingresa tu contraseña' }]}
              >
                <Input.Password
                  size="large"
                  className="input"
                  placeholder="••••••••••••••••"
                  iconRender={visible => (
                    <button type="button">
                      {visible ? <EyeSlashFill /> : <EyeFill />}
                    </button>
                  )}
                />
              </Form.Item>
              <Form.Item
                label={<span className="label">PAÍS</span>}
                name="country"
                colon={false}
              >
                <div className="radios">
                  <div onClick={() => handleCountry('sv')} className="radio-wrap">
                    <div className="radio-ext">
                      {country === 'sv' && <div className="inner-radio" />}
                    </div>
                    <span>El Salvador</span>
                  </div>
                  <div onClick={() => handleCountry('ni')} className="radio-wrap">
                    <div className="radio-ext">
                      {country === 'ni' && <div className="inner-radio" />}
                    </div>
                    <span>Nicaragua</span>
                  </div>
                </div>
              </Form.Item>
              <Form.Item>
                <Button className="submit" block htmlType="submit" loading={sending}>
                  Iniciar Sesión
                </Button>
              </Form.Item>
              {error.length > 0 && (
                <Alert
                  message={error}
                  type="error"
                  showIcon
                  closable 
                  onClose={() => handleError('')}
                />
              )}
            </Form>
          </div>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} lg={{ span: 14 }}>
          <div className="background" />
        </Col>
      </Row>
    </div>
  );
};

export default LoginUI;
