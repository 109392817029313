import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Col, Row, Input, Button} from 'antd';
import {selectUserOrder} from '../TakeOrdersSlice';
import {getCurrency} from '../../../utils/format';
import '../styles/ClientEditor.scss';

const ClientEditor = ({roleTuNicaragua, country}) => {
  const user = useSelector(selectUserOrder);
  const history = useHistory();

  const editUser = () => {
    history.push(`/users/${user.id}`, {take_order: true, addresses: user.addresses});
  };

  return (
    <div className="user-register-take-order">
      <div className="form-register">
          <Row gutter={[20, 20]}>
            <Col xs={8}>
              <span className="label">Nombre</span>
              <Input size="large" className="input" value={user.name} readOnly />
            </Col>
            <Col xs={8}>
              <span className="label">Apellido</span>
              <Input size="large" className="input" value={user.lastname} readOnly />
            </Col>
            <Col xs={8}>
              <span className="label">Correo electrónico</span>
              <Input size="large" className="input" value={user.email} readOnly />
            </Col>
            <Col xs={8}>
              <span className="label">Teléfono casa</span>
              <Input size="large" className="input" value={user.phone} readOnly />
            </Col>
            <Col xs={8}>
              <span className="label">Teléfono móvil</span>
              <Input size="large" className="input" value={user.mobile} readOnly />
            </Col>
            {!roleTuNicaragua && (
              <Col xs={8}>
                <span className="label">Ordenes para free delivery</span>
                <Input
                  size="large"
                  className="input"
                  value={user.loyalty_orders === 5 ? 'Free Delivery' : 5 - user.loyalty_orders}
                  readOnly
                />
              </Col>
            )}
            {!roleTuNicaragua && (
              <Col xs={8}>
                <span className="label">Crédito disponible</span>
                <Input
                  size="large"
                  className="input"
                  value={getCurrency(country, user.credit || 0)}
                  readOnly
                />
              </Col>
            )}
            {/* <Col xs={8}>
              <Form.Item
                label={<span className="label">Crédito del cliente</span>}
                name="credit"
                colon={false}
                rules={[{ required: false }]}
              >
                <Input size="large" className="input" />
              </Form.Item>
            </Col> */}
          </Row>
          <Row>
            <Col xs={24}>
              <Input.TextArea
                className="input input-notes"
                placeholder="Notas internas del cliente"
                size="large"
                style={{marginTop: 15}}
                rows={2}
                readOnly
                value={user.notes}
              />
            </Col>
          </Row>
      </div>
      <div className="actions">
        <Button className="submit" onClick={editUser}>
          Editar
        </Button>
      </div>
    </div>
  );
};

export default ClientEditor;