import { createSlice } from '@reduxjs/toolkit';
import {
  getInitialCash,
  createInitialCash,
  getCashAvailable,
  getCashierOrders,
  closeCashier,
  editCashierRecord,
  getHistory,
  getDetail,
} from './Api'

// Reducer
export const cashflowSlice = createSlice({
  name: 'cashflow',
  initialState: {
    orders: [],
    initialCash: {
      value: '',
      created: false,
    },
    cashAvailable: 0,
    history: [],
    detail: {},
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setCashAvailable: (state, action) => {
      state.cashAvailable = action.payload;
    },
    setInitialCashValue: (state, action) => {
      state.initialCash.value = action.payload;
    },
    setInitialCashCreated: (state, action) => {
      state.initialCash.created = action.payload;
    },
    replaceRecord: (state, action) => {
      const {index, record} = action.payload;
      state.orders[index] = record;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
});


//Actions

export const {
  setOrders,
  setInitialCashValue,
  setInitialCashCreated,
  setCashAvailable,
  replaceRecord,
  setHistory,
  setDetail,
} = cashflowSlice.actions;

export const getOrders = () => async dispatch => {
  try {
    const response = await getCashierOrders();
    if (!response.error && response.status === 200) {
      const orders = response.data.orders;
      const drivers = [];
      const partners = [];
      dispatch(setOrders(orders));
      orders.map(order => {
        if (order.id_driver) {
          const index = drivers.findIndex(el => el.value === order.id_driver);
          if (index < 0) {
            drivers.push({
              text: `${order.driver_name} ${order.driver_lastname}`,
              value: order.id_driver,
            });
          }
        }
        const indexPartner = partners.findIndex(el => el.value === order.id_partner);
        if (indexPartner < 0) {
          partners.push({
            text: order.partner_name,
            value: order.id_partner,
          });
        }
      });
      return {
        status: 'success',
        drivers,
        partners,
        orders,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getDailyCash = () => async dispatch => {
  try {
    const response = await getInitialCash();
    if (!response.error && response.status === 200) {
      const {record} = response.data
      if (record) {
        dispatch(setInitialCashValue(record.initial_cash_balance));
        dispatch(setInitialCashCreated(true));
      }
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getCash = () => async dispatch => {
  try {
    const response = await getCashAvailable();
    if (!response.error && response.status === 200) {
      const {cash_available} = response.data
      dispatch(setCashAvailable(cash_available));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const setInitialCash = amount => async dispatch => {
  try {
    const data = {
      initial_cash: amount,
      role: 'agent',
    };
    const response = await createInitialCash(data);
    if (!response.error && response.status === 200) {
      dispatch(setInitialCashCreated(true));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const reviewAndCloseCashier = info => async dispatch => {
  try {
    const response = await closeCashier(info);
    if (!response.error && response.status === 200) {
      if (info.done === 'true') {
        dispatch(setInitialCashCreated(false));
        dispatch(setCashAvailable(0));
        dispatch(setInitialCashValue(0));
        dispatch(setOrders([]));
      }
      return {
        status: 'success',
        summary: response.data.record,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const editRecord = (data, index) => async dispatch => {
  try {
    const response = await editCashierRecord(data);
    if (!response.error && response.status === 200) {
      dispatch(replaceRecord({
        index,
        record: response.data.cashier_record[0],
      }));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getCashierCloseHistory = config => async dispatch => {
  try {
    const response = await getHistory(config);
    if (!response.error && response.status === 200) {
      dispatch(setHistory(response.data.records));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getCashierCloseDetail = id => async dispatch => {
  try {
    const response = await getDetail(id);
    if (!response.error && response.status === 200) {
      const {cashierClose, transactions} = response.data;
      const drivers = [];
      const partners = [];
      dispatch(setDetail({
        summary: cashierClose[0],
        orders: transactions,
      }));
      transactions.map(order => {
        if (order.id_driver) {
          const index = drivers.findIndex(el => el.value === order.id_driver);
          if (index < 0) {
            drivers.push({
              text: `${order.driver_name} ${order.driver_lastname}`,
              value: order.id_driver,
            });
          }
        }
        const indexPartner = partners.findIndex(el => el.value === order.id_partner);
        if (indexPartner < 0) {
          partners.push({
            text: order.partner_name,
            value: order.id_partner,
          });
        }
      });
      return {
        status: 'success',
        drivers,
        partners,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectOrders = state => state.cashflow.orders;
export const selectDetail = state => state.cashflow.detail;
export const selectHistory = state => state.cashflow.history;
export const selectInitialCash = state => state.cashflow.initialCash;
export const selectCashAvailable = state => state.cashflow.cashAvailable;

export default cashflowSlice.reducer;
