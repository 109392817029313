import {DatePicker, Spin, Select, Row, Col, Button} from 'antd';
import History from './Orders';
import '../styles/ReportsUI.scss';

// const {Search} = Input;
const {RangePicker} = DatePicker;

const ReportsUI = (props) => {
  const {
    orders,
    country,
    today,
    beginDate,
    endDate,
    loading,
    handleDates,
    // orderSearch,
    loadingPartners,
    partners,
    selectPartner,
    exporting,
    exportData,
    deselectPartner,
  } = props;

  return (
    <div className="reports-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Reportes</h1>
          <h3 className="subtitle">
            {today.format('dddd D [de] MMMM')}
          </h3>
        </div>
        <div className="number-orders">
          <div className="number-data">
            <h1 className="title">{orders.length}</h1>
            <h3 className="subtitle">Ordenes</h3>
          </div>
        </div>
      </div>
      <div className="filters-wrap actions">
        <Row gutter={20}>
          {/* <Col xs={6}>
            <Search
              placeholder="Buscar por ticket"
              allowClear
              enterButton
              size="large"
              loading={loading}
              onSearch={orderSearch}
            />
          </Col> */}
          <Col xs={6}>
            <RangePicker
              disabled={loading}
              size="large"
              placeholder={['Fecha Inicio', 'Fecha Final']}
              allowClear={false}
              value={[beginDate, endDate]}
              onChange={handleDates}
            />
          </Col>
          <Col xs={6}>
            <Select
              mode="multiple"
              size="large"
              placeholder="Seleccionar Comercios"
              defaultValue={[]}
              onChange={selectPartner}
              style={{ width: '100%' }}
              loading={loadingPartners || loading}
              disabled={loadingPartners || loading}
              onDeselect={deselectPartner}
              maxTagCount="responsive"
              options={partners}
            />
          </Col>
          <Col xs={6} offset={6}>
            <Button
              className="export-btn"
              size="large"
              loading={exporting}
              onClick={exportData}
              type="primary">
              Exportar
            </Button>
          </Col>
        </Row>
      </div>
      {loading ? (
        <div className="loader-wrap">
          <Spin size="large" />
        </div>
      ) : (
        <History orders={orders} country={country} />
      )}
    </div>
  );
};

export default ReportsUI;
