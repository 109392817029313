import { useState, useRef } from "react";
import { Table, Input, Button, Space } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { selectCountry, selectUser } from "../pages/login/UserSlice";
import { getCurrency } from "../utils/format";
import InputCashier from "./InputCashier";

const TableOrders = (props) => {
  const [searchText, handleSearchText] = useState("");
  const [searchedColumn, handleSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const {
    orders,
    partners,
    editRecordCashier,
    drivers,
    filterTable,
    settlement,
  } = props;
  const user = useSelector(selectUser);
  const country = useSelector(selectCountry);
  const isAdmin = user.role === 10;

  const getCurrencyCountry = (val) => getCurrency(country, val);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    handleSearchText(selectedKeys[0]);
    handleSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    handleSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={"Buscar por ticket"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /*  const cols = [
    {
      title: settlement ? "Fecha" : "Hora",
      dataIndex: "date",
      width: settlement ? 200 : 100,
      align: "center",
      render: (date) => (
        <span className="normal">
          {moment(date).format(settlement ? "DD/MM/YYYY hh:mm a" : "hh:mm a")}
        </span>
      ),
    },
    {
      title: "Ticket",
      dataIndex: "ticket",
      width: 100,
      align: "center",
      ...getColumnSearchProps("ticket"),
    },
    {
      title: "Driver",
      dataIndex: "driver_name",
      width: 150,
      align: "center",
      filters: drivers,
      onFilter: (value, record) => record.id_driver === value,
      filterSearch: true,
      render: (name, order) => (
        <span className="normal">
          {name ? `${name} ${order.driver_lastname}` : "-"}
        </span>
      ),
    },
    {
      title: "Zona",
      dataIndex: "coverage",
      width: 150,
      align: "center",
      render: (coverage) => <span className="normal">{coverage}</span>,
    },
    {
      title: "Fee Zona",
      dataIndex: "delivery_fee_main",
      width: 100,
      align: "center",
      render: (val, record) => getCurrencyCountry(val * record.conversion_rate),
    },
    {
      title: "O",
      dataIndex: "platform",
      align: "center",
      width: 80,
      render: (val) => {
        if (val === "app") {
          return <i className="bi bi-phone blue icon-o" />;
        }
        return <i className="bi bi-headset red icon-o" />;
      },
    },
    {
      title: "Subtotal",
      dataIndex: "order_subtotal",
      width: 100,
      align: "center",
      render: (val) => getCurrencyCountry(val),
    },
    {
      title: "Delivery",
      dataIndex: "delivery_fee",
      width: 100,
      align: "center",
      render: (val) => getCurrencyCountry(val),
    },
    {
      title: "Total",
      dataIndex: "order_total",
      width: 100,
      align: "center",
      render: (val) => getCurrencyCountry(val),
    },
    {
      title: "Efectivo",
      dataIndex: "cash",
      width: 130,
      align: "center",
      render: (val, record, index) =>
        record.card === 0 && editRecordCashier && record.get_driver === 1 ? (
          <InputCashier
            val={val}
            action={editRecordCashier}
            record={record}
            index={index}
          />
        ) : (
          <span className="teal">{getCurrencyCountry(val)}</span>
        ),
    },
    {
      title: "Tarjeta",
      dataIndex: "card",
      width: 100,
      align: "center",
      render: (val) => <span className="teal">{getCurrencyCountry(val)}</span>,
    },
    {
      title: "Crédito",
      dataIndex: "credit",
      width: 100,
      align: "center",
      render: (val) => <span className="teal">{getCurrencyCountry(val)}</span>,
    },
    {
      title: "Pago Com",
      dataIndex: "repay",
      width: 100,
      align: "center",
      render: (val) => <span className="red">{getCurrencyCountry(val)}</span>,
    },
    {
      title: "Cambio",
      dataIndex: "change",
      width: 100,
      align: "center",
      render: (val) => <span className="red">{getCurrencyCountry(val)}</span>,
    },
    {
      title: settlement ? "% Fee Get" : "Comercio",
      dataIndex: "id_partner",
      width: settlement ? 100 : 200,
      align: "center",
      filters: partners || false,
      onFilter: (value, record) => record.id_partner === value,
      filterSearch: true,
      render: (val, order) => (
        <span className="yellow">
          {`${settlement ? "" : order.partner_name}
          ${
            isAdmin ? ` (${Math.round(order.get_fee_percentage * 100)}%)` : ""
          }`}
        </span>
      ),
    },
    {
      title: "Tipo",
      dataIndex: "payment_type",
      width: 100,
      align: "center",
      render: (type) => (
        <span className="yellow">{!!type ? "Crédito" : "Contado"}</span>
      ),
    },
    {
      title: "P. Driver",
      dataIndex: "driver_payment",
      width: 100,
      align: "center",
      render: (val) => <span className="red">{getCurrencyCountry(val)}</span>,
    },
    {
      title: "Ver",
      dataIndex: "id_order",
      fixed: "right",
      width: 100,
      align: "center",
      render: (val) => (
        <Link className="order-detail" to={`/orders/${val}`}>
          <span>Detalle</span>
        </Link>
      ),
    },
  ];

  if (isAdmin) {
    cols.splice(12, 0, {
      title: "Fee Get",
      dataIndex: "get_fee",
      width: 100,
      align: "center",
      render: (val) => <span className="blue">{getCurrencyCountry(val)}</span>,
    });
    cols.splice(18, 0, {
      title: "Fee Banco",
      dataIndex: "bank_fee",
      width: 100,
      align: "center",
      render: (val, order) => (
        <span className="red">
          {`${getCurrencyCountry(val)} (${(
            order.bank_fee_percentage * 100
          ).toFixed(2)}%)`}
        </span>
      ),
    });
    cols.splice(19, 0, {
      title: "IVA Comisión",
      dataIndex: "bank_fee_iva",
      width: 100,
      align: "center",
      render: (val) => <span className="red">{getCurrencyCountry(val)}</span>,
    });
    cols.splice(20, 0, {
      title: "2% Adelanto",
      dataIndex: "tax_advance",
      width: 100,
      align: "center",
      render: (val) => <span className="red">{getCurrencyCountry(val)}</span>,
    });
    cols.splice(21, 0, {
      title: "Extra",
      dataIndex: "bank_fee_extra",
      width: 100,
      align: "center",
      render: (val) => <span className="red">{getCurrencyCountry(val)}</span>,
    });
    cols.splice(22, 0, {
      title: "Cash Out",
      dataIndex: "cash_out",
      width: 100,
      align: "center",
      render: (val) => <span className="red">{getCurrencyCountry(val)}</span>,
    });
    cols.splice(23, 0, {
      title: "Cash In",
      dataIndex: "cash_in",
      width: 100,
      align: "center",
      render: (val) => <span className="teal">{getCurrencyCountry(val)}</span>,
    });
    cols.splice(24, 0, {
      title: "Balance Driver",
      dataIndex: "driver_balance_withdrawal",
      width: 150,
      align: "center",
      render: (val) =>
        val ? <span className="teal">{getCurrencyCountry(val)}</span> : "-",
    });
    cols.splice(25, 0, {
      title: "% Incremento",
      dataIndex: "price_percentage_increase",
      width: 150,
      align: "center",
      render: (val) =>
        val ? <span className="teal">{`${val * 100}%`}</span> : "-",
    });
    cols.splice(26, 0, {
      title: "Incremento",
      dataIndex: "price_increase",
      width: 150,
      align: "center",
      render: (val) =>
        val ? <span className="teal">{getCurrencyCountry(val)}</span> : "-",
    });
    cols.splice(27, 0, {
      title: "Utilidad",
      dataIndex: "profits",
      width: 100,
      align: "center",
      render: (val) => <span className="blue">{getCurrencyCountry(val)}</span>,
    });
  }  */
  const newCols = [
    {
      title: settlement ? "Fecha" : "Hora",
      dataIndex: "date",
      width: settlement ? 200 : 100,
      align: "center",
      render: (date) => (
        <span className="normal">
          {moment(date).format(settlement ? "DD/MM/YYYY hh:mm a" : "hh:mm a")}
        </span>
      ),
    },
    {
      title: "Ticket",
      dataIndex: "ticket",
      width: 100,
      align: "center",
      ...getColumnSearchProps("ticket"),
    },
    {
      title: "Driver",
      dataIndex: "driver_name",
      width: 150,
      align: "center",
      filters: drivers,
      onFilter: (value, record) => record.id_driver === value,
      filterSearch: true,
      render: (name, order) => (
        <span className="normal">
          {name ? `${name} ${order.driver_lastname}` : "-"}
        </span>
      ),
    },
    {
      title: "Zona",
      dataIndex: "coverage",
      width: 150,
      align: "center",
      render: (coverage) => <span className="normal">{coverage}</span>,
    },
    {
      title: "Total",
      dataIndex: "order_total",
      width: 100,
      align: "center",
      render: (val) => getCurrencyCountry(val),
    },
    {
      title: "Efectivo",
      dataIndex: "cash",
      width: 130,
      align: "center",
      render: (val, record, index) =>
        record.card === 0 && editRecordCashier && record.get_driver === 1 ? (
          <InputCashier
            val={val}
            action={editRecordCashier}
            record={record}
            index={index}
          />
        ) : (
          <span className="teal">{getCurrencyCountry(val)}</span>
        ),
    },
    {
      title: "Cambio",
      dataIndex: "change",
      width: 100,
      align: "center",
      render: (val) => <span className="red">{getCurrencyCountry(val)}</span>,
    },
    {
      title: settlement ? "% Fee Get" : "Comercio",
      dataIndex: "id_partner",
      width: settlement ? 100 : 200,
      align: "center",
      filters: partners || false,
      onFilter: (value, record) => record.id_partner === value,
      filterSearch: true,
      render: (val, order) => (
        <span className="yellow">
          {`${settlement ? "" : order.partner_name}
          ${
            isAdmin ? ` (${Math.round(order.get_fee_percentage * 100)}%)` : ""
          }`}
        </span>
      ),
    },
    {
      title: "Ver",
      dataIndex: "id_order",
      fixed: "right",
      width: 100,
      align: "center",
      render: (val) => (
        <Link className="order-detail" to={`/orders/${val}`}>
          <span>Detalle</span>
        </Link>
      ),
    },
  ]

if (isAdmin) {
  
  newCols.splice(8, 0, {
      title: "Cash Out",
      dataIndex: "cash_out",
      width: 100,
      align: "center",
      render: (val) => <span className="red">{getCurrencyCountry(val)}</span>,
    });
    newCols.splice(9, 0, {
      title: "Cash In",
      dataIndex: "cash_in",
      width: 100,
      align: "center",
      render: (val) => <span className="teal">{getCurrencyCountry(val)}</span>,
    });
   
    newCols.splice(10, 0, {
      title: "% Incremento",
      dataIndex: "price_percentage_increase",
      width: 150,
      align: "center",
      render: (val) =>
        val ? <span className="teal">{`${val * 100}%`}</span> : "-",
    });
    newCols.splice(11, 0, {
      title: "Incremento",
      dataIndex: "price_increase",
      width: 150,
      align: "center",
      render: (val) =>
        val ? <span className="teal">{getCurrencyCountry(val)}</span> : "-",
    });
    newCols.splice(12, 0, {
      title: "Utilidad",
      dataIndex: "profits",
      width: 100,
      align: "center",
      render: (val) => <span className="blue">{getCurrencyCountry(val)}</span>,
    });
  }
  return (
    <Table
      className="table-orders"
      scroll={{ x: 1500 }}
      dataSource={orders}
      rowKey="id"
      onChange={filterTable}
      columns={newCols}
    />
  );
};

export default TableOrders;
