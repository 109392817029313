import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {
  getOrdersMonitor,
  getOrderProducts,
  getBranches,
  toPartner,
  getDrivers,
  acceptOrder,
  assignDriver,
  dispatchOrder,
  startDelivery,
  finishDelivery,
  cancelOrder,
} from './Api'

// Reducer

export const monitorSlice = createSlice({
  name: 'monitor',
  initialState: {
    orders: [],
    orderDetail: {},
    mode: 'all',
  },
  reducers: {
    setMonitorMode: (state, action) => {
      state.mode = action.payload;
    },
    setOrdersMonitor: (state, action) => {
      state.orders = action.payload;
    },
    setAcceptedTime: (state, action) => {
      state.orderDetail.partner_acepted_time = action.payload;
    },
    setDispatchedTime: (state, action) => {
      state.orderDetail.partner_dispatched_time = action.payload;
    },
    setOrderDetail: (state, action) => {
      state.orderDetail = action.payload;
    },
    setOrderBranch: (state, action) => {
      const {order, branchInfo} = action.payload;
      const index = state.orders.findIndex((el) => el.id === order);
      if (index > -1) {
        const newOrders = state.orders;
        newOrders[index].branch = branchInfo;
        state.orders = newOrders;
      }
      state.orderDetail.branch = branchInfo;
    },
    newOrder: (state, action) => {
      state.orders.push(action.payload);
    },
    setOrderStatus: (state, action) => {
      const {id, status} = action.payload;
      const index = state.orders.findIndex((el) => el.id === id);
      if (index > -1) {
        const newOrders = state.orders;
        newOrders[index].status = status;
        state.orders = newOrders;
      }
      state.orderDetail.status = status;
    },
    setOrderDriver: (state, action) => {
      const {id, driver} = action.payload;
      const index = state.orders.findIndex((el) => el.id === id);
      if (index > -1) {
        const newOrders = state.orders;
        newOrders[index].provisional_driver = driver;
        state.orders = newOrders;
      }
      state.orderDetail.provisional_driver = driver;
    },
    finishOrder: (state, action) => {
      const index = state.orders.findIndex((el) => el.id === action.payload);
      if (index > -1) {
        const newOrders = state.orders;
        newOrders.splice(index, 1);
        state.orders = newOrders;
      }
    },
  },
});


//Actions

export const {
  setOrdersMonitor,
  setOrderDetail,
  setOrderStatus,
  setAcceptedTime,
  setDispatchedTime,
  setOrderDriver,
  finishOrder,
  setMonitorMode,
  newOrder,
  setOrderBranch,
} = monitorSlice.actions;

export const getOrders = (role) => async dispatch => {
  try {
    const response = await getOrdersMonitor(role);
    if (!response.error && response.status === 200) {
      const {orders} = response.data;
      dispatch(setOrdersMonitor(orders));
      /* console.log('dsadas')
      console.log(detail)
      if (detail) {
        const index = response.orders.findIndex((el) => el.id === detail);
        console.log('index')
        console.log(index)
        if (index > -1) {
          dispatch(setOrderDetail(response.orders[index]));
        }
      } */
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getProducts = (order) => async dispatch => {
  try {
    const response = await getOrderProducts(order);
    if (!response.error && response.status === 200) {
      const {order} = response.data;
      return {
        status: 'success',
        order,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getBranchesOrder = (order) => async dispatch => {
  try {
    const response = await getBranches(order);
    if (!response.error && response.status === 200) {
      const {branches} = response.data;
      return {
        status: 'success',
        branches,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getDriversOrder = (order) => async dispatch => {
  try {
    const response = await getDrivers(order);
    if (!response.error && response.status === 200) {
      const {drivers} = response.data;
      return {
        status: 'success',
        drivers,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const sendToPartner = (order, branch, user, branchInfo) => async dispatch => {
  try {
    const response = await toPartner(order, branch, user);
    if (!response.error && response.status === 200) {
      if (response.data.order) {
        dispatch(setOrderStatus({
          id: order,
          status: response.data.order.status,
        }));
        if (branchInfo) {
          dispatch(setOrderBranch({order, branchInfo}));
        }
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const acceptOrderBranch = (order, branch, time) => async dispatch => {
  try {
    const response = await acceptOrder(order, branch, time);
    if (!response.error && response.status === 200) {
      if (response.data.order) {
        dispatch(setOrderStatus({
          id: order,
          status: response.data.order.status,
        }));
        dispatch(setAcceptedTime(
          moment.tz('America/El_Salvador').format()
        ));
        return {
          status: 'success',
          cireba: response.data.order.cireba || false,
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const sendToDriver = (order, driver) => async dispatch => {
  try {
    const response = await assignDriver(order, driver);
    if (!response.error && response.status === 200) {
      if (response.data.order) {
        dispatch(setOrderStatus({
          id: order,
          status: response.data.order.status,
        }));
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const dispatchOrderBranch = (order) => async dispatch => {
  try {
    const response = await dispatchOrder(order);
    if (!response.error && response.status === 200) {
      if (response.data.order) {
        dispatch(setOrderStatus({
          id: order,
          status: response.data.order.status,
        }));
        dispatch(setDispatchedTime(
          moment.tz('America/El_Salvador').format()
        ));
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const deliveryStarted = (order, driver) => async dispatch => {
  try {
    const response = await startDelivery(order, driver);
    if (!response.error && response.status === 200) {
      if (response.data.order) {
        dispatch(setOrderStatus({
          id: order,
          status: response.data.order.status,
        }));
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const completeOrder = (order, driver) => async dispatch => {
  try {
    const response = await finishDelivery(order, driver);
    if (!response.error && response.status === 200) {
      if (response.data.order) {
        dispatch(finishOrder(order));
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const cancelOrderMonitor = (order, reason, comments, user) => async dispatch => {
  try {
    const response = await cancelOrder(order, reason, comments, user);
    if (!response.error && response.status === 200) {
      if (response.data.order) {
        dispatch(finishOrder(order));
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}



export const selectOrders = state => state.monitor.orders;
export const selectOrderDetail = state => state.monitor.orderDetail;
export const selectMode = state => state.monitor.mode;

export default monitorSlice.reducer;
