import { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';

const Timer = ({begin}) => {
  const [time, handleTime] = useState('00:00:00');
  useEffect(() => {
    const getTime = () => {
      const today = moment.tz('America/El_Salvador');
      const order = moment.tz(begin, 'America/El_Salvador');
      const newTime = today.subtract(order).add(42, 'seconds');
      const getHours = () => {
        const days = (newTime.days() - 4) *24;
        const hours = newTime.hours() + 6 + days;
        if (hours < 10) {
          return `0${hours}`
        }
        return hours;
      };
      handleTime(
        `${getHours()}:${
              (newTime.minutes() < 10) ?
              `0${newTime.minutes()}` :
              newTime.minutes()}:${
                (newTime.seconds() < 10) ?
                `0${newTime.seconds()}` :
                newTime.seconds()
        }`
      );
    };

    const countup = setInterval(getTime, 1000);

    return () => {
      clearInterval(countup);
    };
  });

  return <span>{time}</span>;
};

export default Timer;
