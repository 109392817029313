import { Table, Button } from "antd";
import { getCurrency } from "../../../utils/format";
import InputBonus from "../../../components/InputCashier";
import moment from "moment";

const Drivers = ({
  settlements,
  country,
  openModal,
  driversUsed,
  editBonus,
}) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Getter",
      dataIndex: "id_driver",
      filters: driversUsed || false,
      onFilter: (value, record) => record.id_driver.id === value,
      filterSearch: true,
      render: (val) => `${val.name} ${val.lastname}`,
    },
    {
      title: "Fecha Apertura",
      dataIndex: "opening_date",
      render: (val) => {
        return moment(val).format("DD/MM/YYYY, HH:mm a");
      },
    },
    {
      title: "Apertura",
      dataIndex: "initial_cash_balance",
      align: "center",
      render: (val) => getCurrency(country, val),
    },
    {
      title: "Flotante",
      dataIndex: "floating",
      align: "center",
      render: (val) => (val ? getCurrency(country, val) : "-"),
    },
    {
      title: "Bono",
      dataIndex: "bonus",
      align: "center",
      width: 140,
      render: (val, record, index) =>
        !record.closing_date ? (
          <InputBonus
            val={val}
            action={editBonus}
            record={record}
            index={index}
            bonus
          />
        ) : (
          getCurrency(country, val)
        ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      align: "center",
      render: (val, record) =>
        getCurrency(country, val - record.bonus + (record.floating || 0)),
    },
    {
      title: "Pago Driver",
      dataIndex: "driver_payment",
      align: "center",
      render: (val) => (val === 0 ? "-" : getCurrency(country, val)),
    },
    {
      title: "A Remesar",
      dataIndex: "closing_cash_balance",
      align: "center",
      render: (val) => (val === 0 ? "-" : getCurrency(country, val)),
    },
    {
      title: "Faltante",
      dataIndex: "missing",
      align: "center",
      render: (val) => (!val ? "-" : getCurrency(country, val)),
    },
    {
      title: "Sobregiro",
      dataIndex: "surplus",
      align: "center",
      render: (val) => (!val ? "-" : getCurrency(country, val)),
    },
    {
      title: "Fecha Cierre",
      dataIndex: "closing_date",
      render: (val) => {
        return val ? moment(val).format("DD/MM/YYYY, HH:mm a") : "-";
      },
    },
    {
      title: "No Referencia",
      dataIndex: "codeRef",
      render: (val) => val || "-",
    },
    {
      title: "Comentarios",
      dataIndex: "comments",
      render: (val) => val || "-",
    },
    {
      title: "Ordenes",
      dataIndex: "transactions",
      align: "center",
    },
    {
      title: "Cerrar",
      dataIndex: "id",
      align: "center",
      filters: [
        {
          text: "Completados",
          value: true,
        },
        {
          text: "Sin Cerrar",
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => !!record.closing_date === value,
      render: (val, record) =>
        !record.closing_date && (
          <Button onClick={() => openModal(record)} type="primary">
            Cerrar
          </Button>
        ),
    },
  ];

  return (
    <Table
      rowKey="id"
      scroll={{ x: 2000, y: 600 }}
      dataSource={settlements}
      columns={columns}
    />
  );
};

export default Drivers;
