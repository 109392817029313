export const getCurrency = (country, price, dollars = false) => {
  if (dollars && (dollars === 1 || dollars === '1')) {
    return `$ ${parseFloat(price).toFixed(2)}`;
  }
  switch (country) {
    case 'ni': {
      return `C$ ${parseFloat(price).toFixed(2)}`;
    }
    default: {
      return `$ ${parseFloat(price).toFixed(2)}`;
    }
  }
};

export const slugify = (str) => {
  const map = {
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };

  str = str.toLowerCase();

  for (let pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  }

  return str;
}
