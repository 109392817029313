import '../styles/FeedSortUI.scss';
import { Row, Col, Typography, Form, DatePicker, TimePicker, Checkbox, Switch, Input, Divider, Button, Table, Spin  } from 'antd';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

import moment from 'moment';

const { Title } = Typography;
const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const columns_categories = [
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    title: 'Categoría',
    dataIndex: 'name',
    className: 'drag-visible',
    render: (value, record) => (<><img style={{width:'30px'}} src={record.image} /> {value}</>),
  },
];

const columns_partners = [
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    title: 'Comercio',
    dataIndex: 'name',
    className: 'drag-visible',
    width: '300px',
    render: (value, record) => (<><img style={{width:'30px', borderRadius: '100px', marginRight: '10px'}} src={record.logo} /> {value}</>),
  },
  // {
  //   title: 'Descripción',
  //   dataIndex: 'description',
  //   className: 'drag-visible',Sending Mail for Order
  // },
  {
    title: 'Get Fee',
    dataIndex: 'fee',
    className: 'drag-visible',
  },
];

const options = [
  { label: 'Lun', value: 'Mon' },
  { label: 'Mar', value: 'Tue' },
  { label: 'Mié', value: 'Wed' },
  { label: 'Jue', value: 'Thu' },
  { label: 'Vie', value: 'Fri' },
  { label: 'Sáb', value: 'Sat' },
  { label: 'Dom', value: 'Sun' },
];

const SortableItem = sortableElement(props => <tr style={{width:'30px'}} {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const FeedSortDetailUI = ({loading, loadingActive, name, handleName, activation, dtSort, categories, partners, handledtSort, handleCategories, handlePartners, startDate, handleStartDate, endDate, handleEndDate, startTime, handleStartTime, endTime, handleEndTime, days, handleDays, active, handleActive, banner, handleBanner, deleteSort}) => {

  ///////////////// Sort
  const onSortEnd = ({ oldIndex, newIndex }, type) => {
    var arr = [];

    if (oldIndex !== newIndex) {
      if (type === 1) {
        arr = categories;
      } else {
        arr = partners;
      }

      const newData = arrayMoveImmutable([].concat(arr), oldIndex, newIndex).filter(el => !!el);
      if (type === 1) {
        handleCategories(newData);
      } else {
        handlePartners(newData);
      }
    }
  }
  
  const DraggableContainer = (props, type) => {
    return (
        <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={(e) => onSortEnd(e, type)}
        {...props}
      />
    )
  };
  
  const DraggableBodyRow = ({ className, style, ...restProps }, type) => {
    // function findIndex base on Table rowKey props and should always be a right array index

    var arr = [];

    if (type === 1) {
      arr = categories;
    } else {
      arr = partners;
    }

    const index = arr.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  ///////////////// Sort

  const [form] = Form.useForm();

  form.setFieldsValue({
    dates: [startDate, endDate],
    start_time: startTime,
    end_time: endTime,
  });

  // onChange Active Switch
  const activeOnChange = (status) => {
    console.log(status);
    handleActive(status);
  }

  // onChange Days
  const daysOnChange = (checkedValues) => {
    handleDays(checkedValues);
  }

  const datesOnChange = (values) => {
    handleStartDate(values[0]);
    handleEndDate(values[1]);
  }

  const startTimeOnChange = (value) => {
    handleStartTime(value);
  }

  const endTimeOnChange = (value) => {
    handleEndTime(value);
  }

  const bannerOnChange = (e) => {
    handleBanner(e.target.value);
  }

  const nameOnChange = (e) => {
    handleName(e);
  }

  // const onFinish = (values) => {

  // };

  if (loading) {
    return (
      <div className="loader-wrap">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="FeedSortUI">
      <div className="partners-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title"><Paragraph editable={{ onChange: nameOnChange }}>{name}</Paragraph></h1>
        </div>
      </div>
      <Form form={form} name="dtsort_form" layout="vertical">
      <Row gutter={16}>
        <Col flex={3}>
          <div className="form">
            <Title level={5}>Información de orden programado</Title>
            <Divider orientation="left"></Divider>
              <Row gutter={32}>
                <Col>
                  <Form.Item name="dates" label="Rango de Fechas">
                    <RangePicker placeholder={['Fecha Inicio', 'Fecha Final']} onChange={datesOnChange}/>
                  </Form.Item>
                </Col>
                <Col>
                  <Row>
                    <label>Seleccionar días</label>
                  </Row>
                  <Row>
                    <Col>
                      <div className="checks">
                        <Checkbox.Group value={days} options={options} onChange={daysOnChange}/>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col>
                  <Form.Item name="start_time" label="Hora Inicial">
                    <TimePicker placeholder="Hora Inicial" onChange={startTimeOnChange}/>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="end_time" label="Hora Final">
                    <TimePicker placeholder="Hora Final" onChange={endTimeOnChange}/>
                  </Form.Item>
                </Col>
                <Col>
                  <div className="active-switch">
                    <label>Activo</label>
                    <div>
                      <Switch checked={active} onChange={activeOnChange} />
                    </div>
                  </div>
                </Col>
                <Col flex="auto">
                  <div className="save">
                    <Button style={{marginRight: '10px'}} onClick={deleteSort} danger>
                      Eliminar
                    </Button>
                    <Button loading={loadingActive} onClick={activation} type="primary">
                      Guardar
                    </Button>
                  </div>
                </Col>
              </Row>

          </div>
        </Col>
        <Col flex={3}>
            <div className="banner-form">
              <Row>
                <Col flex="auto">
                  <Form.Item label="Inserte URL de la Imágen">
                      <Input type="text" value={`${banner}`} style={{ width: '100%' }} onChange={bannerOnChange} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex="auto">
                  <div style={{backgroundImage: `url(${banner})`}} className="banner-image">
                  </div>    
                </Col>
              </Row>
            </div>
        </Col>
      </Row>

      <Row gutter={1}>
        <Col>
          <div className="categories" style={{maxWidth: '50'}}>
              <Table
              pagination={false}
              dataSource={categories}
              columns={columns_categories}
              rowKey="index"
              components={{
                body: {
                  wrapper: (e) => DraggableContainer(e, 1),
                  row: (e) => DraggableBodyRow(e, 1),
                },
              }}
            />
          </div>
        </Col>
        <Col flex={'auto'}>
          <div className="categories" >
                <Table
                pagination={false}
                dataSource={partners}
                columns={columns_partners}
                rowKey="index"
                components={{
                  body: {
                    wrapper: (e) => DraggableContainer(e, 2),
                    row: (e) => DraggableBodyRow(e, 2),
                  },
                }}
              />
            </div>
        </Col>
      </Row>

      </Form>

      </div>
    </div>
  );
};

export default FeedSortDetailUI;
