import {Row, Col, Spin, Input, Tag, List, Avatar, Rate, Timeline, Modal, message} from 'antd';
import {useHistory, Link} from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';
import 'moment/locale/es';
import 'moment-timezone';
import Products from './Products';
import CancelOrder from './CancelOrder';
import Address from '../../take-orders/components/AddressUI';
import {ReactComponent as Cash} from '../../../assets/methods/cash.svg';
import {ReactComponent as Credit} from '../../../assets/methods/credit.svg';
import {getCurrency} from '../../../utils/format';
import Visa from '../../../assets/methods/visa.png';
import '../styles/OrderDetailUI.scss';

const OrderDetailUI = props => {
  const {
    loading,
    order,
    country,
    tuNicaragua,
    showCancel,
    handleShowCancel,
    revertOrder,
    isAdmin,
  } = props;

  const {
    products,
    partner,
    id_user,
    id_driver,
    id_address,
    ticket,
    id,
    created_time,
    partner_acepted_time,
    partner_dispatched_time,
    driver_delivered_time,
    branch,
    description,
    payment_method,
    subtotal,
    total,
    delivery_fee,
    iva,
    get_fee,
    total_usd,
    cashback,
    change,
    platform,
    tn,
    status,
    reason_for_cancellation,
    comment_for_cancellation,
    extra_description,
    id_agent_cancellation,
    partner_score,
    driver_score,
    serfinsa_payment_logs,
    card_type,
    card_digits,
    version,
    credit,
    id_replaced_order,
  } = order;

  const history = useHistory();

  const getHour = (val) => moment.tz(val, 'America/El_Salvador').format('hh:mm a');

  const getSubtotal = () => {
    const newSubtotal = subtotal || (total - delivery_fee - (iva || 0));
    return tuNicaragua ? getValuePlusIVA(newSubtotal) :  newSubtotal;
  };

  const getPartnerAmount = () => {
    const deliveryFee = (getSubtotal() ) - get_fee;
    return deliveryFee;
  };

  const getValuePlusIVA = (val) => {
    return val * 1.15;
  };

  const verifyRejected = () => {
    let notRefound = true;
    if (serfinsa_payment_logs) {
      serfinsa_payment_logs.forEach(({rejected, response_code}) => {
        if (rejected === 1 && response_code === '12') {
          notRefound = true;
        }
      });
    }
    return notRefound;
  };

  const actionRejection = () => {
    if (!verifyRejected()) {
      message.error('Esta orden ya ha sido revertida');
    } else {
      handleShowCancel(true);
    }
  };

  if (loading) {
    return (
      <div className="loader-wrap">
        <Spin size="large" />
      </div>
    );
  }

  var scores = [];

  if (partner_score.length > 0) {
    scores.push({
      avatar: `${partner.logo}`,
      title: `${partner.name}`,
      description: `${partner_score[0].comment}`,
      count: partner_score[0].score
    });
  }

  if (driver_score.length > 0) {
    scores.push({
      avatar: `${id_driver.photo}`,
      title: `${id_driver.name} ${id_driver.lastname}`,
      description: `${driver_score[0].comment}`,
      count: driver_score[0].score
    });
  }

  return (
    <div className="order-detail-wrap">
      <Row gutter={40}>
        <Col xs={24} sm={24} md={24} lg={16}>
          <div className="main-info">
            <div className="user">
              <span className="label name-client">
                <button
                  onClick={() => history.goBack()}
                  className="back"
                >
                  <i className="bi bi-arrow-left-short" />
                </button>
                {`${id_user.name} ${id_user.lastname}`}
              </span>
              <span className="value id-client">{id_user.id}</span>
            </div>
            <div className="order-main-wrap">
              {[12, 14].includes(status) && (
                <div className="circle">
                  <i className="bi bi-check2" />
                </div>
              )}
              {[8, 15, 16].includes(status) && (
                <div className="circle danger">
                  <i className="bi bi-x" />
                </div>
              )}
              {platform === 'app' ? (
                <i className="bi bi-phone blue" />
              ) : (
                <i className="bi bi-headset red" />
              )}
              <div className="order">
                <span className="label ticket">{ticket}</span>
                <span className="value">{id}</span>
              </div>
            </div>
          </div>
          <div className={cx('client-info', {tuNicaragua})}>
            <div className="principal">
              <div className="item-client">
                <span className="label">CASA</span>
                <span className="value">{id_user.phone}</span>
              </div>
              <div className="item-client">
                <span className="label">TELÉFONO</span>
                <span className="value">{id_user.mobile}</span>
              </div>
              <div className="item-client">
                <span className="label">CORREO</span>
                <span className="value">{id_user.email}</span>
              </div>
              <div className="item-client">
                <span className="label">FECHA</span>
                <span className="value">{moment(created_time).format('DD/MM/YYYY')}</span>
              </div>
            </div>
            {id_driver && (
              <div className="item-client agent">
                <span className="label">DRIVER</span>
                <span className="value">{`${id_driver.name} ${id_driver.lastname}`}</span>
                <span className="id">{id_driver.id}</span>
              </div>
            )}
          </div>
          {[8, 16, 15].includes(status) && (
            <div className="client-info">
              <div className="principal">
                <div className="item-client">
                  <span className="label">MOTIVO</span>
                  <span className="value">{reason_for_cancellation}</span>
                </div>
                {comment_for_cancellation && comment_for_cancellation.length > 0 && (
                  <div className="item-client">
                    <span className="label">COMENTARIO</span>
                    <span className="value">{comment_for_cancellation}</span>
                  </div>
                )}
              </div>
              <div className="item-client agent">
                <span className="label">CANCELADO POR </span>
                <span className="value">{id_agent_cancellation?.username}</span>
                <span className="id">{id_agent_cancellation?.id}</span>
              </div>
            </div>
          )}
          {id_user.notes && (
            <div className="item-client">
              <span className="label">NOTAS DEL CLIENTE</span>
              <span className="value">{id_user.notes}</span>
            </div>
          )}
          {version && (
            <div className="item-client">
              <span className="label">VERSIÓN</span>
              <span className="value">{version}</span>
            </div>
          )}
          {!tuNicaragua && scores.length > 0 && (
            <div className="order_scores">
            <List
              itemLayout="horizontal"
              dataSource={scores}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={`${item.avatar}`} />}
                    title={item.title}
                    description={item.description}
                  />
                  <div><Rate disabled={true} defaultValue={item.count} /></div>
                </List.Item>
              )}
            />
            </div>
          )}
          {!tuNicaragua && (
            <div className="times-wrap">
              <div className="item">
                <span className="label">ORDEN RECIBIDA</span>
                <span className="value">{getHour(created_time)}</span>
              </div>
              <div className="item">
                <span className="label">EN PREPARACIÓN</span>
                <span className="value">{partner_acepted_time ? getHour(partner_acepted_time) : '-'}</span>
              </div>
              <div className="item">
                <span className="label">DESPACHADA</span>
                <span className="value">{partner_dispatched_time ? getHour(partner_dispatched_time) : '-'}</span>
              </div>
              <div className="item">
                <span className="label">COMPLETADA</span>
                <span className="value">{driver_delivered_time ? getHour(driver_delivered_time) : '-'}</span>
              </div>
            </div>
          )}
          <Products
            products={products}
            country={country}
            partner={partner}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <div className="detail-wrap">
            <div className="header-detail">
              Detalle del pedido
            </div>
            <div className="partner-info">
              <div
                className="partner-logo"
                style={{backgroundImage: `url(${partner.logo})`}}
              />
              <div className="partner-name">
                <span className="name">{partner.name}</span>
                <span className="type">{branch.zone}</span>
                {!!(tn) && !tuNicaragua && (
                  <Tag color="blue" className="label-tunicaragua">Pedido Tu Nicaragua</Tag>
                )}
              </div>
              {payment_method === 2 && [15, 8].includes(status) && isAdmin && (
                <button onClick={actionRejection} className="reverse-btn">
                  <i className="bi bi-credit-card" />
                </button>
              )}
            </div>
            <div className="address-wrap">
              <span className="title-address">Dirección de entrega</span>
              <Address address={id_address} selected noAction />
              <div className="notes-wrap">
                <Input.TextArea
                  placeholder="Notas al repartidor"
                  className="notes"
                  size="large"
                  value={description}
                  readOnly
                />
              </div>
              <div className="notes-wrap">
                <Input placeholder="Información extra"
                  className="notes"
                  size="large"
                  value={extra_description}
                  readOnly
                />
              </div>
            </div>
            {!!id_replaced_order && (
              <div className="payment-wrap replace">
                <div className="payment-header">
                  <span>Orden remplazada</span>
                </div>
                <Link to={`/orders/${id_replaced_order}`}>Ver orden anterior</Link>
              </div>
            )}
            <div className="payment-wrap">
              <div className="payment-header">
                <span>Método de pago</span>
              </div>
              <div className="payments">
                {!!credit && (
                  <div className="payment">
                    <Credit width="45px" height="34px" />
                    <div className="payment-info">
                      <div className="texts">
                        <span className="name">Crédito</span>
                        <span className="description">{getCurrency(country, credit)}</span>
                      </div>
                    </div>
                  </div>
                )}
                {payment_method === 1 ? (
                  <div className="payment">
                    <Cash width="45px" height="34px" />
                    <div className="payment-info">
                      <div className="texts">
                        <span className="name">Efectivo</span>
                        <span className="description">{getCurrency(country, total - (credit || 0))}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="payment">
                      <img src={Visa} className="card" alt="" />
                      <div className="payment-info">
                        <div className="texts">
                          <span className="name">{card_type ? card_type : 'Tarjeta'}</span>
                          <span className="description">{card_digits ? `•••• ${card_digits}` : '-'}</span>
                        </div>
                      </div>
                    </div>
                    {serfinsa_payment_logs?.length > 0 && (
                        <div className="card-data">
                        <h4>Serfinsa Logs:</h4>
                        <Timeline>
                          {serfinsa_payment_logs.map((serfinsa) => {
                            return (
                              <Timeline.Item color={`${serfinsa.response_code === '00' ? 'green' : 'red'}`}>{serfinsa.response_code} → <b>{serfinsa.reference_code}</b> - {`${moment(serfinsa.date).format('DD/MM/YYYY hh:mm:ss')}`}</Timeline.Item>
                            )
                          })}
                        </Timeline>
                      </div>
                      )}
                  </div>
                )}
              </div>
            </div>
            <div className="results">
              <div className="item">
                <span>{`Subtotal${tuNicaragua ? ' + IVA' : ''}`}</span>
                <span>
                  {getCurrency(country, getSubtotal())}
                </span>
              </div>
              <div className="item">
                <span>
                  {`Costo de envío${tuNicaragua ? ' + IVA' : ''}`}
                </span>
                <span>
                  {getCurrency(country, tuNicaragua ? getValuePlusIVA(delivery_fee) : delivery_fee)}
                </span>
              </div>
              {country === 'ni' && !tuNicaragua && (
                <div className="item">
                  <span>IVA</span>
                  <span>
                    {getCurrency(country, iva)}
                  </span>
                </div>
              )}
              <div className="item total">
                <span>Total</span>
                <span>{getCurrency(country, total)}</span>
              </div>
              {country === 'ni' && (
                <div className="item total">
                  <span>Total (Dólares)</span>
                  <span>{getCurrency(country, total_usd, 1)}</span>
                </div>
              )}
              {(!tuNicaragua && get_fee > 0) && (
                <div className="item">
                  <span>Entregar a comercio</span>
                  <span>
                    {getCurrency(country, getPartnerAmount())}
                  </span>
                </div>
              )}
              {(payment_method === 1 && !tuNicaragua) && (
                <>
                  <div className="item">
                    <span>Cambio para</span>
                    <span>
                      {getCurrency(country, cashback)}
                    </span>
                  </div>
                  <div className="item">
                    <span>Entregar al cliente</span>
                    <span>
                      {getCurrency(country, change)}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        title="Revertir Pedido"
        destroyOnClose
        visible={showCancel}
        onCancel={() => handleShowCancel(false)}
        footer={false}
      >
        <CancelOrder revertOrder={revertOrder} />
      </Modal>
    </div>
  );
}

export default OrderDetailUI;
