import {useState, useEffect} from 'react';
import {Form, Modal} from 'antd';
import {useDispatch} from 'react-redux';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {createUser, getUserProfile, updateUser} from '../users/UsersSlice';
import {setUserOrder} from '../take-orders/TakeOrdersSlice';
import UsersEditorUI from './components/UserEditorUI';

const UserEditor = () => {
  const [loading, handleLoading] = useState(true);
  const [sending, handleSending] = useState(false);
  const {id} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();

  useEffect(() => {
    const initialFetch =  async () => {
      if (id !== 'new') {
        const response = await dispatch(getUserProfile(id));
        if (response.status !== 'success') {
          Modal.error({
            title: 'Hubo un error',
            content: 'Inténtalo de nuevo más tarde',
          });
          history.push('/users');
        } else {
          handleLoading(false);
          form.setFieldsValue(response.user);
        }
      } else {
        handleLoading(false);
      }
    };
    initialFetch();
  }, [dispatch, form, history, id]);

  const goBack = () => {
    history.goBack();
  };

  const save = async () => {
    handleSending(true);
    try {
      const values = await form.validateFields();
      const newUser = {
        email: values.email,
        name: values.name,
        lastname: values.lastname,
        password: String(values.password),
        phone: values.phone,
        mobile: values.mobile,
        notes: values.notes,
      };
      if (id === 'new') {
        const response = await dispatch(createUser(newUser));
        if (response.status === 'success') {
          if (location.state && location.state.take_order) {
            const userTakeOrder = response.user;
            userTakeOrder.addresses = [];
            dispatch(setUserOrder(userTakeOrder));
            history.push('/take-orders');
          } else {
            Modal.success({
              title: 'Usuario creado',
              content: 'Se ha creado un nuevo usuario',
            });
            history.push(`/users/${response.id}`);
          }
        } else if (response.type === 'email-taken') {
          Modal.error({
            title: 'Email tomado',
            content: 'Este email ya ha sido tomado',
          });
        } else {
          Modal.error({
            title: 'Hubo un error',
            content: 'Inténtalo de nuevo más tarde',
          });
        }
      } else {
        newUser.id_user = id;
        const response = await dispatch(updateUser(newUser));
        if (response.status === 'success') {
          if (location.state && location.state.take_order) {
            const userTakeOrder = response.user;
            userTakeOrder.addresses = location.state.addresses;
            dispatch(setUserOrder(userTakeOrder));
            history.push('/take-orders');
          } else {
            Modal.success({
              title: 'Cambios guardados',
              content: 'Se han guardado los cambios',
            });
          }
        } else if (response.type === 'email-taken') {
          Modal.error({
            title: 'Email tomado',
            content: 'Este email ya ha sido tomado',
          });
        } else {
          Modal.error({
            title: 'Hubo un error',
            content: 'Inténtalo de nuevo más tarde',
          });
        }
      }
    } catch (errorInfo) {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleSending(false);
  };

  return (
    <UsersEditorUI
      id={id}
      loading={loading}
      sending={sending}
      form={form}
      save={save}
      goBack={goBack}
  />);
};

export default UserEditor;
