import {Input} from 'antd';
import cx from 'classnames';
import valid from 'card-validator';
import produce from 'immer';
import {formatCard, formatNumber} from '../utils/Format';
import {ReactComponent as Cash} from '../../../assets/methods/cash.svg';
import Mastercard from '../../../assets/methods/mastercardCard.png';
import Default from '../../../assets/methods/defaultCard.png';
import Visa from '../../../assets/methods/visa.png';
import '../styles/Payment.scss';

const Payment = props => {
  const {
    handleMethod,
    methodOrder,
    handleCashback,
    cashbackOrder,
    tuNicaragua,
    cardInfo,
    setCard,
    country,
  } = props;

  const getImage = () => {
    const type = cardInfo.type;
    if (type === 'visa') {
      return <img src={Visa} className="cardImg" alt="" />;
    } else if (type === 'mastercard') {
      // return <Mastercard width="25px" height="23.64px" className="cardImg" />;
      return <img src={Mastercard} className="cardImg" alt="" />;
    }
    return <img src={Default} className="cardImg" alt="" />;
  };

  const handleCard = async (name, value) => {
    if (name === 'number') {
      const cardNumber = formatCard(value);
      const cardValidated = valid.number(cardNumber);
      const cardType =
        !cardValidated.card ? '' : cardValidated.card.type;
      const newCard = produce(cardInfo, draftState => {
        draftState.number = cardNumber;
        draftState.type = cardType;
      });
      setCard(newCard);
    } else if (name === 'exp_month' || name === 'exp_year') {
      if (formatNumber(value) && value.length <= 2) {
        setCard(name, value);
        const newCard = produce(cardInfo, draftState => {
          draftState[name] = value;
        });
        setCard(newCard);
      }
    } else {
      const newCard = produce(cardInfo, draftState => {
        draftState[name] = value;
      });
      setCard(newCard);
    }
  };

  return (
    <div className="payment-take-order">
      <div className="payment-header">
        <span>Selecciona tu método de pago</span>
      </div>
      <div className="payments">
        <div onClick={() => handleMethod('cash')} className="payment">
          <Cash width="45px" height="34px" />
          <div className="payment-info">
            <div className="texts">
              <span className="name">Efectivo</span>
              <span className="description">{country === 'sv' ? 'USD' : 'Córdobas'}</span>
            </div>
            <div className={cx('check-wrap', {active: methodOrder === 'cash'})}>
              <i className="bi bi-check2" />
            </div>
          </div>
        </div>
        {methodOrder === 'cash' && (
          <div className="cashback">
            <span>Cambio para</span>
            <Input
              className="input"
              size="large"
              placeholder="0.00"
              type="number"
              value={cashbackOrder}
              onChange={(e) => handleCashback(e.target.value)}
            />
          </div>
        )}
        <div onClick={() => handleMethod('card')} className="payment">
          <img src={Visa} className="card" alt="" />
          <div className="payment-info">
            <div className="texts">
              <span className="name">Tarjeta</span>
              <span className="description">Teclado abierto</span>
            </div>
            <div className={cx('check-wrap', {active: methodOrder === 'card'})}>
              <i className="bi bi-check2" />
            </div>
          </div>
        </div>
        {methodOrder === 'card' && !tuNicaragua && (
          <div className="form-card">
            <div className="input-wrap">
              {getImage()}
              <Input
                className="input-form number-card"
                size="large"
                placeholder="Número de tarjeta"
                type="text"
                value={cardInfo.number}
                onChange={(e) => handleCard('number', e.target.value)}
              />
            </div>
            <div className="input-wrap">
              <span className="input-label">Vencimiento</span>
              <div className="horizontal">
                <Input
                  className="input-form"
                  size="large"
                  placeholder="MM"
                  type="text"
                  value={cardInfo.exp_month}
                  onChange={(e) => handleCard('exp_month', e.target.value)}
                />
                <span className="divider">/</span>
                <Input
                  className="input-form"
                  size="large"
                  placeholder="YY"
                  type="text"
                  value={cardInfo.exp_year}
                  onChange={(e) => handleCard('exp_year', e.target.value)}
                />
              </div>
            </div>
            <div className="input-wrap">
              <span className="input-label">Número de Identidad</span>
              <Input
                className="input-form"
                size="large"
                placeholder="00000-0"
                type="text"
                value={cardInfo.dui}
                onChange={(e) => handleCard('dui', e.target.value)}
              />
            </div>
            <div className="input-wrap">
              <span className="input-label">Nombre en la tarjeta</span>
              <Input
                className="input-form"
                size="large"
                placeholder="Nombre del tarjetahabiente"
                type="text"
                value={cardInfo.name}
                onChange={(e) => handleCard('name', e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
