import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrders,
  selectOrders,
  setOrderDetail,
  cancelOrderMonitor,
  setMonitorMode,
  selectMode,
  selectOrderDetail,
} from "./MonitorSlice";
import { Modal } from "antd";
import { selectUser, selectCountry } from "../login/UserSlice";
import MonitorUI from "./components/MonitorUI";

const Monitor = () => {
  const [showDetails, handleShowDetails] = useState(false);
  const [showOrder, handleShowOrder] = useState(false);
  const [showMap, handleShowMap] = useState(false);
  const [showCancel, handleShowCancel] = useState(false);
  const [loading, handleLoading] = useState(true);
  const orderDetail = useSelector(selectOrderDetail);
  const orders = useSelector(selectOrders);
  const mode = useSelector(selectMode);
  // const orderDetail = useSelector(selectOrderDetail);
  const country = useSelector(selectCountry);
  const user = useSelector(selectUser);
  const tuNicaragua = user.role === 3 && country === "ni";
  const dispatch = useDispatch();
  const isDispatch = user.role === 5;

  const initialFetch = useCallback(async () => {
    const response = await dispatch(getOrders(user.role));
    if (response.status === "success") {
    }
    handleLoading(false);
  }, [dispatch, user.role]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  useEffect(() => {
    const countup = setInterval(initialFetch, 30000);

    return () => {
      clearInterval(countup);
    };
    // initialFetch();
  }, [initialFetch]);

  const open = (order) => {
    dispatch(setOrderDetail(order));
    handleShowDetails(true);
  };

  const close = () => {
    handleShowDetails(false);
  };

  const handleMode = (mode) => {
    dispatch(setMonitorMode(mode));
  };

  const cancelOrder = async (reason, comments) => {
    const response = await dispatch(
      cancelOrderMonitor(orderDetail.id, reason, comments, user.id)
    );
    if (response.status === "success") {
      handleShowCancel(false);
      handleShowDetails(false);
      Modal.success({
        title: "Orden cancelada",
        content: "Se ha cancelado la orden exitosamente.",
      });
    } else {
      Modal.error({
        title: "Hubo un problema",
        content: "No se ha podido realizar esta acción. Inténtalo de nuevo.",
      });
    }
  };

  const filterOrders = (newMode) => {
    const realMode = newMode || mode;
    if (realMode === "new") {
      const newOrders = orders.filter(
        (el) => el.status <= 6 || el.status === 13
      );
      return newOrders;
    } else if (realMode === "preparing") {
      const newOrders = orders.filter((el) => el.status >= 7 && el.status <= 9);
      return newOrders;
    } else if (realMode === "delivery") {
      const newOrders = orders.filter(
        (el) => el.status >= 11 && el.status !== 13
      );
      return newOrders;
    }
    return orders;
  };

  return (
    <MonitorUI
      showDetails={showDetails}
      showOrder={showOrder}
      showMap={showMap}
      open={open}
      close={close}
      loading={loading}
      orders={orders}
      filteredOrders={filterOrders()}
      filterOrders={filterOrders}
      handleShowOrder={handleShowOrder}
      handleShowMap={handleShowMap}
      mode={mode}
      handleMode={handleMode}
      tuNicaragua={tuNicaragua}
      isDispatch={isDispatch}
      showCancel={showCancel}
      handleShowCancel={handleShowCancel}
      cancelOrder={cancelOrder}
      country={country}
    />
  );
};

export default Monitor;
