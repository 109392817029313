import { Col, Row, Button, Drawer, Modal, Switch, Select } from 'antd';
import ClientInfo from './ClientInfo';
import ClientEditor from './ClientEditor';
import Partners from './Partners';
import OrderDetail from './OrderDetail';
import Address from './Address';
import AddressEditor from './AddressEditor';
import Addresses from './Addresses';
import Payment from './Payment';
import Menu from './Menu';
import Product from './Product';
import CustomProduct from './CustomProduct';
import {getCurrency} from '../../../utils/format';
import '../styles/TakeOrderUI.scss';

const {Option} = Select;

const TakeOrdersUI = (props) => {
  const {
    menuVisible,
    productVisible,
    customProductVisible,
    openMenu,
    handleMenuVisible,
    handleProductVisible,
    handleCustomProductVisible,
    openProduct,
    partner,
    product,
    partnerOrder,
    selectProduct,
    productsOrder,
    getSubtotal,
    country,
    deleteProductOrder,
    editProduct,
    edit,
    editProductOrder,
    userOrder,
    addressListVisible,
    handleAddressListVisible,
    addressOrder,
    addressEditorVisible,
    handleAddressEditorVisible,
    newAddress,
    editAddress,
    address,
    handleAddress,
    deleteAddress,
    selectAddress,
    handleDescription,
    handleCashback,
    handleMethod,
    descriptionOrder,
    methodOrder,
    cashbackOrder,
    getDelivery,
    getTotal,
    createOrder,
    sending,
    getIVA,
    getTotalUSD,
    tuNicaragua,
    handleTuNicaragua,
    roleTuNicaragua,
    customProduct,
    openCustomProduct,
    customEdit,
    customProducts,
    handleCustomProducts,
    addCustomProduct,
    customProductEdited,
    editCustomProduct,
    extraInfo,
    handleExtraInfo,
    cardInfo,
    setCard,
    loadingReplace,
    orderToReplace,
    handleOrderToReplace,
    previousOrders,
    getLastOrders,
    getFinalAmount,
    getCreditToUse,
    users,
    handleUsers,
  } = props;

  return (
    <div className="take-orders-wrap">
      <Row gutter={20}>
        <Col xs={16}>
          <ClientInfo
            roleTuNicaragua={roleTuNicaragua}
            getLastOrders={getLastOrders}
            users={users}
            handleUsers={handleUsers}
          />
          {(userOrder.id || userOrder.name) && <ClientEditor roleTuNicaragua={roleTuNicaragua} country={country} />}
          <Partners openMenu={openMenu} />
        </Col>
        <Col xs={8}>
          <div className="detail-wrap">
            <div className="header-detail">
              Checkout
            </div>
            <OrderDetail
              partnerOrder={partnerOrder}
              productsOrder={productsOrder}
              country={country}
              deleteProductOrder={deleteProductOrder}
              editProduct={editProduct}
            />
            {userOrder.id && (
              <Address
                openAddresses={() => handleAddressListVisible(true)}
                address={addressOrder}
                addresses={userOrder.addresses}
                user={userOrder}
                handleDescription={handleDescription}
                descriptionOrder={descriptionOrder}
                extraInfo={extraInfo}
                handleExtraInfo={handleExtraInfo}
                sending={sending}
              />
            )}
            {userOrder.id && !roleTuNicaragua && (
              <div className="replace-order">
                <span>Remplazar orden</span>
                <Select
                  value={orderToReplace}
                  loading={loadingReplace}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={handleOrderToReplace}>
                  <Option value={-1}>Orden nueva</Option>
                  {previousOrders.map((prev, index) => (
                    <Option value={index}>
                      {`(${prev.ticket}) ${prev.id_partner.name} - ${getCurrency(country, prev.total)}`}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            <Payment
              handleCashback={handleCashback}
              handleMethod={handleMethod}
              methodOrder={methodOrder}
              cashbackOrder={cashbackOrder}
              tuNicaragua={tuNicaragua}
              cardInfo={cardInfo}
              setCard={setCard}
              country={country}
            />
            <div className="results">
              <div className="item">
                <span>Subtotal</span>
                <span>
                  {getCurrency(country, getSubtotal())}
                </span>
              </div>
              <div className="item">
                <span>Costo de envío</span>
                <span>
                  {/* {userOrder.id && userOrder.loyalty_orders === 5 ? 'Gratis' : getCurrency(country, getDelivery())} */}
                  {getCurrency(country, getDelivery())}
                </span>
              </div>
              {country === 'ni' && (
                <div className="item">
                  <span>IVA</span>
                  <span>
                    {getCurrency(country, getIVA())}
                  </span>
                </div>
              )}
              <div className="item total">
                <span>Total</span>
                <span>{getCurrency(country, getTotal())}</span>
              </div>
              {country === 'ni' && (
                <div className="item total">
                  <span>Total (Dólares)</span>
                  <span>{getCurrency(country, getTotalUSD(), 1)}</span>
                </div>
              )}
              {!!userOrder.credit && (
                <div className="item total">
                  <span>Pago en crédito</span>
                  <span>-{getCurrency(country, getCreditToUse())}</span>
                </div>
              )}
              {!!userOrder.credit && (
                <div className="item total">
                  <span>{getFinalAmount() > 0 ? 'Monto a cobrar' : 'Crédito nuevo'}</span>
                  <span>{getCurrency(country, Math.abs(getFinalAmount()))}</span>
                </div>
              )}
              {country === 'ni' && !roleTuNicaragua && (
                <div className="tunicaragua-wrapper">
                  <span>Pedido Tu Nicaragua</span>
                  <Switch
                    checked={tuNicaragua}
                    onChange={(val) => handleTuNicaragua(val)}
                  />
                </div>
              )}
            </div>
            <div className="action">
              <Button
                onClick={createOrder}
                type="primary"
                block
                shape="round"
                size="large"
                loading={sending}
              >
                Continuar
                <span className="price">{getCurrency(country, getFinalAmount() < 0 ? 0 : getFinalAmount())}</span>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Drawer
        width={400}
        closable={false}
        onClose={() => handleMenuVisible(false)}
        visible={menuVisible}
        destroyOnClose
        bodyStyle={{padding: 0}}
      >
        <Menu
          close={() => handleMenuVisible(false)}
          partner={partner}
          openProduct={openProduct}
        />
        <Drawer
          width={400}
          closable={false}
          onClose={() => handleProductVisible(false)}
          visible={productVisible}
          destroyOnClose
          bodyStyle={{padding: 0}}
        >
          <Product
            close={() => handleProductVisible(false)}
            product={product}
            selectProduct={selectProduct}
            partner={partner}
            partnerOrder={partnerOrder}
            edit={edit}
            editProductOrder={editProductOrder}
            customProducts={customProducts}
            handleCustomProducts={handleCustomProducts}
            openCustomProduct={openCustomProduct}
            editCustomProduct={editCustomProduct}
          />
          <Drawer
            width={400}
            closable={false}
            onClose={() => handleCustomProductVisible(false)}
            visible={customProductVisible}
            destroyOnClose
            bodyStyle={{padding: 0}}
          >
            <CustomProduct
              close={() => handleCustomProductVisible(false)}
              product={customProduct}
              selectProduct={addCustomProduct}
              partner={partner}
              edit={customEdit}
              customProductEdited={customProductEdited}
            />
          </Drawer>
        </Drawer>
      </Drawer>
      <Drawer
        width={400}
        onClose={() => handleAddressListVisible(false)}
        visible={addressListVisible}
        title="Selecciona una dirección"
        destroyOnClose
        bodyStyle={{padding: 0}}
      >
        <Addresses
          close={() => handleProductVisible(false)}
          addresses={userOrder.addresses}
          addressSelected={addressOrder}
          newAddress={newAddress}
          editAddress={editAddress}
          deleteAddress={deleteAddress}
          selectAddress={selectAddress}
        />
      </Drawer>
      <Modal
        title={address.id ? 'Editar Dirección' : 'Crear Dirección'}
        closable
        maskClosable={false}
        onCancel={() => handleAddressEditorVisible(false)}
        footer={false}
        width={850}
        destroyOnClose
        visible={addressEditorVisible}
      >
        <AddressEditor
          addressToEdit={address}
          country={country}
          user={userOrder}
          handleAddress={handleAddress}
        />
      </Modal>
    </div>
  );
};

export default TakeOrdersUI;
