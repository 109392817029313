import {Input} from 'antd';
import AddressUI from './AddressUI';

import '../styles/Address.scss';

const Address = props => {
  const {
    openAddresses,
    address,
    descriptionOrder,
    handleDescription,
    extraInfo,
    handleExtraInfo,
    sending,
  } = props;

  const getAddress = () => {
    if (address.id) {
      return (
        <AddressUI address={address} selected select={openAddresses} />
      );
    }
    return (
      <div className="empty-address">
        <span>Selecciona una dirección</span>
        <button onClick={openAddresses}>
          <i className="bi bi-plus" />
        </button>
      </div>
    );
  };
  return (
    <div className="address-order-take-order">
      <div className="address-header">
        <span>Dirección de entrega</span>
      </div>
      {getAddress()}
      <div className="notes-wrap">
        <Input
          className="notes"
          placeholder="Agregar nota al repartidor"
          size="large"
          value={descriptionOrder}
          onChange={(e) => handleDescription(e.target.value)}
        />
      </div>
      <div className="notes-wrap">
        <Input
          className="notes"
          placeholder="Información extra"
          value={extraInfo}
          size="large"
          onChange={e => handleExtraInfo(e.target.value)}
          disabled={sending}
        />
      </div>
    </div>
  );
};

export default Address;
