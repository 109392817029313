import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { login as apiLogin } from './Api'

// Reducer

const initialUser = localStorage.getItem('get_admin')
  ? jwt_decode(localStorage.getItem('get_admin'))
  : null;

const initialCountry = localStorage.getItem('get_country') || 'sv';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    info: initialUser,
    country: initialCountry,
  },
  reducers: {
    loginSuccess: (state, action) => {
      const user = jwt_decode(action.payload);
      state.info = user;
    },
    logoutSuccess: (state) => {
      state.info = null;
      localStorage.removeItem('get_admin');
      localStorage.removeItem('get_country');
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
  },
});


//Actions

export const { loginSuccess, logoutSuccess, setCountry } = userSlice.actions;

export const login = (username, password, country) => async dispatch => {
  try {
    const response = await apiLogin(username, password);
    if (!response.error && response.status === 200) {
      const {token} = response.data;
      await localStorage.setItem('get_admin', token);
      await localStorage.setItem('get_country', country);
      dispatch(loginSuccess(token));
      return {
        status: 'success',
      };
    }
    if (response.error.response.status === 401) {
      return {
        status: 'error',
        type: 'not-found'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectCountry = state => state.user.country;
export const selectUser = state => state.user.info;

export default userSlice.reducer;
