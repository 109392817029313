import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Spin, Button, Tooltip} from 'antd';
import cx from 'classnames';
import {BlobProvider} from '@react-pdf/renderer';
import Ticket from './Ticket';
import {getProducts, selectOrderDetail} from '../MonitorSlice';
import {selectCountry, selectUser} from '../../login/UserSlice';
import {getCurrency} from '../../../utils/format';
import '../styles/OrderDetails.scss';

const OrderDetail = () => {
  const [loading, handleLoading] = useState(true);
  const [details, handleDetails] = useState({});
  const order = useSelector(selectOrderDetail);
  const country = useSelector(selectCountry);
  const user = useSelector(selectUser);
  const tuNicaragua = user.role === 3 && country === 'ni';
  const {
    iva,
    total,
    delivery_fee,
    id,
    partner,
    cashback,
  } = order;

  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const response = await dispatch(getProducts(id));
      if (response.status === 'success') {
        handleDetails(response.order);
      }
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch, id]);

  const getSubtotal = () => {
    const subtotal = details.subtotal || (total - delivery_fee - (iva || 0));
    return tuNicaragua ? getValuePlusIVA(subtotal) :  subtotal;
  };

  const getPartnerAmount = () => {
    const deliveryFee = getSubtotal() - details.get_fee;
    return deliveryFee;
  };

  const getValuePlusIVA = (val) => {
    return val * 1.15;
  };

  const isFreeDelivery = () => {
    const {loyalty, promodevices} = details;
    if (loyalty && loyalty.length > 0) {
      return true;
    } else if (promodevices && promodevices.length > 0) {
      const promo = promodevices[0];
      const type = promo.id_promotion ? promo.id_promotion.id_type : promo.id_promocode.id_type;
      if (type === 1 || type === 6) {
        return true;
      }
      return false;
    }
    return false;
  };

  const printTicket = url => {
    window.open(url, '_blank');
  };


  const getExtras = (product) => {
    const {extras, product_categories} = product;
    if (product_categories.length > 0) {
      return (
        <div className="product-extras">
          {product_categories.map((cat) => {
            return (
              <div key={cat.id} className="cat-wrapper">
                <span className="cat-main">{cat.name}</span>
                {cat.extras.map((extra) => {
                  const {id, name, price} = extra;
                  return (
                    <div key={id} className="extra horizontal">
                      <span className="label">{`${name}${extra.quantity ? ` (${extra.quantity})` : ''}`}</span>
                      {price > 0 && (
                        <span className="price">
                          {`+ ${getCurrency(country, price, partner.default_currency)}`}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    } else if (extras.length > 0) {
      return (
        <div className="product-extras">
          {extras.map((extra) => {
            const {id, name, price} = extra;
            return (
              <div key={id} className="extra horizontal">
                <span className="label">{name}</span>
                {price > 0 && (
                  <span className="price">
                    {`+ ${getCurrency(country, price, partner.default_currency)}`}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      );
    }
    return <span />;
  };

  const Product = ({product}) => {
    const {unit_price, total} = product;
    return (
      <div className="product-wrap">
        <div className="product box">
          <div className="product-header">
            <div className="quantity-wrap">
              {product.quantity}
            </div>
            <span className="product-name label">
              {product.name}
            </span>
            <span className="price">
              {getCurrency(country, unit_price, partner.default_currency)}
            </span>
          </div>
          {getExtras(product)}
          {product.comments && product.comments !== 'N/A'  && (
            <div className="comments">
              Comentarios: {product.comments}
            </div>
          )}
        </div>
        <div className="product-total box horizontal">
          <span className="label">Suma</span>
          <span className="price">
            {getCurrency(country, total, partner.default_currency)}
          </span>
        </div>
      </div>
    );
  };

  const PrintButton = ({user}) => (
    <BlobProvider
      document={<Ticket order={details} country={country} user={user} />}
    >
      {({ url }) => (
        <Tooltip placement="bottom" title={user ? 'Ticket usuario' : 'Ticket comercio'}>
          <Button
            onClick={() => printTicket(url)}
            type="primary"
            className={cx('print-btn', {userPrint: user})}
            shape="circle"
            icon={!user ? <i className="bi bi-bag-fill print-icon" /> : <i className="bi bi-person-fill print-icon" />}
          />
        </Tooltip>
      )}
    </BlobProvider>
  );

  if (!loading) {
    const {products, payment_method} = details;
    return (
      <div className="order-detail-wrap">
        {country === 'sv' && (
          <div className="print-wrap">
            <PrintButton />
            <PrintButton user />
          </div>
        )}
        {products.map((product) => (
          <Product key={product.id} product={product} />
        ))}
        {details.promodevices.length > 0 && (
          <img
            src={
              details.promodevices[0].id_promotion ?
                details.promodevices[0].id_promotion.image :
                details.promodevices[0].id_promocode.image
            }
            className="image-promo"
            alt="" />
        )}
        <div className="totals">
          <div className="subtotal box horizontal">
            <span className="label">
              {`Subtotal${tuNicaragua ? ' + IVA' : ''}`}
            </span>
            <span className="price">
              {getCurrency(country, getSubtotal())}
            </span>
          </div>
          <div className="delivery box horizontal">
            <span className="label">
              {`Costo de envío${tuNicaragua ? ' + IVA' : ''}`}
            </span>
            <span className="price">
              {isFreeDelivery() ?
                `Free Delivery${details.loyalty.length > 0 && ' (Sexto Pedido)'}` :
                getCurrency(country, tuNicaragua ? getValuePlusIVA(delivery_fee) : delivery_fee)
            }
            </span>
          </div>
          {(country === 'ni' && !tuNicaragua) && (
            <div className="iva box horizontal">
              <span className="label">IVA</span>
              <span className="price">
                {getCurrency(country, iva)}
              </span>
            </div>
          )}
          <div className="total box horizontal">
            <span className="label">Total</span>
            <span className="price">
              {getCurrency(country, total)}
            </span>
          </div>
          {!!(country === 'ni' && !tuNicaragua && details.total_usd) && (
            <div className="total box horizontal">
              <span className="label">Total dólares</span>
              <span className="price">
                {getCurrency(country, details.total_usd, 1)}
              </span>
            </div>
          )}
        </div>
        {(payment_method === 1 && !tuNicaragua) && (
          <>
            {details.credit && (
              <>
                <div className="subtotal box horizontal cashback">
                  <span className="label">Crédito</span>
                  <span className="price">
                    - {getCurrency(country, details.credit)}
                  </span>
                </div>
                <div className="subtotal box horizontal cashback">
                  <span className="label">Monto a cobrar</span>
                  <span className="price">
                    {getCurrency(country, total - details.credit)}
                  </span>
                </div>
              </>
            )}
            <div className="subtotal box horizontal cashback">
              <span className="label">Cambio para</span>
              <span className="price">
                {getCurrency(country, cashback)}
              </span>
            </div>
            <div className="subtotal box horizontal cashback">
              <span className="label">Entregar al cliente</span>
              <span className="price">
                {getCurrency(country, details.change)}
              </span>
            </div>
          </>
        )}
        {(!tuNicaragua && details.get_fee > 0) && (
          <div className="total box horizontal cashback">
            <span className="label">Entregar a comercio</span>
            <span className="price">
              {getCurrency(country, getPartnerAmount())}
            </span>
          </div>
        )}
        <div className="method-wrap">
          <div className="box horizontal">
            <span className="label">Método de Pago</span>
            <span className="label method">
              {payment_method === 1 ? 'Efectivo' : 'Tarjeta'}
            </span>
          </div>
        </div>
        {/* details.credit && (
          <div className="method-wrap">
            <div className="box horizontal">
              <span className="label">Crédito</span>
              <span className="label method">
                {getCurrency(country, details.credit)}
              </span>
            </div>
          </div>
        ) */}
      </div>
    );
  }

  return (
    <div className="loader-detail-wrap">
      <Spin />
    </div>
  );

};

export default OrderDetail;
