import { createSlice } from '@reduxjs/toolkit';
import { getOrdersHistory } from './Api'

// Reducer
export const ordersSlice = createSlice({
  name: 'orders_history',
  initialState: {
    orders: [],
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
  },
});


//Actions

export const { setOrders } = ordersSlice.actions;

export const getOrders = (config) => async dispatch => {
  try {
    const response = await getOrdersHistory(config);
    if (!response.error && response.status === 200) {
      const partners = [];
      const orders = response.data.orders;
      dispatch(setOrders(orders));
      orders.map(order => {
        const indexPartner = partners.findIndex(el => el.value === order.id_partner);
        if (indexPartner < 0) {
          partners.push({
            text: order.partner_name,
            value: order.id_partner,
          });
        }
      });
      return {
        status: 'success',
        partners,
        orders,
      };
    }
    if (response.error.response.status === 401) {
      return {
        status: 'error',
        type: 'not-found'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectOrders = state => state.orders_history.orders;

export default ordersSlice.reducer;
