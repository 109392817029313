import {Link} from 'react-router-dom';
import {DatePicker, Spin, Row, Col} from 'antd';
import History from './History';
import '../styles/CashierCloseHistoryUI.scss';

const {RangePicker} = DatePicker;

const CashierCloseHistoryUI = props => {
  const {
    historyCashierClose,
    country,
    today,
    beginDate,
    endDate,
    loading,
    handleDates,
  } = props;

  return (
    <div className="closes-wrap">
      <div className="main-titles">
        <div>
          <Link to="/cashflow" className="link">
            <i className="bi bi-arrow-left-short" /> Caja
          </Link>
          <h1 className="title">Cierres de caja</h1>
          <h3 className="subtitle">
            {today.format('dddd D [de] MMMM')}
          </h3>
        </div>
        <div className="number-orders">
          <div className="number-data">
            <h1 className="title">
              {historyCashierClose.length}
            </h1>
            <h3 className="subtitle">
              Cierres
            </h3>
          </div>
        </div>
      </div>
      <div className="filters-wrap actions">
        <Row gutter={20}>
          <Col xs={24}>
            <RangePicker
              disabled={loading}
              size="large"
              placeholder={['Fecha Inicio', 'Fecha Final']}
              allowClear={false}
              value={[beginDate, endDate]}
              onChange={handleDates}
            />
          </Col>
        </Row>
      </div>
      {loading ? (
        <div className="loader-wrap">
          <Spin size="large" />
        </div>
      ) : (
        <History closes={historyCashierClose} country={country} />
      )}
    </div>
  );
};

export default CashierCloseHistoryUI;
