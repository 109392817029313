import {useState, useEffect, PureComponent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Input, Row, Col, Spin, Pagination } from 'antd';
import { partnersFeed, selectPartners } from '../TakeOrdersSlice';
import { slugify } from '../../../utils/format';
import '../styles/Partners.scss';
class Partner extends PureComponent {
  render() {
    const {partner, openMenu} = this.props;
    return (
      <Col xs={24} md={12} lg={8}>
        <div
          onClick={() => openMenu(partner)}
          className="partner"
        >
          <div
            className="partner-cover"
            style={{backgroundImage: `url(${partner.cover})`}}
          >
            <div
              className="partner-logo"
              style={{backgroundImage: `url(${partner.logo})`}}
            />
            {(!partner.isOpen) && (
              <div className="filter">
                Cerrado
              </div>
            )}
          </div>
          <div className="partner-info">
            <span className="partner-name">
              {partner.name.length > 17 ? `${partner.name.substring(0, 15)}...` : partner.name}
            </span>
            <div className="partner-details">
              <i className="bi bi-star-fill star" />
              <span>5.0</span>
              <i className="bi bi-clock-fill clock" />
              <span>
                {`${partner.min_waiting_time} - ${partner.max_waiting_time} min`}
              </span>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}


const Partners = ({openMenu}) => {
  const partners = useSelector(selectPartners);
  const [loading, handleLoading] = useState(true);
  const [results, handleResults] = useState([]);
  const [search, handleSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const response = await dispatch(partnersFeed());
      handleResults(response.partners.slice(0, 9));
      handleLoading(false);
    };

    initialFetch();
  }, [dispatch]);

  const handlePagination = (newPage) => {
    const newResults = partners.slice(((newPage - 1) * 9), newPage * 9);
    handleResults(newResults);
  };

  const searchPartner = (newSearch) => {
    if (newSearch.length > 0) {
      const filteredProducts = partners.filter(
        (el) => slugify(el.name).indexOf(slugify(newSearch)) >= 0,
      );
      handleResults(filteredProducts);
    } else {
      handlePagination(1);
    }
    handleSearch(newSearch);
  };

  return (
    <div className="client-wrap-take-order">
      <div className="client-header">
        <div className="title-wrap">
          <span className="title">Comercios</span>
        </div>
        <Input
          className="input"
          placeholder="Buscar por nombre"
          size="large"
          onChange={(e) => searchPartner(e.target.value)}
        />
      </div>
      <div className="partners-list-take-order">
        {!loading ? (
          <>
            <Row gutter={[20, 20]}>
              {results.map((partner) => (
                <Partner
                  key={partner.id}
                  partner={partner}
                  openMenu={openMenu}
                />
              ))}
            </Row>
            <Pagination
              defaultPageSize={9}
              defaultCurrent={1}
              total={search.length > 0 ? 1 : partners.length}
              showSizeChanger={false}
              onChange={(newPage) => handlePagination(newPage)}
            />
          </>
        ) : (
          <div className="loader-wrap">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}


export default Partners;