import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {
  getUnpaidSettlements,
  createSettlement,
  getUnpaidSettlement,
  getHistory,
  getSettlementDetail,
} from './Api'

// Reducer
export const settlementsSlice = createSlice({
  name: 'settlements',
  initialState: {
    unpaid: [],
    settlementDetail: {},
    history: [],
    partners: [],
    beginDate: moment.tz('America/El_Salvador').subtract(1, 'days').format('YYYY-MM-DD'),
    endDate: moment.tz('America/El_Salvador').format('YYYY-MM-DD'),
    payment_type: 2,
    loadedPendings: false,
  },
  reducers: {
    setUnpaid: (state, action) => {
      state.unpaid = action.payload;
    },
    setPartners: (state, action) => {
      state.partners = action.payload;
    },
    setSettlementDetail: (state, action) => {
      state.settlementDetail = action.payload;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setBeginDate: (state, action) => {
      state.beginDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setPaymentType: (state, action) => {
      state.payment_type = action.payload;
    },
    setLoadedPendings: (state, action) => {
      state.loadedPendings = action.payload;
    },
  },
});


//Actions

export const {
  setUnpaid,
  setSettlementDetail,
  setHistory,
  setBeginDate,
  setEndDate,
  setLoadedPendings,
  setPaymentType,
  setPartners,
} = settlementsSlice.actions;

export const getUnpaid = config => async dispatch => {
  try {
    const response = await getUnpaidSettlements(config);
    if (!response.error && response.status === 200) {
      const settlements = response.data.settlements;
      settlements.sort(function(a, b){
        if(a.partner_name < b.partner_name) { return -1; }
        if(a.partner_name > b.partner_name) { return 1; }
        return 0;
      })
      const partners = [];
      dispatch(setUnpaid(settlements));
      settlements.map(settlement => {
        const indexPartner = partners.findIndex(el => el.value === settlement.id_partner);
        if (indexPartner < 0) {
          partners.push({
            text: settlement.partner_name,
            value: settlement.id_partner,
          });
        }
      });
      dispatch(setPartners(partners));
      return {
        status: 'success',
        unpaid: settlements,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getUnpaidSettlementDetail = (idPartner, config) => async dispatch => {
  try {
    const response = await getUnpaidSettlement(idPartner, config);
    if (!response.error && response.status === 200) {
      const orders = response.data.orders;
      const settlement = {
        partner_name: orders[0].partner_name,
        date: moment.tz('America/El_Salvador'),
        orders,
      };
      dispatch(setSettlementDetail(settlement));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getSettlementDetails = idSettlement => async dispatch => {
  try {
    const response = await getSettlementDetail(idSettlement);
    if (!response.error && response.status === 200) {
      const orders = response.data.transactions;
      const settlement = {
        partner_name: orders[0].partner_name,
        date: moment.tz('America/El_Salvador'),
        orders,
      };
      dispatch(setSettlementDetail(settlement));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const paySettlement = config => async () => {
  try {
    const response = await createSettlement(config);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getSettlementsHistory = config => async dispatch => {
  try {
    const response = await getHistory(config);
    if (!response.error && response.status === 200) {
      const settlements = response.data.records;
      dispatch(setHistory(settlements));
      const partners = [];
      settlements.map(settlement => {
        const indexPartner = partners.findIndex(el => el.value === settlement.id_partner.id);
        if (indexPartner < 0) {
          partners.push({
            text: settlement.id_partner.name,
            value: settlement.id_partner.id,
          });
        }
      });
      return {
        status: 'success',
        partners,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectUnpaid = state => state.settlements.unpaid;
export const selectPartners = state => state.settlements.partners;
export const selectSettlementdDetail = state => state.settlements.settlementDetail;
export const selectHistory = state => state.settlements.history;
export const selectBeginDate = state => state.settlements.beginDate;
export const selectEndDate = state => state.settlements.endDate;
export const selectPaymentType = state => state.settlements.payment_type;
export const selectLoadedPendings = state => state.settlements.loadedPendings;

export default settlementsSlice.reducer;
