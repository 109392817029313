import {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { login as actionLogin, selectCountry, setCountry } from './UserSlice';
import LoginUI from './components/LoginUI';

const Login = (props) => {
  const [sending, handleSending] = useState(false);
  const [error, handleError] = useState('');
  const [form] = Form.useForm();
  const country = useSelector(selectCountry);
  const dispatch = useDispatch();

  const login = async () => {
    handleSending(true);
    try {
      const { username, password } = await form.validateFields();
      const response = await dispatch(actionLogin(username, password, country));
      if (response.status === 'success') {
      } else if (response.type === 'not-found') {
        handleError('Usuario o contraseña incorrecta');
      } else {
        handleError('Inténtalo de nuevo más tarde');
      }
    } catch (errorInfo) {
      handleError('Inténtalo de nuevo más tarde');
    }
    handleSending(false);
  };

  const handleCountry = async (newCountry) => {
    await dispatch(setCountry(newCountry));
  };

  return (
    <LoginUI
      form={form}
      login={login}
      sending={sending}
      error={error}
      handleError={handleError}
      handleCountry={handleCountry}
      country={country}
    />
  );
};

export default Login;
