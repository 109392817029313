import "../styles/ChartsUI.scss";
import { DatePicker, Spin } from "antd";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartsUI = (props) => {
  const {
    today,
    loadingOrders,
    loadingProfits,
    ordersBeginDate,
    profitsBeginDate,
    ordersEndDate,
    profitsEndDate,
    handleOrdersDates,
    handleProfitsDates,
    profits,
    orders,
  } = props;

  const getProfitsData = () => {
    const data = {
      labels: profits.labels,
      datasets: [
        {
          label: "Ganancias",
          data: profits.profits,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Ingresos",
          data: profits.income,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
    return data;
  };

  const getProfitsOptions = () => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Ganancias vs Ingresos",
        },
      },
    };
    return options;
  };

  const getOrdersData = () => {
    const data = {
      labels: orders.labels,
      datasets: [
        {
          label: "App",
          data: orders.app,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Callcenter",
          data: orders.callcenter,
          borderColor: "rgb(249, 164, 26)",
          backgroundColor: "rgba(249, 164, 26, 0.5)",
        },
        {
          label: "Total",
          data: orders.total,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(80, 162, 235, 0.5)",
        },
      ],
    };
    return data;
  };

  const getOrdersOptions = () => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Ordenes",
        },
      },
    };
    return options;
  };

  return (
    <div className="charts-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Gráficos</h1>
          <h3 className="subtitle">{today.format("dddd D [de] MMMM")}</h3>
        </div>
      </div>
      <div className="chart">
        <div className="chart-filters">
          <RangePicker
            disabled={loadingOrders}
            size="large"
            placeholder={["Fecha Inicio", "Fecha Final"]}
            allowClear={false}
            value={[ordersBeginDate, ordersEndDate]}
            onChange={handleOrdersDates}
          />
        </div>
        {loadingOrders ? (
          <div className="loader-wrap">
            <Spin size="large" />
          </div>
        ) : (
          <Line options={getOrdersOptions()} data={getOrdersData()} />
        )}
      </div>
      <div className="chart">
        <div className="chart-filters">
          <RangePicker
            disabled={loadingProfits}
            size="large"
            placeholder={["Fecha Inicio", "Fecha Final"]}
            allowClear={false}
            value={[profitsBeginDate, profitsEndDate]}
            onChange={handleProfitsDates}
          />
        </div>
        {loadingProfits ? (
          <div className="loader-wrap">
            <Spin size="large" />
          </div>
        ) : (
          <Line options={getProfitsOptions()} data={getProfitsData()} />
        )}
      </div>
    </div>
  );
};

export default ChartsUI;
