import { createSlice } from '@reduxjs/toolkit';
import {
  getPartners,
  getPartnerMenu,
  getProductMenu,
  checkCoverage,
  createAddress,
  editAddress,
  deleteAddress,
  createOrder,
  getExchange,
  getLastOrders,
} from './Api'

// Reducer

const initialOrder = {
  user: {},
  partner: {},
  products: [],
  address: {},
  description: '',
  method: '',
  cashback: 0,
  previousOrders: [],
  orderToReplace: -1,
};

export const takeOrdersSlice = createSlice({
  name: 'take_orders',
  initialState: {
    partners: [],
    order: initialOrder,
    exchange: 1,
  },
  reducers: {
    closeSession: (state, action) => {
      state.order = initialOrder
    },
    setPartners: (state, action) => {
      state.partners = action.payload;
    },
    setPartnerOrder: (state, action) => {
      state.order.partner = action.payload;
    },
    setProductOrder: (state, action) => {
      state.order.products.push(action.payload);
    },
    setDescriptionOrder: (state, action) => {
      state.order.description = action.payload;
    },
    setMethodOrder: (state, action) => {
      state.order.method = action.payload;
    },
    setCashbackOrder: (state, action) => {
      state.order.cashback = action.payload;
    },
    setNewProductOrder: (state, action) => {
      state.order.products = [action.payload];
    },
    deleteProduct: (state, action) => {
      state.order.products.splice(action.payload, 1);
    },
    setProductEdited: (state, action) => {
      const {index, product} = action.payload;
      state.order.products[index] = product;
    },
    setUserOrder: (state, action) => {
      state.order.user = action.payload;
    },
    setPreviousOrders: (state, action) => {
      state.order.previousOrders = action.payload;
    },
    setOrderToReplace: (state, action) => {
      state.order.orderToReplace = action.payload;
    },
    setAddressOrder: (state, action) => {
      state.order.address = action.payload;
    },
    setExchange: (state, action) => {
      state.exchange = action.payload;
    },
    setNewAddress: (state, action) => {
      state.order.user.addresses.push(action.payload);
    },
    resetOrder: (state, action) => {
      state.order = {
        user: {},
        partner: {},
        products: [],
        address: {},
        description: '',
        method: '',
        cashback: 0,
        previousOrders: [],
        orderToReplace: -1,
      };
    },
    addressEdited: (state, action) => {
      const {address, index} = action.payload;
      if (index > -1) {
        state.order.user.addresses[index] = address;
        if (state.order.address.id && state.order.user.addresses[index].id === state.order.address.id) {
          state.order.address = address;
        }
      }
    },
    addressRemoved: (state, action) => {
      if (state.order.address.id && state.order.user.addresses[action.payload].id === state.order.address.id) {
        state.order.address = {};
      }
      state.order.user.addresses.splice(action.payload, 1);
    },
  },
});


//Actions

export const {
  setPartners,
  setPartnerOrder,
  setProductOrder,
  setNewProductOrder,
  deleteProduct,
  setProductEdited,
  setUserOrder,
  setNewAddress,
  setAddressOrder,
  addressEdited,
  addressRemoved,
  setDescriptionOrder,
  setMethodOrder,
  setCashbackOrder,
  resetOrder,
  setExchange,
  closeSession,
  setPreviousOrders,
  setOrderToReplace,
} = takeOrdersSlice.actions;

export const partnersFeed = () => async dispatch => {
  try {
    const response = await getPartners();
    if (!response.error && response.status === 200) {
      const {partners} = response.data;
      dispatch(setPartners(partners));
      return {
        status: 'success',
        partners,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const partnerMenu = (partner) => async dispatch => {
  try {
    const response = await getPartnerMenu(partner);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        menu: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getPreviousOrders = user => async dispatch => {
  try {
    const response = await getLastOrders(user);
    if (!response.error && response.status === 200) {
      dispatch(setPreviousOrders(response.data.orders));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const productMenu = (product, partner, setMenu) => async dispatch => {
  try {
    const response = await getProductMenu(product, partner);
    if (!response.error && response.status === 200) {
      const {product} = response.data;
      const categories = product.categories;
      if (setMenu) {
        //* Se agrega custom para identificar que tiene opciones personalizadas
        //* (Opciones que te llevan a vista de otro producto) (Buffalo)
        categories.splice(1, 0, {
          extras: [],
          id: 100,
          multiple: 'No',
          name: 'Personaliza tus opciones',
          required: 'Si',
          custom: true,
        });
      }
      product.categories = categories;
      return {
        status: 'success',
        product,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const verifyLocation = (latitude, longitude) => async dispatch => {
  try {
    const response = await checkCoverage(latitude, longitude);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        data: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const newAddress = (address) => async dispatch => {
  try {
    const response = await createAddress(address);
    if (!response.error && response.status === 200) {
      dispatch(setNewAddress(response.data.address));
      return {
        status: 'success',
        address: response.data.address
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const changeAddress = (address, index) => async dispatch => {
  try {
    const response = await editAddress(address);
    if (!response.error && response.status === 200) {
      dispatch(addressEdited({address: response.data.address[0], index}));
      return {
        status: 'success',
        address: response.data.address[0],
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const removeAddress = (address, index) => async dispatch => {
  try {
    const response = await deleteAddress(address);
    if (!response.error && response.status === 200) {
      dispatch(addressRemoved(index));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const newOrder = (order) => async dispatch => {
  try {
    const response = await createOrder(order);
    if (!response.error && response.status === 200) {
      if (response.data.status === 'success') {
        dispatch(resetOrder());
        return {
          status: 'success',
        };
      } else if (response.data.type === 'card_issue') {
        return response.data;
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getExchangeOrder = () => async dispatch => {
  try {
    const response = await getExchange();
    if (!response.error && response.status === 200) {
      dispatch(setExchange(response.data.conversion_rate));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectPartners = state => state.take_orders.partners;
export const selectMenu = state => state.take_orders.menu;
export const selectPartnerOrder = state => state.take_orders.order.partner;
export const selectProductsOrder = state => state.take_orders.order.products;
export const selectAddressOrder = state => state.take_orders.order.address;
export const selectUserOrder = state => state.take_orders.order.user;
export const selectDescriptionOrder = state => state.take_orders.order.description;
export const selectMethodOrder = state => state.take_orders.order.method;
export const selectCashbackOrder = state => state.take_orders.order.cashback;
export const selectExchange = state => state.take_orders.exchange;
export const selectPreviousOrders = state => state.take_orders.order.previousOrders;
export const selectOrderToReplace = state => state.take_orders.order.orderToReplace;

export default takeOrdersSlice.reducer;
