import {Table} from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {getCurrency} from '../../../utils/format';
import {ReactComponent as Cash} from '../../../assets/methods/cash.svg';
import Card from '../../../assets/methods/visa.png';
import '../styles/Orders.scss';

const Orders = ({orders, country}) => {
  const getSubtotal = (subtotal, delivery, total) => {
    const newSubtotal = subtotal || (total - delivery);
    return newSubtotal;
  };

  const getPartnerAmount = (subtotal, delivery, total, fee) => {
    const deliveryFee = getSubtotal(subtotal, delivery, total) - fee;
    return deliveryFee;
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'created_time',
      key: 'created_time',
      fixed: 'left',
      render: (val) => {
        return moment.tz(val, 'America/El_Salvador').format('DD/MM/YYYY, HH:mm a');
      },
    },
    {
      title: 'Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
      align: 'center',
      render: (val) => val.toUpperCase(),
    },
    {
      title: 'Comercio',
      dataIndex: 'id_partner',
      key: 'id_partner',
      align: 'center',
      render: (val) => val ? val.name : '-',
    },
    {
      title: 'Tipo pago',
      dataIndex: 'id_partner',
      key: 'id_partner',
      align: 'center',
      render: (val) => val.payment_type ? 'Crédito' : 'Contado',
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      align: 'center',
      render: (val, record) => (
        getCurrency(country, getSubtotal(val, record.delivery_fee, record.total))
      )
    },
    {
      title: 'Comisión',
      dataIndex: 'get_fee',
      key: 'get_fee',
      align: 'center',
      render: (val) => (
        val ? getCurrency(country, val) : '-'
      )
    },
    {
      title: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      align: 'center',
      render: (val) => (
        val ? getCurrency(country, val) : '-'
      )
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      render: (val) => (
        getCurrency(country, val)
      )
    },
    {
      title: 'Reintegrar',
      dataIndex: 'get_fee',
      key: 'get_fee',
      align: 'center',
      render: (val, record) => (
        val ? getCurrency(country, getPartnerAmount(record.subtotal, record.delivery_fee, record.total, val)) : '-'
      ),
    },
    {
      title: 'Método',
      dataIndex: 'payment_method',
      key: 'payment_method',
      align: 'center',
      render: (val) => {
        if (val === 2) {
          return <img src={Card} alt="" className="card" />;
        }
        return <Cash width="35px" height="23.49px" />;
      },
    },
  ];

  return (
    <div className="orders-reports-wrap">
      <Table
        columns={columns}
        dataSource={orders}
        rowKey="id"
        scroll={{ x: 1500 }}
      />
    </div>
  );
};

export default Orders;
