import {Modal, message, Tag} from 'antd';
import {useState, useCallback, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {getSortList, updateSortList} from './FeedSortUISlice';
import FeedSortUI from './components/FeedSortUI';
import {useHistory} from 'react-router-dom';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const columns = [
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
  },
  {
    title: 'Fecha Inicio',
    dataIndex: 'start_date',
    className: 'drag-visible',
  },
  {
    title: 'Fecha Fin',
    dataIndex: 'end_date',
  },
  {
    title: 'Hora Inicio',
    dataIndex: 'start_time',
  },
  {
    title: 'Hora Fin',
    dataIndex: 'end_time',
  },
  {
    title: 'Días',
    dataIndex: 'days',
  },
  {
    title: 'Estado',
    dataIndex: 'active',
    filters: [
      {
        text: 'Activo',
        value: 1,
      },
      {
        text: 'Inactivo',
        value: 0,
      },
    ],
    onFilter: (value, record) => record.active === value,
    render: (val, record) => (
      val ? <Tag color="success">Activo</Tag> : <Tag color="error">Inactivo</Tag>
    ),
  },
];

const FeedSort = () => {

  const dispatch = useDispatch();

  const [dtSort, handledtSort] = useState([]);
  const [loading, handleLoading] = useState(true);

  const initialFetch = useCallback(async () => {
    const response = await dispatch(getSortList());
    if (response.status === 'success') {
      handledtSort(response.dt_sort);
    }
    handleLoading(false);
  }, [dispatch]);

  const updateSL = async (list) => {
    const response = await dispatch(updateSortList(list));
    console.log(response);
    if (response.status === 'success') {
      message.info('Se ha reordenado correctamente');
    } else {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
  }

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);
  
  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);

  const today = moment.tz('America/El_Salvador');

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(dtSort), oldIndex, newIndex).filter(el => !!el);
      console.log(newData);
      updateSL(newData);
      handledtSort(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // const { dadtSorttaSource } = state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dtSort.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const history = useHistory();

  return (
    <FeedSortUI
      today={ today }
      getList={ dtSort }
      loading={ loading }

      columns = { columns }
      onSortEnd = { onSortEnd }
      DraggableContainer = { DraggableContainer }
      DraggableBodyRow  = { DraggableBodyRow }
      history = { history }
    />
  );
};

export default FeedSort;
