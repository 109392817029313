import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Upload,
  Spin,
  Modal,
  Switch,
  TreeSelect,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {Images, CompassFill, LockFill, X} from '../../../components/Icon';
import Map from './Map';
import '../styles/DriverEditorUI.scss';

const {Option} = Select;
const {TreeNode} = TreeSelect;

const DriverEditorUI = (props) => {
  const {
    loadingImg,
    beforeUpload,
    imageUrl,
    handleChange,
    form,
    sending,
    save,
    loading,
    id,
    activation,
    loadingActive,
    isActive,
    editingDriver,
    showMap,
    handleShowMap,
    enabling,
    disabling,
    confirmEnableDriver,
    confirmDisableDriver,
    partners,
    isAdmin,
  } = props;

  const blocked = editingDriver?.available === 2;

  const uploadButton = (
    <div className="placeholder-img">
      {loadingImg ? <LoadingOutlined /> : <Images />}
    </div>
  );

  const getBranches = () => (
    partners.map(partner => (
      <TreeNode
        key={partner.id}
        selectable={false}
        value={partner.id}
        title={partner.name}
      >
        {partner.branches.map(branch => (
          <TreeNode
            key={branch.id}
            value={branch.id}
            title={`${partner.name} ${branch.zone}`}
          />
        ))}
      </TreeNode>
    ))
  );

  if (loading) {
    return (
      <div className="loader-wrap">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="drivers-editor">
      <div className="main-titles">
        <div>
          <h1 className="title">Motoristas</h1>
          <h3 className="subtitle">
            {id === 'new' ? 'Crear motorista' : 'Editar motorista'}
          </h3>
        </div>
      </div>
      <div className="form-editor">
        <Form
          form={form}
          name="driver"
          layout="vertical"
          requiredMark={false}
          onFinish={save}
          onFinishFailed={() => {}}
          initialValues={{
            gender: 'male',
            branch: 0,
          }}
        >
          {id !== 'new' && (
            <div className="permissions">
              <Form.Item
                name="auto_dispatch"
                label={<span className="label">DESPACHO</span>}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="edit_address"
                label={<span className="label">EDITAR</span>}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </div>
          )}
          <Row gutter={{ xs: 0, sm: 18, md: 24}}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 6 }}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ?
                  <div className="photo" style={{backgroundImage: `url(${imageUrl})`}} /> 
                :
                  uploadButton
                }
              </Upload>
              <span className="disclaimer">
                Sube la foto de perfil 500px x 500px de un motorista.
              </span>
              {id !== 'new' && !blocked && (
                <div className="sub-actions">
                  {editingDriver && editingDriver.latitude.length > 0 && editingDriver.longitude.length > 0 &&(
                    <Button
                      onClick={() => handleShowMap(true)}
                      htmlType="button"
                      className="btn btn-map"
                    >
                      Última Ubicación <CompassFill />
                    </Button>
                  )}
                  <Button
                    onClick={confirmEnableDriver}
                    htmlType="button"
                    className="btn btn-available"
                    loading={enabling}
                  >
                    Habilitar Driver {!enabling && <i className="bi bi-check-circle" />}
                  </Button>
                  <Button
                    onClick={confirmDisableDriver}
                    htmlType="button"
                    className="btn btn-lock"
                    loading={disabling}
                  >
                    Bloquear Driver <LockFill />
                  </Button>
                </div>
              )}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 9 }}
            >
              <Form.Item
                label={<span className="label">NOMBRE</span>}
                name="name"
                colon={false}
                rules={[{ required: true, message: 'Ingresa un nombre' }]}
              >
                <Input size="large" className="input" disabled={blocked} />
              </Form.Item>
              <Form.Item
                label={<span className="label">APELLIDO</span>}
                name="lastname"
                colon={false}
                rules={[{ required: true, message: 'Ingresa un apellido' }]}
              >
                <Input size="large" className="input" disabled={blocked} />
              </Form.Item>
              <Form.Item
                label={<span className="label">CORREO</span>}
                name="email"
                colon={false}
                rules={[{ required: true, message: 'Ingresa el correo' }]}
              >
                <Input size="large" className="input" disabled={blocked} />
              </Form.Item>
              <Form.Item
                label={<span className="label">CONTRASEÑA</span>}
                name="password"
                colon={false}
                rules={[{ required: true, message: 'Ingresa la contraseña' }]}
              >
                <Input.Password
                  visibilityToggle={false}
                  size="large"
                  className="input"
                  autoComplete="new-password"
                  disabled={blocked}
                />
              </Form.Item>
              <Form.Item
                label={<span className="label">PLACA DEL VEHÍCULO</span>}
                name="plate"
                colon={false}
                rules={[{ required: true, message: 'Ingresa la placa del vehículo' }]}
              >
                <Input size="large" className="input" disabled={blocked} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 9 }}
            >
              <Form.Item
                label={<span className="label">NÚMERO DE TELÉFONO</span>}
                name="phone"
                colon={false}
                rules={[{ required: true, message: 'Ingresa un teléfono' }]}
              >
                <Input size="large" className="input" disabled={blocked} />
              </Form.Item>
              <Form.Item
                label={<span className="label">DIRECCIÓN DE RESIDENCIA</span>}
                name="address"
                colon={false}
                rules={[{ required: true, message: 'Ingresa una dirección' }]}
              >
                <Input size="large" className="input" disabled={blocked} />
              </Form.Item>
              <Form.Item
                label={<span className="label">NÚMERO DE IDENTIFICACIÓN (DUI O CÉDULA)</span>}
                name="national_id"
                colon={false}
                rules={[{ required: true, message: 'Ingresa una identificación' }]}
              >
                <Input size="large" className="input" disabled={blocked} />
              </Form.Item>
              <Form.Item
                name="gender"
                label={<span className="label">GÉNERO</span>}
              >
                <Select
                  className="select"
                  onChange={() => {}}
                  size="large"
                  dropdownClassName="select"
                  disabled={blocked}
                >
                  <Option value="male">Hombre</Option>
                  <Option value="female">Mujer</Option>
                </Select>
              </Form.Item>
              {isAdmin && (
                <Form.Item
                  name="branch"
                  label={<span className="label">SUCURSAL</span>}
                >
                  <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Please select"
                    treeDefaultExpandAll
                  >
                    <TreeNode value={0} title="Get Driver" />
                    {getBranches()}
                  </TreeSelect>
                </Form.Item>
              )}
              <Form.Item>
                <div className="actions">
                  {id !== 'new' && !blocked ? (
                    <Button
                      disabled={sending}
                      loading={loadingActive}
                      onClick={activation}
                      type="primary"
                      htmlType="button"
                      className="btn active-btn"
                    >
                      {isActive ? 'Desactivar' : 'Activar'}
                    </Button>
                  ) : (
                    <div />
                  )}
                  {!blocked && (
                    <Button loading={sending} htmlType="submit" className="btn save-btn" onClick={() => {}}>
                      Guardar
                    </Button>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal
          open={showMap}
          onCancel={() => handleShowMap(false)}
          footer={false}
          className="modal-map"
          destroyOnClose
          closeIcon={
            <div className="close-wrap">
              <X />
            </div>
          }
        >
          <Map
            latitude={editingDriver && editingDriver.latitude}
            longitude={editingDriver && editingDriver.longitude}
            name={editingDriver && editingDriver.name}
            photo={imageUrl}
          />
        </Modal>
      </div>
    </div>
  );
}

export default DriverEditorUI;
