import {Link} from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';
import {Spin, Row, Col} from 'antd';
import {BlobProvider} from '@react-pdf/renderer';
import Print from '../../cashflow/components/CloseCashierPrint';
import {getCurrency} from '../../../utils/format';
import TableOrders from '../../../components/TableOrders';
import '../styles/CashierCloseDetailUI.scss';

const CashierCloseDetailUI = props => {
  const {
    country,
    loading,
    details,
    partners,
    drivers,
    exportData,
    user,
  } = props;

  if (loading) {
    return (
      <div className="loader-wrap">
        <Spin size="large" />
      </div>
    );
  }

  const {summary, orders} = details;
  const getCurrencyCountry = val => getCurrency(country, val);
  const {
    initial_cash_balance,
    bill_coin_total,
    closing_date,
    pos_app,
    pos_callcenter,
    total_income,
    total_expenses,
    to_deposit,
    missing,
    surplus,
    cash_available,
    cashier_cash,
    cash,
    bill_1,
    bill_5,
    bill_10,
    bill_20,
    bill_50,
    bill_100,
    coin_1,
    coin_5,
    coin_10,
    coin_25,
    coin_100,
    ni_bill_1000,
    ni_bill_500,
    ni_bill_200,
    ni_bill_100,
    ni_bill_50,
    ni_bill_20,
    ni_bill_10,
    ni_bill_5,
    ni_coin_500,
    ni_coin_100,
    ni_coin_50,
    ni_coin_25,
    ni_coin_10,
    ni_coin_5,
    comments,
  } = summary;

  const Detail = ({label, quantity, value}) => (
    <div className="detail">
      <span className="label-detail">
        {label}
      </span>
      <span className="input-cash">
        {Math.round(quantity / value)}
      </span>
      <span className="amount">
        {getCurrencyCountry(quantity)}
      </span>
    </div>
  );

  const DetailSummary = ({label, value, gray, teal, red, bold, blue}) => (
    <div className="detail-summary">
      <span className={cx({bold})}>{label}</span>
      <span
        className={cx('value', {gray, teal, red, blue})}>
        {value}
      </span>
    </div>
  );

  const printSummary = url => {
    window.open(url, '_blank');
  };

  return (
    <div className="close-detail-wrap">
      <div className="main-titles">
        <div>
          <Link to="/cashflow/history" className="link">
            <i className="bi bi-arrow-left-short" /> Historial
          </Link>
          <h1 className="title">Cierre de caja</h1>
          <h3 className="subtitle">
            {moment(closing_date).format('dddd D [de] MMMM')}
          </h3>
        </div>
        <div className="number-orders">
          <div className="number-data">
            <h1 className="title">
              {orders.length}
            </h1>
            <h3 className="subtitle">
              Ordenes
            </h3>
          </div>
        </div>
      </div>
      <div className="actions">
        <button
          disabled={loading}
          onClick={exportData}
          className="action green"
        >
          Exportar
        </button>
        <BlobProvider
          document={
            <Print
              summary={summary}
              country={country}
              comments={summary.comments}
              name={user.username}
              date={moment(closing_date).format('dddd D [de] MMMM')}
            />
          }
        >
          {({ url }) => (
            <button
              disabled={loading}
              onClick={() => printSummary(url)}
              className="action blue"
            >
              Imprimir
            </button>
          )}
        </BlobProvider>
      </div>
      <div className="close-detail">
        <Row gutter={20}>
          <Col span={6}>
            <div className="summary">
              <DetailSummary
                label="Contado"
                gray
                value={getCurrencyCountry(bill_coin_total)}
              />
              <div className="horizontal" />
              <DetailSummary
                label="Apertura de caja" 
                value={getCurrencyCountry(initial_cash_balance)}
              />
              <DetailSummary
                label="Ingreso fondo de caja"
                gray
                value={getCurrencyCountry(cashier_cash)}
              />
              <DetailSummary
                label="Ingresos efectivo"
                gray
                value={getCurrencyCountry(cash)}
              />
              <DetailSummary
                label="Egresos"
                bold
                red
                value={`- ${getCurrencyCountry(total_expenses)}`}
              />
              <DetailSummary
                label="Efectivo según sistema"
                bold
                blue
                value={getCurrencyCountry(cash_available)}
              />
              <div className="horizontal" />
              <DetailSummary
                label="Valor a remesar"
                gray
                value={getCurrencyCountry(to_deposit)}
              />
              <DetailSummary
                label="(-) Faltante"
                gray
                value={getCurrencyCountry(missing)}
              />
              <DetailSummary
                label="(+) Sobrante"
                gray
                value={getCurrencyCountry(surplus)}
              />
              <div className="horizontal" />
              <DetailSummary
                label="Cobros día (Cash In)"
                gray
                value={getCurrencyCountry(cash)}
              />
              <DetailSummary
                label="Ingreso fondo de caja"
                gray
                value={getCurrencyCountry(cashier_cash)}
              />
              <DetailSummary
                label="POS App" 
                gray
                value={getCurrencyCountry(pos_app)}
              />
              <DetailSummary
                label="POS Callcenter"
                gray
                value={getCurrencyCountry(pos_callcenter)}
              />
              <DetailSummary
                label="Ingresos"
                bold
                teal
                value={getCurrencyCountry(total_income)}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="details">
              <div className="header-details">
                <span className="denomination">
                  Denominación
                </span>
                <span className="amount">
                  Cantidad
                </span>
                <span className="total">
                  Total
                </span>
              </div>
              {country === 'sv' ? (
                <div className="body-details">
                  <Detail
                    label="Billete de 100"
                    quantity={bill_100}
                    value={100}
                  />
                  <Detail
                    label="Billete de 50"
                    quantity={bill_50}
                    value={50}
                  />
                  <Detail
                    label="Billete de 20"
                    quantity={bill_20}
                    value={20}
                  />
                  <Detail
                    label="Billete de 10"
                    quantity={bill_10}
                    value={10}
                  />
                  <Detail
                    label="Billete de 5"
                    quantity={bill_5}
                    value={5}
                  />
                  <Detail
                    label="Billete de 1"
                    quantity={bill_1}
                    value={1}
                  />
                  <Detail
                    label="Moneda de 1"
                    quantity={coin_100}
                    value={1}
                  />
                  <Detail
                    label="Moneda de 0.25"
                    quantity={coin_25}
                    value={0.25}
                  />
                  <Detail
                    label="Moneda de 0.10"
                    quantity={coin_10}
                    value={0.10}
                  />
                  <Detail
                    label="Moneda de 0.05"
                    quantity={coin_5}
                    value={0.05}
                  />
                  <Detail
                    label="Moneda de 0.01"
                    quantity={coin_1}
                    value={0.01}
                  />
                </div>
              ) : (
                <div className="body-details">
                  <Detail
                    label="Billete de 1000"
                    quantity={ni_bill_1000}
                    value={1000}
                  />
                  <Detail
                    label="Billete de 500"
                    quantity={ni_bill_500}
                    value={500}
                  />
                  <Detail
                    label="Billete de 200"
                    quantity={ni_bill_200}
                    value={200}
                  />
                  <Detail
                    label="Billete de 100"
                    quantity={ni_bill_100}
                    value={100}
                  />
                  <Detail
                    label="Billete de 50"
                    quantity={ni_bill_50}
                    value={50}
                  />
                  <Detail
                    label="Billete de 20"
                    quantity={ni_bill_20}
                    value={20}
                  />
                  <Detail
                    label="Billete de 10"
                    quantity={ni_bill_10}
                    value={10}
                  />
                  <Detail
                    label="Billete de 5"
                    quantity={ni_bill_5}
                    value={5}
                  />
                  <Detail
                    label="Moneda de 5"
                    quantity={ni_coin_500}
                    value={5}
                  />
                  <Detail
                    label="Moneda de 1"
                    quantity={ni_coin_100}
                    value={1}
                  />
                  <Detail
                    label="Moneda de 0.50"
                    quantity={ni_coin_50}
                    value={0.50}
                  />
                  <Detail
                    label="Moneda de 0.25"
                    quantity={ni_coin_25}
                    value={0.25}
                  />
                  <Detail
                    label="Moneda de 0.10"
                    quantity={ni_coin_10}
                    value={0.10}
                  />
                  <Detail
                    label="Moneda de 0.05"
                    quantity={ni_coin_5}
                    value={0.05}
                  />
                </div>
              )}
            </div>
            <div className="comments">
              <span>{comments}</span>
            </div>
          </Col>
        </Row>
        <div className="orders-detail-close">
          <TableOrders
            orders={orders}
            filterOrders={() => {}}
            partners={partners}
            drivers={drivers}
          />
        </div>
      </div>
    </div>
  );
};

export default CashierCloseDetailUI;
