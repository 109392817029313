import { createSlice } from "@reduxjs/toolkit";
import { getOrdersRecords, getProfitsRecords } from "./Api";

// Reducer
export const chartsSlice = createSlice({
  name: "charts",
  initialState: {
    orders: {},
    profits: {},
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setProfits: (state, action) => {
      state.profits = action.payload;
    },
  },
});

//Actions

export const { setOrders, setProfits } = chartsSlice.actions;

export const getOrders = (config) => async (dispatch) => {
  try {
    const response = await getOrdersRecords(config);
    if (!response.error && response.status === 200) {
      dispatch(setOrders(response.data));
      return {
        status: "success",
      };
    }
    if (response.error.response.status === 401) {
      return {
        status: "error",
        type: "not-found",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unkown",
    };
  }
};

export const getProfits = (config) => async (dispatch) => {
  try {
    const response = await getProfitsRecords(config);
    if (!response.error && response.status === 200) {
      dispatch(setProfits(response.data));
      return {
        status: "success",
      };
    }
    if (response.error.response.status === 401) {
      return {
        status: "error",
        type: "not-found",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unkown",
    };
  }
};

export const selectOrders = (state) => state.charts.orders;
export const selectProfits = (state) => state.charts.profits;

export default chartsSlice.reducer;
