import {Table, Input, Tag, Spin} from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import {PersonPlusFill} from '../../../components/Icon';
import '../styles/UsersUI.scss';

const DriversUI = (props) => {
  const {
    users,
    loading,
    createUser,
    searching,
    editUser,
    onSearch,
  } = props;

  const {Search} = Input;

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Nombres',
      dataIndex: 'name',
    },
    {
      title: 'Apellidos',
      dataIndex: 'lastname',
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      align: 'center',
    },
    {
      title: 'Lealtad',
      dataIndex: 'loyalty_orders',
      align: 'center',
    },
  ];

  return (
    <div className="users-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Usuarios</h1>
          <h3 className="subtitle">{moment().format('dddd D [de] MMMM')}</h3>
        </div>
        {/* <div className="align-right">
          <h1 className="title">{drivers.length}</h1>
          <h3 className="subtitle">Motoristas</h3>
        </div> */}
      </div>
      <div className="actions">
        <div className="search-wrap">
          <Search
            placeholder="Buscar usuario..."
            enterButton
            size="large"
            loading={searching}
            onSearch={onSearch}
          />
        </div>
        <div className="main-actions">
          <button className="new-btn" onClick={createUser}>
            Crear nuevo Usuario <PersonPlusFill /> 
          </button>
        </div>
      </div>
      <div className="drivers-info">
        {searching ? (
          <div className="loader-wrap">
            <Spin size="large" />
          </div>
        ) : (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={users}
            onRow={(record) => {
              return {
                onClick: () => editUser(record.id), // click row
              };
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DriversUI;