import {Table} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {getCurrency} from '../../../utils/format';
import '../styles/History.scss';

const History = ({settlements, country, partners}) => {
  const getCurrencyCountry = val => getCurrency(country, val);

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'date',
      align: 'center',
      width: 200,
      render: val => (
        <span className="normal">
          {moment.tz(val, 'America/El_Salvador').format('DD/MM/YYYY, HH:mm a')}
        </span>
      ),
    },
    {
      title: 'Comercio',
      dataIndex: 'id_partner',
      align: 'center',
      width: 250,
      filters: partners,
      onFilter: (value, record) => record.id_partner.id === value,
      filterSearch: true,
      render: partner => (
        <span className="yellow">
          {partner.name}
        </span>
      ),
    },
    {
      title: 'No Confirmación',
      dataIndex: 'confirmation_number',
      align: 'center',
      width: 200,
      render: val => (
        <span className="normal">{val}</span>
      ),
    },
    {
      title: 'Ordenes',
      dataIndex: 'transactions',
      align: 'center',
      width: 100,
      render: val => (
        <span className="normal">{val}</span>
      ),
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      width: 100,
      align: 'center',
      render: val => getCurrencyCountry(val),
    },
    {
      title: 'Comisión',
      dataIndex: 'comision',
      width: 100,
      align: 'center',
      render: val => (
        <span className="teal">{getCurrencyCountry(val)}</span>
      ),
    },
    {
      title: 'IVA (13%)',
      dataIndex: 'iva',
      width: 100,
      align: 'center',
      render: val => (
        <span className="teal">{getCurrencyCountry(val)}</span>
      ),
    },
    {
      title: 'Reintegrar',
      dataIndex: 'repay',
      width: 100,
      align: 'center',
      render: val => (
        <span className="box yellow">{getCurrencyCountry(val)}</span>
      ),
    },
    {
      title: 'Cuenta',
      dataIndex: 'account_number',
      align: 'center',
      render: val => (
        <span className="normal">{val}</span>
      ),
    },
    {
      title: 'Ver',
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: val => (
        <Link
          className="close-detail"
          to={`/settlements/history/${val}`}
        >
          Detalle
        </Link>
      ),
    },
  ];

  return (
    <div className="history-settlements-wrap">
      <Table
        columns={columns}
        dataSource={settlements}
        rowKey="id"
        scroll={{ x: 1500 }}
      />
    </div>
  );
};

export default History;
