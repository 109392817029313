import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Modal, message} from 'antd';
import produce from 'immer';
import moment from 'moment';
import isFloat from 'validator/lib/isFloat';
import 'moment/locale/es';
import 'moment-timezone';
import {selectCountry, selectUser} from '../login/UserSlice';
import {
  getDailyCash,
  selectOrders,
  selectInitialCash,
  setInitialCashValue,
  setInitialCash,
  getCash,
  selectCashAvailable,
  getOrders,
  reviewAndCloseCashier,
  editRecord,
} from './CashflowSlice';
import CashflowUI from './components/CashflowUI';
import exportOrders from '../../utils/exportOrders';
import initialCashier from './utils/initialCash';

const Cashflow = () => {
  const user = useSelector(selectUser);
  const country = useSelector(selectCountry);
  const orders = useSelector(selectOrders);
  const initialCash = useSelector(selectInitialCash);
  const cashAvailable = useSelector(selectCashAvailable);
  const today = moment.tz('America/El_Salvador');
  const [cash, handleCash] = useState(initialCashier(country));
  const [cashSummary, handleCashSummary] = useState({});
  const [partners, handlePartners] = useState([]);
  const [drivers, handleDrivers] = useState([]);
  const [currentData, handleCurrentData] = useState([]);
  const [loading, handleLoading] = useState(true);
  const [exporting, handleExporting] = useState(false);
  const [loadingOpen, handleLoadingOpen] = useState(false);
  const [closeCashflow, handleCloseCashFlow] = useState(false);
  const [closeCashflow2, handleCloseCashFlow2] = useState(false);
  const dispatch = useDispatch();
  const isAdmin = user.role === 10;

  const getCashierOrders = useCallback(async () => {
    const response = await dispatch(getOrders());
    if (response.status !== 'success') {
      /* Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      }); */
    } else {
      handleDrivers(response.drivers);
      handlePartners(response.partners);
      handleCurrentData(response.orders);
    }
  }, [dispatch]);

  const getInitialCash = useCallback(async () => {
    const response = await dispatch(getDailyCash());
    if (response.status !== 'success') {
      /* Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      }); */
    }
  }, [dispatch]);

  const getCashAvailable = useCallback(async () => {
    const response = await dispatch(getCash());
    if (response.status !== 'success') {
      /* Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      }); */
    }
  }, [dispatch]);

  const requestInformation = useCallback(async () => {
    await getCashierOrders();
    await getCashAvailable();
    await getInitialCash();
  }, [getCashAvailable, getCashierOrders, getInitialCash]);

  useEffect(() => {
    const initialFetch = async () => {
      await requestInformation();
      handleLoading(false);
    };
    initialFetch();
  }, [requestInformation]);

  useEffect(() => {
    const countup = setInterval(requestInformation, 30000);

    return () => {
      clearInterval(countup);
    };
  }, [requestInformation]);

  const handleInitialCash = e => {
    const value = e.target.value;
    if (isFloat(value) || value === '') {
      dispatch(setInitialCashValue(value));
    }
  };

  const openCloseCashier = () => {
    if (initialCash.created) {
      handleCloseCashFlow(true)
    } else {
      Modal.error({
        title: 'Caja no aperturada',
        content: 'Se debe aperturar la caja para realizar el corte.',
      });
    }
  }

  const confirmClose = (done, comments) => {
    Modal.confirm({
      title: '¿Estás seguro de cerrar la caja con esta información?',
      content: 'Esta operación no se podrá deshacer.',
      okText: 'Cerrar caja',
      cancelText: 'Cancelar',
      async onOk() {
        await closeCashier(done, comments);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const confirmOpen = () => {
    const amount = initialCash.value;
    if (amount !== '' && parseFloat(amount) > 0) {
      Modal.confirm({
        title: '¿Estás seguro de abrir la caja con este monto?',
        content: 'Esta operación no se podrá deshacer hasta el siguiente día.',
        okText: 'Aperturar',
        cancelText: 'Cancelar',
        onOk() {
          openCashier();
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    } else {
      Modal.error({
        title: 'Ingresa una cantidad',
        content: 'Debes ingresar un monto para continuar.',
      });
    }
  };

  const openCashier = async () => {
    handleLoadingOpen(true);
    const response = await dispatch(
      setInitialCash(initialCash.value)
    );
    if (response.status !== 'success') {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    await getCashAvailable();
    handleLoadingOpen(false);
  };

  const editRecordCashier = async (id, cash, card, cash_out, index) => {
    const data = {
      id,
      cash,
      card,
      cash_out,
      role: 'agent',
    };
    const response = await dispatch(
      editRecord(data, index)
    );
    if (response.status !== 'success') {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    } else {
      await getCashAvailable();
      message.success('El registro se ha modificado correctamente');
    }
  };

  const closeCashier = async (done, comments) => {
    const realCash = produce(cash, draftState => {
      draftState.done = `${done}`;
      if (done) {
        draftState.comments = comments;
      }
    });
    const response = await dispatch(
      reviewAndCloseCashier(realCash)
    );
    if (response.status !== 'success') {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    } else if (done) {
      handleCloseCashFlow2(false);
      handleCloseCashFlow(false);
      handleCashSummary({});
      handleCash(initialCashier);
      handleCurrentData([]);
      Modal.success({
        title: 'Se ha cerrado la caja',
        content: 'La información se ha guardado correctamente.',
      });
    } else {
      handleCashSummary(response.summary);
      handleCloseCashFlow2(true);
    }
  };

  const exportData = async () => {
    handleExporting(true);
    await exportOrders(currentData, today);
    handleExporting(false);
  };

  return (
    <CashflowUI
      today={today}
      orders={orders}
      country={country}
      loading={loading}
      partners={partners}
      cashAvailable={cashAvailable}
      loadingOpen={loadingOpen}
      cash={cash}
      openCloseCashier={openCloseCashier}
      exporting={exporting}
      exportData={exportData}
      confirmClose={confirmClose}
      handleCash={handleCash}
      handleInitialCash={handleInitialCash}
      initialCash={initialCash}
      closeCashflow={closeCashflow}
      handleCloseCashFlow={handleCloseCashFlow}
      closeCashflow2={closeCashflow2}
      confirmOpen={confirmOpen}
      handleCloseCashFlow2={handleCloseCashFlow2}
      closeCashier={closeCashier}
      cashSummary={cashSummary}
      editRecordCashier={editRecordCashier}
      isAdmin={isAdmin}
      drivers={drivers}
      currentData={currentData}
      handleCurrentData={handleCurrentData}
    />
  );
};

export default Cashflow;
