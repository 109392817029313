import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Input, Table, Modal, message} from 'antd';
import {searchUser} from '../../users/UsersSlice';
import {setUserOrder, setAddressOrder} from '../TakeOrdersSlice';
import '../styles/ClientInfo.scss';

const { Search } = Input;

const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Apellido',
    dataIndex: 'lastname',
    key: 'lastname',
  },
  {
    title: 'Teléfono casa',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Teléfono móvil',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  {
    title: 'Correo electrónico',
    dataIndex: 'email',
    key: 'email',
  },
];


const ClientInfo = ({getLastOrders, users, handleUsers}) => {
  const [sending, handleSending] = useState(false);
  const dispatch = useDispatch();

  const onSearch = async (search) => {
    handleSending(true);
    if (search.length < 3) {
      Modal.error({
        title: 'Busqueda muy corta',
        content: 'Intenta buscar con más caracteres',
      });
    } else {
      const response = await dispatch(searchUser(search));
      if (response.status !== 'success') {
        Modal.error({
          title: 'Hubo un error',
          content: 'Inténtalo de nuevo más tarde',
        });
      } else {
        handleUsers(response.users);
      }
    }
    handleSending(false);
  };

  const selectUser = (user) => {
    dispatch(setUserOrder(user));
    dispatch(setAddressOrder({}));
    getLastOrders(user.id);
    message.success('Se ha seleccionado el usuario para la orden');
  };

  return (
    <div className="client-wrap-take-order">
      <div className="client-header">
        <Link to="/" className="back">
          <i className="bi bi-arrow-left-short" />
        </Link>
        <div className="title-wrap">
          <span className="title">Cliente</span>
          <Link
            to={{pathname: '/users/new', state: {take_order: true}}}
            className="add-btn">
            <span>
              Crear usuario
            </span>
            <i className="bi bi-person-plus-fill" />
          </Link>
        </div>
        <Search
          className="input"
          placeholder="Buscar por teléfono"
          loading={sending}
          onSearch={onSearch}
          enterButton
          size="large"
        />
      </div>
      <div className="table-clients">
        <Table
          onRow={(record) => {
            return {
              onClick: () => selectUser(record),
            };
          }}
          dataSource={users}
          columns={columns}
          rowKey="id"
        />
      </div>
    </div>
  );
}

export default ClientInfo;