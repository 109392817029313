import { Row, Col, Spin, Modal, Drawer } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import cx from "classnames";
import {
  TvFill,
  PhoneFill,
  CartFill,
  Bicycle,
  ExclamationCircleFill,
  X,
} from "../../../components/Icon";
import Order from "./Order";
import CancelOrder from "./CancelOrder";
import Details from "./Details";
import OrderDetail from "./OrderDetail";
import Map from "./Map";
import "../styles/MonitorUI.scss";

const MonitorUI = (props) => {
  const {
    showDetails,
    open,
    close,
    loading,
    orders,
    showOrder,
    handleShowOrder,
    showMap,
    mode,
    handleShowMap,
    handleMode,
    filteredOrders,
    filterOrders,
    tuNicaragua,
    isDispatch,
    showCancel,
    handleShowCancel,
    cancelOrder,
    country,
  } = props;

  const getTotalOrders = (type) => {
    const filter = orders.filter((el) => el.platform === type);
    return filter.length;
  };

  if (loading) {
    return (
      <div className="loader-wrap">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="monitor-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Monitor</h1>
          <h3 className="subtitle">{moment().format("dddd D [de] MMMM")}</h3>
        </div>
        <div className="number-orders">
          <div className="number-data">
            <h1 className="title">{orders.length}</h1>
            <h3 className="subtitle">Pedidos Activos</h3>
          </div>
          {!tuNicaragua && (
            <>
              <div className="number-data">
                <h1 className="title">{getTotalOrders("app")}</h1>
                <h3 className="subtitle">Pedidos App</h3>
              </div>
              <div className="number-data">
                <h1 className="title">{getTotalOrders("callcenter")}</h1>
                <h3 className="subtitle">Pedidos Llamada</h3>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="filters-wrap">
        <Row>
          {!tuNicaragua && (
            <Col xs={20}>
              <div className="filters">
                <button
                  className={cx("filter", { active: mode === "all" })}
                  onClick={() => handleMode("all")}
                >
                  {`${orders.length} Todos`}
                  <TvFill />
                </button>
                <button
                  className={cx("filter", { active: mode === "new" })}
                  onClick={() => handleMode("new")}
                >
                  {`${filterOrders("new").length} Nuevas`}
                  <ExclamationCircleFill />
                </button>
                <button
                  className={cx("filter", { active: mode === "preparing" })}
                  onClick={() => handleMode("preparing")}
                >
                  {`${filterOrders("preparing").length} Preparando`}
                  <PhoneFill />
                </button>
                <button
                  className={cx("filter", "on-the-way", {
                    active: mode === "delivery",
                  })}
                  onClick={() => handleMode("delivery")}
                >
                  {`${filterOrders("delivery").length} En Camino`}
                  <Bicycle />
                </button>
              </div>
            </Col>
          )}
          <Col xs={tuNicaragua ? 24 : 4}>
            {!isDispatch && (
              <div className="take-orders">
                <Link to="/take-orders">
                  Toma pedidos
                  <CartFill />
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div className="orders">
        <Row gutter={[16, 16]}>
          {filteredOrders.map((order) => (
            <Col
              key={order.id}
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
              xxl={{ span: 6 }}
            >
              <Order
                open={open}
                order={order}
                tuNicaragua={tuNicaragua}
                country={country}
              />
            </Col>
          ))}
        </Row>
      </div>
      <Drawer
        className="details-drawer"
        placement="right"
        destroyOnClose={true}
        width={470}
        closable={false}
        onClose={close}
        visible={showDetails}
      >
        <Details
          handleShowOrder={handleShowOrder}
          handleShowMap={handleShowMap}
          close={close}
          cancelOrder={() => handleShowCancel(true)}
        />
      </Drawer>
      <Modal
        title="Detalle del pedido"
        destroyOnClose
        visible={showOrder}
        onCancel={() => handleShowOrder(false)}
        footer={false}
      >
        <OrderDetail />
      </Modal>
      <Modal
        title="Cancelar Pedido"
        destroyOnClose
        visible={showCancel}
        onCancel={() => handleShowCancel(false)}
        footer={false}
      >
        <CancelOrder cancelOrder={cancelOrder} />
      </Modal>
      <Modal
        visible={showMap}
        onCancel={() => handleShowMap(false)}
        footer={false}
        className="modal-map"
        destroyOnClose
        closeIcon={
          <div className="close-wrap">
            <X />
          </div>
        }
      >
        <Map />
      </Modal>
    </div>
  );
};

export default MonitorUI;
