import {
  Table,
  Select,
  Input,
  DatePicker,
  Button,
  Form,
  Row,
  Col,
  Spin,
} from 'antd';
import {Link} from 'react-router-dom';
import Amount from './InputNumber';
import columns from '../utils/columns';
import '../styles/DepositDebitUI.scss';
const {Option} = Select;
const {RangePicker} = DatePicker;

const DawRecordsUI = props => {
  const {
    today,
    records,
    country,
    loading,
    beginDate,
    endDate,
    categories,
    handleDates,
    createDawRecord,
    form,
    sending,
    deleteRecordDaw,
    exporting,
    exportRecords,
  } = props;

  const getCategories = () => (
    categories.map(category => {
      const {id, name, type} = category;
      return (
        <Option value={id}>
          {`${name} / ${type === 'Deposit' ? 'Abono' : 'Retiro'}`}
        </Option>
      );
    })
  );

  return (
    <div className="depositdebit-wrap">
      <div className="main-titles">
        <div>
          <Link to="/cashflow" className="link">
            <i className="bi bi-arrow-left-short" /> Caja
          </Link>
          <h1 className="title">Abonos y Retiros</h1>
          <h3 className="subtitle">
            {today.format('dddd D [de] MMMM')}
          </h3>
        </div>
      </div>
      <div className="initial-wrap">
        <RangePicker
          disabled={loading}
          size="large"
          placeholder={['Fecha Inicio', 'Fecha Final']}
          allowClear={false}
          value={[beginDate, endDate]}
          onChange={handleDates}
        />
        <Button
          className="export-btn"
          size="large"
          loading={exporting}
          onClick={exportRecords}
          type="primary">
          Exportar
        </Button>
      </div>
      <div className="actions-wrap">
        <Form
          form={form}
          className="form"
          autoComplete="off"
          onFinish={createDawRecord}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="category"
                rules={[{ required: true, message: 'Ingresa categoría' }]}
              >
                <Select
                  disabled={loading}
                  className="input"
                  size="large"
                  placeholder="Categoría"
                >
                  {getCategories()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="comments"
              >
                <Input
                  disabled={loading}
                  placeholder="Comentarios"
                  size="large"
                  defaultValue=""
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="amount"
                rules={[{ required: true, message: 'Ingresa monto' }]}
              >
                <Amount loading={loading} />
              </Form.Item>
            </Col>
            <Col span={4} offset={2} className="btn-wrap">
              <Form.Item>
                <Button
                  className="create-btn"
                  size="large"
                  loading={sending}
                  htmlType="submit"
                  disabled={loading}
                  type="primary">
                  Crear
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {!loading ? (
        <div className="deposit-debit">
          <Table
            dataSource={records}
            columns={columns(country, categories, deleteRecordDaw)}
          />
        </div>
      ) : (
        <div className="loader-wrap">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default DawRecordsUI;