import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Modal} from 'antd';
import {saveAs} from 'file-saver';
import produce from 'immer';
import XLSX from 'xlsx';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {selectCountry, selectUser} from '../login/UserSlice';
import {getOrders, selectOrders} from './ReportsSlice';
import { partnersFeed } from '../take-orders/TakeOrdersSlice';
import ReportsUI from './components/ReportsUI';

const Reports = () => {
  const user = useSelector(selectUser);
  const country = useSelector(selectCountry);
  const today = moment.tz('America/El_Salvador');
  const [selectedPartners, handleSelectedPartners] = useState([]);
  const [partners, handlePartners] = useState([]);
  const [loading, handleLoading] = useState(true);
  const [exporting, handleExporting] = useState(false);
  const [loadingPartners, handleLoadingPartners] = useState(true);
  const [endDate, handleEndDate] = useState(today);
  const [beginDate, handleBeginDate] = useState(
    moment.tz('America/El_Salvador').subtract(1, 'days')
  );
  const orders = useSelector(selectOrders);
  const dispatch = useDispatch();
  const searchOrders = useCallback(async (newdBegin, newEnd, searchVal) => {
    handleLoading(true);
    const begin = newdBegin || beginDate
    const end = newEnd || endDate;
    const partnersIds = selectedPartners.map((partner) => {
      const arrPartner = partner.split('-');
      return arrPartner[1];
    });
    const config = {
      status: [12, 14],
      startDate: begin.format('YYYY-MM-DD 00:00:00'),
      endDate: end.format('YYYY-MM-DD 23:59:59'),
      role: user.role,
      partners: partnersIds,
    };
    if (searchVal) {
      config.ticket = searchVal;
    }
    const response = await dispatch(getOrders(config));
    if (response.status !== 'success') {
      Modal.warning({
        title: 'Hubo un problema',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleLoading(false);
  }, [beginDate, dispatch, endDate, selectedPartners, user.role]);

  const getPartners = useCallback(async () => {
    const response = await dispatch(partnersFeed());
    if (response.status === 'success') {
      const partnersList = response.partners.map((partner) => {
        return {label: partner.name, value: `${partner.name}-${partner.id}`};
      });
      handlePartners(partnersList);
    }
    handleLoadingPartners(false);
  }, [dispatch]);

  const handleDates = (newDates) => {
    handleBeginDate(newDates[0]);
    handleEndDate(newDates[1]);
    searchOrders(newDates[0], newDates[1]);
  };

  const orderSearch = (val) => {
    searchOrders(false, false, val);
  };

  const selectPartner = (newList) => {
    handleSelectedPartners(newList);
  };

  const deselectPartner = (partner) => {
    const index = selectedPartners.indexOf(partner);
    const newPartners = produce(selectedPartners, (draftState) => {
      draftState.splice(index, 1);
    });
    handleSelectedPartners(newPartners);
  };

  const s2ab = (s) => { 
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;    
  };

  const getSubtotal = (subtotal, delivery, total) => {
    const newSubtotal = subtotal || (total - delivery);
    return newSubtotal;
  };

  const getPartnerAmount = (subtotal, delivery, total, fee) => {
    const deliveryFee = getSubtotal(subtotal, delivery, total) - fee;
    return deliveryFee;
  };

  const exportData = () => {
    handleExporting(true);
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: 'Reporte Ordenes Get',
      Subject: 'Reporte de ordenes ',
      Author: 'Get Technologies',
      CreatedDate: new Date(),
    };
    
    wb.SheetNames.push('Ordenes');
    const ws_data = [
      ['Fecha' , 'Ticket', 'Comercio', 'Subtotal', 'Comisión', 'IVA', 'Total', 'Reintegrar', 'Método']
    ];

    orders.forEach(order => {
      const newRegister = [
        moment.tz(order.created_time, 'America/El_Salvador').format('DD/MM/YYYY, HH:mm a'),
        order.ticket,
        order.id_partner ? order.id_partner.name : '-',
        getSubtotal(order.subtotal, order.delivery_fee, order.total),
        order.get_fee || 0,
        order.iva || 0,
        order.total,
        order.get_fee ? getPartnerAmount(order.subtotal, order.delivery_fee, order.total, order.get_fee) : 0,
        order.payment_method === 1 ? 'Efectivo' : 'Tarjeta',
      ];
      ws_data.push(newRegister);
    });
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets['Ordenes'] = ws;
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'ReporteOrdenesGet.xlsx');
    handleExporting(false);
  };

  useEffect(() => {
    searchOrders();
    getPartners();
  }, [getPartners, searchOrders]);

  return (
    <ReportsUI
      orders={orders}
      country={country}
      beginDate={beginDate}
      endDate={endDate}
      today={today}
      loading={loading}
      handleDates={handleDates}
      orderSearch={orderSearch}
      partners={partners}
      loadingPartners={loadingPartners}
      selectPartner={selectPartner}
      deselectPartner={deselectPartner}
      exportData={exportData}
      exporting={exporting}
    />
  );
};

export default Reports;
