import axios from "axios";
import { getSettlements } from "../getters-cashflow/Api";

export async function getOrdersHistory(filters) {
  const store = require("../../store/index").default;
  let newState = await store.getState();
  const country = newState.user.country;
  const token = localStorage.getItem("get_admin");

  return await axios.get(
    "https://prod.getlaapp.com/settlements/cashier-records",
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        country: country,
      },
    }
  );
}

export async function getLiquidations(begin, end) {
//  return await getSettlements(begin, end);
  const store = require("../../store/index").default;
  let newState = await store.getState();
  const country = newState.user.country;
  const token = localStorage.getItem("get_admin");

  return await axios.post(
    "https://prod.getlaapp.com/drivers/settlements",
    { start_date: begin, end_date: end },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        country: country,
      },
    },
    
  );
}
