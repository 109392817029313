import FeedSortDetailUI from './components/FeedSortDetailUI';
import {useState, useCallback, useEffect} from 'react';
import { Modal } from 'antd';

import {useHistory} from 'react-router-dom';
import { getFeedSortDetail, createFeedSort, updateFeedSort, deleteFeedSort } from './FeedSortDetailUISlice';
import { useDispatch } from 'react-redux';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const FeedSortDetail = () => {

  const history = useHistory();

  const dispatch = useDispatch();

  const [loading, handleLoading] = useState(true);
  const [loadingActive, handleLoadingActive] = useState(false);

  const {id} = useParams();
  const [name, handleName] = useState('');
  const [dtSort, handledtSort] = useState([]);
  const [partners, handlePartners] = useState([]);
  const [categories, handleCategories] = useState([]);

  const today = moment.tz('America/El_Salvador');

  const [startDate, handleStartDate] = useState();
  const [endDate, handleEndDate] = useState();

  const [startTime, handleStartTime] = useState('');
  const [endTime, handleEndTime] = useState('');

  const [days, handleDays] = useState('');

  const [active, handleActive] = useState(false);

  const [banner, handleBanner] = useState('');

  const initialFetch = useCallback(async () => {
    const response = await dispatch(getFeedSortDetail(id));
    if (response.status === 'success') {
      handledtSort(response.dt_sort);
      handleCategories(response.dt_sort.categories);
      handlePartners(response.dt_sort.partners);

      handleStartDate(moment(response.dt_sort.start_date).tz('America/El_Salvador'));
      handleEndDate(moment(response.dt_sort.end_date).tz('America/El_Salvador'));

      handleStartTime(moment(`2021-01-01T${response.dt_sort.start_time}`).tz('America/El_Salvador'));
      handleEndTime(moment(`2021-01-01T${response.dt_sort.end_time}`).tz('America/El_Salvador'));

      const daysArr = JSON.parse(response.dt_sort.days);
    
      handleDays(daysArr);

      handleActive(response.dt_sort.active);

      handleBanner(response.dt_sort.banner);

      handleName(response.dt_sort.name);
    }
    handleLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (id !== 'new') {
      initialFetch();
    } else {
      handleName('Crear nuevo orden');
      handleLoading(false);
    }
  }, [initialFetch]);

  const deleteSort = async () => {
    Modal.confirm({
      title: 'Eliminar',
      icon: <ExclamationCircleOutlined />,
      content: '¿Deseas eliminar este horario programado?',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      async onOk() {
        await deleteSortDetail();
      },
    });
  }

  const deleteSortDetail = async () => {
    const response = await dispatch(deleteFeedSort(id));
    console.log(response);
    if (response.status === 'success') {
      Modal.success({
        title: 'Eliminado correctamente',
        content: 'Se ha eliminado el orden de los comercios',
      });
      history.push(`/feed-sort`);
    } else {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
  }

  // Saving
  const activation = async () => {
    handleLoadingActive(true);
    try {
      const start_date = moment(startDate).tz('America/El_Salvador').format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      const end_date = moment(endDate).tz('America/El_Salvador').format('YYYY-MM-DDTHH:mm:ss.SSSSZ');

      const start_time = moment(startTime).tz('America/El_Salvador').format('HH:mm:ss');
      const end_time = moment(endTime).tz('America/El_Salvador').format('HH:mm:ss');

      var new_categories = [];
      var new_partner = [];

      categories.forEach(category => {
        new_categories.push(category.id);
      });

      partners.forEach(partner => {
        new_partner.push(partner.id);
      });

      const new_dtsort = {
        id: dtSort.id,
        start_date: start_date,
        end_date: end_date,
        start_time: start_time,
        end_time: end_time,
        days: days,
        name: name,
        active: active,
        banner: banner,
        categories: new_categories,
        partners: new_partner
      }

      var response;

      if (id !== 'new') {
        response = await dispatch(updateFeedSort(new_dtsort));
      } else {
        // dtSort
        new_dtsort.categories = [];
        new_dtsort.partners = [];
        response = await dispatch(createFeedSort(new_dtsort));
      }

      if (response.status === 'success') {
        console.log(response);
        if (id === 'new') {
          history.push(`/feed-sort-detail/${response.dtSort.id}`);
          history.go();
        } else {
          Modal.success({
            title: 'Orden actualizado',
            content: 'Se ha actualizado el orden de los comercios',
          });
        }
      } else {
        Modal.error({
          title: 'Hubo un error',
          content: 'Inténtalo de nuevo más tarde',
        });
      }
    } catch (error) {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleLoadingActive(false);
  };

  return (
    <FeedSortDetailUI loading={loading} loadingActive={loadingActive} name={name} handleName={handleName} activation={activation} dtSort={dtSort} categories={categories} partners={partners} handledtSort={handledtSort} handleCategories={handleCategories} handlePartners={handlePartners} startDate={startDate} handleStartDate={handleStartDate} endDate={endDate} handleEndDate={handleEndDate} startTime={startTime} handleStartTime={handleStartTime} endTime={endTime} handleEndTime={handleEndTime} days={days} handleDays={handleDays} active={active} handleActive={handleActive} banner={banner} handleBanner={handleBanner} deleteSort={deleteSort}/>
  );
};

export default FeedSortDetail;
