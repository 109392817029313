import { Link } from "react-router-dom";
import { DatePicker, Spin, Row, Col } from "antd";
import History from "./History";
import "../styles/SettlementsHistoryUI.scss";

const { RangePicker } = DatePicker;

const SettlementsHistoryUI = (props) => {
  const {
    settlements,
    country,
    today,
    beginDate,
    endDate,
    loading,
    handleDates,
    partners,
  } = props;

  return (
    <div className="settlements-history-wrap">
      <div className="main-titles">
        <div>
          <Link to="/settlements" className="link">
            <i className="bi bi-arrow-left-short" /> Pendientes
          </Link>
          <h1 className="title">Acreditaciones</h1>
          <h3 className="subtitle">{today.format("dddd D [de] MMMM")}</h3>
        </div>
        <div className="number-orders">
          <div className="number-data">
            <h1 className="title">{settlements.length}</h1>
            <h3 className="subtitle">Acreditaciones</h3>
          </div>
        </div>
      </div>
      <div className="filters-wrap actions">
        <Row gutter={20}>
          <Col xs={6}>
            <RangePicker
              disabled={loading}
              size="large"
              placeholder={["Fecha Inicio", "Fecha Final"]}
              allowClear={false}
              value={[beginDate, endDate]}
              onChange={handleDates}
            />
          </Col>
        </Row>
      </div>
      {loading ? (
        <div className="loader-wrap">
          <Spin size="large" />
        </div>
      ) : (
        <History
          settlements={settlements}
          country={country}
          partners={partners}
        />
      )}
    </div>
  );
};

export default SettlementsHistoryUI;
