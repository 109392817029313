import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./pages/login/Main";
import Dashboard from "./pages/dashboard/Main";

const App = () => {
  const user = useSelector((state) => state.user);
  return (
    <Router>
      <Switch>
        <Route path="/">{user.info ? <Dashboard /> : <Login />}</Route>
      </Switch>
    </Router>
  );
};

export default App;
