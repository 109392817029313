import {Button, Input} from 'antd';
import Address from './AddressUI';
import '../styles/Addresses.scss';
import { useEffect, useState } from 'react';

const Addresses = ({addresses, addressSelected, newAddress, editAddress, deleteAddress, selectAddress}) => {
  const [searchTerm,setSearchTerm] = useState('')
  const [listAddresses,setListAddresses] = useState([])

  useEffect(() => {
    
    if(searchTerm === '') {
      setListAddresses(addresses)

      return
    };

    const timer = setTimeout(() => {
      const results = addresses.filter((item) => {
        if(item.name.toLowerCase().includes(searchTerm.toLowerCase())){
          return item
        }
      })
      setListAddresses(results)

    }, 2000);
   
  
    return () => {
      clearTimeout(timer)
    }
    
  }, [searchTerm,addresses])
 
  
  return (
    <div className="addresses-wrap-take-order">
      <div className="addresses-list">
        <div className='search-input'>
        Buscar
        <Input
          placeholder="Buscar dirección"
          size="middle"
          onChange={(e)=> setSearchTerm(e.target.value)}
        />
        </div>
       
        {listAddresses.map((address, index) => (
          <Address
            key={address.id}
            address={address}
            editable
            selected={addressSelected.id && addressSelected.id === address.id}
            onEdit={editAddress}
            index={index}
            onDelete={deleteAddress}
            select={() => selectAddress(address)}
          />
        ))}
      </div>
      <div className="new-address-wrap">
        <Button
          size="large"
          block
          onClick={newAddress}
        >
          Nueva Dirección
        </Button>
      </div>
    </div>
  );
};

export default Addresses;
