import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, message } from "antd";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import isFloat from "validator/lib/isFloat";
import { selectCountry } from "../login/UserSlice";
import {
  getDrivers,
  selectDrivers,
  getSettlementsList,
  selectSettlements,
  openSettlement,
  endSettlement,
  editBonusSettlement,
} from "./GettersCashflowSlice";
import GettersCashflowUI from "./components/GettersCashflowUI";

const GettersCashflow = () => {
  const today = moment.tz("America/El_Salvador");
  const drivers = useSelector(selectDrivers);
  const country = useSelector(selectCountry);
  const settlements = useSelector(selectSettlements);
  const [exporting, handleExporting] = useState(false);
  const [initialCash, handleInitialCashValue] = useState("");
  const [driverSelected, handleDriverSelected] = useState(null);
  const [cancelModal, handleCancelModal] = useState(false);
  const [settlementToClose, handleSettlementToClose] = useState(null);
  const [loadingOpen, handleLoadingOpen] = useState(false);
  const [loading, handleLoading] = useState(true);
  const [loadingDrivers, handleLoadingDrivers] = useState(true);
  const [endDate, handleEndDate] = useState(today);
  const [beginDate, handleBeginDate] = useState(today);
  const [driversUsed, handleDriversUsed] = useState([]);
  const dispatch = useDispatch();

  const getDriversAvailables = useCallback(async () => {
    const response = await dispatch(getDrivers());
    if (response.status === "success") {
    }
    handleLoadingDrivers(false);
  }, [dispatch]);

  const searchSettlements = useCallback(
    async (newdBegin, newEnd) => {
      const begin = newdBegin || beginDate;
      const end = newEnd || endDate;
      handleLoading(true);
      const start_date = begin.format("YYYY-MM-DD 00:00:00");
      const end_date = end.format("YYYY-MM-DD 23:59:59");
      const response = await dispatch(getSettlementsList(start_date, end_date));
      if (response.status !== "success") {
        Modal.warning({
          title: "Hubo un problema",
          content: "Inténtalo de nuevo más tarde",
        });
      }
      handleLoading(false);
    },
    [beginDate, dispatch, endDate]
  );

  useEffect(() => {
    getDriversAvailables();
    searchSettlements();
  }, [getDriversAvailables, searchSettlements]);

  useEffect(() => {
    const newDriversUsed = [];
    settlements.map((settlement) => {
      const index = newDriversUsed.findIndex(
        (el) => el.value === settlement.id_driver.id
      );
      if (index < 0) {
        newDriversUsed.push({
          text: `(${settlement.id_driver.id}) ${settlement.id_driver.name} ${settlement.id_driver.lastname}`,
          value: settlement.id_driver.id,
        });
      }
    });
    handleDriversUsed(newDriversUsed);
  }, [settlements]);

  const handleInitialCash = (e) => {
    const value = e.target.value;
    if (isFloat(value) || value === "") {
      handleInitialCashValue(value);
    }
  };

  const confirmOpen = () => {
    if (initialCash !== "" && parseFloat(initialCash) > 0 && driverSelected) {
      Modal.confirm({
        title: "¿Estás seguro de aperturar Getter con este monto?",
        content: "Esta operación no se podrá deshacer.",
        okText: "Aperturar",
        cancelText: "Cancelar",
        onOk() {
          openDriver();
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    } else if (!driverSelected) {
      Modal.error({
        title: "Selecciona un Getter",
        content: "Debes seleccionar un Getter para aperturar.",
      });
    } else {
      Modal.error({
        title: "Ingresa una cantidad",
        content: "Debes ingresar un monto para continuar.",
      });
    }
  };

  const openDriver = async () => {
    handleLoadingOpen(true);
    const index = drivers.findIndex((el) => el.id === driverSelected);
    const response = await dispatch(
      openSettlement(driverSelected, parseFloat(initialCash), drivers[index])
    );
    if (response.status !== "success") {
      Modal.error({
        title: "Hubo un error",
        content: "Inténtalo de nuevo más tarde",
      });
    } else {
      handleDriverSelected(null);
      handleInitialCashValue("");
    }
    handleLoadingOpen(false);
  };

  const handleDates = (newDates) => {
    handleBeginDate(newDates[0]);
    handleEndDate(newDates[1]);
    searchSettlements(newDates[0], newDates[1]);
  };

  const openModal = (record) => {
    handleSettlementToClose(record);
    handleCancelModal(true);
  };

  const closeSettlement = async (info) => {
    if (
      info.closing_cash_balance !== "" &&
      parseFloat(info.closing_cash_balance) > 0
    ) {
      endSettlement();
      const response = await dispatch(endSettlement(info, settlementToClose));
      if (response.status !== "success") {
        Modal.error({
          title: "Hubo un error",
          content: "Inténtalo de nuevo más tarde",
        });
      } else {
        handleCancelModal(false);
        handleSettlementToClose(null);
        Modal.success({
          title: "Completado",
          content: "Se ha liquidado a este Getter.",
        });
      }
    } else {
      Modal.error({
        title: "Ingresa una cantidad",
        content: "Debes ingresar un monto para continuar.",
      });
    }
  };

  const editBonus = async (settlement, bonus, index) => {
    const response = await dispatch(
      editBonusSettlement(settlement, bonus, index)
    );
    if (response.status !== "success") {
      Modal.error({
        title: "Hubo un error",
        content: "Inténtalo de nuevo más tarde",
      });
    } else {
      message.success("El bono se ha modificado correctamente");
    }
  };

  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const exportSettlement = async () => {
    handleExporting(true);
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Reporte Liquidaciones Drivers Get",
      Subject: "Reporte de liquidaciones drivers ",
      Author: "Get Technologies",
      CreatedDate: new Date(),
    };
    wb.SheetNames.push("Liquidaciones");
    const ws_data = [
      [
        "Id",
        "Getter",
        "Fecha Apertura",
        "Apertura",
        "Bono",
        "Balance",
        "Pago Driver",
        "A Remesar",
        "Flotante",
        "Sobrante",
        "Fecha Cierre",
        "Referencia",
        "Comentarios",
        "Ordenes",
      ],
    ];

    settlements.forEach((settlement) => {
      const newRegister = [
        settlement.id,
        `${settlement.id_driver.name} ${settlement.id_driver.lastname}`,
        moment(settlement.opening_date).format("DD/MM/YYYY, HH:mm a"),
        settlement.initial_cash_balance,
        settlement.bonus || "",
        settlement.balance - settlement.bonus,
        settlement.driver_payment,
        settlement.closing_cash_balance,
        settlement.missing || "",
        settlement.surplus || "",
        settlement.closing_date
          ? moment(settlement.closing_date).format("DD/MM/YYYY, HH:mm a")
          : "",
        settlement.codeRef || "",
        settlement.comments || "",
        settlement.transactions,
      ];
      ws_data.push(newRegister);
    });
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Liquidaciones"] = ws;
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "ReporteLiquidacionesDrivers.xlsx"
    );
    handleExporting(false);
  };

  return (
    <GettersCashflowUI
      today={today}
      loadingDrivers={loadingDrivers}
      loading={loading}
      drivers={drivers}
      driverSelected={driverSelected}
      handleDriverSelected={handleDriverSelected}
      handleInitialCash={handleInitialCash}
      initialCash={initialCash}
      confirmOpen={confirmOpen}
      loadingOpen={loadingOpen}
      country={country}
      settlements={settlements}
      beginDate={beginDate}
      endDate={endDate}
      handleDates={handleDates}
      handleCancelModal={handleCancelModal}
      cancelModal={cancelModal}
      settlementToClose={settlementToClose}
      openModal={openModal}
      closeSettlement={closeSettlement}
      driversUsed={driversUsed}
      exportSettlement={exportSettlement}
      exporting={exporting}
      editBonus={editBonus}
    />
  );
};

export default GettersCashflow;
