import {useState} from 'react';
import cx from 'classnames';
import {Button, Modal} from 'antd';
import {ReactComponent as Home} from '../../../assets/address/home.svg';
import {ReactComponent as Favorite} from '../../../assets/address/favorite.svg';
import {ReactComponent as Bag} from '../../../assets/address/bag.svg';
import {ReactComponent as Gps} from '../../../assets/address/near-me.svg';
import '../styles/AddressUI.scss';

const AddressUI = (props) => {
  const [loading, handleLoading] = useState(false);
  const {address, editable, selected, onEdit, onDelete, index, select = () => {}, noAction} = props;

  const removeAddress = async () => {
    handleLoading(true);
    Modal.confirm({
      title: 'La dirección se eliminará',
      content: 'Esta información no podrá recuperarse',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      okType: 'danger',
      async onOk() {
        await onDelete(address.id, index);
        handleLoading(false);
      },
      onCancel() {
        handleLoading(false);
      }
    });
    handleLoading(false);
  };

  const getIcon = (icon) => {
    if (icon === 'home') {
      return (
        <Home
          width="25px"
          height="25px"
          className={cx({active: selected})}
        />
      );
    } else if (icon === 'favorite') {
      return (
        <Favorite
          width="25px"
          height="25px"
          className={cx({active: selected})}
        />
      );
    } else if (icon === 'near-me') {
      return (
        <Gps
          width="25px"
          height="25px"
          className={cx({active: selected})}
        />
      );
    }
    return (
      <Bag
        width="25px"
        height="25px"
        className={cx({active: selected})}
      />
    );
  };

  return (
    <div className="address-ui">
      <div className="main-info" onClick={select}>
        <div className="icon">
          {getIcon(address.icon)}
        </div>
        <div className="info-address">
          <div className="texts">
            <span className="name">{address.name || '-'}</span>
            <span className="address-preview">
              {address.address}
            </span>
          </div>
          {!editable && !noAction && (
            <i className="bi bi-chevron-right next" />
          )}
        </div>
      </div>
      {editable && !noAction && (
        <div className="actions">
          <Button className="edit" onClick={() => onEdit(address)}>
            <i className="bi bi-pencil-fill" />
          </Button>
          <Button className="delete" onClick={removeAddress} disabled={loading}>
            <i className="bi bi-trash-fill" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddressUI;
