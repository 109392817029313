import {useState, useEffect, useCallback} from 'react';
import {notification, Modal} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import io from 'socket.io-client';
import { logoutSuccess, selectCountry } from '../login/UserSlice';
import { newOrder } from '../monitor/MonitorSlice';
import { closeSession } from '../../pages/take-orders/TakeOrdersSlice';
import {urlSV, urlNI} from '../../Api';
import DashboardUI from './components/DashboardUI';
import OrderSound from '../../assets/sounds/newOrder.m4a';

let socket;
const audio = new Audio(OrderSound);

const Dashboard = () => {
  const [active, handleActive] = useState('monitor');
  const [lastOrder, handleLastOrder] = useState('');
  const user = useSelector(state => state.user.info);
  const history = useHistory();
  const location = useLocation();
  const country = useSelector(selectCountry);
  const tuNicaragua = user.role === 3 && country === 'ni';
  const isDispatch = user.role === 5;
  const isSuperadmin = user.role === 10;

  const dispatch = useDispatch();

  useEffect(() => {
    const {pathname} = location;
    const base = pathname.split('/')[1];
    if (base.length === 0 || base === 'take-orders') {
      handleActive('monitor');
    } else {
      handleActive(base);
    }
  }, [location]);

  useEffect(() => {
    if (!tuNicaragua) {
      const socketURL = country === 'sv' ? urlSV : urlNI;
      socket = io(socketURL, {
        transports: ['websocket'],
      });
      socket.on('connect', () => {
        console.log(`>> socket Id: ${socket.id}`);
        if (socket.io.connecting[socket.id]) {
          socket.io.connecting[socket.id].disconnect();
        }
        socket.on('reconnecting', (data) => {
          // console.log(data);
        });
        socket.emit(
          'subscribe',
          {
            room: 'dashboard',
          },
          (error, response) => {
            if (!error) {
              // console.log(response);
            } else {
              // console.log(error);
            }
          },
        );
  
        socket.on('new-order', (data) => {
          if (data.id !== lastOrder) {
            audio.play();
            notification['success']({
              message: 'Orden Nueva',
              description:
                'Se te ha recibido una orden',
            });
            dispatch(newOrder(data));
            handleLastOrder(data.id);
          }
        });
      });
  
      socket.on('disconnect', async () => {
        // console.log('>> disconect');
      });

      return () => {
        socket.disconnect();
      };
    }

  }, []);

  const logout = useCallback(async () => {
    localStorage.removeItem('get_admin')
    dispatch(closeSession());
    dispatch(logoutSuccess());
  }, [dispatch]);

  /* useEffect(() => {
    if (user.exp) {
      const exp = user.exp;
      const actual = Math.floor(Date.now() / 1000);
      if (parseInt(actual, 10) > parseInt(exp, 10)) {
        Modal.warning('¡Tu sesión ha expirado!', 'Ingresa de nuevo para continuar', 'warning');
        logout();
      }
    } else {
      logout();
    }
  }, [logout, user.exp]); */

  const onClikMenu = ({key}) => {
    handleActive(key);
    history.push(key !== 'monitor' ? `/${key}` : '/');
  };

  return (
    <DashboardUI
      user={user}
      logout={logout}
      onClikMenu={onClikMenu}
      active={active}
      isDispatch={isDispatch}
      isSuperadmin={isSuperadmin}
    />
  );
};

export default Dashboard;
