import axios from "axios";

export const urlSV = "https://prod.getlaapp.com";
export const urlNI = "https://nicaragua.getlaapp.com";

// export const urlSV = "https://dev-api.getmyfood.com.sv";
// export const urlNI = "https://dev-apinica.getmyfood.com.sv";


const getInstance = async () => {
  const store = require("./store/index").default;
  let newState = await store.getState();
  const country = newState.user.country;
  const baseURL = country === "sv" ? urlSV : urlNI;
  const timeout = 150000;

  const request = axios.create({
    baseURL,
    timeout,
    headers: { "Content-Type": "application/json" },
  });

  request.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("get_admin");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      config.headers.country = country;
      return config;
    },
    (error) => Promise.reject(error)
  );
  return request;
};

export default getInstance;
