const initialState = country => {
  if (country === 'sv') {
    return (
      {
        'bill_100': '',
        'bill_50': '',
        'bill_20': '',
        'bill_10': '',
        'bill_5': '',
        'bill_1': '',
        'coin_100': '',
        'coin_25': '',
        'coin_10': '',
        'coin_5': '',
        'coin_1': '',
      }
    );
  }
  return (
    {
      'ni_bill_1000': '',
      'ni_bill_500': '',
      'ni_bill_200': '',
      'ni_bill_100': '',
      'ni_bill_50': '',
      'ni_bill_20': '',
      'ni_bill_10': '',
      'ni_bill_5': '',
      'ni_coin_500': '',
      'ni_coin_100': '',
      'ni_coin_50': '',
      'ni_coin_25': '',
      'ni_coin_10': '',
      'ni_coin_5': '',
    }
  );
};

export default initialState;
