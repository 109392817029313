import cx from 'classnames';
import moment from 'moment';
import {getCurrency} from '../../../utils/format';
// import ActionsRecord from '../components/ActionsRecord';

const columns = (country, categories, deleteRecordDaw) => {
  const getCategory = cat => {
    if (typeof(cat) !== 'object') {
      const index = categories.findIndex(el => el.id === cat);
      if (index > -1) {
        return categories[index].name;
      }
      return '-';
    }
    return cat.name;
  };

  const getFilterCategories = () => (
    categories.map(cat => (
      {
        text: cat.name,
        value: cat.id,
      }
    ))
  );

  const filterCat = (value, record) => {
    const cat = record.id_category;
    if (typeof(cat) !== 'object') {
      return cat === value;
    }
    return cat.id === value;
  };

  return [
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'id',
      width: 40,
      align: 'center',
      render: date => (
        <span className="normal">
          {moment(date).format('DD/MM/YYYY hh:mm a')}
        </span>
      ),
    },
    {
      title: 'Categoría',
      dataIndex: 'id_category',
      width: 150,
      align: 'center',
      filters: getFilterCategories(),
      filterSearch: true,
      onFilter: (value, record) => filterCat(value, record),
      render: cat => (
        <span className="normal">{getCategory(cat)}</span>
      ),
    },
    {
      title: 'Comentarios',
      dataIndex: 'comment',
      key: 'id',
      width: 200,
      align: 'center',
    },
    {
      title: 'Tipo',
      dataIndex: 'id_type',
      key: 'id',
      width: 100,
      align: 'center',
      filters: [
        {
          text: 'Retiro',
          value: 0,
        },
        {
          text: 'Abono',
          value: 1,
        },
      ],
      onFilter: (value, record) => record.id_type === value,
      render: val => (
        <span
          className={cx('tag', {
            debit: val === 0,
            deposit: val === 1,
          })}>
          {val === 0 ? 'Retiro' : 'Abono'}
        </span>
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'id',
      width: 100,
      align: 'center',
      render: (val, row) => {
        const isDebit = row.id_type === 0;
        return (
          <span
            className={cx({
              red: isDebit,
              teal: !isDebit,
            })}>
            {`${isDebit ? '- ' : ''}${getCurrency(country, val)}`}
          </span>
        );
      },
    },
    /* {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      align: 'right',
      render: (id, record, index) => (
        <ActionsRecord
          id={id}
          index={index}
          deleteRecordDaw={deleteRecordDaw}
        />
      ),
    }, */
  ];
};

export default columns;