import {useState} from 'react';
import {Modal} from 'antd';
import {useDispatch} from 'react-redux'
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {checkPartnersSchedule} from './PartnersSlice';
import PartnersUI from './components/PartnersUI';

const Partners = () => {
  const [sending, handleSending] = useState(false);
  const today = moment.tz('America/El_Salvador');
  const dispatch = useDispatch();

  const checkSchedules = async () => {
    handleSending(true);
    const response = await dispatch(checkPartnersSchedule());
    if (response.status !== 'success') {
      Modal.warning({
        title: 'Hubo un problema',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleSending(false);
  };

  return (
    <PartnersUI
      today={today}
      checkSchedules={checkSchedules}
      sending={sending}
    />
  );
};

export default Partners;
