const int = /^(?:[-+]?(?:0|[1-9][0-9]*))$/;
const intLeadingZeroes = /^[-+]?[0-9]+$/;

function assertString(input) {
  const isString = typeof input === 'string' || input instanceof String;

  if (!isString) {
    let invalidType;
    if (input === null) {
      invalidType = 'null';
    } else {
      invalidType = typeof input;
      if (
        invalidType === 'object' &&
        input.constructor &&
        input.constructor.hasOwnProperty('name')
      ) {
        invalidType = input.constructor.name;
      } else {
        invalidType = `a ${invalidType}`;
      }
    }
    throw new TypeError(`Expected string but received ${invalidType}.`);
  }
}

function isInt(str, options) {
  assertString(str);
  options = options || {};

  // Get the regex to use for testing, based on whether
  // leading zeroes are allowed or not.
  let regex =
    options.hasOwnProperty('allow_leading_zeroes') &&
    !options.allow_leading_zeroes
      ? int
      : intLeadingZeroes;

  // Check min/max/lt/gt
  let minCheckPassed = !options.hasOwnProperty('min') || str >= options.min;
  let maxCheckPassed = !options.hasOwnProperty('max') || str <= options.max;
  let ltCheckPassed = !options.hasOwnProperty('lt') || str < options.lt;
  let gtCheckPassed = !options.hasOwnProperty('gt') || str > options.gt;

  return (
    regex.test(str) &&
    minCheckPassed &&
    maxCheckPassed &&
    ltCheckPassed &&
    gtCheckPassed
  );
}

export function formatNumber(cardNumber) {
  if (cardNumber.length < 1) {
    return true;
  }
  return isInt(cardNumber);
}

export function formatCard(card) {
  let newNumber = '';
  const completeNumber = card.split(' ').join('');
  if (formatNumber(card.slice(-1))) {
    if (completeNumber.length <= 4) {
      newNumber = completeNumber;
    } else if (completeNumber.length > 4 && completeNumber.length <= 8) {
      newNumber = `${completeNumber.substring(0, 4)} ${completeNumber.substring(
        4,
        completeNumber.length,
      )}`;
    } else if (completeNumber.length > 8 && completeNumber.length <= 12) {
      newNumber = `${completeNumber.substring(0, 4)} ${completeNumber.substring(
        4,
        8,
      )} ${completeNumber.substring(8, completeNumber.length)}`;
    } else if (completeNumber.length > 12 && completeNumber.length <= 16) {
      newNumber = `${completeNumber.substring(0, 4)} ${completeNumber.substring(
        4,
        8,
      )} ${completeNumber.substring(8, 12)} ${completeNumber.substring(
        12,
        completeNumber.length,
      )}`;
    } else if (completeNumber.length > 16) {
      newNumber = `${completeNumber.substring(0, 4)} ${completeNumber.substring(
        4,
        8,
      )} ${completeNumber.substring(8, 12)} ${completeNumber.substring(
        12,
        16,
      )}`;
    }
    return newNumber;
  }
  return card.substring(0, card.length - 1);
}

export const getAmount = amount => {
  const template = '000000000000';
  const quantities = String(parseFloat(amount).toFixed(2)).split('.');
  let result = template.substring(
    0,
    template.length - quantities[0].length - quantities[1].length,
  );
  result += `${quantities[0]}${quantities[1]}`;
  return result;
};
