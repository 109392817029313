import instance from '../../Api';

export const cancelOrder = async (user, order, code, date) => {
  const api = await instance();
  let data = await api
    .post('/order-refund', {
      id_user: user,
      id_order: order,
      date,
      authorization: code,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
