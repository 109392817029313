import instance from '../../Api';

export const getOrdersMonitor = async (role) => {
  const api = await instance();
  let data = await api
    .get(`/monitor?role=${role}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getOrderProducts = async (order) => {
  const api = await instance();
  let data = await api
    .get(`/monitor/${order}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getBranches = async (order) => {
  const api = await instance();
  let data = await api
    .get(`/orders/order-best-branch/${order}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getDrivers = async (order) => {
  const api = await instance();
  let data = await api
    .get(`/orders/order-best-driver/${order}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const toPartner = async (order, branch, user) => {
  const api = await instance();
  let data = await api
    .post('/orders/status/send-to-partner', {
      orderId: order,
      branchId: branch,
      agentId: user,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const acceptOrder = async (order, branch, time) => {
  const api = await instance();
  let data = await api
    .post('/orders/status/order-being-prepared', {
      orderId: order,
      branchId: branch,
      estimatedTime: time,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const assignDriver = async (order, driver) => {
  const api = await instance();
  let data = await api
    .post('/orders/status/assign-driver', {
      orderId: order,
      driverId: driver,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const dispatchOrder = async (order) => {
  const api = await instance();
  let data = await api
    .post('/orders/status/order-dispatched', {
      orderId: order,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const startDelivery = async (order, driver) => {
  const api = await instance();
  let data = await api
    .post('/orders/start-delivery', {
      orderId: order,
      driverId: driver,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const finishDelivery = async (order, driver) => {
  const api = await instance();
  let data = await api
    .post('/orders/complete-delivery', {
      orderId: order,
      driverId: driver,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const cancelOrder = async (order, reason, comments, user) => {
  const api = await instance();
  let data = await api
    .post('/orders/cancel-order', {
      orderId: order,
      canceledBy: 'agent',
      id_agent: user,
      reason,
      comment: comments,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
