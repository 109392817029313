import {Input} from 'antd';
import isFloat from 'validator/lib/isFloat';

const InputNumber = ({value = '', onChange, loading}) => {
  const handleChange = e => {
    const val = e.target.value;
    if ((isFloat(val) && !(parseFloat(val) < 0)) || val === '') {
      onChange(val)
    }
  };

  return (
    <Input
      disabled={loading}
      placeholder="Monto"
      size="large"
      value={value}
      onChange={handleChange}
    />
  );
};

export default InputNumber;
