import {useState} from 'react';
import {Input, Button, Modal, DatePicker} from 'antd';

const CancelOrder = ({revertOrder}) => {
  const [code, setCode] = useState('');
  const [date, setDate] = useState(null);
  const [sending, handleSending] = useState(false);

  const cancel = async () => {
    if (!date) {
      Modal.error({
        title: 'Selecciona una fecha válida',
        content: 'Ingresa una fecha válida para realizar esta acción.',
      })
    } else if (!code.length) {
      Modal.error({
        title: 'Ingresa un código válida',
        content: 'Ingresa una código válido para realizar esta acción.',
      })
    } else {
      handleSending(true);
      await revertOrder(code, date.toDate());
      handleSending(false);
    }
  };

  return (
    <div style={{paddingBottom: '35px'}}>
      <DatePicker
        showTime
        style={{width: '100%', marginBottom: '20px'}}
        size="large"
        placeholder="Seleccionar Fecha"
        value={date}
        onChange={setDate}
      />
      <Input
        value={code}
        onChange={e => setCode(e.target.value)}
        size="large"
        style={{marginBottom: '20px'}}
        placeholder="Código de autorización"
        rows={2}
      />
      <Button
        loading={sending}
        onClick={cancel}
        size="large"
        style={{float: 'right', fontWeight: 700}}
        type="danger">
          Revertir
        </Button>
    </div>
  );
}

export default CancelOrder;
