import {Input, DatePicker, Spin, Button} from 'antd';
import History from './History';
import '../styles/OrdersUI.scss';

const {Search} = Input;
const {RangePicker} = DatePicker;

const OrdersUI = (props) => {
  const {
    orders,
    country,
    today,
    beginDate,
    endDate,
    loading,
    handleDates,
    orderSearch,
    tuNicaragua,
    partners,
    exporting,
    exportData,
    isAdmin,
    handleCurrentData,
  } = props;

  return (
    <div className="orders-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Historial</h1>
          <h3 className="subtitle">
            {today.format('dddd D [de] MMMM')}
          </h3>
        </div>
        <div className="filters-wrap">
          {(isAdmin || tuNicaragua) && (
            <Button
              className="export-btn"
              size="large"
              loading={exporting}
              onClick={exportData}
              disabled={loading}
              type="primary">
              Exportar
            </Button>
          )}
          <Search
            placeholder="Buscar por ticket"
            allowClear
            enterButton
            size="large"
            loading={loading}
            onSearch={orderSearch}
          />
          <RangePicker
            disabled={loading}
            size="large"
            placeholder={['Fecha Inicio', 'Fecha Final']}
            allowClear={false}
            value={[beginDate, endDate]}
            onChange={handleDates}
          />
        </div>
      </div>
      {loading ? (
        <div className="loader-wrap">
          <Spin size="large" />
        </div>
      ) : (
        <History
          orders={orders}
          country={country}
          tuNicaragua={tuNicaragua}
          partners={partners}
          handleCurrentData={handleCurrentData}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};

export default OrdersUI;
