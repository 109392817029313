import {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Modal} from 'antd';
import {
  getUnpaidSettlementDetail,
  selectSettlementdDetail,
  getSettlementDetails,
  selectBeginDate,
  selectEndDate
} from '../settlements/SettlementsSlice';
import {selectCountry} from '../login/UserSlice';
import SettlementDetailUI from './components/SettlementDetailUI';
import exportOrders from '../../utils/exportOrders';

const SettlementDetail = () => {
  const country = useSelector(selectCountry);
  const [loading, handleLoading] = useState(true);
  const {idPartner, idSettlement} = useParams();
  const beginDate = useSelector(selectBeginDate);
  const endDate = useSelector(selectEndDate);
  const settlement = useSelector(selectSettlementdDetail);
  const dispatch = useDispatch();

  const getSettlementDetail = useCallback(async () => {
    const config = {
      start_date: beginDate.format('YYYY-MM-DD 00:00:00'),
      end_date: endDate.format('YYYY-MM-DD 23:59:59'),
    };
    const response = idPartner ?
      await dispatch(getUnpaidSettlementDetail(idPartner, config)) :
      await dispatch(getSettlementDetails(idSettlement));
    if (response.status !== 'success') {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
  }, [dispatch, idPartner, idSettlement]);

  useEffect(() => {
    const initialFetch = async () => {
      await getSettlementDetail();
      handleLoading(false);
    };
    initialFetch();
  }, [
    getSettlementDetail,
  ]);

  const exportData = async () => {
    await exportOrders(
      settlement.orders,
      settlement.date,
      true,
      settlement.partner_name
    );
  };

  return (
    <SettlementDetailUI
      loading={loading}
      country={country}
      settlement={settlement}
      exportData={exportData}
      idPartner={idPartner}
    />
  );
};

export default SettlementDetail;
