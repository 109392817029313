import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from 'antd';
import produce from 'immer';
import ProductUI from './ProductUI';
import {productMenu} from '../TakeOrdersSlice';
import {selectCountry} from '../../login/UserSlice';

const CustomProduct = props => {
  const [loading, handleLoading] = useState(true);
  const [calculating, handleCalculating] = useState(false);
  const [productDetail, handleProductDetail] = useState({});
  const [quantity, handleQuantity] = useState(1);
  const [total, handleTotal] = useState('0.00');
  const [totalUnit, handleTotalUnit] = useState(0);
  const [comments, handleComments] = useState('');
  const country = useSelector(selectCountry);
  const dispatch = useDispatch();

  const {
    close,
    product,
    partner,
    selectProduct,
    edit,
    customProductEdited,
  } = props;

  useEffect(() => {
    const initialFetch = async () => {
      if (!edit) {
        const response = await dispatch(productMenu(product.id_product, partner.id));
        if (response.status === 'success') {
          const newProduct = response.product;
          newProduct.comments = '';
          handleProductDetail(newProduct);
          handleTotal(newProduct.price);
          handleTotalUnit(newProduct.price);
        }
      } else {
        handleProductDetail(product);
        handleTotal(product.total);
        handleTotalUnit(product.totalUnit);
        handleQuantity(product.quantity);
        handleComments(product.comments);
      }
      handleLoading(false)
    };
    initialFetch();
  }, [dispatch, edit, product.id]);

  //* Función para seleccionar una opción, tomando en cuenta si tiene cantidad para restarla o sumarla
  const handleOption = (indexCategory, indexOption, value, previous, quantityExtra, newQuantity) => {
    const newProduct = produce(productDetail, draftState => {
      draftState.categories[indexCategory].extras[indexOption].selected = value;
      if (previous !== false && previous > -1) {
        draftState.categories[indexCategory].extras[previous].selected = false;
      }
      if (quantityExtra) {
        const total_selected = draftState.categories[indexCategory].total_selected;
        if (total_selected === undefined && value) {
          draftState.categories[indexCategory].total_selected = 1;
        } else if (value) {
          draftState.categories[indexCategory].total_selected = total_selected + 1;
        } else if (newQuantity) {
          draftState.categories[indexCategory].total_selected = total_selected - newQuantity;
        } else {
          draftState.categories[indexCategory].total_selected = total_selected - 1;
        }

        if (value) {
          draftState.categories[indexCategory].extras[indexOption].quantity = 1;
        } else {
          draftState.categories[indexCategory].extras[indexOption].quantity = 0;
        }
      }
    });
    handleProductDetail(newProduct);
  };

  const selectOption = async (indexCategory, indexOption, hasQuantity = false) => {
    const options = productDetail.categories;
    const categorySelected = options[indexCategory];
    const optionSelected = categorySelected.extras[indexOption];
    const multiple = categorySelected.multiple === 'Si';
    const selected = optionSelected.selected;
    const price = optionSelected.price;

    if (selected) {
      if (hasQuantity) {
        handleOption(indexCategory, indexOption, false, false, hasQuantity, optionSelected.quantity);
        calculateTotal(price * optionSelected.quantity, false);
      } else {
        handleOption(indexCategory, indexOption, false, false);
        calculateTotal(price, false);
      }
    } else if (!multiple) {
      let prevSelected = -1;
      categorySelected.extras.map((option, index) => {
        if (option.selected) {
          prevSelected = index;
        }
      });
      if (prevSelected > -1) {
        handleOption(indexCategory, indexOption, true, prevSelected);
      } else {
        handleOption(indexCategory, indexOption, true, false);
      }
      const new_price =
        prevSelected > -1
          ? parseFloat(price) -
            parseFloat(
              categorySelected.extras[prevSelected].price,
            )
          : parseFloat(price);
      calculateTotal(new_price, true);
    } else if (!selected) {
      const total_selected = productDetail.categories[indexCategory].total_selected;
      const max = productDetail.categories[indexCategory].max;
      if (!total_selected || total_selected < max) {
        handleOption(indexCategory, indexOption, true, false, hasQuantity);
        calculateTotal(price, true);
      } else {
        Modal.warning({
          title: 'Máximo alcanzado',
          content: 'Has alcanzado el máximo de opciones para esta categoría'
        });
      }
    }
  };

  const quantityUpdate = (add) => {
    const val = add ? quantity + 1 : quantity - 1;
    if (val > 0) {
      const newTotal = (parseFloat(totalUnit) * val).toFixed(2);
      handleQuantity(val);
      handleTotal(newTotal);
    }
  };

  const calculateTotal = (price, add) => {
    if (parseFloat(price) > 0 || parseFloat(price) < 0) {
      const newTotalUnit =
        parseFloat(totalUnit) + parseFloat(price) * (add ? 1 : -1);
      handleTotalUnit(`${newTotalUnit.toFixed(2)}`);
      if (quantity > 1) {
        const newTotal = newTotalUnit * quantity;
        handleTotal(`${newTotal.toFixed(2)}`);
      } else {
        handleTotal(`${newTotalUnit.toFixed(2)}`);
      }
    }
  };

  const handleQuantityExtra = async (prevQuantity, add, price, indexCategory, indexOption) => {
    handleCalculating(true);
    const newQuantity = add ? prevQuantity + 1 : prevQuantity - 1;
    const total_selected = productDetail.categories[indexCategory].total_selected;
    const newTotal = total_selected + (newQuantity - prevQuantity);
    if (newTotal <= productDetail.categories[indexCategory].max) {
      const newProduct = produce(productDetail, draftState => {
        draftState.categories[indexCategory].total_selected = newTotal;
        draftState.categories[indexCategory].extras[indexOption].quantity = newQuantity;
      });
      handleProductDetail(newProduct);
      if (newQuantity > 0) {
        const realTotal = (price * newQuantity) - (price * prevQuantity);
        calculateTotal(realTotal, true);
      } else {
        handleOption(indexCategory, indexOption, false, false, true, newQuantity);
        calculateTotal(price * prevQuantity, false);
      }
    } else {
      Modal.warning({
        title: 'Máximo alcanzado',
        content: 'Has alcanzado el máximo de opciones para esta categoría'
      });
    }
    handleCalculating(false);
  };

  const verifyRequired = () => {
    let state = true;
    productDetail.categories.map((opt) => {
      if (opt.required === 'Si') {
        let optionState = false;
        const hasQuantity = opt.min !== undefined && opt.max !== undefined && !(opt.max === 1 && opt.min === 1);
        if (hasQuantity && opt.total_selected >= opt.min) {
          optionState = true;
        } else if (!hasQuantity) {
          opt.extras.map((el) => {
            if (el.selected) {
              optionState = true;
            }
          });
        }
        if (state) {
          state = optionState;
        }
      }
    });
    return state;
  };

  const addToCart = () => {
    const productToCart = produce(productDetail, draftState => {
      draftState.total = total;
      draftState.totalUnit = totalUnit;
      draftState.quantity = quantity;
      draftState.comments = comments;
      draftState.photo = product.photo;
      draftState.id_product = product.id_product;
      draftState.id = product.id;
    });
    if (!verifyRequired()) {
      Modal.error({
        title: 'Selecciona todas las opciones',
        content: 'Debes seleccionar todas las opciones requeridas',
      });
    } else if (!edit) {
      selectProduct(productToCart);
    } else {
      customProductEdited(productToCart);
    }
  };

  return (
    <ProductUI
      custom
      selectOption={selectOption}
      calculating={calculating}
      handleQuantityExtra={handleQuantityExtra}
      country={country}
      partner={partner}
      product={product}
      productDetail={productDetail}
      close={close}
      loading={loading}
      comments={comments}
      handleComments={handleComments}
      quantity={quantity}
      quantityUpdate={quantityUpdate}
      addToCart={addToCart}
      verifyRequired={verifyRequired}
      edit={edit}
      total={total}
    />
  );
};

export default CustomProduct;
