import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  // Font,
} from '@react-pdf/renderer';
import Logo from '../../../assets/logo/logo.png';
import {getCurrency} from '../../../utils/format';


// Font.register({
//   family: 'Nunito',
//   fonts: [
//     { src: 'https://fonts.gstatic.com/s/nunito/v16/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2'},
//     { src: 'https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAjsOUbOvIWzgPDEtj.woff2', fontWeight: 700 },
//     { src: 'https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAksCUb-vIWzgPDEtj.woff2', fontWeight: 800 },
//   ],
// });

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 15,
    backgroundColor: '#FFF'
  },
  header: {
    textAlign: 'center',
    position: 'relative',
    paddingTop: 8,
    marginBottom: 40,
  },
  title: {
    // fontFamily: 'Nunito',
    // fontWeight: 800,
    marginBottom: 30,
    fontSize: 13,
  },
  subtitle: {
    fontSize: 12,
  },
  logo: {
    width: 50,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cashier: {
    width: '50%',
  },
  headerDetails: {
    backgroundColor: '#F9F9F9',
    borderWidth: 1,
    borderColor: '#F9F9F9',
    paddingHorizontal: 15,
    paddingVertical: 10,
    fontWeight: 700,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    flexDirection: 'row',
  },
  denomination: {
    flex: 1,
    fontSize: 12
  },
  amount: {
    textAlign: 'center',
    marginHorizontal: 10,
    width: 60,
    fontSize: 12
  },
  total: {
    textAlign: 'right',
    width: 60,
    fontSize: 12
  },
  bodyDetails: {
    padding: 15,
    borderColor: '#E8E7E7',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderBottomRightRadius: 3,
    borderBottomLeftRadius: 3,
  },
  detail: {
    alignItems: 'center',
    marginBottom: 15,
    flexDirection: 'row',
  },
  labelDetail: {
    flex: 1,
    fontSize: 12,
  },
  quantityWrap: {
    width: 60,
    height: 25,
    marginHorizontal: 10,
    borderWidth: 1,
    borderColor: '#CECECE',
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  amountQuantity: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 12,
  },
  amountDetail: {
    textAlign: 'right',
    fontWeight: 500,
    width: 60,
    fontSize: 12,
  },
  noMargin: {
    marginBottom: 0,
  },
  summaryWrap: {
    width: '48%',
  },
  summary: {
    borderWidth: 1,
    borderColor: '#E8E7E7',
    borderRadius: 5,
    padding: 15,
  },
  detailSummary: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  value: {
    // width: 80,
    // height: 32,
    width: 100,
    height: 25,
    textAlign: 'center',
    borderWidth: 1,
    borderColor: '#CECECE',
    borderRadius: 3,
    justifyContent: 'center',
  },
  labelValue: {
    color: '#9EA0A5',
    fontSize: 12,
  },
  labelDetailSummary: {
    fontSize: 12,
  },
  bold: {
    fontWeight: 700,
  },
  gray: {
    borderColor: '#EAECF0',
    backgroundColor: '#FBFBFC',
    color: '#020815',
  },
  labelGray: {
    color: '#020815',
  },
  teal: {
    borderColor: '#5EC1A9',
    backgroundColor: '#E5FDF1',
    color: '#5EC1A9',
  },
  labelTeal: {
    color: '#5EC1A9',
  },
  red: {
    borderColor: '#EAECF0',
    backgroundColor: '#FBFBFC',
    color: '#ED243D',
  },
  labelRed: {
    color: '#ED243D',
  },
  blue: {
    borderColor: '#3357E3',
    backgroundColor: '#F1F5FE',
    color: '#3357E3',
  },
  labelBlue: {
    color: '#3357E3',
  },
  horizontal: {
    borderTopWidth: 1,
    borderTopColor: '#E8E7E7',
    marginVertical: 15,
    marginHorizontal: -15,
  },
  commentsWrap: {
    marginTop: 15,
  },
  labelComments: {
    fontSize: 10,
    marginBottom: 10,
  },
  commentsBox: {
    borderWidth: 1,
    borderColor: '#E8E7E7',
    borderRadius: 3,
    padding: 10,
    width: '100%',
    minHeight: 75,
  },
  comments: {
    fontSize: 12,
  },
  signature: {
    marginTop: 80,
  },
  signPlaceholder: {
    fontSize: 12,
    marginBottom: 5,
  },
  user: {
    fontSize: 12,
  },
});

// Create Document Component
const MyDocument = ({country, summary, comments, name, date}) => {
  const {
    initial_cash_balance,
    bill_coin_total,
    // pos_app,
    // pos_callcenter,
    // total_income,
    total_expenses,
    to_deposit,
    missing,
    surplus,
    cash_available,
    cashier_cash,
    cash,
    bill_1,
    bill_5,
    bill_10,
    bill_20,
    bill_50,
    bill_100,
    coin_1,
    coin_5,
    coin_10,
    coin_25,
    coin_100,
    ni_bill_1000,
    ni_bill_500,
    ni_bill_200,
    ni_bill_100,
    ni_bill_50,
    ni_bill_20,
    ni_bill_10,
    ni_bill_5,
    ni_coin_500,
    ni_coin_100,
    ni_coin_50,
    ni_coin_25,
    ni_coin_10,
    ni_coin_5,
  } = summary;

  const getCurrencyCountry = val => getCurrency(country, val);

  const Detail = ({label, quantity, value, noMargin}) => (
    <View style={[styles.detail, noMargin && styles.noMargin]}>
      <Text style={styles.labelDetail}>
        {label}
      </Text>
      <View style={styles.quantityWrap}>
        <Text style={styles.amountQuantity}>
          {parseInt(quantity / value, 10)}
        </Text>
      </View>
      <Text style={styles.amountDetail}>
        {getCurrencyCountry(quantity)}
      </Text>
    </View>
  );

  const DetailSummary = ({label, value, gray, teal, red, bold, blue, noMargin}) => (
    <View style={[styles.detailSummary, noMargin && styles.noMargin]}>
      <Text style={[styles.labelDetailSummary, bold && styles.bold]}>
        {label}
      </Text>
      <View
        style={[
          styles.value,
          gray && styles.gray,
          teal && styles.teal,
          red && styles.red,
          blue && styles.blue,
        ]}>
        <Text
          style={[
            styles.labelValue,
            gray && styles.labelGray,
            teal && styles.labelTeal,
            red && styles.labelRed,
            blue && styles.labelBlue,
          ]}>
          {value}
        </Text>
      </View>
    </View>
  );


  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>
            Get Technologies S.A de C.V
          </Text>
          <Text style={styles.subtitle}>{`Informe de corte de caja ${date}.`}</Text>
          <Text style={styles.subtitle}>Vendedor: App y Call Center</Text>
          <Image src={Logo} style={styles.logo} />
        </View>
        <View style={styles.content}>
          <View style={styles.cashier}>
            <View style={styles.details}>
              <View style={styles.headerDetails}>
                <Text style={styles.denomination}>
                  Denominación
                </Text>
                <Text style={styles.amount}>
                  Cantidad
                </Text>
                <Text style={styles.total}>
                  Total
                </Text>
              </View>
              {country === 'sv' ? (
                <View style={styles.bodyDetails}>
                  <Detail
                    label="Billete de 100"
                    quantity={bill_100}
                    value={100}
                  />
                  <Detail
                    label="Billete de 50"
                    quantity={bill_50}
                    value={50}
                  />
                  <Detail
                    label="Billete de 20"
                    quantity={bill_20}
                    value={20}
                  />
                  <Detail
                    label="Billete de 10"
                    quantity={bill_10}
                    value={10}
                  />
                  <Detail
                    label="Billete de 5"
                    quantity={bill_5}
                    value={5}
                  />
                  <Detail
                    label="Billete de 1"
                    quantity={bill_1}
                    value={1}
                  />
                  <Detail
                    label="Moneda de 1"
                    quantity={coin_100}
                    value={1}
                  />
                  <Detail
                    label="Moneda de 0.25"
                    quantity={coin_25}
                    value={0.25}
                  />
                  <Detail
                    label="Moneda de 0.10"
                    quantity={coin_10}
                    value={0.1}
                  />
                  <Detail
                    label="Moneda de 0.05"
                    quantity={coin_5}
                    value={0.05}
                  />
                  <Detail
                    label="Moneda de 0.01"
                    quantity={coin_1}
                    value={0.01}
                    noMargin
                  />
                </View>
              ) : (
                <View style={styles.bodyDetails}>
                  <Detail
                    label="Billete de 1000"
                    quantity={ni_bill_1000}
                    value={1000}
                  />
                  <Detail
                    label="Billete de 500"
                    quantity={ni_bill_500}
                    value={500}
                  />
                  <Detail
                    label="Billete de 200"
                    quantity={ni_bill_200}
                    value={200}
                  />
                  <Detail
                    label="Billete de 100"
                    quantity={ni_bill_100}
                    value={100}
                  />
                  <Detail
                    label="Billete de 50"
                    quantity={ni_bill_50}
                    value={50}
                  />
                  <Detail
                    label="Billete de 20"
                    quantity={ni_bill_20}
                    value={20}
                  />
                  <Detail
                    label="Billete de 10"
                    quantity={ni_bill_10}
                    value={10}
                  />
                  <Detail
                    label="Billete de 5"
                    quantity={ni_bill_5}
                    value={5}
                  />
                  <Detail
                    label="Moneda de 5"
                    quantity={ni_coin_500}
                    value={5}
                  />
                  <Detail
                    label="Moneda de 1"
                    quantity={ni_coin_100}
                    value={1}
                  />
                  <Detail
                    label="Moneda de 0.50"
                    quantity={ni_coin_50}
                    value={0.50}
                  />
                  <Detail
                    label="Moneda de 0.25"
                    quantity={ni_coin_25}
                    value={0.25}
                  />
                  <Detail
                    label="Moneda de 0.10"
                    quantity={ni_coin_10}
                    value={0.10}
                  />
                  <Detail
                    label="Moneda de 0.05"
                    quantity={ni_coin_5}
                    value={0.05}
                    noMargin
                  />
                </View>
              )}
            </View>
          </View>
          <View style={styles.summaryWrap}>
            <View style={styles.summary}>
              <DetailSummary
                label="Contado"
                gray
                noMargin
                value={getCurrencyCountry(bill_coin_total)}
              />
              <View style={styles.horizontal} />
              <DetailSummary
                label="Apertura de caja" 
                value={getCurrencyCountry(initial_cash_balance)}
              />
              <DetailSummary
                label="Ingreso fondo de caja"
                gray
                value={getCurrencyCountry(cashier_cash)}
              />
              <DetailSummary
                label="Ingresos efectivo"
                gray
                value={getCurrencyCountry(cash)}
              />
              <DetailSummary
                label="Egresos"
                bold
                red
                value={`- ${getCurrencyCountry(total_expenses)}`}
              />
              <DetailSummary
                label="Efectivo según sistema"
                bold
                blue
                value={getCurrencyCountry(cash_available)}
                noMargin
              />
              <View style={styles.horizontal} />
              <DetailSummary
                label="Valor a remesar"
                gray
                value={getCurrencyCountry(to_deposit)}
              />
              <DetailSummary
                label="(-) Faltante"
                gray
                value={getCurrencyCountry(missing)}
              />
              <DetailSummary
                label="(+) Sobrante"
                gray
                noMargin
                value={getCurrencyCountry(surplus)}
              />
              {/* <View style={styles.horizontal} />
              <DetailSummary
                label="Cobros día (Cash In)"
                gray
                value={getCurrencyCountry(cash)}
              />
              <DetailSummary
                label="Ingreso fondo de caja"
                gray
                value={getCurrencyCountry(cashier_cash)}
              />
              <DetailSummary
                label="POS App" 
                gray
                value={getCurrencyCountry(pos_app)}
              />
              <DetailSummary
                label="POS Callcenter"
                gray
                value={getCurrencyCountry(pos_callcenter)}
              />
              <DetailSummary
                label="Ingresos"
                bold
                teal
                noMargin
                value={getCurrencyCountry(total_income)}
              /> */}
            </View>
            <View style={styles.commentsWrap}>
              <Text style={styles.labelComments}>
                COMENTARIOS
              </Text>
              <View style={styles.commentsBox}>
                <Text style={styles.comments}>
                  {comments}
                </Text>
              </View>
            </View>
            <View style={styles.signature}>
              <Text style={styles.signPlaceholder}>
                F.________________________________
              </Text>
              <Text style={styles.user}>
                {`Generado por: ${name}`}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
