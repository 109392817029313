import {useState} from 'react';
import { useDispatch } from 'react-redux';
import {Modal} from 'antd';
import {useHistory} from 'react-router-dom';
// import {selectUsers} from './UsersSlice';
import UsersUI from './components/UsersUI';
import {searchUser} from './UsersSlice';

const Users = () => {
  // const [loading, handleLoading] = useState(false);
  const [searching, handleSearching] = useState(false);
  const [users, handleUsers] = useState([]);
  // const users = useSelector(selectUsers);
  const history = useHistory();
  const dispatch = useDispatch();

  const createUser = () => {
    history.push('/users/new');
  };

  const editUser = (user) => {
    history.push(`/users/${user}`);
  };

  const onSearch = async (search) => {
    handleSearching(true);
    if (search.length < 3) {
      Modal.error({
        title: 'Busqueda muy corta',
        content: 'Intenta buscar con más caracteres',
      });
    } else {
      const response = await dispatch(searchUser(search));
      if (response.status !== 'success') {
        Modal.error({
          title: 'Hubo un error',
          content: 'Inténtalo de nuevo más tarde',
        });
      } else {
        handleUsers(response.users);
      }
    }
    handleSearching(false);
  };

  return (
    <UsersUI
      users={users}
      searching={searching}
      createUser={createUser}
      onSearch={onSearch}
      editUser={editUser}
    />
  );
};

export default Users;
