import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Form, Modal} from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {selectCountry} from '../login/UserSlice';
import {
  getRecords,
  selectRecords,
  getDawCategories,
  createRecord,
  deleteRecord,
} from './DawRecordsSlice';
import exportData from './utils/exportRecords';
import DawRecordsUI from './components/DawRecordsUI';

const DawRecords = () => {
  const country = useSelector(selectCountry);
  const records = useSelector(selectRecords);
  const today = moment.tz('America/El_Salvador');
  const [form] = Form.useForm();
  const [categories, handleCategories] = useState([]);
  const [loading, handleLoading] = useState(true);
  const [exporting, handleExporting] = useState(false);
  const [sending, handleSending] = useState(false);
  const [endDate, handleEndDate] = useState(today);
  const [beginDate, handleBeginDate] = useState(today);
  const dispatch = useDispatch();

  const getDawRecords = useCallback(async (newdBegin, newEnd) => {
    handleLoading(true);
    const begin = newdBegin || beginDate
    const end = newEnd || endDate;
    const config = {
      start_date: begin.format('YYYY-MM-DD 00:00:00'),
      end_date: end.format('YYYY-MM-DD 23:59:59'),
    };
    const response = await dispatch(getRecords(config));
    if (response.status !== 'success') {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleLoading(false);
  }, [beginDate, dispatch, endDate]);

  const getCategories = useCallback(async () => {
    const response = await dispatch(getDawCategories());
    if (response.status !== 'success') {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    } else {
      handleCategories(response.categories);
    }
  }, [dispatch]);

  useEffect(() => {
    const initialFetch = async () => {
      await getDawRecords();
      await getCategories();
      handleLoading(false);
    };
    initialFetch();
  }, [getDawRecords, getCategories]);

  const handleDates = (newDates) => {
    const newdBegin = newDates[0];
    const newEnd = newDates[1];
    handleBeginDate(newdBegin);
    handleEndDate(newEnd);
    getDawRecords(newdBegin, newEnd);
  };

  const createDawRecord = async values => {
    handleSending(true);
    const {category, amount, comments} = values;
    const data = {
      id_category: category,
      comment: comments || '-',
      amount,
    };
    const response = await dispatch(createRecord(data));
    if (response.status !== 'success') {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    } else {
      form.resetFields();
    }
    handleSending(false);
  };

  const deleteRecordDaw = async (id, index) => {
    const response = await dispatch(deleteRecord(id, index));
    if (response.status !== 'success') {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
  };

  const exportRecords = async () => {
    handleExporting(true);
    await exportData(records, beginDate, endDate, categories);
    handleExporting(false);
  };

  return (
    <DawRecordsUI
      form={form}
      today={today}
      records={records}
      country={country}
      loading={loading}
      beginDate={beginDate}
      endDate={endDate}
      handleDates={handleDates}
      categories={categories}
      createDawRecord={createDawRecord}
      sending={sending}
      deleteRecordDaw={deleteRecordDaw}
      exportRecords={exportRecords}
      exporting={exporting}
    />
  );
};

export default DawRecords;
