import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import produce from "immer";
import ProductUI from "./ProductUI";
import { productMenu } from "../TakeOrdersSlice";
import { selectCountry } from "../../login/UserSlice";

const Product = (props) => {
  const [loading, handleLoading] = useState(true);
  const [calculating, handleCalculating] = useState(false);
  const [productDetail, handleProductDetail] = useState({});
  const [quantity, handleQuantity] = useState(1);
  const [total, handleTotal] = useState("0.00");
  const [totalUnit, handleTotalUnit] = useState(0);
  const [comments, handleComments] = useState("");
  const country = useSelector(selectCountry);
  const dispatch = useDispatch();

  const {
    close,
    product,
    partnerOrder,
    partner,
    selectProduct,
    edit,
    editProductOrder,
    customProducts,
    handleCustomProducts,
    openCustomProduct,
    editCustomProduct,
  } = props;

  useEffect(() => {
    const initialFetch = async () => {
      if (!edit) {
        const response = await dispatch(
          productMenu(product.id, partner.id, product.id_setmenu || false)
        );
        if (response.status === "success") {
          const newProduct = response.product;
          newProduct.comments = "";
          handleProductDetail(newProduct);
          handleTotal(newProduct.price);
          handleTotalUnit(newProduct.price);
          handleCustomProducts([]);
        }
      } else {
        handleProductDetail(product);
        handleTotal(product.total);
        handleTotalUnit(product.totalUnit);
        handleQuantity(product.quantity);
        handleComments(product.comments);
        handleCustomProducts(product.customProducts);
      }
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch, edit, product.id]);

  //* Función para seleccionar una opción, tomando en cuenta si tiene cantidad para restarla o sumarla
  const handleOption = (
    indexCategory,
    indexOption,
    value,
    previous,
    quantityExtra,
    newQuantity
  ) => {
    const newProduct = produce(productDetail, (draftState) => {
      draftState.categories[indexCategory].extras[indexOption].selected = value;
      if (previous !== false && previous > -1) {
        draftState.categories[indexCategory].extras[previous].selected = false;
      }
      if (quantityExtra) {
        const total_selected =
          draftState.categories[indexCategory].total_selected;
        if (total_selected === undefined && value) {
          draftState.categories[indexCategory].total_selected = 1;
        } else if (value) {
          draftState.categories[indexCategory].total_selected =
            total_selected + 1;
        } else if (newQuantity) {
          draftState.categories[indexCategory].total_selected =
            total_selected - newQuantity;
        } else {
          draftState.categories[indexCategory].total_selected =
            total_selected - 1;
        }

        if (value) {
          draftState.categories[indexCategory].extras[indexOption].quantity = 1;
        } else {
          draftState.categories[indexCategory].extras[indexOption].quantity = 0;
        }
      }
    });
    handleProductDetail(newProduct);
    return newProduct;
  };

  const selectOption = async (
    indexCategory,
    indexOption,
    hasQuantity = false
  ) => {
    const options = productDetail.categories;
    const categorySelected = options[indexCategory];
    const optionSelected = categorySelected.extras[indexOption];
    const multiple = categorySelected.multiple === "Si";
    const selected = optionSelected.selected;
    const price = optionSelected.price;
    let newProduct;

    if (selected) {
      if (hasQuantity) {
        /* newProduct = await handleOption(indexCategory, indexOption, false, false, hasQuantity, optionSelected.quantity);
        calculateTotal(price * optionSelected.quantity, false);
        //* ------ BUFFALO INTEGRATION ------
        for (let i = 1; i <= optionSelected.quantity; i++) {
          deleteCustomOption(indexCategory, optionSelected?.id, i, newProduct);
        } */
      } else {
        newProduct = await handleOption(
          indexCategory,
          indexOption,
          false,
          false
        );
        calculateTotal(price, false);
        //* ------ BUFFALO INTEGRATION ------
        deleteCustomOption(indexCategory, optionSelected?.id, 1, newProduct);
      }
    } else if (!multiple) {
      let prevSelected = -1;
      categorySelected.extras.map((option, index) => {
        if (option.selected) {
          prevSelected = index;
        }
      });
      if (prevSelected > -1) {
        newProduct = await handleOption(
          indexCategory,
          indexOption,
          true,
          prevSelected
        );
      } else {
        newProduct = await handleOption(
          indexCategory,
          indexOption,
          true,
          false
        );
      }
      const new_price =
        prevSelected > -1
          ? parseFloat(price) -
            parseFloat(categorySelected.extras[prevSelected].price)
          : parseFloat(price);
      calculateTotal(new_price, true);
      //* ------ BUFFALO INTEGRATION ------
      addCustomOption(indexCategory, optionSelected, 1, newProduct);
    } else if (!selected) {
      const total_selected =
        productDetail.categories[indexCategory].total_selected;
      const max = productDetail.categories[indexCategory].max;
      if (!total_selected || total_selected < max) {
        newProduct = await handleOption(
          indexCategory,
          indexOption,
          true,
          false,
          hasQuantity
        );
        calculateTotal(price, true);
        //* ------ BUFFALO INTEGRATION ------
        addCustomOption(indexCategory, optionSelected, 1, newProduct);
      } else {
        Modal.warning({
          title: "Máximo alcanzado",
          content: "Has alcanzado el máximo de opciones para esta categoría",
        });
      }
    }
  };

  const quantityUpdate = (add) => {
    const val = add ? quantity + 1 : quantity - 1;
    if (val > 0) {
      const newTotal = (parseFloat(totalUnit) * val).toFixed(2);
      handleQuantity(val);
      handleTotal(newTotal);
    }
  };

  const calculateTotal = (price, add) => {
    if (parseFloat(price) > 0 || parseFloat(price) < 0) {
      const newTotalUnit =
        parseFloat(totalUnit) + parseFloat(price) * (add ? 1 : -1);
      handleTotalUnit(`${newTotalUnit.toFixed(2)}`);
      if (quantity > 1) {
        const newTotal = newTotalUnit * quantity;
        handleTotal(`${newTotal.toFixed(2)}`);
      } else {
        handleTotal(`${newTotalUnit.toFixed(2)}`);
      }
    }
  };

  const handleQuantityExtra = async (
    prevQuantity,
    add,
    price,
    indexCategory,
    indexOption
  ) => {
    handleCalculating(true);
    const newQuantity = add ? prevQuantity + 1 : prevQuantity - 1;
    const total_selected =
      productDetail.categories[indexCategory].total_selected;
    const newTotal = total_selected + (newQuantity - prevQuantity);
    let newProduct;
    if (newTotal <= productDetail.categories[indexCategory].max) {
      newProduct = produce(productDetail, (draftState) => {
        draftState.categories[indexCategory].total_selected = newTotal;
        draftState.categories[indexCategory].extras[indexOption].quantity =
          newQuantity;
      });
      handleProductDetail(newProduct);
      if (newQuantity > 0) {
        const realTotal = price * newQuantity - price * prevQuantity;
        calculateTotal(realTotal, true);
      } else {
        newProduct = await handleOption(
          indexCategory,
          indexOption,
          false,
          false,
          true,
          newQuantity
        );
        calculateTotal(price * prevQuantity, false);
      }
      //* ------ BUFFALO INTEGRATION ------
      const selectedExtra = productDetail.categories[0].extras[indexOption];
      if (add) {
        addCustomOption(indexCategory, selectedExtra, newQuantity, newProduct);
      } else {
        deleteCustomOption(
          indexCategory,
          selectedExtra?.id,
          prevQuantity,
          newProduct
        );
      }
      //* ------------
    } else {
      Modal.warning({
        title: "Máximo alcanzado",
        content: "Has alcanzado el máximo de opciones para esta categoría",
      });
    }
    handleCalculating(false);
  };

  //* ------ BUFFALO INTEGRATION ------
  const addCustomOption = (
    indexCategory,
    selectedExtra,
    quantityExtra,
    newProduct
  ) => {
    if (product.id_setmenu && indexCategory === 0) {
      const newSelectedExtra = produce(selectedExtra, (draftState) => {
        draftState.id_product = selectedExtra.id;
        draftState.id = `${selectedExtra.id}-${quantityExtra}`;
      });
      const newProductDetail = produce(newProduct, (draftState) => {
        draftState.categories[1].extras.push(newSelectedExtra);
      });
      handleProductDetail(newProductDetail);
    }
  };

  //* ------ BUFFALO INTEGRATION ------
  const deleteCustomOption = (indexCategory, id, quantityExtra, newProduct) => {
    if (product.id_setmenu && indexCategory === 0) {
      const customId = `${id}-${quantityExtra}`;
      // ----------------
      const indexCustom = newProduct.categories[1].extras.findIndex(
        (el) => el.id === customId
      );
      const newProductDetail = produce(newProduct, (draftState) => {
        if (indexCustom >= 0) {
          draftState.categories[1].extras.splice(indexCustom, 1);
        }
      });
      handleProductDetail(newProductDetail);
      // ----------------
      const index = customProducts.findIndex((el) => el.id === customId);
      if (index >= 0) {
        // ! Arreglar los precios adionales con los productos anidados
        // calculateTotal(customProducts[index].total, false);
        const newCustomProducts = produce(customProducts, (draftState) => {
          if (index >= 0) {
            draftState.splice(index, 1);
          }
        });
        handleCustomProducts(newCustomProducts);
      }
    }
  };

  //* ------ BUFFALO INTEGRATION ------
  const goToCustom = (option, indexCustom) => {
    if (indexCustom >= 0) {
      const customProduct = customProducts[indexCustom];
      editCustomProduct(customProduct);
    } else {
      const newProduct = produce(option, (draftState) => {
        draftState.photo = product.photo;
      });
      openCustomProduct(newProduct);
    }
  };

  const verifyRequired = () => {
    let state = true;
    productDetail.categories.map((opt) => {
      if (opt.required === "Si") {
        let optionState = false;
        if (opt.custom) {
          optionState = true;
          opt.extras.map((el) => {
            const indexCustom = customProducts.findIndex((e) => e.id === el.id);
            if (indexCustom < 0) {
              optionState = false;
            }
          });
        } else {
          const hasQuantity =
            opt.min !== undefined &&
            opt.max !== undefined &&
            !(opt.max === 1 && opt.min === 1);
          if (hasQuantity && opt.total_selected >= opt.min) {
            optionState = true;
          } else if (!hasQuantity) {
            opt.extras.map((el) => {
              if (el.selected) {
                optionState = true;
              }
            });
          }
        }
        if (state) {
          state = optionState;
        }
      }
    });
    return state;
  };

  const addToCart = () => {
    const productToCart = produce(productDetail, (draftState) => {
      draftState.total = total;
      draftState.totalUnit = totalUnit;
      draftState.quantity = quantity;
      draftState.comments = comments;
      draftState.customProducts = customProducts;
    });
    if (!verifyRequired()) {
      Modal.error({
        title: "Selecciona todas las opciones",
        content: "Debes seleccionar todas las opciones requeridas",
      });
    } else if (partnerOrder.id && partnerOrder.id !== partner.id) {
      Modal.confirm({
        title: "Orden iniciada",
        content: `Ya tienes una orden iniciada con ${partnerOrder.name}. ¿Deseas iniciar una nueva orden con este comercio?`,
        okText: "Iniciar nueva orden",
        cancelText: "Cancelar",
        onOk() {
          selectProduct(productToCart, partner);
        },
        onCancel() {},
      });
    } else if (partnerOrder.id && partnerOrder.id === partner.id) {
      if (!edit) {
        selectProduct(productToCart);
      } else {
        editProductOrder(productToCart);
      }
    } else if (!partnerOrder.id) {
      selectProduct(productToCart, partner);
    }
  };

  return (
    <ProductUI
      selectOption={selectOption}
      calculating={calculating}
      handleQuantityExtra={handleQuantityExtra}
      country={country}
      partner={partner}
      product={product}
      productDetail={productDetail}
      close={close}
      loading={loading}
      comments={comments}
      handleComments={handleComments}
      quantity={quantity}
      quantityUpdate={quantityUpdate}
      addToCart={addToCart}
      verifyRequired={verifyRequired}
      edit={edit}
      total={total}
      customProducts={customProducts}
      goToCustom={goToCustom}
    />
  );
};

export default Product;
