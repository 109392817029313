import { Table } from 'antd'
import React from 'react'

const TableSummations = ({loading,dataSource,setOpenModal,setRecord}) => {
 const columns = [
    {title:'Driver',dataIndex:'full_name',index:'full_name', width:150,ellipsis:true,render:(text)=>text.split('_').join(' ')},
    {title:'Ordenes',dataIndex:'orders',index:'orders', width:100,ellipsis:true,render:(text)=> text ?? '-'},
    {title:'Apertura',dataIndex:'opening',index:'opening',width:100,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Bono',dataIndex:'bonus',index:'bonus',width:100,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Efectivo',dataIndex:'cash',index:'cash',width:100,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Flotante',dataIndex:'floating',index:'floating',width:100,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Pagó Menos',dataIndex:'paid_less',index:'paid_less',width:120,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Pagó Mas',dataIndex:'paid_more',index:'paid_more',width:100,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Compras',dataIndex:'purchases',index:'purchases',width:100,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Cambios',dataIndex:'repay',index:'repay',width:100,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Ajuste de zona',dataIndex:'zone_adjustment',index:'zone_adjustment',width:150,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Remesas',dataIndex:'remittance',index:'remittance',width:125,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Pago Driver',dataIndex:'driver_payment',index:'driver_payment',width:125,ellipsis:true,render:(text)=>parseFloat(text).toFixed(2) },
    {title:'Balance',dataIndex:'balance',index:'balance',width:100,ellipsis:true,
    render:(_,record)=> {
      const positive = parseFloat(record.opening + record.bonus + record.cash + record.floating + record.paid_less).toFixed(2)
      const negative = parseFloat(record.paid_more + record.purchases + record.repay + record.zone_adjustment + record.remittance + record.driver_payment ).toFixed(2)
      return (parseFloat(positive) + parseFloat(negative)).toFixed(2)
    }},
    {
      title: 'Ver',
      dataIndex: 'full_name',
      index:'full_name',
      align: 'center',
      width:200,
      ellipsis:true,
      render: (_,record) => (
        <span
          className="close-detail"
          onClick={()=>{
            const positive = parseFloat(record.opening + record.bonus + record.cash + record.floating + record.paid_less).toFixed(2)
            const negative = parseFloat(record.paid_more + record.purchases + record.repay + record.zone_adjustment + record.remittance + record.driver_payment ).toFixed(2)
            const data = {...record,balance:(parseFloat(positive) + parseFloat(negative)).toFixed(2)}
            setOpenModal(true) 
            setRecord(data)
          }}
          >
          Detalle
        </span>
      ),
    },
    
 ]

  return (
     <Table columns={columns} loading={loading} scroll={{x:'100%',y:'400px'}} dataSource={dataSource}/>
  )
}

export default TableSummations