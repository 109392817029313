import { saveAs } from "file-saver";
import XLSX from "xlsx";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";

const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

const exportData = (orders, date, settlement = false, partner) => {
  const wb = XLSX.utils.book_new();
  const title = settlement
    ? `Reporte de Acreditación ${partner} ${date.format("DD_MM_YYYY")}`
    : `Reporte de caja Get ${date.format("DD_MM_YYYY")}`;

  wb.Props = {
    Title: title,
    Subject: settlement ? "Reporte de acreditación" : "Reporte de caja Get",
    Author: "Get Technologies",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push("Ordenes");
  const ws_data = [
    [
      "Fecha",
      "Ticket",
      "Driver",
      "Zona",
      "Fee Zona",
      "Plataforma",
      "Subtotal",
      "Delivery",
      "Total",
      "Efectivo",
      "Tarjeta",
      "Credito",
      "Fee Get",
      "% Fee Get",
      "Pago Comercio",
      "Cambio",
      "Comercio",
      "Tipo",
      "Pago Driver",
      "Fee Banco",
      "% Fee Banco",
      "IVA Comisión",
      "2% adelanto",
      "Extra",
      "Cash Out",
      "Cash In",
      "Balance Driver",
      "Porcentaje incremento",
      "Incremento",
      "Utilidad",
    ],
  ];

  orders.forEach((order) => {
    const newRegister = [
      moment
        .tz(order.date, "America/El_Salvador")
        .format("DD/MM/YYYY, HH:mm a"),
      order.ticket,
      order.driver_name || "-",
      order.coverage,
      order.delivery_fee_main,
      order.platform,
      order.order_subtotal,
      order.delivery_fee,
      order.order_total,
      order.cash,
      order.card,
      order.credit,
      order.get_fee,
      order.get_fee_percentage,
      order.repay,
      order.change,
      order.partner_name,
      !!order.payment_type ? "Crédito" : "Contado",
      order.driver_payment,
      order.bank_fee,
      order.bank_fee_percentage,
      order.bank_fee_iva,
      order.tax_advance,
      order.bank_fee_extra,
      order.cash_out,
      order.cash_in,
      order.driver_balance_withdrawal,
      order.price_percentage_increase || 0,
      order.price_increase || 0,
      order.profits,
    ];
    ws_data.push(newRegister);
  });
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Ordenes"] = ws;
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${title}.xlsx`
  );
};

export default exportData;
