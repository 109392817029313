import { Input, Button, Table, Spin, DatePicker, Select } from "antd";
import { Link, useHistory } from "react-router-dom";
import columns from "../utils/columns";
import "../styles/SettlementsUI.scss";
const { RangePicker } = DatePicker;
const { Option } = Select;

const SettlementsUI = (props) => {
  const {
    today,
    loading,
    country,
    partners,
    unpaid,
    handleSelectedSettlements,
    sending,
    confirmation,
    handleConfirmation,
    confirmSettlement,
    handleSelectedIds,
    selectedIds,
    exporting,
    exportSettlement,
    description,
    handleDescription,
    beginDate,
    endDate,
    handleDates,
    handleCurrentData,
    currentData,
    handlePaymenType,
    payment_type,
    getUnpaidSettlements,
  } = props;

  const history = useHistory();

  const filterTable = (pagination, filters, sorter, extra) => {
    handleCurrentData(extra.currentDataSource);
    handleSelectedIds([]);
    handleSelectedSettlements([]);
  };

  const onSelectChange = (record, selected, selectedRows) => {
    const indexes = selectedRows.map((el) => el.id_partner);
    handleSelectedIds(indexes);
    handleSelectedSettlements(selectedRows);
  };

  const onSelectAll = (selected) => {
    if (selected) {
      const indexes = currentData.map((el) => el.id_partner);
      handleSelectedIds(indexes);
      handleSelectedSettlements(currentData);
    } else {
      handleSelectedIds([]);
      handleSelectedSettlements([]);
    }
  };

  return (
    <div className="settlements-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Pendientes</h1>
          <h3 className="subtitle">{today.format("dddd D [de] MMMM")}</h3>
        </div>
      </div>
      <div className="actions-wrap">
        <div className="initial-wrap">
          <span className="label-cash">INGRESAR Nº DE CONFIRMACIÓN</span>
          <div className="input-confirmation">
            <Input
              disabled={sending}
              className="number-confirmation"
              value={confirmation}
              onChange={(e) => handleConfirmation(e.target.value)}
              size="large"
            />
            <Button
              className="btn-confirmation"
              size="large"
              type="primary"
              onClick={confirmSettlement}
              loading={sending}
              disabled={sending || loading}
            >
              Acreditar
            </Button>
          </div>
        </div>
        <div className="actions">
          <button
            disabled={loading}
            loading={exporting}
            onClick={exportSettlement}
            className="action green"
          >
            Exportar
          </button>
          <Link to="/settlements/history" className="action purple">
            Historial
          </Link>
        </div>
      </div>
      <div className="actions-wrap">
        <div className="filters-wrap">
          <RangePicker
            size="large"
            placeholder={["Fecha Inicio", "Fecha Final"]}
            allowClear={false}
            value={[beginDate, endDate]}
            onChange={handleDates}
          />
          <Select
            value={payment_type}
            size="large"
            className="select-type"
            onChange={handlePaymenType}
          >
            <Option value={1}>Crédito</Option>
            <Option value={0}>Contado</Option>
            <Option value={2}>Todos</Option>
          </Select>
          <Button
            onClick={getUnpaidSettlements}
            className="filter-btn"
            type="primary"
            size="large"
            disabled={loading}
          >
            Buscar
          </Button>
        </div>
        <div>
          {/* <span className="label-cash">CONCEPTO DE LIQUIDACIÓN</span> */}
          <Input
            disabled={sending}
            className="number-confirmation description"
            placeholder="Concepto de acreditación"
            value={description}
            onChange={(e) => handleDescription(e.target.value)}
            size="large"
          />
        </div>
      </div>
      {!loading ? (
        <div className="pendings">
          <Table
            rowSelection={{
              type: "checkbox",
              onSelect: onSelectChange,
              selectedRowKeys: selectedIds,
              onSelectAll,
            }}
            onChange={filterTable}
            rowKey="id_partner"
            scroll={{ x: 1500 }}
            dataSource={unpaid}
            columns={columns(country, partners, history)}
          />
        </div>
      ) : (
        <div className="loader-wrap">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default SettlementsUI;
