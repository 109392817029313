import instance from '../../Api';

export const getDawRecords = async config => {
  const api = await instance();
  let data = await api
    .post('/settlements/daw-records', config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getCategories = async () => {
  const api = await instance();
  let data = await api
    .post(
      '/settlements/daw-categories',
      {role: 'agent'}
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const createDawRecord = async record => {
  const api = await instance();
  let data = await api
    .post(
      '/settlements/add-daw',
      record,
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteDawRecord = async id => {
  const api = await instance();
  let data = await api
    .post(
      '/settlements/delete-daw',
      {id},
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
