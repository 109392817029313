import { createSlice } from '@reduxjs/toolkit';
import { getOrdersHistory } from './Api'

// Reducer
export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    orders: [],
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
  },
});


//Actions

export const { setOrders } = reportsSlice.actions;

export const getOrders = (config) => async dispatch => {
  try {
    const response = await getOrdersHistory(config);
    if (!response.error && response.status === 200) {
      dispatch(setOrders(response.data.orders));
      return {
        status: 'success',
      };
    }
    if (response.error.response.status === 401) {
      return {
        status: 'error',
        type: 'not-found'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectOrders = state => state.reports.orders;

export default reportsSlice.reducer;
