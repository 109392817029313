import { createSlice } from '@reduxjs/toolkit';
import {
    getDtSort,
    createDtSort,
    updateDtSort,
    deleteDtSort,
  } from './Api'

// Reducer

export const feedSortDetailSlice = createSlice({
    name: 'feedsortdetail',
    initialState: {
        sortdetail: [],
    },
    reducers: {
        setSortDetail: (state, action) => {
            state.sortdetail = action.payload;
        },
    },
});


//Actions

export const {
    setSortDetail,
  } = feedSortDetailSlice.actions;

export const getFeedSortDetail = (id) => async dispatch => {
    try {
        const response = await getDtSort(id);
        if (!response.error && response.status === 200) {
            return {
                status: 'success',
                dt_sort: response.data.dt_sort,
            };
        }
        return {
            status: 'error',
            type: 'unkown'
        };
    } catch (e) {
        return {
            status: 'error',
            type: 'unkown'
        };
    }
}

export const createFeedSort = (dtSort) => async dispatch => {
    try {
      const response = await createDtSort(dtSort);
      if (!response.error && response.status === 200) {
        if (response.data.dt_sort) {
          return {
            status: 'success',
            dtSort: response.data.dt_sort,
          };
        }
        return {
          status: 'error',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    } catch (e) {
      return {
        status: 'error',
        type: 'unkown'
      };
    }
  }

export const updateFeedSort = (dtSort) => async dispatch => {
    try {
      const response = await updateDtSort(dtSort);
      if (!response.error && response.status === 200) {
        if (response.data.dt_sort) {
          return {
            status: 'success',
          };
        }
        return {
          status: 'error',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    } catch (e) {
      return {
        status: 'error',
        type: 'unkown'
      };
    }
  }

export const deleteFeedSort = (id) => async dispatch => {
    try {
      const response = await deleteDtSort(id);
      if (!response.error && response.status === 200) {
        if (response.data.dt_sort) {
          return {
            status: 'success',
          };
        }
        return {
          status: 'error',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    } catch (e) {
      return {
        status: 'error',
        type: 'unkown'
      };
    }
  }

export default feedSortDetailSlice.reducer;