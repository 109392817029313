import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Tooltip, Modal, Alert, Tag } from 'antd';
import Timer from './Timer';
import Step from './Step';
import { X, TelephoneFill, ListCheck, CompassFill } from '../../../components/Icon';
import {
  getBranchesOrder,
  sendToPartner,
  getDriversOrder,
  acceptOrderBranch,
  sendToDriver,
  dispatchOrderBranch,
  deliveryStarted,
  completeOrder,
  selectOrderDetail,
  setOrderDriver,
} from '../MonitorSlice';
import { selectUser, selectCountry } from '../../login/UserSlice';
import '../styles/Details.scss';

const Details = (props) => {
  const [loadingBranches, handleLoadingBranches] = useState(false);
  const [cirebaWarning, handleCirebaWarning] = useState(false);
  const [branches, handleBranches] = useState([]);
  const [drivers, handleDrivers] = useState([]);
  const [driverSelected, handleDriverSelected] = useState(null);
  const [branchSelected, handleBranchSelected] = useState(null);
  const [timeSelected, handleTimeSelected] = useState(null);
  const user = useSelector(selectUser);
  const country = useSelector(selectCountry);
  const tuNicaragua = user.role === 3 && country === 'ni';
  const orderDetail = useSelector(selectOrderDetail);
  const dispatch = useDispatch();

  const {
    close,
    handleShowOrder,
    handleShowMap,
    cancelOrder,
  } = props;

  const {
    partner,
    branch,
    created_time,
    ticket,
    id_user,
    id_address,
    id,
    status,
    id_driver,
    partner_acepted_time,
    partner_dispatched_time,
    description,
    platform,
    get_driver,
  } = orderDetail;

  useEffect(() => {
    const initialFetch = async () => {
      handleLoadingBranches(true);
      if (id) {
        const response = await dispatch(getBranchesOrder(id));
        const response_drivers = await dispatch(getDriversOrder(id));
        if (response.status === 'success') {
          handleBranches(response.branches);
        }
        if (response_drivers.status === 'success') {
          handleDrivers(response_drivers.drivers);
        }
      }
      handleLoadingBranches(false);
    };

    initialFetch();
  }, [dispatch, id]);


  const asssignBranch = async () => {
    if (branchSelected) {
      const index = branches.findIndex(el => el.id === branchSelected);
      const branchInfo = index > -1 ? branches[index] : false;
      const response = await dispatch(sendToPartner(id, branchSelected, user.id, branchInfo));
      if (response.status === 'success') {
        Modal.success({
          title: 'Orden enviada',
          content: 'Se ha enviado la orden al comercio',
        });
      } else {
        Modal.error({
          title: 'Hubo un problema',
          content: 'No se ha podido realizar esta acción. Inténtalo de nuevo.',
        });
      }
    } else {
      Modal.error({
        title: 'Selecciona una sucursal',
        content: 'Debes escoger una sucursal para poder realizar esta acción',
      });
    }
  };

  const acceptOrder = async () => {
    if (timeSelected) {
      const response = await dispatch(acceptOrderBranch(id, branch.id || branchSelected, timeSelected));
      if (response.status === 'success') {
        Modal.success({
          title: 'Orden aceptada por sucursal',
          content: 'Se ha aceptado la orden',
        });
        if (response.cireba && (response.cireba.remote_id === false || response.cireba.remote_id === '0')) {
          handleCirebaWarning(true);
        }
      } else {
        Modal.error({
          title: 'Hubo un problema',
          content: 'No se ha podido realizar esta acción. Inténtalo de nuevo.',
        });
      }
    } else {
      Modal.error({
        title: 'Selecciona un tiempo de preparación',
        content: 'Debes escoger una tiempo para poder realizar esta acción',
      });
    }
  };

  const assignDriver = async () => {
    if (driverSelected !== null) {
      const response = await dispatch(sendToDriver(id, drivers[driverSelected].id));
      if (response.status === 'success') {
        dispatch(setOrderDriver({id, driver: drivers[driverSelected]}));
        Modal.success({
          title: 'Orden asignada a driver',
          content: 'Se ha asignado el driver para esta orden',
        });
      } else {
        Modal.error({
          title: 'Hubo un problema',
          content: 'No se ha podido realizar esta acción. Inténtalo de nuevo.',
        });
      }
    } else {
      Modal.error({
        title: 'Selecciona un driver',
        content: 'Debes escoger una driver para poder realizar esta acción',
      });
    }
  };

  const assignDriverPartner = () => {
    Modal.confirm({
      title: '¿Deseas asignar un Getter a esta orden',
      content: 'Esta orden indica que será entregada por el comercio. ¿Estás seguro de continuar?',
      okText: 'Asignar Getter',
      cancelText: 'Cancelar',
      async onOk() {
        await assignDriver();
      },
      onCancel() {}
    });
  };

  const dispatchOrder = async () => {
    const response = await dispatch(dispatchOrderBranch(id));
    if (response.status === 'success') {
      Modal.success({
        title: 'Orden despachada',
        content: 'Se ha despachado la orden',
      });
    } else {
      Modal.error({
        title: 'Hubo un problema',
        content: 'No se ha podido realizar esta acción. Inténtalo de nuevo.',
      });
    }
  };

  const toDelivery = async () => {
    const response = await dispatch(deliveryStarted(id, id_driver.id));
    if (response.status === 'success') {
      Modal.success({
        title: 'Orden en camino',
        content: 'Se ha colocado la orden en camino',
      });
    } else {
      Modal.error({
        title: 'Hubo un problema',
        content: 'No se ha podido realizar esta acción. Inténtalo de nuevo.',
      });
    }
  };

  const complete = async () => {
    const response = await dispatch(completeOrder(id, id_driver.id));
    if (response.status === 'success') {
      close();
      Modal.success({
        title: 'Orden entregada',
        content: 'Se ha entregado la orden',
      });
    } else {
      Modal.error({
        title: 'Hubo un problema',
        content: 'No se ha podido realizar esta acción. Inténtalo de nuevo.',
      });
    }
  };

  const showMap = () => {
    handleShowMap(true);
  };

  return (
    <>
      {partner ? (
        <>
          <div className="partner-info">
            <button className="close-btn" onClick={close}>
              <X onClick={close} />
            </button>
            <div className="info">
              {partner && (
                <div className="logo" style={{backgroundImage: `url(${[partner.logo]})`}} />
              )}
              <div className="names">
                <span className="name">{partner.name}</span>
                <span className="branch">{branch.zone}</span>
              </div>
              <Tooltip
                placement="bottomRight" 
                  title={
                    `${typeof branch.phone === 'string' ? branch.phone : ''}
                    ${typeof branch.phone_two === 'string' ? ` - ${branch.phone_two}` : ''}
                    ${typeof branch.phone_three === 'string' ? ` - ${branch.phone_three}` : ''}`
                  }
              >
                <div className="action phone-wrap">
                  <TelephoneFill />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="section ticket-info">
            {!tuNicaragua && (
              <span className="timer">
                <Timer begin={created_time} />
              </span>
            )}
            <div className="ticket-wrap">
              {platform === 'app' ? (
                <i className="bi bi-phone app" />
              ) : (
                <i className="bi bi-headset call" />
              )}
              <span className="ticket">{ticket}</span>
            </div>
          </div>
          {(cirebaWarning || orderDetail.cireba_remote_id === false || orderDetail.cireba_remote_id === '0') && (
            <div className="warning-wrap">
              <Alert
                message="No se pudo ingresar la orden en el sistema CIREBA. Ingresarla de nuevo con su sistema."
                type="error"
              />
            </div>
          )}
          <div className="section user-info">
            <div>
              <span className="name">{`${id_user.name} ${id_user.lastname}`}</span>
              <div className="actions">
                <button onClick={() => handleShowOrder(true)} className="action list-wrap">
                  <ListCheck />
                </button>
                {!tuNicaragua && (
                  <>
                    <button onClick={showMap} className="action compass-wrap">
                      <CompassFill />
                    </button>
                    <Tooltip placement="bottomRight" title={id_user.mobile}>
                      <button className="action phone-wrap">
                        <TelephoneFill />
                      </button>
                    </Tooltip>
                  </>
                )}
                {(!tuNicaragua) && (
                  <button onClick={cancelOrder} className="action list-wrap cancel-wrap">
                    <i className="bi bi-x" />
                  </button>
                )}
              </div>
            </div>
            <div>
              {!!(orderDetail.tn) && !tuNicaragua && (
                <Tag color="blue" className="label-tunicaragua">Pedido Tu Nicaragua</Tag>
              )}
              <span className="label">DIRECCIÓN</span>
              <p>{id_address.address}</p>
              <span className="label">PUNTO DE REFERENCIA</span>
              <p>{id_address.reference_point}</p>
              {description && (
                <>
                  <span className="label">COMENTARIOS</span>
                  <p>{description}</p>
                </>
              )}
            </div>
          </div>
          {!tuNicaragua && (
            <div className="steps">
              <Step
                state="recieved"
                loadingBranches={loadingBranches}
                branches={branches}
                handleBranchSelected={handleBranchSelected}
                action={asssignBranch}
                branchSelected={branchSelected}
                showMap={showMap}
                phone={branch.phone}
                disabled={status !== 1 && status !== 13 && status !== 6}
                tuNicaragua={tuNicaragua}
              />
              <Step
                state="toPreparation"
                timeSelected={timeSelected}
                handleTimeSelected={handleTimeSelected}
                // disabled={status !== 6}
                action={acceptOrder}
                hour={partner_acepted_time}
                tuNicaragua={tuNicaragua}
              />
              <Step
                state="toDriver"
                drivers={drivers}
                loadingBranches={loadingBranches}
                driverSelected={driverSelected}
                handleDriverSelected={handleDriverSelected}
                showMap={showMap}
                disabled={status < 7 || status === 13}
                action={get_driver === 1 ? assignDriver : assignDriverPartner}
                tuNicaragua={tuNicaragua}
              />
              <Step
                state="toDispatch"
                disabled={status !== 7 || !id_driver}
                action={dispatchOrder}
                hour={partner_dispatched_time}
                tuNicaragua={tuNicaragua}
              />
              <Step
                state="toDelivery"
                disabled={status !== 9 || !id_driver}
                action={toDelivery}
                tuNicaragua={tuNicaragua}
              />
              <Step
                showMap={showMap}
                phone={id_driver ? id_driver.phone : ''}
                state="completed"
                disabled={status !== 11}
                action={complete}
                tuNicaragua={tuNicaragua}
              />
            </div>
          )}
        </>
      ) : (
        <span />
      )}
    </>
  );
};

export default Details;