import instance from "../../Api";

export const getOrdersRecords = async (config) => {
  const api = await instance();
  let data = await api.post("/graph/sales", config).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getProfitsRecords = async (config) => {
  const api = await instance();
  let data = await api.post("/graph/profits", config).catch((error) => {
    return {
      error,
    };
  });
  return data;
};
