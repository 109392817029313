import {getCurrency} from '../../../utils/format';

const columns = (
  country,
  partners,
  history,
) => {
  const getCurrencyCountry = val => getCurrency(country, val);

  return [
    {
      title: 'Comercio',
      dataIndex: 'partner_name',
      width: 200,
      align: 'center',
      filters: partners,
      onFilter: (value, record) => record.id_partner === value,
      filterSearch: true,
      render: (val) => (
        <span className="normal">
          {val}
        </span>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'payment_type',
      width: 100,
      align: 'center',
      filters: [
        {
          text: 'Crédito',
          value: 1,
        },
        {
          text: 'Contado',
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.payment_type === value,
      render: (val) => (
        <span className="normal">
          {val === 1 ? 'Crédito' : 'Contado'}
        </span>
      ),
    },
    {
      title: 'Ordenes',
      dataIndex: 'transactions',
      width: 110,
      align: 'center',
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      width: 100,
      align: 'center',
      render: val => getCurrencyCountry(val),
    },
    {
      title: '% Desc',
      dataIndex: 'get_fee_percentage',
      width: 100,
      align: 'center',
      render: val => (
        <span className="normal">
          {`${(val * 100).toFixed(2)}%`}
        </span>
      ),
    },
    {
      title: 'Comisión',
      dataIndex: 'get_fee',
      width: 100,
      align: 'center',
      render: val => (
        <span className="teal">{getCurrencyCountry(val)}</span>
      ),
    },
    {
      title: 'IVA (13%)',
      dataIndex: 'iva',
      width: 100,
      align: 'center',
      render: val => (
        <span className="teal">{getCurrencyCountry(val)}</span>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 100,
      align: 'center',
      render: val => (
        <span className="box blue">{getCurrencyCountry(val)}</span>
      ),
    },
    {
      title: 'Reintegrar',
      dataIndex: 'repay',
      width: 100,
      align: 'center',
      render: val => (
        <span className="box yellow">{getCurrencyCountry(val)}</span>
      ),
    },
    {
      title: 'Nº de cuenta',
      dataIndex: 'account_number',
      width: 150,
      align: 'center',
      render: (val) => (
        <span className="normal">
          {val}
        </span>
      ),
    },
    {
      title: 'Banco',
      dataIndex: 'bank_name',
      width: 200,
      render: (val) => (
        <span className="normal">
          {val}
        </span>
      ),
    },
    {
      title: 'Ver',
      dataIndex: 'id_partner',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: val => (
        <button
          className="settlement-detail"
          onClick={() => history.push(`/settlements/unpaid/${val}`)}
        >
          <span>Detalle</span>
        </button>
      ),
    },
  ];
};

export default columns;
