import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import ChartsUI from "./components/ChartsUI";
import {
  getOrders,
  selectOrders,
  getProfits,
  selectProfits,
} from "./ChartsSlice";

const Charts = () => {
  const today = moment.tz("America/El_Salvador");
  const aWeekAgo = moment.tz("America/El_Salvador").subtract(7, "days");
  const [profitsEndDate, handleProfitsEndDate] = useState(today);
  const [profitsBeginDate, handleProfitsBeginDate] = useState(aWeekAgo);
  const [ordersEndDate, handleOrdersEndDate] = useState(today);
  const [ordersBeginDate, handleOrdersBeginDate] = useState(aWeekAgo);
  const [loadingProfits, handleLoadingProfits] = useState(true);
  const [loadingOrders, handleLoadingOrders] = useState(true);
  const orders = useSelector(selectOrders);
  const profits = useSelector(selectProfits);
  const dispatch = useDispatch();

  const searchOrders = useCallback(
    async (newdBegin, newEnd) => {
      handleLoadingOrders(true);
      const begin = newdBegin || ordersBeginDate;
      const end = newEnd || ordersEndDate;
      const config = {
        startDate: begin.format("YYYY-MM-DD 00:00:00"),
        endDate: end.format("YYYY-MM-DD 23:59:59"),
      };
      const response = await dispatch(getOrders(config));
      if (response.status !== "success") {
        Modal.warning({
          title: "Hubo un problema",
          content: "Inténtalo de nuevo más tarde",
        });
      }
      handleLoadingOrders(false);
    },
    [dispatch, ordersBeginDate, ordersEndDate]
  );

  const searchProfits = useCallback(
    async (newdBegin, newEnd) => {
      handleLoadingProfits(true);
      const begin = newdBegin || profitsBeginDate;
      const end = newEnd || profitsEndDate;
      const config = {
        startDate: begin.format("YYYY-MM-DD 00:00:00"),
        endDate: end.format("YYYY-MM-DD 23:59:59"),
      };
      const response = await dispatch(getProfits(config));
      if (response.status !== "success") {
        Modal.warning({
          title: "Hubo un problema",
          content: "Inténtalo de nuevo más tarde",
        });
      }
      handleLoadingProfits(false);
    },
    [dispatch, profitsBeginDate, profitsEndDate]
  );

  const handleOrdersDates = (newDates) => {
    handleOrdersBeginDate(newDates[0]);
    handleOrdersEndDate(newDates[1]);
    searchOrders(newDates[0], newDates[1]);
  };

  const handleProfitsDates = (newDates) => {
    handleProfitsBeginDate(newDates[0]);
    handleProfitsEndDate(newDates[1]);
    searchProfits(newDates[0], newDates[1]);
  };

  useEffect(() => {
    searchOrders();
    searchProfits();
  }, []);

  return (
    <ChartsUI
      today={today}
      loadingProfits={loadingProfits}
      loadingOrders={loadingOrders}
      ordersBeginDate={ordersBeginDate}
      ordersEndDate={ordersEndDate}
      profitsBeginDate={profitsBeginDate}
      profitsEndDate={profitsEndDate}
      handleOrdersDates={handleOrdersDates}
      handleProfitsDates={handleProfitsDates}
      orders={orders}
      profits={profits}
    />
  );
};

export default Charts;
