import {useState, useRef, useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import {getDriversList, selectDrivers} from './DriversSlice';
import {selectUser} from '../login/UserSlice';
import DriversUI from './components/DriversUI';

const Drivers = () => {
  const [searchText, handleSearchText] = useState('');
  const [searchedColumn, handleSearchedColumn] = useState('');
  const [loading, handleLoading] = useState(true);
  const [showMap, handleShowMap] = useState(false);
  const [partners, handlePartners] = useState([]);
  const searchInput = useRef(null);
  const drivers = useSelector(selectDrivers);
  const user = useSelector(selectUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = user.role === 10;

  const initialFetch = useCallback(async () => {
    const response = await dispatch(getDriversList(isAdmin));
    if (response.status === 'success') {
      handlePartners(response.partners);
    }
    handleLoading(false);
  }, [dispatch, isAdmin]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    handleSearchText(selectedKeys[0]);
    handleSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    handleSearchText('');
  };

  const createDriver = () => {
    history.push('/drivers/new');
  };

  const editDriver = (driver) => {
    history.push(`/drivers/${driver}`);
  };

  return (
    <DriversUI
      drivers={drivers}
      searchInput={searchInput}
      searchText={searchText}
      loading={loading}
      searchedColumn={searchedColumn}
      handleSearch={handleSearch}
      handleReset={handleReset}
      createDriver={createDriver}
      editDriver={editDriver}
      showMap={showMap}
      handleShowMap={handleShowMap}
      isAdmin={isAdmin}
      partners={partners}
    />
  );
};

export default Drivers;
