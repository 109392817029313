import {useState} from 'react';
import {Input, Button} from 'antd';
import isInt from 'validator/lib/isInt';
import produce from 'immer';
import {getCurrency} from '../../../utils/format';
import initialCash from '../utils/initialCash';
import '../styles/CashSummary.scss';


const CashSummary = ({country, cash, handleCash, closeCashier}) => {
  const [sending, handleSending] = useState(false);
  const [total, handleTotal] = useState(0);
  const getCurrencyCountry = val => getCurrency(country, val);

  const handleQuantity = (e, type, value) => {
    const input = e.target.value;
    if (isInt(input) || input === '') {
      const val = parseInt(input, 10);
      const newCash = produce(cash, draftState => {
        draftState[type] = isNaN(val) ? '' : val;
      });
      const newTotal =
        total - (cash[type] * value) + (isNaN(val) ? 0 : val) * value;
      handleTotal(newTotal);
      handleCash(newCash);
    }
  };

  const clean = () => {
    handleCash(initialCash(country));
    handleTotal(0);
  };

  const review = async () => {
    handleSending(true);
    await closeCashier(false);
    handleSending(false);
  };

  return (
    <div className="cash-summary">
      <div className="header">
        <span className="title">ARQUEO DE CAJA</span>
        <button onClick={clean} className="clean">
          LIMPIAR
        </button>
      </div>
      <div className="details">
        <div className="header-details">
          <span className="denomination">
            Denominación
          </span>
          <span className="amount">
            Cantidad
          </span>
          <span className="total">
            Total
          </span>
        </div>
        {country === 'sv' ? (
          <div className="body-details">
            <div className="detail">
              <span className="label-detail">
                Billete de 100
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.bill_100}
                onChange={e => handleQuantity(e, 'bill_100', 100)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.bill_100 * 100)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 50
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.bill_50}
                onChange={e => handleQuantity(e, 'bill_50', 50)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.bill_50 * 50)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 20
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.bill_20}
                onChange={e => handleQuantity(e, 'bill_20', 20)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.bill_20 * 20)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 10
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.bill_10}
                onChange={e => handleQuantity(e, 'bill_10', 10)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.bill_10 * 10)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 5
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.bill_5}
                onChange={e => handleQuantity(e, 'bill_5', 5)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.bill_5 * 5)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 1
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.bill_1}
                onChange={e => handleQuantity(e, 'bill_1', 1)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.bill_1 * 1)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 1
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.coin_100}
                onChange={e => handleQuantity(e, 'coin_100', 1)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.coin_100 * 1)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 0.25
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash['coin_25']}
                onChange={e => handleQuantity(e, 'coin_25', 0.25)}
              />
              <span className="amount">
                {getCurrencyCountry(cash['coin_25'] * 0.25)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 0.10
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash['coin_10']}
                onChange={e => handleQuantity(e, 'coin_10', 0.10)}
              />
              <span className="amount">
                {getCurrencyCountry(cash['coin_10'] * 0.10)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 0.05
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash['coin_5']}
                onChange={e => handleQuantity(e, 'coin_5', 0.05)}
              />
              <span className="amount">
                {getCurrencyCountry(cash['coin_5'] * 0.05)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 0.01
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash['coin_1']}
                onChange={e => handleQuantity(e, 'coin_1', 0.01)}
              />
              <span className="amount">
                {getCurrencyCountry(cash['coin_1'] * 0.01)}
              </span>
            </div>
          </div>
        ) : (
          <div className="body-details">
            <div className="detail">
              <span className="label-detail">
                Billete de 1000
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_bill_1000}
                onChange={e => handleQuantity(e, 'ni_bill_1000', 1000)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_bill_1000 * 1000)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 500
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_bill_500}
                onChange={e => handleQuantity(e, 'ni_bill_500', 500)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_bill_500 * 500)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 200
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_bill_200}
                onChange={e => handleQuantity(e, 'ni_bill_200', 200)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_bill_200 * 200)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 100
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_bill_100}
                onChange={e => handleQuantity(e, 'ni_bill_100', 100)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_bill_100 * 100)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 50
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_bill_50}
                onChange={e => handleQuantity(e, 'ni_bill_50', 50)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_bill_50 * 50)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 20
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_bill_20}
                onChange={e => handleQuantity(e, 'ni_bill_20', 20)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_bill_20 * 20)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 10
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_bill_10}
                onChange={e => handleQuantity(e, 'ni_bill_10', 10)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_bill_10 * 10)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Billete de 5
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_bill_5}
                onChange={e => handleQuantity(e, 'ni_bill_5', 5)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_bill_5 * 5)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 5
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_coin_500}
                onChange={e => handleQuantity(e, 'ni_coin_500', 5)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_coin_500 * 5)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 1
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash.ni_coin_100}
                onChange={e => handleQuantity(e, 'ni_coin_100', 1)}
              />
              <span className="amount">
                {getCurrencyCountry(cash.ni_coin_100 * 1)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 0.50
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash['ni_coin_50']}
                onChange={e => handleQuantity(e, 'ni_coin_50', 0.50)}
              />
              <span className="amount">
                {getCurrencyCountry(cash['ni_coin_50'] * 0.50)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 0.25
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash['ni_coin_25']}
                onChange={e => handleQuantity(e, 'ni_coin_25', 0.25)}
              />
              <span className="amount">
                {getCurrencyCountry(cash['ni_coin_25'] * 0.25)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 0.10
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash['ni_coin_10']}
                onChange={e => handleQuantity(e, 'ni_coin_10', 0.10)}
              />
              <span className="amount">
                {getCurrencyCountry(cash['ni_coin_10'] * 0.10)}
              </span>
            </div>
            <div className="detail">
              <span className="label-detail">
                Moneda de 0.05
              </span>
              <Input
                className="input-cash"
                placeholder="0"
                value={cash['ni_coin_5']}
                onChange={e => handleQuantity(e, 'ni_coin_5', 0.05)}
              />
              <span className="amount">
                {getCurrencyCountry(cash['ni_coin_5'] * 0.05)}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="total-wrap">
        <span className="label-total">Total:</span>
        <span className="real-total">{getCurrencyCountry(total)}</span>
      </div>
      <div className="next-wrap">
        <Button
          onClick={review}
          size="large"
          type="primary"
          className="next-btn"
          loading={sending}
        >
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default CashSummary;
