import { Steps, Alert } from "antd";
import {
  PersonXFill,
  PersonPlusFill,
  PhoneFill,
  // BadgePersonFill,
  Warning,
  Bicycle,
  Cash,
  Card,
} from "../../../components/Icon";
import { getCurrency } from "../../../utils/format";
import Timer from "./Timer";
import "../styles/Order.scss";

const { Step } = Steps;

const Order = ({ open, order, tuNicaragua, country }) => {
  const {
    status,
    id_user,
    id_address,
    id_agent,
    partner,
    branch,
    created_time,
    ticket,
    id_driver,
    provisional_driver,
    id,
    platform,
    tn,
    get_driver,
    payment_method,
    pay_to_partner,
  } = order;

  const getStatus = () => {
    if (status === 8) {
      return <Warning />;
    } else if (status >= 7 && status <= 9) {
      return <PhoneFill />;
    }
    return <Bicycle />;
  };

  const getStatusColor = () => {
    if (status >= 7 && status <= 9) {
      return "offRed";
    }
    return "offTeal";
  };

  const openDetails = () => {
    open(order);
  };

  const getStepDriver = () => {
    const newDriver = id_driver || provisional_driver;
    if (newDriver) {
      return (
        <Step
          icon={
            <div
              className="logo"
              style={{ backgroundImage: `url(${newDriver.photo})` }}
            />
          }
          title={
            <span>
              {`${newDriver.name} ${newDriver.lastname}`}
              {!id_driver && provisional_driver && (
                <span className="red"> (P)</span>
              )}
            </span>
          }
          description={<span className="gray">{newDriver.phone}</span>}
        />
      );
    }
    return (
      <Step
        icon={
          <div className="logo no-driver">
            <PersonXFill />
          </div>
        }
        title={<span className="gray">Sin motorista</span>}
        description={<span className="gray">-</span>}
      />
    );
  };

  const getStepAgent = () => {
    if(id_agent) {
      return (
        <Step
          icon={<PersonPlusFill />}
          title={<span>Gestionado por</span>}
          description={<span className="gray">{id_agent.username}</span>}
        />
      );
    }
    return (<></>);
  };

  return (
    <div className="order-monitor">
      {!!tn && !tuNicaragua && <div className="tn-wrap">TN</div>}
      <div className="header-order">
        {!tuNicaragua && <Timer begin={created_time} />}
        <div>
          <span className="id">({id})</span>
          <span>{ticket}</span>
        </div>
      </div>
      {status >= 7 &&
        (order.cireba_remote_id === false ||
          order.cireba_remote_id === "0") && (
          <div className="warning-wrap">
            <Alert message="Orden no ingresada a CIREBA" type="error" />
          </div>
        )}
      {status === 8 && (
        <div className="warning-wrap">
          <Alert message="Orden rechazada por comercio" type="error" />
        </div>
      )}
      {get_driver === 0 && (
        <div className="warning-wrap">
          <Alert message="Delivery por comercio" type="warning" />
        </div>
      )}
      {country === "ni" &&
        payment_method === 2 &&
        platform === "callcenter" && (
          <div className="warning-wrap">
            <Alert message="LLevar POS para cobrar" type="warning" />
          </div>
        )}
      <div className="detail-order">
        <Steps className="progress" direction="vertical" current={3}>
          <Step
            icon={
              <div
                className="logo"
                style={{ backgroundImage: `url(${partner.logo})` }}
              />
            }
            title={partner.name}
            description={branch.zone}
          />
          {!tuNicaragua && getStepAgent()}
          {!tuNicaragua && getStepDriver()}
          <Step
            icon={
              <div
                className={`logo address payment ${
                  payment_method === 2 ? "cardPayment" : "cashPayment"
                }`}
              >
                {payment_method === 2 ? <Card /> : <Cash />}
              </div>
            }
            title={`${id_user.name} ${id_user.lastname}`}
            description={id_address.address}
          />
        </Steps>
        {!tuNicaragua && status >= 7 && status !== 13 && (
          <div className={`status ${getStatusColor()}`}>{getStatus()}</div>
        )}
        {!!pay_to_partner && (
          <span className="payToPartner">
            {`PC: ${getCurrency(country, pay_to_partner)}`}
          </span>
        )}
      </div>
      <div className="action-wrap">
        <button onClick={openDetails}>
          Ver detalle del pedido
          {platform === "app" ? (
            <i className="bi bi-phone app" />
          ) : (
            <i className="bi bi-headset call" />
          )}
        </button>
      </div>
    </div>
  );
};

export default Order;
