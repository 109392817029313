import instance from '../../Api';

export const getPartners = async () => {
  const api = await instance();
  let data = await api
    .get('/partners/feed')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getPartnerMenu = async (partner) => {
  const api = await instance();
  let data = await api
    .post(`/partners/menu/${partner}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getProductMenu = async (product, partner) => {
  const api = await instance();
  let data = await api
    .post(`/products/menu/${partner}/${product}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const checkCoverage = async (latitude, longitude) => {
  const api = await instance();
  let data = await api
    .post('/address/check-coverage', {latitude, longitude})
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const createAddress = async (address) => {
  const api = await instance();
  let data = await api
    .post('/address', address)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editAddress = async (address) => {
  const api = await instance();
  let data = await api
  .put(`/address/${address.id}`, address)
  .catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const deleteAddress = async (address) => {
  const api = await instance();
  let data = await api
  .delete(`/address/${address}`)
  .catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const createOrder = async (order) => {
  const api = await instance();
  let data = await api
    .post('/v3/orders/create', order)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getExchange = async () => {
  const api = await instance();
  let data = await api
    .post('/partners/currency')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getLastOrders = async user => {
  const api = await instance();
  let data = await api
    .post('/orders/last-five-orders', {id_user: user})
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
