import { Table } from "antd";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import "moment/locale/es";
import "moment-timezone";
import { getCurrency } from "../../../utils/format";
import { ReactComponent as Cash } from "../../../assets/methods/cash.svg";
import Card from "../../../assets/methods/visa.png";
import "../styles/History.scss";

const History = (props) => {
  const { orders, country, tuNicaragua, partners, handleCurrentData, isAdmin } =
    props;
  const history = useHistory();

  const getSubtotal = (subtotal, delivery, iva, total) => {
    const newSubtotal = subtotal || total - delivery - (iva || 0);
    return tuNicaragua ? getValuePlusIVA(newSubtotal) : newSubtotal;
  };

  const getPartnerAmount = (subtotal, delivery, iva, total, fee) => {
    const deliveryFee = getSubtotal(subtotal, delivery, iva, total) - fee;
    return deliveryFee;
  };

  const getValuePlusIVA = (val) => {
    return val * 1.15;
  };

  const filterTable = (pagination, filters, sorter, extra) => {
    handleCurrentData(extra.currentDataSource);
  };

  const columns = [
    {
      title: "Fecha",
      dataIndex: "created_time",
      key: "created_time",
      fixed: "left",
      render: (val) => {
        return moment
          .tz(val, "America/El_Salvador")
          .format("DD/MM/YYYY, HH:mm a");
      },
    },
    {
      title: "Ticket",
      dataIndex: "ticket",
      key: "ticket",
      align: "center",
      render: (val) => val.toUpperCase(),
    },
    {
      title: "Medio",
      dataIndex: "platform",
      key: "platform",
      align: "center",
      render: (val) => {
        if (val === "app") {
          return <i className="bi bi-phone blue" />;
        }
        return <i className="bi bi-headset red" />;
      },
    },
    {
      title: "Cliente",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (val, record) => `${val} ${record.last_name}`,
    },
    {
      title: "Teléfono",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",
    },
    {
      title: "Método",
      dataIndex: "payment_method",
      key: "payment_method",
      align: "center",
      render: (val) => {
        if (val === 2) {
          return <img src={Card} alt="" className="card" />;
        }
        return <Cash width="35px" height="23.49px" />;
      },
    },
    {
      title: "Comercio",
      dataIndex: "partner_name",
      key: "partner_name",
      align: "center",
      filters: partners,
      onFilter: (value, record) => record.id_partner === value,
      filterSearch: true,
    },
    {
      title: `Subtotal${tuNicaragua ? " + IVA" : ""}`,
      dataIndex: "subtotal",
      key: "subtotal",
      align: "center",
      render: (val, record) =>
        getCurrency(
          country,
          getSubtotal(val, record.delivery_fee, record.iva, record.total)
        ),
    },
    {
      title: `Envío${tuNicaragua ? " + IVA" : ""}`,
      dataIndex: "delivery_fee",
      key: "delivery_fee",
      align: "center",
      render: (val) =>
        getCurrency(country, tuNicaragua ? getValuePlusIVA(val) : val),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (val) => getCurrency(country, val),
    },
    {
      title: "Acreditar",
      dataIndex: "get_fee",
      key: "get_fee",
      align: "center",
      render: (val, record) =>
        val
          ? getCurrency(
              country,
              getPartnerAmount(
                record.subtotal,
                record.delivery_fee,
                record.iva,
                record.total,
                val
              )
            )
          : "-",
    },
    {
      title: "ID Remplazo",
      dataIndex: "id_replaced_order",
      align: "center",
      filters: [
        {
          text: "Remplazada",
          value: true,
        },
        {
          text: "No remplazada",
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => value === !!record.id_replaced_order,
      render: (val) => val || "-",
    },
    {
      title: "Extra",
      dataIndex: "extra_description",
      align: "center",
      render: (val) => val || "-",
    },
    {
      title: "Razón",
      dataIndex: "reason_for_cancellation",
      align: "center",
      render: (val) => val || "-",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        {
          text: "Completada",
          value: [12, 14],
        },
        {
          text: "Cancelada",
          value: [15, 16],
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => value.includes(record.status),
      render: (val) => (
        <div className={cx("circle", { danger: val !== 12 && val !== 14 })}>
          {val === 12 || val === 14 ? (
            <i className="bi bi-check2" />
          ) : (
            <i className="bi bi-x" />
          )}
        </div>
      ),
    },
  ];

  if (tuNicaragua) {
    columns.splice(10, 1);
  } else if (country === "ni") {
    columns.splice(9, 0, {
      title: "IVA",
      dataIndex: "iva",
      key: "iva",
      align: "center",
      render: (val) => getCurrency(country, val),
    });
    if (isAdmin) {
      columns.splice(12, 0, {
        title: "Canal",
        dataIndex: "tn",
        align: "center",
        width: 120,
        filters: [
          {
            text: "Get",
            value: 0,
          },
          {
            text: "Tu Nicaragua",
            value: 1,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => value === record.tn,
        render: (val) => (val ? "Tu Nicaragua" : "Get"),
      });
    }
  }

  return (
    <div className="orders-history-wrap">
      <Table
        columns={columns}
        dataSource={orders}
        rowKey="id"
        scroll={{ x: 2500 }}
        onChange={filterTable}
        onRow={(record) => {
          return {
            onClick: () => history.push(`/orders/${record.id}`),
          };
        }}
      />
    </div>
  );
};

export default History;
