import instance from '../../Api';

export const getUnpaidSettlements = async config => {
  const api = await instance();
  let data = await api
    .post('/settlements/unpaid-settlements', config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const createSettlement = async config => {
  const api = await instance();
  let data = await api
    .post('/settlements/pay-settlements', config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getUnpaidSettlement = async (idPartner, config) => {
  const api = await instance();
  let data = await api
    .post(`/settlements/unpaid-settlements-detail/${idPartner}`, config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getSettlementDetail = async idSettlement => {
  const api = await instance();
  let data = await api
    .get(`/settlements/settlements-detail/${idSettlement}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getHistory = async config => {
  const api = await instance();
  let data = await api
    .post('/settlements/history', config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
