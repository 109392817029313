import { Select, Input, Button, Spin, DatePicker, Modal } from "antd";
import { getCurrency } from "../../../utils/format";
import Settlements from "./Settlements";
import Close from "./Close";
import "../styles/GettersCashflowUI.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;

const GettersCashflowUI = (props) => {
  const {
    today,
    loadingDrivers,
    drivers,
    driverSelected,
    handleDriverSelected,
    handleInitialCash,
    initialCash,
    confirmOpen,
    loadingOpen,
    loading,
    country,
    settlements,
    beginDate,
    endDate,
    handleDates,
    openModal,
    cancelModal,
    handleCancelModal,
    settlementToClose,
    closeSettlement,
    driversUsed,
    exporting,
    exportSettlement,
    editBonus,
  } = props;

  return (
    <div className="getters-cashflow-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Gestión Getters</h1>
          <h3 className="subtitle">{today.format("dddd D [de] MMMM")}</h3>
        </div>
        <div className="number-orders">
          <div className="number-data">
            <h1 className="title">{settlements.length}</h1>
            <h3 className="subtitle">Gestiones</h3>
          </div>
        </div>
      </div>
      <div className="filters-wrap">
        <div className="create-settlement">
          <Select
            showSearch
            style={{ width: 300 }}
            placeholder="Seleccionar Getter"
            optionFilterProp="children"
            onChange={handleDriverSelected}
            value={driverSelected}
            size="large"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={loadingDrivers}
          >
            {drivers.map((driver) => (
              <Option key={driver.id} value={driver.id}>
                {`(${driver.id}) ${driver.name} ${driver.lastname}${
                  !!(driver.cashflow && driver.cashflow.length > 0)
                    ? ` - Flotante: ${getCurrency(
                        country,
                        driver.cashflow[0].missing
                      )}`
                    : ""
                }`}
              </Option>
            ))}
          </Select>
          <Input
            className="initial-amount"
            placeholder="Monto"
            value={initialCash}
            onChange={handleInitialCash}
            size="large"
          />
          <Button
            className="open-cashier"
            size="large"
            type="primary"
            onClick={confirmOpen}
            loading={loadingOpen}
            disabled={loading}
          >
            Aperturar
          </Button>
        </div>
        <RangePicker
          disabled={loading}
          size="large"
          placeholder={["Fecha Inicio", "Fecha Final"]}
          allowClear={false}
          value={[beginDate, endDate]}
          onChange={handleDates}
        />
        <button
          disabled={loading}
          loading={exporting}
          onClick={exportSettlement}
          className="export"
        >
          Exportar
        </button>
      </div>
      {!loading ? (
        <div className="settlements-drivers">
          <Settlements
            settlements={settlements}
            country={country}
            openModal={openModal}
            driversUsed={driversUsed}
            editBonus={editBonus}
          />
        </div>
      ) : (
        <div className="loader-wrap">
          <Spin size="large" />
        </div>
      )}
      <Modal
        title="Liquidar"
        destroyOnClose
        visible={cancelModal}
        onCancel={() => handleCancelModal(false)}
        footer={false}
      >
        <Close
          settlement={settlementToClose}
          closeSettlement={closeSettlement}
          country={country}
        />
      </Modal>
    </div>
  );
};

export default GettersCashflowUI;
