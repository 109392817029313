import { useState } from "react";
import { Input, Button, Modal } from "antd";
import isFloat from "validator/lib/isFloat";

const InputCashier = ({ val, action, record, index, bonus }) => {
  const [amount, handleAmount] = useState(val);
  const [sending, handleSending] = useState(false);

  const handleChange = (e) => {
    const input = e.target.value;
    if (isFloat(input) || input === "") {
      handleAmount(input);
    }
  };

  const edit = async () => {
    handleSending(true);
    if (bonus) {
      const { id } = record;
      if (amount !== "" && parseFloat(amount) >= 0) {
        await action(id, amount, index);
      } else if (amount === "") {
        Modal.error({
          title: "Ingresa una cantidad válida",
          content: "Debes ingresar una cantidad válida para seguir.",
        });
      }
    } else {
      const { id_order, card, cash_out, order_total } = record;
      if (amount !== "" && parseFloat(amount) >= order_total) {
        await action(id_order, amount, card, cash_out, index);
      } else if (amount === "") {
        Modal.error({
          title: "Ingresa una cantidad",
          content: "Debes ingresar una cantidad para seguir.",
        });
      } else {
        Modal.error({
          title: "Ingresa una cantidad mayor",
          content: "Debes ingresar una cantidad mayor o igual al total.",
        });
      }
    }
    handleSending(false);
  };

  return (
    <div className="cash-input-wrap">
      <Input
        className="input-cashflow"
        value={amount}
        onChange={handleChange}
        disabled={sending}
      />
      <Button onClick={edit} loading={sending} className="btn-save">
        {!sending && <i className="bi bi-check2" />}
      </Button>
    </div>
  );
};

export default InputCashier;
