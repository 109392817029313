import {Table} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {getCurrency} from '../../../utils/format';
import '../styles/History.scss';

const History = ({closes, country}) => {
  const getCurrencyCountry = val => getCurrency(country, val);

  const columns = [
    {
      title: 'Fecha de cierre',
      dataIndex: 'closing_date',
      align: 'center',
      render: val => (
        <span className="normal">
          {moment.tz(val, 'America/El_Salvador').format('DD/MM/YYYY, HH:mm a')}
        </span>
      ),
    },
    {
      title: 'Ordenes',
      dataIndex: 'transactions',
      align: 'center',
      render: val => (
        <span className="normal">{val}</span>
      ),
    },
    {
      title: 'Apertura',
      dataIndex: 'initial_cash_balance',
      align: 'center',
      render: val => (
        <span>{getCurrencyCountry(val)}</span>
      ),
    },
    {
      title: 'En caja',
      dataIndex: 'bill_coin_total',
      align: 'center',
      render: val => (
        <span className="yellow">
          {getCurrencyCountry(val)}
        </span>
      ),
    },
    {
      title: 'En sistema',
      dataIndex: 'cash_available',
      align: 'center',
      render: val => (
        <span className="teal">
          {getCurrencyCountry(val)}
        </span>
      ),
    },
    {
      title: 'Faltante',
      dataIndex: 'missing',
      align: 'center',
      render: val => (
        <span className="red">
          {getCurrencyCountry(val)}
        </span>
      ),
    },
    {
      title: 'Sobrante',
      dataIndex: 'surplus',
      align: 'center',
      render: val => (
        <span className="blue">
          {getCurrencyCountry(val)}
        </span>
      ),
    },
    {
      title: 'Ver',
      dataIndex: 'id',
      align: 'center',
      render: val => (
        <Link
          className="close-detail"
          to={`/cashflow/history/${val}`}
        >
          Detalle
        </Link>
      ),
    },
  ];

  return (
    <div className="history-closes-wrap">
      <Table
        columns={columns}
        dataSource={closes}
        rowKey="id"
      />
    </div>
  );
};

export default History;
