import { useState } from "react";
import { Input, Button } from "antd";
import isFloat from "validator/lib/isFloat";
import { getCurrency } from "../../../utils/format";
import "../styles/Close.scss";

const { TextArea } = Input;

const Close = ({ settlement, country, closeSettlement }) => {
  const [sending, handleSending] = useState(false);
  const [current, handleCurrent] = useState("");
  const [reference, setReference] = useState("");
  const [comments, handleComments] = useState("");
  const realBalance =
    settlement.balance - (settlement.bonus || 0) + (settlement.floating || 0);

  const setCurrent = (e) => {
    const value = e.target.value;
    if (isFloat(value) || value === "") {
      handleCurrent(value);
    }
  };

  const handleReference = (e) => {
    const value = e.target.value;
    setReference(value);
  };

  const getMissing = () => {
    const realCurrent = current === "" ? 0 : current;
    const result = realBalance - parseFloat(realCurrent);
    return result < 0 ? 0 : result;
  };

  const getSurplus = () => {
    const realCurrent = current === "" ? 0 : current;
    const result = parseFloat(realCurrent) - realBalance;
    return result < 0 ? 0 : result;
  };

  const close = async () => {
    handleSending(true);
    const info = {
      id: settlement.id,
      closing_cash_balance: current,
      missing: getMissing(),
      surplus: getSurplus(),
      codeRef: reference,
      comments: comments.length === 0 ? "-" : comments,
    };
    await closeSettlement(info);
    handleSending(false);
  };

  return (
    <div className="close-settlement-driver">
      <Input
        className="initial-amount"
        placeholder="Valor de entrega"
        value={current}
        onChange={setCurrent}
        size="large"
      />
      <div className="details">
        <div className="item-detail">
          <span className="label">Faltante</span>
          <span className="value missing">
            {getCurrency(country, getMissing())}
          </span>
        </div>
        <div className="item-detail">
          <span className="label">Balance</span>
          <span className="value balance">
            {getCurrency(country, realBalance)}
          </span>
        </div>
        <div className="item-detail">
          <span className="label">Sobrante</span>
          <span className="value surplus">
            {getCurrency(country, getSurplus())}
          </span>
        </div>
      </div>
      <Input
        className="initial-amount"
        placeholder="No Referencia"
        value={reference}
        onChange={handleReference}
        size="large"
      />
      <TextArea
        className="comments"
        placeholder="Comentarios"
        rows={3}
        value={comments}
        onChange={(e) => handleComments(e.target.value)}
      />
      <div className="actions">
        <Button type="primary" size="large" onClick={close} loading={sending}>
          Liquidar
        </Button>
      </div>
    </div>
  );
};

export default Close;
