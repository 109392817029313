import {useState, useEffect, PureComponent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Spin, Input} from 'antd';
import moment from 'moment';
import produce from 'immer';
import 'moment-timezone';
import {partnerMenu} from '../TakeOrdersSlice';
import {selectCountry} from '../../login/UserSlice';
import {getCurrency, slugify} from '../../../utils/format';
import '../styles/Menu.scss';

class Product extends PureComponent {
  render() {
    const {product, openProduct, country, currency} = this.props;
    return (
      <div className="product" onClick={() => openProduct(product)}>
        <div className="product-info">
          <span className="name">{product.name}</span>
          <span className="description">{product.description}</span>
          <span className="price">
            {getCurrency(country, product.price, currency)}
          </span>
        </div>
        {product.photo !== 
          `https://${country === 'sv' ? 'dev' : 'nicaragua'}.gmf.technology/gmf/frontend/web/uploads/img/products/`
        && (
          <div
            className="photo"
            style={{backgroundImage: `url(${product.photo})`}}
          />
        )}
      </div>
    );
  }
}

class Category extends PureComponent {
  render() {
    const {category, openProduct, country, currency} = this.props;
    return (
      <div className="category">
        <div className="category-header">
          <span>{category.name}</span>
        </div>
        {category.products.map((product) => (
          <Product
            key={product.id}
            product={product}
            country={country}
            currency={currency}
            openProduct={openProduct} />
        ))}
      </div>
    );
  }
}

const Menu = ({close, partner, openProduct}) => {
  const [loading, handleLoading] = useState(true);
  const [menu, handleMenu] = useState({});
  const [menuResults, handleMenuResults] = useState([]);
  const [search, handleSearch] = useState('');
  const country = useSelector(selectCountry);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const response = await dispatch(partnerMenu(partner.id));
      if (response.status === 'success') {
        handleMenu(response.menu);
        handleMenuResults(response.menu.categories);
      }
      handleLoading(false)
    };
    initialFetch();
  }, [dispatch, partner.id]);

  const showSchedule = () => {
    const {schedule} = menu.partner;
    const today =
      parseInt(moment.tz('America/El_Salvador').format('d'), 10);
    const index = schedule.findIndex((el) => el.day === today);
    if (index > -1) {
      const today_schedule = schedule[index];
      return `${moment(today_schedule.start_time, 'HH:mm').format(
        'hh:mm a',
      )} - ${moment(today_schedule.end_time, 'HH:mm').format(
        'hh:mm a',
      )}`;
    }
    return 'Cerrado';
  };

  const searchProduct = (newSearch) => {
    if (newSearch.length > 0) {
      const newList = [];
      menu.categories.map((category) => {
        const filteredProducts = category.products.filter(
          (el) => slugify(el.name).indexOf(slugify(newSearch)) >= 0,
        );
        if (filteredProducts.length > 0) {
          const newCategory = produce(category, (draftState) => {
            draftState.products = filteredProducts;
          });
          newList.push(newCategory);
        }
      });
      handleMenuResults(newList);
    } else {
      handleMenuResults(menu.categories);
    }
    handleSearch(newSearch);
  };

  return (
    <div className="menu-wrap-take-order">
    <div
      className="cover"
      style={{backgroundImage: `url(${partner.cover})`}}
    >
      <button onClick={close}>
        <i className="bi bi-arrow-left-short" />
      </button>
    </div>
    {!loading ? (
      <>
        <div className="info-partner">
          <div className="texts">
            <span className="name">{partner.name}</span>
            <span className="schedule">{showSchedule()}</span>
            {country === 'ni' && (
              <span className="disclaimer">* Precios no incluyen IVA</span>
            )}
          </div>
          {/* <span className="category">Mexicana</span> */}
        </div>
        <div className="search-wrap">
          <Input
            size="large"
            value={search}
            placeholder="Buscar producto..."
            onChange={(e) => searchProduct(e.target.value)}
          />
        </div>
        <div className="categories">
          {menuResults.map((category) => (
            <Category
              key={category.id}
              category={category}
              openProduct={openProduct}
              country={country}
              currency={partner.default_currency}
            />
          ))}
        </div>
      </>
    ) : (
      <div className="loader-wrap">
        <Spin />
      </div>
    )}
  </div>
  );
};

export default Menu;
