import { Switch, Route } from "react-router-dom";
import Monitor from "../../monitor/Main";
import TakeOrders from "../../take-orders/Main";
import Drivers from "../../drivers/Main";
import Cashflow from "../../cashflow/Main";
import DawRecords from "../../daw-records/Main";
import Users from "../../users/Main";
import DriverEditor from "../../driver-editor/Main";
import UserEditor from "../../user-editor/Main";
import Orders from "../../orders/Main";
import Partners from "../../partners/Main";
import CashierCloseHistory from "../../cashier-close-history/Main";
import SettlementsHistory from "../../settlements-history/Main";
import CashierCloseDetail from "../../cashier-close-detail/Main";
import Reports from "../../reports/Main";
import GettersCashflow from "../../getters-cashflow/Main";
import Settlements from "../../settlements/Main";
import SettlementDetail from "../../settlement-detail/Main";
import OrderDetail from "../../order-detail/Main";
import FeedSort from "../../feed-sort/Main";
import FeedSortDetail from "../../feed-sort-detail/Main";
import Charts from "../../charts/Main";
import Summations from "../../summations/Main";

const App = ({ isDispatch, isSuperadmin }) => (
  <Switch>
    <Route exact path="/">
      <Monitor />
    </Route>
    {!isDispatch && (
      <Route exact path="/take-orders">
        <TakeOrders />
      </Route>
    )}
    <Route exact path="/orders">
      <Orders />
    </Route>
    {!isDispatch && (
      <Route exact path="/partners">
        <Partners />
      </Route>
    )}
    <Route exact path="/drivers">
      <Drivers />
    </Route>
    {!isDispatch && (
      <Route exact path="/users">
        <Users />
      </Route>
    )}
    {!isDispatch && (
      <Route exact path="/feed-sort">
        <FeedSort />
      </Route>
    )}
    {!isDispatch && (
      <Route exact path="/feed-sort-detail/:id">
        <FeedSortDetail />
      </Route>
    )}
    {(isSuperadmin || isDispatch) && (
      <Route exact path="/cashflow">
        <Cashflow />
      </Route>
    )}
    {isSuperadmin && (
      <Route exact path="/charts">
        <Charts />
      </Route>
    )}
    {(isSuperadmin || isDispatch) && (
      <Route exact path="/getters-cashflow">
        <GettersCashflow />
      </Route>
    )}
     {isSuperadmin && (
      <Route exact path="/summations">
        <Summations />
      </Route>
    )}
    {isSuperadmin && (
      <Route exact path="/settlements">
        <Settlements />
      </Route>
    )}
    {isSuperadmin && (
      <Route exact path="/settlements/history">
        <SettlementsHistory />
      </Route>
    )}
    {isSuperadmin && (
      <Route exact path="/settlements/unpaid/:idPartner">
        <SettlementDetail />
      </Route>
    )}
    {isSuperadmin && (
      <Route exact path="/settlements/history/:idSettlement">
        <SettlementDetail />
      </Route>
    )}
    {isSuperadmin && (
      <Route exact path="/reports">
        <Reports />
      </Route>
    )}
    {isSuperadmin && (
      <Route exact path="/cashflow/history">
        <CashierCloseHistory />
      </Route>
    )}
    {isSuperadmin && (
      <Route exact path="/cashflow/history/:idClose">
        <CashierCloseDetail />
      </Route>
    )}
    {(isSuperadmin || isDispatch) && (
      <Route exact path="/cashflow/daw-records">
        <DawRecords />
      </Route>
    )}
    <Route path="/drivers/:id">
      <DriverEditor />
    </Route>
    {!isDispatch && (
      <Route path="/users/:id">
        <UserEditor />
      </Route>
    )}
    <Route path="/orders/:id">
      <OrderDetail />
    </Route>
  </Switch>
);

export default App;
