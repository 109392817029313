import instance from '../../Api';

export const getInitialCash = async () => {
  const api = await instance();
  let data = await api
    .get('/settlements/daily-cashier-close')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getCashierOrders = async () => {
  const api = await instance();
  let data = await api
    .get('/settlements/cashier-records')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getCashAvailable = async () => {
  const api = await instance();
  let data = await api
    .get('/settlements/cash-available')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const createInitialCash = async info => {
  const api = await instance();
  let data = await api
    .post('/settlements/add-initial-cash', info)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const closeCashier = async info => {
  const api = await instance();
  let data = await api
    .post('/settlements/cashier-close', info)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editCashierRecord = async info => {
  const api = await instance();
  let data = await api
    .post('/settlements/edit-cashier-record', info)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getHistory = async config => {
  const api = await instance();
  let data = await api
    .post('/settlements/cashier-close-history', config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getDetail = async id => {
  const api = await instance();
  let data = await api
    .get(`/settlements/cashier-close/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
