import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Modal} from 'antd';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {selectCountry, selectUser} from '../login/UserSlice';
import {getCashierCloseDetail, selectDetail} from '../cashflow/CashflowSlice';
import exportOrders from '../../utils/exportOrders';
import CashierCloseDetailUI from './components/CashierCloseDetailUI';

const CashierCloseDetail = () => {
  const country = useSelector(selectCountry);
  const user = useSelector(selectUser);
  const [loading, handleLoading] = useState(true);
  const {idClose} = useParams();
  const details = useSelector(selectDetail);
  const [partners, handlePartners] = useState([]);
  const [drivers, handleDrivers] = useState([]);
  const dispatch = useDispatch();

  const getDetails = useCallback(async () => {
    const response = await dispatch(getCashierCloseDetail(idClose));
    if (response.status !== 'success') {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    } else {
      handleDrivers(response.drivers);
      handlePartners(response.partners);
    }
  }, [dispatch, idClose]);

  useEffect(() => {
    const initialFetch = async () => {
      await getDetails();
      handleLoading(false);
    };
    initialFetch();
  }, [
    getDetails,
  ]);

  const exportData = async () => {
    await exportOrders(
      details.orders,
      moment(details.summary.closing_date)
    );
  };

  return (
    <CashierCloseDetailUI
      country={country}
      details={details}
      loading={loading}
      partners={partners}
      drivers={drivers}
      user={user}
      exportData={exportData}
    />
  );
};

export default CashierCloseDetail;
