import {Button} from 'antd';
import '../styles/PartnersUI.scss';

const PartnersUI = ({today, checkSchedules, sending}) => (
  <div className="partners-wrap">
    <div className="main-titles">
      <div>
        <h1 className="title">Restaurantes</h1>
        <h3 className="subtitle">
          {today.format('dddd D [de] MMMM')}
        </h3>
      </div>
      <Button
        className="check-btn"
        size="large"
        loading={sending}
        onClick={checkSchedules}
        type="primary">
        Revisar horarios
      </Button>
    </div>
  </div>
);

export default PartnersUI;
