import instance from '../../Api';

export const getDtSort = async (id) => {
  const api = await instance();
  let data = await api
    .get(`/dtsort/find/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const createDtSort = async (dtSort) => {
  const api = await instance();
  let data = await api
    .post(
      '/dtsort/create',
      dtSort
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const updateDtSort = async (dtSort) => {
  const api = await instance();
  let data = await api
    .post(
      '/dtsort/update',
      dtSort
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteDtSort = async (id) => {
  const api = await instance();
  let data = await api
    .post(
      '/dtsort/delete',
      {
        id: id
      }
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};