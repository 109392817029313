import {Input, Drawer, Button, Spin} from 'antd';
import {Link} from 'react-router-dom';
import {getCurrency} from '../../../utils/format';
import CashSummary from './CashSummary';
import CloseCashier from './CloseCashier';
import TableOrders from '../../../components/TableOrders';
import '../styles/CashflowUI.scss';

const CashflowUI = props => {
  const {
    today,
    isAdmin,
    orders,
    country,
    loading,
    cash,
    exporting,
    exportData,
    cashAvailable,
    initialCash,
    loadingOpen,
    confirmOpen,
    handleInitialCash,
    handleCash,
    partners,
    closeCashflow,
    handleCloseCashFlow,
    closeCashflow2,
    handleCloseCashFlow2,
    closeCashier,
    cashSummary,
    editRecordCashier,
    confirmClose,
    openCloseCashier,
    drivers,
    currentData,
    handleCurrentData,
  } = props;

  const getCurrencyCountry = val => getCurrency(country, val);

  const filterTable = (pagination, filters, sorter, extra) => {
    handleCurrentData(extra.currentDataSource)
  };

  const getValueSummary = attribute => {
    let amount = 0;
    currentData.map(order => {
      amount += order[attribute];
    });
    return getCurrencyCountry(amount);
  };

  const SummaryItem = ({label, value}) => (
    <div className="summary-item">
      <span className="label-summary">
        {label}
      </span>
      <span className="value-summary">
        {getValueSummary(value)}
      </span>
    </div>
  );

  const HeaderDrawer = ({close}) => (
    <div className="header-drawer">
      <span className="title">Cerrar corte</span>
      <button onClick={close} className="close">
        <i className="bi bi-x" />
      </button>
    </div>
  );

  return (
    <div className="cashflow-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Caja</h1>
          <h3 className="subtitle">
            {today.format('dddd D [de] MMMM')}
          </h3>
        </div>
        <div className="number-orders">
          {isAdmin && (
            <div className="current-cash-wrap">
              <span className="label-cash">Efectivo Actual</span>
              <span className="current-cash">{getCurrencyCountry(cashAvailable)}</span>
            </div>
          )}
          <div className="number-data">
            <h1 className="title">{currentData.length}</h1>
            <h3 className="subtitle">Pedidos</h3>
          </div>
        </div>
      </div>
      <div className="actions-wrap">
        <div className="initial-wrap">
          <span className="label-cash">APERTURA DE CAJA</span>
          <div className="initial-amount-cash">
            <Input
              disabled={initialCash.created}
              className="initial-amount"
              placeholder="0.00"
              value={initialCash.value}
              onChange={handleInitialCash}
              size="large"
            />
            <Button
              className="open-cashier"
              size="large"
              type="primary"
              onClick={confirmOpen}
              loading={loadingOpen}
              disabled={initialCash.created || loading}
            >
              Aperturar
            </Button>
          </div>
        </div>
        <div className="actions">
          {(isAdmin && orders.length > 0) && (
            <button
              disabled={loading}
              loading={exporting}
              onClick={exportData}
              className="action green"
            >
              Exportar
            </button>
          )}
          {isAdmin && (
            <Link to="/cashflow/history"  className="action purple">
              Historial
            </Link>
          )}
          <Link to="/cashflow/daw-records"  className="action yellow">
            Abonos & Retiros
          </Link>
          <button
            disabled={loading}
            onClick={openCloseCashier}
            className="action blue"
          >
            Cerrar corte
          </button>
        </div>
      </div>
      {!loading ? (
        <div className="cashflow">
          <div className="summary">
            {isAdmin && (
              <SummaryItem
                label="Delivery"
                value="delivery_fee"
              />
            )}
            <SummaryItem
              label="Pago Driver"
              value="driver_payment"
            />
            {isAdmin && (
              <>
                <SummaryItem
                  label="Ingresos"
                  value="order_total"
                />
                <SummaryItem
                  label="Fee Get"
                  value="get_fee"
                />
                <SummaryItem
                  label="Comercio"
                  value="repay"
                />
                <SummaryItem
                  label="Utilidad"
                  value="profits"
                />
              </>
            )}
          </div>
          <TableOrders
            orders={orders}
            partners={partners}
            editRecordCashier={editRecordCashier}
            drivers={drivers}
            filterTable={filterTable}
          />
        </div>
      ) : (
        <div className="loader-wrap">
          <Spin size="large" />
        </div>
      )}
      <Drawer
        title={<HeaderDrawer close={() => handleCloseCashFlow(false)} />}
        placement="right"
        closable={false}
        onClose={() => handleCloseCashFlow(false)}
        width={400}
        visible={closeCashflow}
      >
        <CashSummary
          country={country}
          cash={cash}
          handleCash={handleCash}
          closeCashier={closeCashier}
        />
        <Drawer
          title={<HeaderDrawer close={() => handleCloseCashFlow2(false)} />}
          placement="right"
          closable={false}
          onClose={() => handleCloseCashFlow2(false)}
          width={400}
          visible={closeCashflow2}
        >
          <CloseCashier
            cashSummary={cashSummary}
            country={country}
            closeCashier={confirmClose}
            isAdmin={isAdmin}
            today={today}
          />
        </Drawer>
      </Drawer>
    </div>
  );
};

export default CashflowUI;