import {useState, useEffect, useCallback, useRef} from 'react';
import {useDispatch} from 'react-redux';
import { GoogleMap, LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import {Row, Col, Form, Input, Button, Modal} from 'antd';
import cx from 'classnames';
import {verifyLocation, newAddress, changeAddress} from '../TakeOrdersSlice';
import {ReactComponent as Marker} from '../assets/userMarker.svg';
import {ReactComponent as Home} from '../../../assets/address/home.svg';
import {ReactComponent as Favorite} from '../../../assets/address/favorite.svg';
import {ReactComponent as Bag} from '../../../assets/address/bag.svg';
import {ReactComponent as Gps} from '../../../assets/address/near-me.svg';
import '../styles/AddressEditor.scss';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
};

const libraries = ["places"];

const AddressEditor = ({addressToEdit, country, user, handleAddress}) => {
  const [position, handlePosition] = useState({lat: 0, lng: 0});
  const [sending, handleSending] = useState(false);
  const [icon, handleIcon] = useState('home');
  const mapRef = useRef(null);
  const searchBox = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const newPosition = useCallback((latitude, longitude) => {
    handlePosition(getCoordinates(latitude, longitude))
  }, []);

  useEffect(() => {
    if (!addressToEdit.id) {
      if (country === 'sv') {
        newPosition(13.6921717, -89.2366611);
      } else {
        newPosition(12.121386686360069, -86.25987586290005);
      }
    } else {
      form.setFieldsValue(addressToEdit);
      handleIcon(addressToEdit.icon);
      newPosition(addressToEdit.latitude, addressToEdit.longitude)
    }
  }, []);

  const getCoordinates = (latitude, longitude) => {
    return {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };
  };

  const handleLoad = (map) =>  {
    mapRef.current = map;
  }

  const handleCenter = () => {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    handlePosition(newPos);
  }

  const onLoad = (ref) => {
    searchBox.current = ref
  };

  const onPlacesChanged = () => {
    const places = searchBox.current.getPlaces();
    const placeSelected = places[0].geometry.location;
    newPosition(placeSelected.lat(), placeSelected.lng());
  };

  const save = async () => {
    handleSending(true);
    try {
      const values = await form.validateFields();
      const dataAddress = {
        address: values.address,
        name: values.name,
        referencePoint: values.reference_point,
        icon,
        number: values.number,
        latitude: position.lat,
        longitude: position.lng,
        userId: user.id,
      };
      const response_location = await dispatch(verifyLocation(dataAddress.latitude, dataAddress.longitude));
      if (response_location.status === 'success') {
        if (!response_location.data.inside) {
          Modal.error({
            title: 'Fuera de la zona de cobertura',
            content: 'Te encuentras fuera de la zona de cobertura',
          });
        } else {
          dataAddress.fee = response_location.data.fee;
          dataAddress.idCoverage = response_location.data.id_coverage;
          if (addressToEdit.id) {
            dataAddress.id = addressToEdit.id;
            const index = user.addresses.findIndex((el) => el.id === dataAddress.id);
            const response = await dispatch(changeAddress(dataAddress, index));
            if (response.status === 'success') {
              handleAddress(response.address);
              Modal.success({
                title: 'Dirección editada',
                content: 'Se ha editado la dirección',
              });
            } else {
              Modal.error({
                title: 'Hubo un error',
                content: 'Inténtalo de nuevo más tarde',
              });
            }
          } else {
            const response = await dispatch(newAddress(dataAddress));
            if (response.status === 'success') {
              handleAddress(response.address);
              Modal.success({
                title: 'Dirección creada',
                content: 'Se ha creado la dirección',
              });
            } else {
              Modal.error({
                title: 'Hubo un error',
                content: 'Inténtalo de nuevo más tarde',
              });
            }
          }
        }
      } else {
        Modal.error({
          title: 'Hubo un error',
          content: 'Inténtalo de nuevo más tarde',
        });
      }
    } catch (errorInfo) {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleSending(false);
  };

  return (
    <div className="address-editor-take-order">
      <Row gutter={20}>
        <Col lg={14}>
          <div className="map-wrap">
            <LoadScript
              googleMapsApiKey="AIzaSyCYkhtnCIFD6yWm3v1V78OKIwmUaPQqNvk"
              libraries={libraries}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={position}
                onLoad={handleLoad}
                onDragEnd={handleCenter}
                onZoomChanged={handleCenter}
                zoom={18}
                options={{
                  disableDefaultUI: true,
                }}
              >
                <StandaloneSearchBox
                  onLoad={onLoad}
                  onPlacesChanged={
                    onPlacesChanged
                  }
                >
                  <input
                    type="text"
                    placeholder="Buscar..."
                    style={{
                      boxSizing: 'border-box',
                      border: '1px solid transparent',
                      width: '250px',
                      height: '40px',
                      padding: '0 12px',
                      borderRadius: '5px',
                      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                      fontSize: '14px',
                      outline: 'none',
                      textOverflow: 'ellipses',
                      position: 'absolute',
                      left: '15px',
                      top: '15px',
                    }}
                  />
                </StandaloneSearchBox>
              </GoogleMap>
            </LoadScript>
            <Marker className="marker" width="50px" height="81.51px" />
          </div>
        </Col>
        <Col lg={10}>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={save}
            onFinishFailed={() => {}}
          >
            <Form.Item
              label={<span className="label">Nombre</span>}
              name="name"
              colon={false}
              rules={[{ required: true, message: 'Ingresa un nombre' }]}
            >
              <Input size="large" className="input" />
            </Form.Item>
            <Form.Item
              label={<span className="label">Dirección</span>}
              name="address"
              colon={false}
              rules={[{ required: true, message: 'Ingresa una dirección' }]}
            >
              <Input.TextArea rows={2} size="large" className="input" />
            </Form.Item>
            <Form.Item
              label={<span className="label">Número de casa/apartamento</span>}
              name="number"
              colon={false}
              rules={[{ required: true, message: 'Ingresa un número de casa/apartamento' }]}
            >
              <Input
                size="large"
                className="input"
              />
            </Form.Item>
            <Form.Item
              label={<span className="label">Punto de referencia</span>}
              name="reference_point"
              colon={false}
              rules={[{ required: true, message: 'Ingresa un punto de referencia' }]}
            >
              <Input size="large" className="input" />
            </Form.Item>
            <div className="icons">
              <div onClick={() => handleIcon('home')} className="btn-icon">
                <Home
                  width="20px"
                  height="20px"
                  className={cx({active: icon === 'home'})}
                />
              </div>
              <div onClick={() => handleIcon('favorite')} className="btn-icon">
                <Favorite
                  width="20px"
                  height="20px"
                  className={cx({active: icon === 'favorite'})}
                />
              </div>
              <div onClick={() => handleIcon('bag')} className="btn-icon">
                <Bag
                  width="20px"
                  height="20px"
                  className={cx({active: icon === 'bag'})}
                />
              </div>
              <div onClick={() => handleIcon('near-me')} className="btn-icon">
                <Gps
                  width="20px"
                  height="20px"
                  className={cx({active: icon === 'near-me'})}
                />
              </div>
            </div>
            <div className="action">
              <Button htmlType="submit" block size="large" loading={sending}>
                Guardar
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AddressEditor;