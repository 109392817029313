import instance from '../../Api';
/* 
export const getDrivers = async () => {
  const api = await instance();
  let data = await api
    .get('/drivers')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
}; */

export const getUser = async (user) => {
  const api = await instance();
  let data = await api
    .get(`/users/profile/${user}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const search = async (value) => {
  const api = await instance();
  let data = await api
    .post('/users', {search: value})
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newUser = async (user) => {
  const api = await instance();
  let data = await api
    .post(
      '/users/create',
      user
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editUser = async (user) => {
  const api = await instance();
  let data = await api
    .post(
      '/users/update',
      user
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

/* export const activeDriver = async (driver, value) => {
  const api = await instance();
  let data = await api
    .post(
      '/drivers/activate',
      {
        driverId: driver,
        active: value,
      },
    )
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
}; */
