import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, message } from "antd";
import produce from "immer";
import valid from "card-validator";
import {
  setProductOrder,
  selectPartnerOrder,
  setPartnerOrder,
  selectProductsOrder,
  setNewProductOrder,
  deleteProduct,
  setProductEdited,
  selectUserOrder,
  selectAddressOrder,
  removeAddress,
  setAddressOrder,
  setDescriptionOrder,
  setMethodOrder,
  setCashbackOrder,
  selectDescriptionOrder,
  selectMethodOrder,
  selectCashbackOrder,
  newOrder,
  getExchangeOrder,
  selectExchange,
  setExchange,
  getPreviousOrders,
  selectPreviousOrders,
  setOrderToReplace,
  selectOrderToReplace,
} from "./TakeOrdersSlice";
import { selectCountry, selectUser } from "../login/UserSlice";
import { getAmount } from "./utils/Format";
import TakeOrdersUI from "./components/TakeOrdersUI";

const TakeOrders = () => {
  const [menuVisible, handleMenuVisible] = useState(false);
  const [productVisible, handleProductVisible] = useState(false);
  const [customProductVisible, handleCustomProductVisible] = useState(false);
  const [addressListVisible, handleAddressListVisible] = useState(false);
  const [addressEditorVisible, handleAddressEditorVisible] = useState(false);
  const [partner, handlePartner] = useState(null);
  const [product, handleProduct] = useState(null);
  const [customProduct, handleCustomProduct] = useState(null);
  const [address, handleAddress] = useState({});
  const [edit, handleEdit] = useState(false);
  const [customEdit, handleCustomEdit] = useState(false);
  const [sending, handleSending] = useState(false);
  const [extraInfo, handleExtraInfo] = useState("");
  const [customProducts, handleCustomProducts] = useState([]);
  const [loadingReplace, setLoadingReplace] = useState(false);
  const partnerOrder = useSelector(selectPartnerOrder);
  const productsOrder = useSelector(selectProductsOrder);
  const userOrder = useSelector(selectUserOrder);
  const addressOrder = useSelector(selectAddressOrder);
  const descriptionOrder = useSelector(selectDescriptionOrder);
  const methodOrder = useSelector(selectMethodOrder);
  const cashbackOrder = useSelector(selectCashbackOrder);
  const country = useSelector(selectCountry);
  const user = useSelector(selectUser);
  const previousOrders = useSelector(selectPreviousOrders);
  const orderToReplace = useSelector(selectOrderToReplace);
  const exchange = useSelector(selectExchange);
  const [exchangeToUse, handleExchangeToUse] = useState(1);
  const [users, handleUsers] = useState([]);
  // const roleTuNicaragua = user.role === 3 && country === 'ni';
  const roleTuNicaragua = country === "ni";
  const [tuNicaragua, handleTuNicaragua] = useState(roleTuNicaragua);
  const [cardInfo, handleCardInfo] = useState({
    number: "",
    exp_month: "",
    exp_year: "",
    type: "",
    dui: "",
    name: "",
  });
  // --------------
  // const [exchange, handleExchange] = useState(1);
  // const [sending, handleSending] = useState(false);
  // const [sendingCuppon, handleSendingCuppon] = useState(false);
  // const [cupponModal, handleCupponModal] = useState(false);
  // const [cashbackModal, handleCashbackModal] = useState(false);
  // const [addressModal, handleAddressModal] = useState(false);
  // const [cuppon, handleCuppon] = useState('');
  // const [currencyCashback, handleCurrencyCashback] = useState('dolar');
  // const [offer, handleOffer] = useState(false);
  // const [finalCashback, handleFinalCashback] = useState(0);
  // --------------
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const response = await dispatch(getExchangeOrder());
      if (response.status !== "success") {
        Modal.error({
          title: "Hubo un problema",
          content: "Inténtalo de nuevo más tarde",
        });
      }
    };

    if (country === "ni") {
      initialFetch();
    } else {
      dispatch(setExchange(1));
    }
  }, [country, dispatch]);

  useEffect(() => {
    if (
      partnerOrder.default_currency === 1 ||
      partnerOrder.default_currency === "1"
    ) {
      handleExchangeToUse(exchange);
    }
  }, [partnerOrder]);

  const setCard = (newCard) => {
    handleCardInfo(newCard);
  };

  const openMenu = (newPartner) => {
    handlePartner(newPartner);
    handleMenuVisible(true);
  };

  const openProduct = (newProduct) => {
    handleProduct(newProduct);
    handleEdit(false);
    handleProductVisible(true);
  };

  const openCustomProduct = (newProduct) => {
    handleCustomProduct(newProduct);
    handleCustomEdit(false);
    handleCustomProductVisible(true);
  };

  const newAddress = () => {
    handleAddress({});
    handleAddressEditorVisible(true);
  };

  const editAddress = (addressToEdit) => {
    handleAddress(addressToEdit);
    handleAddressEditorVisible(true);
  };

  const selectAddress = (address) => {
    dispatch(setAddressOrder(address));
    handleAddressListVisible(false);
  };

  const handleDescription = (description) => {
    dispatch(setDescriptionOrder(description));
  };

  const handleMethod = (method) => {
    dispatch(setMethodOrder(method));
  };

  const handleCashback = (cashback) => {
    dispatch(setCashbackOrder(cashback));
  };

  const deleteAddress = async (address, index) => {
    const response = await dispatch(removeAddress(address, index));
    if (response.status !== "success") {
      Modal.error({
        title: "Hubo un problema",
        content: "No se ha podido eliminar esta dirección",
      });
    }
  };

  const getSubtotal = () => {
    let price = 0;
    productsOrder.map((product) => {
      price += parseFloat(product.total);
    });
    return price * exchangeToUse;
  };

  const getDelivery = () => {
    /* const freeDelivery = userOrder.id && userOrder.loyalty_orders === 5 && !roleTuNicaragua;
    if (freeDelivery) {
      return 0;
    } */
    if (orderToReplace >= 0) {
      return previousOrders[orderToReplace].delivery_fee;
    }
    return addressOrder.id ? addressOrder.fee * exchange : 0;
  };

  const getBeforeTaxes = () => {
    return getSubtotal() + getDelivery();
  };

  const getIVA = () => {
    return getBeforeTaxes() * 0.15;
  };

  const getTotal = () => {
    if (country === "ni") {
      return getBeforeTaxes() + getIVA();
    }
    return getBeforeTaxes();
  };

  const getTotalUSD = () => {
    return getTotal() / exchange;
  };

  const getCreditToUse = () => {
    const credit = userOrder.credit;
    if (credit) {
      const total = getTotal();
      if (credit >= total) {
        return total;
      }
      return credit;
    }
    return 0;
  };

  const getFinalAmount = () => {
    return getTotal() - getCreditToUse();
  };

  const deleteProductOrder = (index) => {
    if (productsOrder.length === 1) {
      dispatch(setPartnerOrder({}));
    }
    dispatch(deleteProduct(index));
  };

  //* Coloca el producto a editar en la vista
  const editProduct = async (product, index) => {
    handleProduct(product);
    handlePartner(partnerOrder);
    handleCustomProducts(product.customProducts);
    //* Se coloca mas uno por si el index es 0 no se ecalue como false en la otra vista
    handleEdit(index + 1);
    handleMenuVisible(true);
    handleProductVisible(true);
  };

  const selectProduct = (newProduct, newPartner = false) => {
    if (newPartner) {
      dispatch(setPartnerOrder(newPartner));
      dispatch(setNewProductOrder(newProduct));
    } else {
      dispatch(setProductOrder(newProduct));
    }
    handleProductVisible(false);
    message.success("Se ha agregado este producto a tu orden");
  };

  const addCustomProduct = (newCustomProduct) => {
    const newCustomProducts = produce(customProducts, (draftState) => {
      draftState.push(newCustomProduct);
    });
    handleCustomProducts(newCustomProducts);
    handleCustomProductVisible(false);
  };

  const editCustomProduct = (newCustomProduct) => {
    handleCustomProduct(newCustomProduct);
    handleCustomEdit(true);
    handleCustomProductVisible(true);
  };

  const customProductEdited = (newCustomProduct) => {
    const index = customProducts.findIndex(
      (el) => el.id === newCustomProduct.id
    );
    const newCustomProducts = produce(customProducts, (draftState) => {
      draftState[index] = newCustomProduct;
    });
    handleCustomProducts(newCustomProducts);
    handleCustomProductVisible(false);
  };

  //* Remplaza la información del producto editado
  const editProductOrder = (newProduct) => {
    dispatch(setProductEdited({ product: newProduct, index: edit - 1 }));
    handleProductVisible(false);
    message.success("Se ha editado este producto en tu orden");
  };

  const getProducts = () => {
    const realCustomProducts = [];
    const realProducts = productsOrder.map((newProduct) => {
      newProduct.customProducts.map((custom) => {
        const newCustom = produce(custom, (draftState) => {
          draftState.id = custom.id_product;
        });
        realCustomProducts.push(newCustom);
      });
      return newProduct;
    });
    Array.prototype.push.apply(realProducts, realCustomProducts);
    return realProducts;
  };

  const setOrder = async () => {
    const realTotal = getTotal();
    const totalUsd = getTotalUSD();
    const credit = getCreditToUse();
    const methodsInfo = [
      {
        method: methodOrder,
        cash: cashbackOrder,
        ver: 1,
      },
    ];

    if (methodOrder === "card") {
      methodsInfo[0].card = cardInfo.number.replace(/\s/g, "");
      // methodsInfo[0].card = '9999995286545848';
      methodsInfo[0].amount = getAmount(
        (country === "SV" ? realTotal : totalUsd) - credit * exchange
      );
      // methodInfo.amount = '000000000001';
      methodsInfo[0].expiration_date = `${cardInfo.exp_year}${cardInfo.exp_month}`;
      // methodsInfo[0].expiration_date = '2012';
      methodsInfo[0].document_number = cardInfo.dui;
      methodsInfo[0].card_holder = cardInfo.name;
      methodsInfo[0].type_document = country === "ni" ? "cedula" : "dui";
      methodsInfo[0].type = cardInfo.type;
      methodsInfo[0].cvv = "123";
    }

    if (credit) {
      methodsInfo.unshift({
        method: "credit",
        amount: credit,
      });
    }

    const dataOrder = {
      userId: userOrder.id,
      description: descriptionOrder,
      address_name: addressOrder.name,
      address: `${addressOrder.address} ${addressOrder.number || ""}`,
      reference_point: addressOrder.reference_point,
      latitude: addressOrder.latitude,
      longitude: addressOrder.longitude,
      delivery_fee: getDelivery(),
      // tn: tuNicaragua ? 1 : 0,
      tn: tuNicaragua && user.role === 3 ? 1 : 0,
      /* bill: {
        name: 'Parks And Rec Inc',
        address: 'Billing to TRIBECA New York',
        document: '05157866-6',
      }, */
      payment_method: methodsInfo,
      partners: [
        {
          partnerId: partnerOrder.id,
          products: getProducts(),
        },
      ],
      platform: "callcenter",
      agent_id: user.id,
      extra_description: extraInfo,
    };
    if (orderToReplace > -1) {
      dataOrder.id_replaced_order = previousOrders[orderToReplace].id;
    }
    if (country === "ni") {
      dataOrder.currency = partnerOrder.default_currency;
      dataOrder.conversion_rate = exchange;
      dataOrder.total_usd = totalUsd;
    }
    const response = await dispatch(newOrder(dataOrder));
    if (response.status === "success") {
      handleCardInfo({
        number: "",
        exp_month: "",
        exp_year: "",
        type: "",
        dui: "",
        name: "",
      });
      Modal.success({
        title: "Orden Creada",
        content: "La orden se ha creado exitosamente",
      });
      handleUsers([]);
    } else if (response.type === "card_issue") {
      Modal.error({
        title: "Problema con la tarjeta",
        content: response.message,
      });
    } else {
      Modal.error({
        title: "Hubo un problema",
        content: "No se ha podido crear la orden",
      });
    }
  };

  const confirmOrder = () => {
    Modal.confirm({
      title: "¿Deseas iniciar la orden?",
      content: `La orden se creará y será visible en el monitor`,
      okText: "Crear Orden",
      cancelText: "Cancelar",
      async onOk() {
        await setOrder();
      },
      onCancel() {},
    });
  };

  const createOrder = async () => {
    handleSending(true);
    if (!userOrder.id) {
      Modal.error({
        title: "Usuario no seleccionado",
        content: "Debes seleccionar un usuario para continuar",
      });
    } else if (!addressOrder.id) {
      Modal.error({
        title: "Dirección no seleccionada",
        content: "Debes seleccionar una dirección para continuar",
      });
    } else if (!productsOrder.length) {
      Modal.error({
        title: "No existen productos",
        content: "Selecciona productos para continuar",
      });
    } else if (!methodOrder.length) {
      Modal.error({
        title: "Método de pago",
        content: "Selecciona un método de pago para continuar",
      });
    } else if (
      methodOrder === "cash" &&
      parseFloat(cashbackOrder) < getFinalAmount()
    ) {
      Modal.error({
        title: "Cambio insuficiente",
        content: "El cambio debe ser mayor o igual al total",
      });
    } else {
      if (methodOrder === "card" && !tuNicaragua) {
        if (
          cardInfo.number !== "" &&
          cardInfo.exp_month !== "" &&
          cardInfo.exp_year !== "" &&
          cardInfo.name !== "" &&
          cardInfo.dui !== ""
        ) {
          const number = valid.number(cardInfo.number);
          if (number.isValid) {
            const date = valid.expirationDate(
              `${cardInfo.exp_month}/${cardInfo.exp_year}`
            );
            if (date.isValid) {
              if (number.card.type !== "american-express") {
                confirmOrder();
              } else {
                Modal.error({
                  title: "Tarjeta Inválida",
                  content: "Todavía no aceptamos American Express",
                });
              }
            } else {
              Modal.error({
                title: "Tarjeta Inválida",
                content: "Ingresa una fecha válida",
              });
            }
          } else {
            Modal.error({
              title: "Tarjeta Inválida",
              content: "Ingresa un número de tarjeta válido",
            });
          }
        } else {
          Modal.error({
            title: "Tarjeta Inválida",
            content: "Completa todos los campos para pago con tarjeta",
          });
        }
      } else {
        confirmOrder();
      }
    }
    handleSending(false);
  };

  const getLastOrders = async (user) => {
    setLoadingReplace(true);
    const response = await dispatch(getPreviousOrders(user));
    if (response.status !== "success") {
      message.error("Error al cargar las ordenes anteriores");
    } else {
      handleOrderToReplace(-1);
    }
    setLoadingReplace(false);
  };

  const handleOrderToReplace = (orderIndex) => {
    dispatch(setOrderToReplace(orderIndex));
  };

  return (
    <TakeOrdersUI
      menuVisible={menuVisible}
      productVisible={productVisible}
      customProductVisible={customProductVisible}
      handleMenuVisible={handleMenuVisible}
      handleProductVisible={handleProductVisible}
      handleCustomProductVisible={handleCustomProductVisible}
      openMenu={openMenu}
      openProduct={openProduct}
      partner={partner}
      product={product}
      partnerOrder={partnerOrder}
      selectProduct={selectProduct}
      productsOrder={productsOrder}
      getSubtotal={getSubtotal}
      country={country}
      deleteProductOrder={deleteProductOrder}
      editProduct={editProduct}
      edit={edit}
      editProductOrder={editProductOrder}
      userOrder={userOrder}
      addressListVisible={addressListVisible}
      handleAddressListVisible={handleAddressListVisible}
      addressOrder={addressOrder}
      addressEditorVisible={addressEditorVisible}
      handleAddressEditorVisible={handleAddressEditorVisible}
      newAddress={newAddress}
      editAddress={editAddress}
      address={address}
      handleAddress={handleAddress}
      deleteAddress={deleteAddress}
      selectAddress={selectAddress}
      handleCashback={handleCashback}
      handleMethod={handleMethod}
      handleDescription={handleDescription}
      descriptionOrder={descriptionOrder}
      methodOrder={methodOrder}
      cashbackOrder={cashbackOrder}
      getDelivery={getDelivery}
      getIVA={getIVA}
      getTotal={getTotal}
      createOrder={createOrder}
      sending={sending}
      getTotalUSD={getTotalUSD}
      tuNicaragua={tuNicaragua}
      handleTuNicaragua={handleTuNicaragua}
      roleTuNicaragua={roleTuNicaragua}
      customProduct={customProduct}
      openCustomProduct={openCustomProduct}
      customEdit={customEdit}
      customProducts={customProducts}
      handleCustomProducts={handleCustomProducts}
      addCustomProduct={addCustomProduct}
      customProductEdited={customProductEdited}
      editCustomProduct={editCustomProduct}
      extraInfo={extraInfo}
      handleExtraInfo={handleExtraInfo}
      cardInfo={cardInfo}
      setCard={setCard}
      loadingReplace={loadingReplace}
      orderToReplace={orderToReplace}
      handleOrderToReplace={handleOrderToReplace}
      previousOrders={previousOrders}
      getLastOrders={getLastOrders}
      getFinalAmount={getFinalAmount}
      getCreditToUse={getCreditToUse}
      users={users}
      handleUsers={handleUsers}
    />
  );
};

export default TakeOrders;
