import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import {getCurrency} from '../../../utils/format';

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'co',
    padding: 10,
  },
  subtitle: {
    marginBottom: 5,
  },
  text: {
    fontSize: 8,
  },
  header: {
    marginBottom: 10,
  },
  infoPartner: {
    marginBottom: 10,
  },
  center: {
    textAlign: 'center',
  },
  infoDate: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  productWrap: {
    marginBottom: 5,
  },
  extra: {
    marginBottom: 3,
    flexDirection: 'row',
  },
  products: {
    marginBottom: 10,
  },
  name: {
    flex: 1,
  },
  price: {
    marginLeft: 5,
  },
  product: {
    flexDirection: 'row',
    marginBottom: 3,
  },
  total: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  summaryWrap: {
    alignItems: 'flex-end',
    marginBottom: 15,
  },
  summary: {
    width: 100,
  },
  disclaimerText: {
    fontSize: 7,
  },
});

// Create Document Component
const MyDocument = ({order, country, user}) => {
  const {
    partner,
    ticket,
    id_user,
    created_time,
    products,
    subtotal,
    get_fee,
    delivery_fee,
    total,
  } = order;

  const TextTicket = ({children, style}) => (
    <Text style={[styles.text, style]}>{children}</Text>
  );

  const Product = ({product}) => (
    <View style={styles.productWrap}>
      <View style={styles.product}>
        <TextTicket style={styles.name}>
          {`(${product.quantity}) ${product.name}`}
        </TextTicket>
        <TextTicket style={styles.price}>
          {getCurrency(country, product.unit_price, partner.default_currency)}
        </TextTicket>
      </View>
      <View style={styles.extras}>
        {product.extras.map(extra => (
          <View key={extra.id} style={styles.extra}>
            <TextTicket style={styles.name}>
              {`- ${extra.name}${extra.quantity ? ` (${extra.quantity})` : ''}`}
            </TextTicket>
            {extra.price > 0 && (
              <TextTicket style={styles.price}>
                {`+ ${getCurrency(country, extra.price, partner.default_currency)}`}
              </TextTicket>
            )}
          </View>
        ))}
      </View>
    </View>
  );

  return (
    <Document>
      <Page size={180} style={styles.page}>
        <View style={styles.header}>
          <TextTicket style={[styles.subtitle, styles.center]}>
            GET TECHNOLOGIES S.A. DE C.V.
          </TextTicket>
          <TextTicket style={styles.center}>
            NRC: 305762-4 NIT: 0614-290721-105-4
          </TextTicket>
          <TextTicket style={styles.center}>
            Giro: Otras actividades de servicio no clasificados previamente.
          </TextTicket>
          <TextTicket style={styles.center}>
            CARR. AL PUERTO DE LA LIBERTAD , URB. NC. OFICINA 4-A ZONA FRANCA SANTA TECLA, NUEVO CUSCATLAN, LA LIBERTAD
          </TextTicket>
        </View>
        <View style={styles.infoPartner}>
          <TextTicket>{`Restaurante: ${partner.name}`}</TextTicket> 
          <TextTicket>{`Ticket: ${ticket}`}</TextTicket> 
          <TextTicket>{`Cliente: ${id_user.name} ${id_user.lastname}`}</TextTicket> 
        </View>
        <View style={styles.infoDate}>
          <TextTicket>{moment(created_time).format('DD/MM/YYYY')}</TextTicket>
          <TextTicket>{moment(created_time).format('HH:mm')}</TextTicket>
        </View>
        <View style={styles.products}>
          {products.map(product => <Product key={product.id} product={product} />)}
        </View>
        <View style={styles.summaryWrap}>
          <View style={styles.summary}>
            <View style={styles.total}>
              <TextTicket>Subtotal:</TextTicket>
              <TextTicket>{getCurrency(country, subtotal)}</TextTicket>
            </View>
            {!user && (
              <View style={styles.total}>
                <TextTicket>Descuento:</TextTicket>
                <TextTicket>- {getCurrency(country, get_fee)}</TextTicket>
              </View>
            )}
            {user && (
              <View style={styles.total}>
                <TextTicket>Delivery:</TextTicket>
                <TextTicket>{getCurrency(country, delivery_fee)}</TextTicket>
              </View>
            )}
            <View style={styles.total}>
              <TextTicket>Total:</TextTicket>
              <TextTicket>{getCurrency(country, !user ? (subtotal - get_fee) : total)}</TextTicket>
            </View>
          </View>
        </View>
        {!user && (
          <View style={styles.disclaimer}>
            <TextTicket style={[styles.center, styles.disclaimerText]}>
              Nota: Este comprobante "Saldo de mesa" no sustituye el ticket fiscal. Exija ticket, factura o crédito fiscal.
            </TextTicket>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default MyDocument;
