import {Spin, Input, Button} from 'antd';
import cx from 'classnames';
import {getCurrency} from '../../../utils/format';
import '../styles/Product.scss';

const ProductUI = props => {
  const {
    custom,
    selectOption,
    handleQuantityExtra,
    calculating,
    country,
    partner,
    product,
    productDetail,
    close,
    loading,
    comments,
    handleComments,
    quantity,
    quantityUpdate,
    addToCart,
    verifyRequired,
    edit,
    total,
    customProducts,
    goToCustom,
  } = props;

  const getLabel = () => {
    if (custom) {
      return 'Seleccionar';
    } else if (edit) {
      return 'Editar';
    }
    return 'Añadir al carrito';
  };

  const Option = ({extra, multiple, indexCategory, indexOption, hasQuantity}) => {
    const selected = extra.selected;

    const controlExtraQuantity = (e, add) =>{
      e.stopPropagation();
      handleQuantityExtra(extra.quantity, add, extra.price, indexCategory, indexOption);
    };

    return (
      <div
        className="option"
        onClick={() => selectOption(indexCategory, indexOption, hasQuantity)}>
        {selected && hasQuantity ? (
          <div className="quantity-extra-wrap">
            <button onClick={(e) => controlExtraQuantity(e, false)} disabled={calculating}>
              <i className="bi bi-dash" />
            </button>
            <span>{extra.quantity}</span>
            <button onClick={(e) => controlExtraQuantity(e, true)} disabled={calculating}>
              <i className="bi bi-plus" />
            </button>
          </div>
        ) : (
          <div className={cx('radio', {active: selected})}>
            {selected && (
              <div className={cx('inner-radio', {multiple})}>
                {multiple && (<i className="bi bi-check2" />)}
              </div>
            )}
          </div>
        )}
        <span className="name">{extra.name}</span>
        {extra.price > 0 && (
          <div className="price-wrap">
            <span className="price">
              {`+ ${getCurrency(country, extra.price, partner.default_currency)}${hasQuantity ? ' c/u' : ''}`}
            </span>
          </div>
        )}
      </div>
    );
  };

  const CustomOption = ({extra}) => {
    const indexCustom = customProducts.findIndex(
      el => el.id === extra.id,
    );

    return (
      <div className="custom-category" onClick={() => goToCustom(extra, indexCustom)}>
        <div className="custom-photo" style={{backgroundImage: `url(${product.photo})`}} />
        <div className="custom-info">
          <span className="name">{extra.name}</span>
          <span className="subtitle">{indexCustom >= 0 ? 'Editar' : 'Selecciona tus opciones'}</span>
        </div>
        <i className="bi bi-chevron-right arrow" />
      </div>
    );
  };
  
  const Category = ({category, indexCategory}) => {
    const hasQuantity = category.max !== undefined && category.min !== undefined  && !(category.max === 1 && category.min === 1);
    return (
      <div className="category">
        <div className="category-header">
          <span>{category.name}</span>
          <div className="notes-wrap">
            {category.required === 'Si' && (
              <span className="note">Requerido</span>
            )}
            {hasQuantity && (
              <span className="note">
                {`${category.required === 'Si' ? ' -' : ''} ${category.max === category.min ? `Seleccionar ${category.max}` : `${category.max > 0 ? `Max. ${category.max}` : ''} ${category.min > 0 ? `Min. ${category.min}` : ''}`}`}
              </span>
            )}
          </div>
        </div>
        <div className="options">
          {category.extras.map((extra, indexOption) => (
            !category.custom ? (
              <Option
                key={extra.id}
                extra={extra}
                multiple={category.multiple === 'Si'}
                indexCategory={indexCategory}
                indexOption={indexOption}
                hasQuantity={hasQuantity}
              />
            ) : (
              <CustomOption
                key={extra.id}
                extra={extra}
              />
            )
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="product-wrap-take-order">
      <div
        className="cover"
        style={{backgroundImage: `url(${product.photo})`}}
      >
        <button onClick={close}>
          <i className="bi bi-arrow-left-short" />
        </button>
      </div>
      {!loading ? (
        <>
          <div className="info-partner">
            <div className="texts">
              <span className="name">{product.name}</span>
              {!custom && (
                <span className="category">
                  {getCurrency(country, product.price, partner.default_currency)}
                </span>
              )}
            </div>
            <span className="description">{product.description}</span>
          </div>
          <div className="categories">
            {productDetail.categories.map((category, indexCategory) => (
              category.extras.length > 0 && (
                <Category
                  key={category.id}
                  category={category}
                  indexCategory={indexCategory}
                />
              )
            ))}
            {!custom && (
              <div className="category">
                <div className="category-header">
                  <span>Instrucciones Especiales</span>
                  <div className="notes-wrap">
                    <span className="note">Opcional</span>
                  </div>
                </div>
                <div className="instructions">
                  <Input.TextArea
                    className="input"
                    rows={3}
                    value={comments}
                    onChange={(e) => handleComments(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
          {!custom && (
            <div className="quantity-wrap">
              <div className="quantity-controller">
                <div
                  className="operator"
                  onClick={() => quantityUpdate(false)}
                >
                  <i className="bi bi-dash" />
                </div>
                <span>{quantity}</span>
                <div
                  className="operator"
                  onClick={() => quantityUpdate(true)}
                >
                  <i className="bi bi-plus" />
                </div>
              </div>
          </div>
          )}
          <div className="action">
            <Button
              onClick={addToCart}
              block
              className={cx({disabled: !verifyRequired()})}>
              <span>{getLabel()}</span>
              {!custom && (
                <span className="total">
                  {getCurrency(country, total, partner.default_currency)}
                </span>
              )}
            </Button>
          </div>
        </>
      ) : (
        <div className="loader-wrap">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default ProductUI;
