import { createSlice } from '@reduxjs/toolkit';
import {
  getDrivers,
  getDriver,
  newDriver,
  editDriver,
  activeDriver,
  enableDriver,
  disableDriver,
  assignBranch,
  getPartnersAvailable,
} from './Api'

// Reducer

export const driversSlice = createSlice({
  name: 'drivers',
  initialState: {
    drivers: [],
  },
  reducers: {
    setDrivers: (state, action) => {
      state.drivers = action.payload;
    },
  },
});


//Actions

export const {
  setDrivers,
} = driversSlice.actions;

export const getDriversList = isAdmin => async dispatch => {
  try {
    const response = await getDrivers();
    if (!response.error && response.status === 200) {
      const {drivers} = response.data;
      const newDrivers = drivers.filter(driver => !isAdmin ? !driver.branch : true);
      dispatch(setDrivers(newDrivers));
      const partners = [{value: 0, text: 'Get'}];
      if (isAdmin) {
        newDrivers.map(driver => {
          if (driver.branch) {
            const {name, id} = driver.branch.id_partner;
            partners.push({
              value: id,
              text: name,
            });
          }
        });
      }
      return {
        status: 'success',
        partners,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getDriversProfile = (driver) => async dispatch => {
  try {
    const response = await getDriver(driver);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        driver: response.data.driver,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const createDriver = (driver) => async dispatch => {
  try {
    const response = await newDriver(driver);
    if (!response.error && response.status === 200) {
      if (response.data.driver) {
        return {
          status: 'success',
          id: response.data.driver.id,
        };
      }
      return {
        status: 'error',
        type: response.data.message === 'Email already taken' ? 'email-taken' : 'unkown',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const updateDriver = (driver) => async dispatch => {
  try {
    const response = await editDriver(driver);
    if (!response.error && response.status === 200) {
      if (response.data.driver) {
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: response.message === 'Email already taken' ? 'email-taken' : 'unkown',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const activationDriver = (driver, value) => async dispatch => {
  try {
    const response = await activeDriver(driver, value);
    if (!response.error && response.status === 200) {
      if (response.data.driver) {
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const enableDriverProfile = (driver) => async dispatch => {
  try {
    const response = await enableDriver(driver);
    if (!response.error && response.status === 200) {
      if (response.data.driver) {
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const disableDriverProfile = (driver) => async dispatch => {
  try {
    const response = await disableDriver(driver);
    if (!response.error && response.status === 200) {
      if (response.data.driver) {
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getPartners = () => async dispatch => {
  try {
    const response = await getPartnersAvailable();
    if (!response.error && response.status === 200) {
      const partners = response.data.partners;
      if (partners) {
        return {
          status: 'success',
          partners,
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const assignDriverBranch = (driver, branch) => async dispatch => {
  try {
    const response = await assignBranch(driver, branch);
    if (!response.error && response.status === 200) {
      if (response.data.driver_branch) {
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectDrivers = state => state.drivers.drivers;

export default driversSlice.reducer;
