import instance from '../../Api';

export const login = async (username, password) => {
  const api = await instance();
  let data = await api
    .post('/auth/agent', {username, password})
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
