import instance from "../../Api";

export const getGettersAvailables = async () => {
  const api = await instance();
  let data = await api.get("/drivers/drivers-availables").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getSettlements = async (begin, end) => {
  const api = await instance();
  let data = await api
    .post("/drivers/settlements", { start_date: begin, end_date: end })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const createSettlement = async (driver, amount) => {
  const api = await instance();
  let data = await api
    .post("/drivers/settlements/add-initial-cash", {
      id_driver: driver,
      initial_cash: amount,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editBonus = async (settlement, amount) => {
  const api = await instance();
  let data = await api
    .post("/drivers/add-bonus", {
      id: settlement,
      bonus: amount,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const closeSettlement = async (config) => {
  const api = await instance();
  let data = await api
    .post("/drivers/settlements/close", config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
