import {useState, useEffect} from 'react';
import { message, Form, Modal } from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import {selectCountry, selectUser} from '../login/UserSlice';
import {
  createDriver,
  getDriversProfile,
  updateDriver,
  activationDriver,
  enableDriverProfile,
  disableDriverProfile,
  getPartners,
  assignDriverBranch,
} from '../drivers/DriversSlice';
import DriverEditorUI from './components/DriverEditorUI';

const DriverEditor = () => {
  const [loading, handleLoading] = useState(true);
  const [enabling, handleEnabling] = useState(false);
  const [disabling, handleDisabling] = useState(false);
  const [loadingImg, handleLoadingImg] = useState(false);
  const [loadingActive, handleLoadingActive] = useState(false);
  const [showMap, handleShowMap] = useState(false);
  const [sending, handleSending] = useState(false);
  const [imageUrl, handleImageUrl] = useState(null);
  const [image, handleImage] = useState(null);
  const [partners, handlePartners] = useState([]);
  const [editingDriver, handleEditingDriver] = useState();
  const {id} = useParams();
  const country = useSelector(selectCountry);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const isAdmin = user.role === 10;

  useEffect(() => {
    const initialFetch =  async () => {
      const res_partners = await dispatch(getPartners());
      if (res_partners.status === 'success') {
        handlePartners(res_partners.partners);
      }
      if (id !== 'new') {
        const response = await dispatch(getDriversProfile(id));
        if (response.status !== 'success') {
          Modal.error({
            title: 'Hubo un error',
            content: 'Inténtalo de nuevo más tarde',
          });
          history.push('/drivers');
        } else {
          
          handleLoading(false);
          handleImageUrl(response.driver.photo);
          handleEditingDriver(response.driver);
          if (response.driver?.branch?.length > 0) {
            response.driver.branch = response.driver.branch[0].id_branch;
          } else {
            response.driver.branch = 0;
          }
          form.setFieldsValue(response.driver);
        }
      } else {
        handleLoading(false);
      }
    };
    initialFetch();
  }, [dispatch, form, history, id]);

  const confirmEnableDriver = () => {
    Modal.confirm({
      title: '¿Deseas habilitar este driver?',
      content: 'Si el driver se encuentra con una orden activa podria ocasionar problemas en su usuario',
      okText: 'Habilitar',
      cancelText: 'Cancelar',
      async onOk() {
        await enableDriver();
      },
      onCancel() {}
    });
  };

  const confirmDisableDriver = () => {
    Modal.confirm({
      title: '¿Deseas bloquear este driver?',
      content: 'Esta acción no podra deshacerse.',
      okText: 'Bloquear',
      cancelText: 'Cancelar',
      okType: 'danger',
      async onOk() {
        await disableDriver();
      },
      onCancel() {}
    });
  };

  const enableDriver = async () => {
    handleEnabling(true);
    const response = await dispatch(enableDriverProfile(id));
    if (response.status === 'success') {
      Modal.success({
        title: 'Driver habilitado',
        content: 'Se ha cambiado el estado del motorista',
      });
      const newDriver = {...editingDriver, ...{available: 1}};
      handleEditingDriver(newDriver);
    } else {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleEnabling(false);
  };

  const disableDriver = async () => {
    handleDisabling(true);
    const response = await dispatch(disableDriverProfile(id));
    if (response.status === 'success') {
      Modal.success({
        title: 'Driver inhabilitado',
        content: 'Se ha cambiado el estado del motorista',
      });
      const newDriver = {...editingDriver, ...{available: 2}};
      handleEditingDriver(newDriver);
    } else {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleDisabling(false);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = info => {
    getBase64(info.file.originFileObj, newUrl => {
        handleLoadingImg(false);
        handleImage(info.file.originFileObj);
        handleImageUrl(newUrl);
      }
    );
  };

  const activation = async () => {
    handleLoadingActive(true);
    const response = await dispatch(activationDriver(id, !editingDriver.active));
    if (response.status === 'success') {
      Modal.success({
        title: 'Cambio realizado',
        content: 'Se ha cambiado el estado del motorista',
      });
      const newDriver = {...editingDriver, ...{active: !editingDriver.active}};
      handleEditingDriver(newDriver);
    } else {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleLoadingActive(false);
  };

  const save = async () => {
    handleSending(true);
    try {
      const values = await form.validateFields();
      const newDriver = {
        address: values.address,
        email: values.email,
        gender: values.gender,
        name: values.name,
        lastname: values.lastname,
        nationalId: values.national_id,
        password: values.password,
        phone: values.phone,
        plate: values.plate,
        auto_dispatch: values.auto_dispatch ? 1 : 0,
        edit_address: values.edit_address ? 1 : 0,
      };
      newDriver.country = country;
      if (image) {
        newDriver.photo = true;
        newDriver.file = image;
      } else {
        newDriver.photo = false;
        newDriver.file = null;
      }
      if (id === 'new') {
        values.available = 1;
        const response = await dispatch(createDriver(newDriver));
        if (response.status === 'success') {
          handleImage(null);
          if (values.branch > 0 && isAdmin) {
            const response_assign =
              await dispatch(assignDriverBranch(response.id, values.branch));
            if (response_assign.status !== 'success') {
              Modal.error({
                title: 'Hubo un error',
                content: 'Inténtalo de nuevo más tarde',
              });
            }
          }
          Modal.success({
            title: 'Motorista creado',
            content: 'Se ha creado un nuevo motorista',
          });
          history.push(`/drivers/${response.id}`);
        } else if (response.type === 'email-taken') {
          Modal.error({
            title: 'Email tomado',
            content: 'Este email ya ha sido tomado',
          });
        } else {
          Modal.error({
            title: 'Hubo un error',
            content: 'Inténtalo de nuevo más tarde',
          });
        }
      } else {
        newDriver.id_driver = id;
        // const updatedDriver = {...editingDriver, ...values};
        const response = await dispatch(updateDriver(newDriver));
        if (response.status === 'success') {
          handleImage(null);
          if (isAdmin) {
            const response_assign =
              await dispatch(
                assignDriverBranch(
                  id,
                  values.branch === 0 ? null : values.branch,
                )
              );
            if (response_assign.status !== 'success') {
              Modal.error({
                title: 'Hubo un error',
                content: 'Inténtalo de nuevo más tarde',
              });
            }
          }
          Modal.success({
            title: 'Cambios guardados',
            content: 'Se han guardado los cambios',
          });
        } else if (response.type === 'email-taken') {
          Modal.error({
            title: 'Email tomado',
            content: 'Este email ya ha sido tomado',
          });
        } else {
          Modal.error({
            title: 'Hubo un error',
            content: 'Inténtalo de nuevo más tarde',
          });
        }
      }
    } catch (errorInfo) {
      Modal.error({
        title: 'Hubo un error',
        content: 'Inténtalo de nuevo más tarde',
      });
    }
    handleSending(false);
  };

  return (
    <DriverEditorUI
      loadingImg={loadingImg}
      beforeUpload={beforeUpload}
      imageUrl={imageUrl}
      handleChange={handleChange}
      form={form}
      sending={sending}
      save={save}
      loading={loading}
      id={id}
      activation={activation}
      loadingActive={loadingActive}
      isActive={editingDriver ? editingDriver.active : false}
      editingDriver={editingDriver}
      showMap={showMap}
      handleShowMap={handleShowMap}
      enabling={enabling}
      confirmEnableDriver={confirmEnableDriver}
      disabling={disabling}
      confirmDisableDriver={confirmDisableDriver}
      partners={partners}
      isAdmin={isAdmin}
    />
  );
};

export default DriverEditor;
