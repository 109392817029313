import {useState} from 'react';
import { Select, Tooltip } from 'antd';
import cx from 'classnames';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import { TelephoneFill, CompassFill, ArrowRight } from '../../../components/Icon';
import Loader from '../../../components/Loader';
import '../styles/Step.scss';

const { Option } = Select;

const Step = (props) => {
  const [sending, handleSending] = useState(false);
  const {
    state,
    loadingBranches,
    branches,
    handleBranchSelected,
    action,
    branchSelected,
    timeSelected,
    handleTimeSelected,
    drivers,
    driverSelected,
    handleDriverSelected,
    showMap,
    disabled,
    phone,
    hour,
    tuNicaragua,
  } = props;

  const states = [
    'recieved',
    'toPreparation',
    'toDriver',
    'toDispatch',
    'toDelivery',
    'completed',
  ];

  const getTitle = () => {
    if (state === states[0]) {
      return 'Orden recibida';
    } else if (state === states[1]) {
      return 'Orden en preparación';
    } else if (state === states[2]) {
      return 'Orden asignada';
    } else if (state === states[3]) {
      return 'Orden despachada';
    } else if  (state === states[4]) {
      return 'Orden en camino';
    } else if (state === states[5]) {
      return 'Orden Completada'
    }
  };

  const getDescription = () => {
    if (state === states[0]) {
      return 'Selecciona la sucursal más óptima para enviar el pedido.';
    } else if (state === states[1]) {
      return 'Acepta el pedido del cliente. Recuerda solo hacer esto si el comercio no puede hacerlo.';
    } else if (state === states[2]) {
      return 'Asigna manualmente un motorista para el pedido.';
    } else if (state === states[3]) {
      return 'Despacha el pedido del cliente. Recuerda solo hacer esto si el comercio tiene problemas para hacerlo.';
    } else if  (state === states[4]) {
      return 'Cambia este estado si el Driver tiene problemas para colocar su pedido en camino.';
    } else if (state === states[5]) {
      return 'Completa la orden solamente si el Driver tiene problemas para hacerlo desde su app.'
    }
  };

  const getLabel = () => {
    if (state === states[0]) {
      return 'RE ENVIAR PEDIDO';
    } else if (state === states[1]) {
      return 'TIEMPO ESTIMADO';
    } else if (state === states[2]) {
      return 'ASIGNAR DRIVER';
    }
    return 'ACCIÓN';
  };

  const getLabelAction = () => {
    if (state === states[3]) {
      return 'Despachar pedido';
    } else if (state === states[4]) {
      return 'Iniciar delivery manualmente';
    } else if (state === states[5]) {
      return 'Completar manualmente';
    }
  };

  const getAction = () => {
    if (state === states[0]) {
      return (
        <Select
          className="select"
          placeholder="Seleccionar sucursal"
          loading={loadingBranches}
          disabled={loadingBranches || disabled}
          size="large"
          value={branchSelected}
          onSelect={(key) => {handleBranchSelected(key)}}
        >
          {branches.map((branch) => (
            <Option key={branch.id} value={branch.id}>
              {branch.zone}
            </Option>
          ))}
        </Select>
      );
    } else if (state === states[1]) {
      return (
        <Select
          className="select"
          placeholder="Selecciona el tiempo estimado"
          size="large"
          value={timeSelected}
          disabled={disabled}
          onSelect={(key) => {handleTimeSelected(key)}}
        >
          <Option value={15}>15 minutos</Option>
          <Option value={20}>20 minutos</Option>
          <Option value={30}>30 minutos</Option>
          <Option value={45}>45 minutos</Option>
          <Option value={60}>60 minutos</Option>
        </Select>
      );
    } else if (state === states[2]) {
      return (
        <Select
          className="select driver-select"
          placeholder="Seleccionar Driver"
          size="large"
          loading={loadingBranches}
          disabled={loadingBranches || disabled}
          value={driverSelected}
          onSelect={(key) => {handleDriverSelected(key)}}
        >
          {drivers.map((driver, index) => (
            <Option key={driver.id} value={index}>
              {`${driver.name} ${driver.lastname}`}
            </Option>
          ))}
        </Select>
      );
    }
    return (
      <div className="action-step">
        {getLabelAction()}
      </div>
    );
  };

  const actionStep = async () => {
    handleSending(true);
    await action();
    handleSending(false);
  };

  return (
    <div className={cx('step-detail', {disabled})}>
      <div className={cx('step-number',
        {
          yellow: state === states[2] || state === states[3],
          teal: state === states[4] || state === states[5],
        })}>
        {states.indexOf(state) + 1}
      </div>
      <div className="section">
        <div className={cx('main', {tuNicaragua})}>
          <div className="info">
          <span className="title">
            {getTitle()}{hour && ` - ${moment.tz(hour, 'America/El_Salvador').format('h:mm a')}`}
          </span>
          </div>
          {(state === states[0] || state === states[5]) && (
            <div className="actions">
              <button
                className="action
                compass-wrap"
                onClick={showMap}
                disabled={disabled}
              >
                <CompassFill />
              </button>
              <Tooltip disabled={disabled} placement="bottomRight" title={phone}>
                <button
                  className="action phone-wrap"
                  disabled={disabled}
                >
                  <TelephoneFill />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
        {!tuNicaragua && (
          <>
            <span className="subtitle">{getDescription()}</span>
            <span className="label">{getLabel()}</span>
            <div className="action">
              {state === states[2] && (
                <div className="avatar-driver">
                  {(!loadingBranches && driverSelected !== null) ? (
                    <div
                      className="photo"
                      style={{backgroundImage: `url(${drivers[driverSelected].photo})`}}
                    />
                  ) : (
                    <span />
                  )}
                </div>
              )}
              {getAction()}
              <button disabled={sending || disabled} className="send" onClick={actionStep}>
                Envíar {sending ? <Loader /> : <ArrowRight />}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Step;