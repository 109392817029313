import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { mkConfig, generateCsv, download } from "export-to-csv";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import {
  selectUnpaid,
  getUnpaid,
  paySettlement,
  selectBeginDate,
  selectEndDate,
  selectLoadedPendings,
  setBeginDate,
  setEndDate,
  setLoadedPendings,
  selectPaymentType,
  setPaymentType,
  selectPartners,
} from "./SettlementsSlice";
import { selectCountry } from "../login/UserSlice";
import SettlementsUI from "./components/SettlementsUI";

const Settlements = () => {
  const country = useSelector(selectCountry);
  const unpaid = useSelector(selectUnpaid);
  const partners = useSelector(selectPartners);
  const beginDate = useSelector(selectBeginDate);
  const endDate = useSelector(selectEndDate);
  const payment_type = useSelector(selectPaymentType);
  const loadedPendings = useSelector(selectLoadedPendings);
  const today = moment.tz("America/El_Salvador");
  const [loading, handleLoading] = useState(false);
  const [sending, handleSending] = useState(false);
  const [exporting, handleExporting] = useState(false);
  const [confirmation, handleConfirmation] = useState("");
  const [description, handleDescription] = useState("");
  const [selectedSettlements, handleSelectedSettlements] = useState([]);
  const [selectedIds, handleSelectedIds] = useState([]);
  const [currentData, handleCurrentData] = useState([]);
  const dispatch = useDispatch();

  const getUnpaidSettlements = useCallback(async () => {
    handleLoading(true);
    const config = {
      start_date: beginDate.format("YYYY-MM-DD 00:00:00"),
      end_date: endDate.format("YYYY-MM-DD 23:59:59"),
      payment_type,
    };
    const response = await dispatch(getUnpaid(config));
    if (response.status !== "success") {
      handleLoading(false);
      Modal.error({
        title: "Hubo un error",
        content: "Inténtalo de nuevo más tarde",
      });
    } else {
      handleSelectedIds([]);
      handleSelectedSettlements([]);
      handleCurrentData(response.unpaid);
      handleLoading(false);
    }
  }, [beginDate, dispatch, endDate, payment_type]);

  const handleDates = (newDates) => {
    dispatch(setBeginDate(newDates[0]));
    dispatch(setEndDate(newDates[1]));
  };

  const confirmSettlement = () => {
    if (confirmation.length > 2 && selectedSettlements.length > 0) {
      Modal.confirm({
        title: "¿Deseas crear la acreditación?",
        content: "Se creará la acreditación para los comercios seleccionados",
        okText: "Acreditar",
        cancelText: "Cancelar",
        async onOk() {
          await createSettlement();
        },
        onCancel() {},
      });
    } else if (confirmation.length < 2) {
      Modal.error({
        title: "Nº de confirmación inválido",
        content: "Ingresa un número de confirmación válido",
      });
    } else {
      Modal.error({
        title: "No se han seleccionado comercios",
        content:
          "Debes seleccionar almenos un comercio para realizar una acreditación",
      });
    }
  };

  const createSettlement = async () => {
    handleSending(true);
    const data = {
      confirmation_number: confirmation,
      settlements: selectedSettlements,
    };
    const response = await dispatch(paySettlement(data));
    if (response.status === "success") {
      await getUnpaidSettlements();
      handleSelectedIds([]);
      handleSelectedSettlements([]);
      handleConfirmation("");
      Modal.success({
        title: "Acreditaciones creadas",
        content: "Se han creado las acreditaciones exitosamente",
      });
    } else {
      Modal.error({
        title: "Hubo un error",
        content: "Inténtalo de nuevo más tarde",
      });
    }
    handleSending(false);
  };

  const handlePaymenType = (e) => {
    dispatch(setPaymentType(e));
  };

  const exportSettlement = async () => {
    if (selectedSettlements.length > 0 && description.length > 0) {
      handleExporting(true);
      const dataAgricola = [];
      const dataACH = [];

      selectedSettlements.map((settlement, index) => {
        const {
          account_number,
          account_type,
          bank,
          repay,
          partner_name,
          email,
          document_type,
          document,
        } = settlement;
        if (bank === "13") {
          dataAgricola.push({
            account_number,
            partner_name,
            empty: " ",
            repay,
            corr: index + 1,
            description,
            email,
          });
        } else {
          dataACH.push({
            account_number,
            bank,
            account_type,
            document_type,
            document,
            partner_name,
            empty: "",
            repay,
            description,
          });
        }
        return settlement;
      });

      const csvExporterAgricola = mkConfig({
        filename: `Acreditaciones Agrícola ${description}`,
        useBom: false,
        quoteStrings: "",
      });

      const csvExporterACH = mkConfig({
        filename: `Acreditaciones ACH ${description}`,
        useBom: false,
        quoteStrings: "",
      });

      const genAgricola = generateCsv(csvExporterAgricola)(dataAgricola);
      const genACH = generateCsv(csvExporterACH)(dataACH);

      dataAgricola.length && download(csvExporterAgricola)(genAgricola);
      dataACH.length && download(csvExporterACH)(genACH);
      handleExporting(false);
    } else if (selectedSettlements.length === 0) {
      Modal.error({
        title: "No se han seleccionado comercios",
        content:
          "Debes seleccionar almenos un comercio para realizar una acreditación",
      });
    } else {
      Modal.error({
        title: "Ingresa concepto",
        content: "Debes ingresar un concepto para la acreditación.",
      });
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      if (!loadedPendings) {
        await getUnpaidSettlements();
        dispatch(setLoadedPendings(true));
      } else {
        handleCurrentData(unpaid);
      }
    };
    initialFetch();
  }, []);

  return (
    <SettlementsUI
      today={today}
      country={country}
      loading={loading}
      partners={partners}
      unpaid={unpaid}
      sending={sending}
      handleSelectedSettlements={handleSelectedSettlements}
      confirmation={confirmation}
      handleConfirmation={handleConfirmation}
      confirmSettlement={confirmSettlement}
      handleSelectedIds={handleSelectedIds}
      selectedIds={selectedIds}
      exportSettlement={exportSettlement}
      exporting={exporting}
      selectedSettlements={selectedSettlements}
      description={description}
      handleDescription={handleDescription}
      beginDate={beginDate}
      endDate={endDate}
      handleDates={handleDates}
      handleCurrentData={handleCurrentData}
      currentData={currentData}
      payment_type={payment_type}
      handlePaymenType={handlePaymenType}
      getUnpaidSettlements={getUnpaidSettlements}
    />
  );
};

export default Settlements;
