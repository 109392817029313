import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import { selectCountry, selectUser } from "../login/UserSlice";
import { getOrders, selectOrders } from "./OrdersSlice";
import OrdersUI from "./components/OrdersUI";

const Orders = () => {
  const user = useSelector(selectUser);
  const country = useSelector(selectCountry);
  const today = moment.tz("America/El_Salvador");
  const [loading, handleLoading] = useState(true);
  const [partners, handlePartners] = useState([]);
  const [currentData, handleCurrentData] = useState([]);
  const [exporting, handleExporting] = useState(false);
  const [endDate, handleEndDate] = useState(today);
  const [beginDate, handleBeginDate] = useState(
    moment.tz("America/El_Salvador").subtract(1, "days")
  );
  const orders = useSelector(selectOrders);
  const tuNicaragua = user.role === 3 && country === "ni";
  const isAdmin = user.role === 10;
  const dispatch = useDispatch();

  const searchOrders = useCallback(
    async (newdBegin, newEnd, searchVal) => {
      handleLoading(true);
      const begin = newdBegin || beginDate;
      const end = newEnd || endDate;
      const config = {
        status: [12, 14, 15, 16, 8],
        startDate: begin.format("YYYY-MM-DD 00:00:00"),
        endDate: end.format("YYYY-MM-DD 23:59:59"),
        role: user.role,
      };
      if (searchVal) {
        config.ticket = searchVal;
      }
      const response = await dispatch(getOrders(config));
      if (response.status !== "success") {
        Modal.warning({
          title: "Hubo un problema",
          content: "Inténtalo de nuevo más tarde",
        });
      } else {
        handlePartners(response.partners);
        handleCurrentData(response.orders);
      }
      handleLoading(false);
    },
    [beginDate, dispatch, endDate, user.role]
  );

  const handleDates = (newDates) => {
    handleBeginDate(newDates[0]);
    handleEndDate(newDates[1]);
    searchOrders(newDates[0], newDates[1]);
  };

  const orderSearch = (val) => {
    searchOrders(false, false, val);
  };

  useEffect(() => {
    searchOrders();
  }, [searchOrders]);

  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const exportData = () => {
    handleExporting(true);
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Reporte Ordenes Get",
      Subject: "Reporte de ordenes ",
      Author: "Get Technologies",
      CreatedDate: new Date(),
    };
    wb.SheetNames.push("Ordenes");
    const ws_data = [
      !tuNicaragua
        ? [
            "ID",
            "Fecha",
            "Ticket",
            "Comercio",
            "Subtotal",
            "Comisión",
            "IVA",
            "Total",
            "Reintegrar",
            "Método",
            "Extra",
            "Razón",
            "Estado",
            "Id Remplazo",
          ]
        : [
            "Fecha",
            "Ticket",
            "Comercio",
            "Subtotal + IVA",
            "Envío + IVA",
            "Total",
            "Extra",
            "Razón",
            "Estado",
          ],
    ];

    currentData.forEach((order) => {
      const newRegister = !tuNicaragua
        ? [
            order.id,
            moment
              .tz(order.created_time, "America/El_Salvador")
              .format("DD/MM/YYYY, HH:mm a"),
            order.ticket,
            order.partner_name,
            order.subtotal,
            order.get_fee || 0,
            order.iva || 0,
            order.total,
            order.get_fee,
            order.payment_method === 1 ? "Efectivo" : "Tarjeta",
            order.extra_description || "-",
            order.reason_for_cancellation || "-",
            order.status !== 12 && order.status !== 14
              ? "Cancelado"
              : "Completado",
            order.id_replaced_order || "-",
          ]
        : [
            moment
              .tz(order.created_time, "America/El_Salvador")
              .format("DD/MM/YYYY, HH:mm a"),
            order.ticket,
            order.partner_name,
            order.subtotal * 1.15,
            order.delivery_fee * 1.15,
            order.total,
            order.extra_description || "-",
            order.reason_for_cancellation || "-",
            order.status !== 12 && order.status !== 14
              ? "Cancelado"
              : "Completado",
          ];
      ws_data.push(newRegister);
    });
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Ordenes"] = ws;
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "ReporteOrdenesGet.xlsx"
    );
    handleExporting(false);
  };

  return (
    <OrdersUI
      orders={orders}
      country={country}
      beginDate={beginDate}
      endDate={endDate}
      today={today}
      loading={loading}
      handleDates={handleDates}
      orderSearch={orderSearch}
      tuNicaragua={tuNicaragua}
      partners={partners}
      exporting={exporting}
      exportData={exportData}
      isAdmin={isAdmin}
      handleCurrentData={handleCurrentData}
    />
  );
};

export default Orders;
