import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Modal} from 'antd';
import {selectOrder, getOrderDetail, revertOrderDetail} from './OrderDetailSlice';
import {selectCountry, selectUser} from '../login/UserSlice';
import OrderDetailUI from './components/OrderDetailUI';

const OrderDetail = () => {
  const [loading, handleLoading] = useState(true);
  const [showCancel, handleShowCancel] = useState(false);
  const {id} = useParams();
  const order = useSelector(selectOrder);
  const country = useSelector(selectCountry);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const tuNicaragua = user.role === 3 && country === 'ni';
  const isAdmin = user.role === 10;

  useEffect(() => {
    const initialFetch = async () => {
      handleLoading(true);
      await dispatch(getOrderDetail(id));
      handleLoading(false);
    };

    initialFetch();
  }, [dispatch, id]);

  const revertOrder = async (code, date) => {
    const response = await dispatch(revertOrderDetail(
      order.id_user.id,
      order.id,
      code,
      date
    ));
    if (response.status === 'success') {
      handleShowCancel(false);
      Modal.success({
        title: 'Orden revertida',
        content: 'Se ha revertido la orden exitosamente.',
      });
    } else {
      Modal.error({
        title: 'Hubo un problema',
        content: 'No se ha podido realizar esta acción. Inténtalo de nuevo.',
      });
    }
  };

  return (
    <OrderDetailUI
      loading={loading}
      order={order}
      country={country}
      tuNicaragua={tuNicaragua}
      showCancel={showCancel}
      handleShowCancel={handleShowCancel}
      revertOrder={revertOrder}
      isAdmin={isAdmin}
    />
  );
};

export default OrderDetail;
