import { useState } from "react";
import "../styles/SummationsFLowUI.scss";
import TableSummations from "./TableSummations";
import ModalDetail from "./ModalDetail";
const SummationsFlowUI = ({
  today,
  loading,
  dataSource,
  exportSettlement,
  country
}) => {
  const [openModal,setOpenModal] = useState(false)
  const [record,setRecord] = useState({})
  return (
    <div className="summations-flow-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Liquidaciones</h1>
          <h3 className="subtitle">{today.format("dddd D [de] MMMM")}</h3>
        </div>
        <div className="number-orders">
          <div className="number-data">
            <h1 className="title">{dataSource?.length} </h1>
            <h3 className="subtitle">Liquidaciones</h3>
          </div>
        </div>
      </div>
      <div className="summations-body">
      <div className="actions">
        
        <button
          onClick={exportSettlement}
          className="export"
        >
          Exportar
        </button>
        </div>

        
        <ModalDetail openModal={openModal} setOpenModal={setOpenModal} record={record} country={country} />


        <TableSummations loading={loading} dataSource={dataSource} setOpenModal={setOpenModal} setRecord={setRecord} />
      </div>
    </div>
  );
};

export default SummationsFlowUI;
