import { createSlice } from '@reduxjs/toolkit';
import { checkSchedules } from './Api'

// Reducer
export const partnersSlice = createSlice({
  name: 'partners',
  initialState: {
    partners: [],
  },
  reducers: {
    setPartners: (state, action) => {
      state.partners = action.payload;
    },
  },
});


//Actions

export const { setPartners } = partnersSlice.actions;

export const checkPartnersSchedule = () => async dispatch => {
  try {
    const response = await checkSchedules();
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectPartners = state => state.partners.partners;

export default partnersSlice.reducer;
