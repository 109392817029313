import {Input, DatePicker, Spin, Button, Table} from 'antd';
import {Link} from 'react-router-dom';

import '../styles/FeedSortUI.scss';

const FeedSortUI = ({loading, DraggableContainer, DraggableBodyRow, today, dataSource, columns, history, getList}) => (
  <div className="feedSortUI">
    <div className="partners-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Ordenar Comercios</h1>
          <h3 className="subtitle">
            {today.format('dddd D [de] MMMM')}
          </h3>
        </div>
        <div className="new">
              <Link to="/feed-sort-detail/new"  className="action purple">
                Crear nuevo orden
              </Link>
          </div>
      </div>
      <div className="orders-history-wrap">
      <Table
          pagination={true}
          loading={loading}
          dataSource={getList}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          onRow={(record) => {
            return {
              onClick: () => history.push(`/feed-sort-detail/${record.id}`),
            };
          }}
        />
      </div>
    </div>
  </div>
);

export default FeedSortUI;
