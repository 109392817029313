import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";
import {
  getGettersAvailables,
  getSettlements,
  createSettlement,
  closeSettlement,
  editBonus,
} from "./Api";

// Reducer
export const gettersCashflowSlice = createSlice({
  name: "getters_cashflow",
  initialState: {
    drivers: [],
    settlements: [],
  },
  reducers: {
    setDrivers: (state, action) => {
      state.drivers = action.payload;
    },
    setSettlements: (state, action) => {
      state.settlements = action.payload;
    },
    newSettlement: (state, action) => {
      state.settlements.unshift(action.payload);
    },
    replaceSettlement: (state, action) => {
      const index = state.settlements.findIndex(
        (el) => el.id === action.payload.id
      );
      if (index > -1) {
        state.settlements[index] = action.payload;
      }
    },
    newBonus: (state, action) => {
      const { index, bonus } = action.payload;
      state.settlements[index].bonus = bonus;
    },
  },
});

//Actions

export const {
  setDrivers,
  setSettlements,
  newSettlement,
  replaceSettlement,
  newBonus,
} = gettersCashflowSlice.actions;

export const getDrivers = () => async (dispatch) => {
  try {
    const response = await getGettersAvailables();
    if (!response.error && response.status === 200) {
      const { drivers } = response.data;
      dispatch(setDrivers(drivers));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unkown",
    };
  }
};

export const getSettlementsList = (begin, end) => async (dispatch) => {
  try {
    const response = await getSettlements(begin, end);
    if (!response.error && response.status === 200) {
      const { settlements } = response.data;
      dispatch(setSettlements(settlements));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unkown",
    };
  }
};

export const openSettlement = (driver, amount, info) => async (dispatch) => {
  try {
    const response = await createSettlement(driver, amount);
    if (!response.error && response.status === 200) {
      const { record } = response.data;
      const newRecord = produce(record, (draftState) => {
        draftState.id_driver = info;
      });
      dispatch(newSettlement(newRecord));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unkown",
    };
  }
};

export const endSettlement = (config, oldSettlement) => async (dispatch) => {
  try {
    const response = await closeSettlement(config);
    if (!response.error && response.status === 200) {
      const settlement = response.data.record;
      settlement.id_driver = oldSettlement;
      dispatch(replaceSettlement(settlement));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    console.log(e);
    return {
      status: "error",
      type: "unkown",
    };
  }
};

export const editBonusSettlement =
  (settlement, bonus, index) => async (dispatch) => {
    try {
      const response = await editBonus(settlement, bonus);
      if (!response.error && response.status === 200) {
        dispatch(
          newBonus({
            index,
            bonus,
          })
        );
        return {
          status: "success",
        };
      }
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      return {
        status: "error",
        type: "unkown",
      };
    }
  };

export const selectDrivers = (state) => state.getters_cashflow.drivers;
export const selectSettlements = (state) => state.getters_cashflow.settlements;

export default gettersCashflowSlice.reducer;
