import { GoogleMap, LoadScript, OverlayView } from '@react-google-maps/api';
import '../styles/Map.scss';

const containerStyle = {
  width: '100%',
  height: '500px'
};

const Map = ({latitude, longitude, name, photo}) => {


  const getCoordinates = (latitude, longitude) => {
    return {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };
  };

  const position = getCoordinates(latitude, longitude);

  const Marker = () => (
    <div className="marker driver">
      <div className="wrap">
        <div
          className="image"
          style={{
            backgroundImage: `url(${photo})`,
          }}
        />
      </div>
      <div className="point" />
      <div className="info">
        {name}
      </div>
    </div>
  );

  const GetMarker = ({ubication, isPartner, isUser, isDriver}) => (
    <OverlayView
      position={ubication}
      getPixelPositionOffset={() => ({x: -20, y: -52})}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <Marker isPartner={isPartner} isDriver={isDriver} isUser={isUser} />
    </OverlayView>
  );

  return (
    <div className="map-wrapper-editor">
      <LoadScript
      googleMapsApiKey="AIzaSyCYkhtnCIFD6yWm3v1V78OKIwmUaPQqNvk"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={15}
          options={{
            disableDefaultUI: true,
          }}
        >
          <GetMarker ubication={position} isDriver />
        </GoogleMap>
      </LoadScript>
      <div className="filter" />
    </div>
  );
};

export default Map;