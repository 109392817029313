import {getCurrency} from '../../../utils/format';

const Products = ({products, country, partner}) => {
  const getExtras = (product) => {
    const {extras, product_categories} = product;
    if (product_categories.length > 0) {
      return (
        <div className="product-extras">
          {product_categories.map((cat) => {
            return (
              <div key={cat.id} className="cat-wrapper">
                <span className="cat-main">{cat.name}</span>
                {cat.extras.map((extra) => {
                  const {id, name, price} = extra;
                  return (
                    <div key={id} className="extra horizontal">
                      <span className="label">{`${name}${extra.quantity ? ` (${extra.quantity})` : ''}`}</span>
                      {price > 0 && (
                        <span className="price">
                          {`+ ${getCurrency(country, price, partner.default_currency)}`}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    } else if (extras.length > 0) {
      return (
        <div className="product-extras">
          {extras.map((extra) => {
            const {id, name, price} = extra;
            return (
              <div key={id} className="extra horizontal">
                <span className="label">{name}</span>
                {price > 0 && (
                  <span className="price">
                    {`+ ${getCurrency(country, price, partner.default_currency)}`}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      );
    }
    return <span />;
  };

  const Product = ({product}) => {
    const {unit_price, total} = product;
    return (
      <div className="product-wrap">
        <div className="product box">
          <div className="product-header">
            <div className="quantity-wrap">
              {product.quantity}
            </div>
            <span className="product-name label">
              {product.name}
            </span>
            <span className="price">
              {getCurrency(country, unit_price, partner.default_currency)}
            </span>
          </div>
          {getExtras(product)}
          {product.comments && product.comments !== 'N/A'  && (
            <div className="comments">
              Comentarios: {product.comments}
            </div>
          )}
        </div>
        <div className="product-total box horizontal">
          <span className="label">Suma</span>
          <span className="price">
            {getCurrency(country, total, partner.default_currency)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="order-detail">
      {products.map((product) => (
        <Product key={product.id} product={product} />
      ))}
    </div>
  );
};

export default Products;