import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Spin,
} from 'antd';
import '../styles/UserEditorUI.scss';

const DriverEditorUI = (props) => {
  const {
    form,
    sending,
    save,
    loading,
    id,
    goBack,
  } = props;

  if (loading) {
    return (
      <div className="loader-wrap">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="users-editor">
      <div className="main-titles">
        <button onClick={goBack} className="back">
          <i className="bi bi-arrow-left-short" />
        </button>
        <div>
          <h1 className="title">Usuarios</h1>
          <h3 className="subtitle">
            {id === 'new' ? 'Crear usuario' : 'Editar usuario'}
          </h3>
        </div>
      </div>
      <div className="form-editor">
        <Form
          form={form}
          name="driver"
          layout="vertical"
          requiredMark={false}
          onFinish={save}
          onFinishFailed={() => {}}
        >
          <Row gutter={{ xs: 0, sm: 18, md: 24}}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
            >
              <Form.Item
                label={<span className="label">NOMBRE</span>}
                name="name"
                colon={false}
                rules={[{ required: true, message: 'Ingresa un nombre' }]}
              >
                <Input size="large" className="input" />
              </Form.Item>
              <Form.Item
                label={<span className="label">CORREO</span>}
                name="email"
                colon={false}
                rules={[{ required: true, message: 'Ingresa el correo' }]}
              >
                <Input size="large" className="input" />
              </Form.Item>
              <Form.Item
                label={<span className="label">CONTRASEÑA</span>}
                name="password"
                colon={false}
                rules={[{ required: true, message: 'Ingresa la contraseña' }]}
              >
                <Input.Password
                  visibilityToggle={false}
                  size="large"
                  className="input"
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
            >
              <Form.Item
                label={<span className="label">APELLIDO</span>}
                name="lastname"
                colon={false}
                rules={[{ required: true, message: 'Ingresa un apellido' }]}
              >
                <Input size="large" className="input" />
              </Form.Item>
              <Form.Item
                label={<span className="label">NÚMERO DE TELÉFONO MÓVIL</span>}
                name="mobile"
                colon={false}
                rules={[{ required: true, message: 'Ingresa un teléfono' }]}
              >
                <Input size="large" className="input" />
              </Form.Item>
              <Form.Item
                label={<span className="label">NÚMERO DE TELÉFONO DE CASA</span>}
                name="phone"
                colon={false}
                rules={[{ required: true, message: 'Ingresa un teléfono' }]}
              >
                <Input size="large" className="input" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label={<span className="label">NOTAS</span>}
                name="notes"
                colon={false}
                rules={[{ required: false }]}
              >
                <Input.TextArea rows={2} size="large" className="input textarea" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item>
                <div className="actions">
                  {id !== 'new' ? (
                    <Button
                      disabled={sending}
                      // loading={loadingActive}
                      // onClick={activation}
                      type="primary"
                      htmlType="button"
                      className="btn active-btn"
                    >
                      {/* {isActive ? 'Desactivar' : 'Activar'} */}
                    </Button>
                  ) : (
                    <div />
                  )}
                  <Button loading={sending} htmlType="submit" className="btn save-btn">
                    {id === 'new' ? 'Crear' : 'Guardar'}
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default DriverEditorUI;