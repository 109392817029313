import {useState} from 'react';
import {Select, Input, Button, Modal} from 'antd';

const {Option} = Select;
const {TextArea} = Input;

const CancelOrder = ({cancelOrder}) => {
  const [reason, handleReason] = useState('');
  const [comments, handleComments] = useState('');
  const [sending, handleSending] = useState(false);

  const reasons = [
    'Comercio cerrado',
    'Saturación',
    'Producto agotado',
    'Rechazo por comercio',
    'Cliente fraudulento',
    'Información incompleta',
    'Cliente no responde',
    'Pedido llego tarde',
    'Pedido llego en mal estado',
    'Orden de prueba',
  ];

  const cancel = async () => {
    if (reason.length === 0) {
      Modal.error({
        title: 'No se ha seleccionado una razón',
        content: 'Ingresa una razón válida para realizar esta acción.',
      })
    } else {
      handleSending(true);
      await cancelOrder(reason, comments);
      handleSending(false);
    }
  };

  return (
    <div style={{paddingBottom: '35px'}}>
      <Select
        size="large"
        style={{width: '100%', marginBottom: '20px'}}
        value={reason}
        onChange={handleReason}>
        <Option value="" disabled>Seleccionar motivo</Option>
        {reasons.map(r => (
          <Option key={r} value={r}>{r}</Option>
        ))}
      </Select>
      <TextArea
        value={comments}
        onChange={e => handleComments(e.target.value)}
        size="large"
        style={{marginBottom: '20px'}}
        placeholder="Comentarios"
        rows={2}
      />
      <Button
        loading={sending}
        onClick={cancel}
        size="large"
        style={{float: 'right', fontWeight: 700}}
        type="danger">
          Cancelar Orden
        </Button>
    </div>
  );
}

export default CancelOrder;
