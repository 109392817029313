import {Table, Input, Button, Space, Tag, Spin, Modal} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/es';
import {PersonPlusFill, CompassFill, X} from '../../../components/Icon';
import Map from './Map';
import '../styles/DriversUI.scss';

const DriversUI = (props) => {
  const {
    drivers,
    loading,
    searchText,
    searchInput,
    searchedColumn,
    handleSearch,
    handleReset,
    isAdmin,
    createDriver,
    editDriver,
    showMap,
    handleShowMap,
    partners,
  } = props;

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar por ${dataIndex === 'name' ? 'nombres' : 'apellidos'}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Nombres',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Apellidos',
      dataIndex: 'lastname',
      ...getColumnSearchProps('lastname'),
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      align: 'center',
    },
    /* {
      title: 'Género',
      dataIndex: 'gender',
      align: 'center',
      render: (gender) => {
        const color = gender === 'male' ? 'geekblue' : 'volcano';
        return (
          <div className="center-tag">
            <Tag color={color}>
              {gender === 'male' ? 'HOMBRE' : 'MUJER'}
            </Tag>
          </div>
        );
      },
    }, */
    {
      title: 'Activo',
      dataIndex: 'active',
      align: 'center',
      filters: [
        {
          text: 'Activo',
          value: 1,
        },
        {
          text: 'Inactivo',
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.active === value,
      render: (active) => {
        const color = active === 1 ? 'green' : 'purple';
        return (
          <div className="center-tag">
            <Tag color={color}>
              {active ? 'ACTIVO' : 'INACTIVO'}
            </Tag>
          </div>
        );
      },
    },
    {
      title: 'Disponible',
      dataIndex: 'available',
      align: 'center',
      filters: [
        {
          text: 'Disponible',
          value: 1,
        },
        {
          text: 'Con Pedido',
          value: 0,
        },
        {
          text: 'Bloqueado',
          value: 2,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.available === value,
      render: (available) => {
        let color = 'green';
        let state = 'DISPONIBLE'
        
        if (available === 0 && available !== null) {
          color = 'blue';
          state = 'CON PEDIDO'
        } else if (available === 2) {
          color = 'red';
          state = 'BLOQUEADO';
        }
        
        return (
          <div className="center-tag">
            <Tag color={color}>
              {state}
            </Tag>
          </div>
        );
      },
    },
    {
      title: 'OC',
      dataIndex: 'orders_rejected',
      align: 'center',
      sortDirections: ['descend'],
      sorter: (a, b) => a.orders_rejected.length - b.orders_rejected.length,
      render: (oc) => {
        return (
          <span>{oc.length}</span>
        );
      }
    },
  ];

  if (isAdmin) {
    columns.splice(3, 0, {
      title: 'Comercio',
      dataIndex: 'branch',
      align: 'center',
      filters: partners,
      onFilter: (value, record) => record.branch ? record.branch.id_partner.id === value : value === 0,
      render: branch => branch ? `${branch.id_partner.name}` : 'Get',
    });
  }

  if (loading) {
    return (
      <div className="loader-wrap">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="drivers-wrap">
      <div className="main-titles">
        <div>
          <h1 className="title">Motoristas</h1>
          <h3 className="subtitle">{moment().format('dddd D [de] MMMM')}</h3>
        </div>
        <div className="align-right">
          <h1 className="title">{drivers.length}</h1>
          <h3 className="subtitle">Motoristas</h3>
        </div>
      </div>
      <div className="actions">
        {/* <button className="deactive">
          Desactivar
        </button> */}
        <div className="main-actions">
          <button onClick={() => handleShowMap(true)} className="map-btn">
            Mapa <CompassFill /> 
          </button>
          <button className="new-btn" onClick={createDriver}>
            Crear nuevo Motorista <PersonPlusFill /> 
          </button>
        </div>
      </div>
      <div className="drivers-info">
        <Table
          rowKey="id"
          columns={columns}
          dataSource={drivers}
          onRow={(record) => {
            return {
              onClick: () => editDriver(record.id), // click row
            };
          }}
          /* rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
            fixed: false,
          }} */
        />
      </div>
      <Modal
        open={showMap}
        onCancel={() => handleShowMap(false)}
        footer={false}
        className="modal-map"
        destroyOnClose
        closeIcon={
          <div className="close-wrap">
            <X />
          </div>
        }
      >
        <Map drivers={drivers} />
      </Modal>
    </div>
  );
};

export default DriversUI;