import { createSlice } from '@reduxjs/toolkit';
import {
  // getDrivers,
  getUser,
  newUser,
  editUser,
  search,
  // activeDriver,
} from './Api'

// Reducer

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});


//Actions

export const {
  setUsers,
} = usersSlice.actions;

export const searchUser = (value) => async dispatch => {
  try {
    const response = await search(value);
    if (!response.error && response.status === 200) {
      const {users} = response.data;
      return {
        status: 'success',
        users,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const getUserProfile = (user) => async dispatch => {
  try {
    const response = await getUser(user);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        user: response.data.user,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const createUser = (user) => async dispatch => {
  try {
    const response = await newUser(user);
    if (!response.error && response.status === 200) {
      if (response.data.user) {
        return {
          status: 'success',
          id: response.data.user.id,
          user: response.data.user,
        };
      }
      return {
        status: 'error',
        type: response.data.message === 'Email already taken' ? 'email-taken' : 'unkown',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const updateUser = (user) => async dispatch => {
  try {
    const response = await editUser(user);
    if (!response.error && response.status === 200) {
      if (response.data.user) {
        return {
          status: 'success',
          user: response.data.user,
        };
      }
      return {
        status: 'error',
        type: response.message === 'Email already taken' ? 'email-taken' : 'unkown',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unkown'
    };
  }
}

export const selectUsers = state => state.users.users;

export default usersSlice.reducer;
