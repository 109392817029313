import {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import { GoogleMap, LoadScript, OverlayView } from '@react-google-maps/api';
import cx from 'classnames';
import io from 'socket.io-client';
import {selectOrderDetail} from '../MonitorSlice';
import {selectCountry} from '../../login/UserSlice';
import {PersonPlusFill} from '../../../components/Icon';
import {urlSV, urlNI} from '../../../Api';
import '../styles/Map.scss';

const containerStyle = {
  width: '100%',
  height: '500px'
};

let socket;

const Map = () => {
  const [position, handlePosition] = useState({lat: 0, lng: 0});
  const order = useSelector(selectOrderDetail);
  const country = useSelector(selectCountry);
  const {branch, id_address, partner, id_driver, id_user, id} = order;

  const newPosition = useCallback((latitude, longitude) => {
    handlePosition(getCoordinates(latitude, longitude))
  }, []);

  useEffect(() => {
    id_driver && newPosition(id_driver.latitude, id_driver.longitude);
  }, [id_driver, newPosition]);

  useEffect(() => {
    const socketURL = country === 'sv' ? urlSV : urlNI;
    socket = io(socketURL, {
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      console.log(`>> socket Id: ${socket.id}`);
      if (socket.io.connecting[socket.id]) {
        socket.io.connecting[socket.id].disconnect();
      }
      socket.on('reconnecting', (data) => {
        // console.log(data);
      });
      socket.emit(
        'subscribe',
        {
          room: id,
        },
        (error, response) => {
          if (!error) {
            // console.log(response);
          } else {
            // console.log(error);
          }
        },
      );

      socket.on('driver', (data) => {
        const {latitude, longitude} = data;
        if (latitude && longitude) {
          newPosition(latitude, longitude);
        }
      });
    });

    socket.on('disconnect', async () => {
      // console.log('>> disconect');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const getCoordinates = (latitude, longitude) => {
    return {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };
  };

  const ubicationPartner = getCoordinates(branch.latitude, branch.longitude);
  const ubicationUser = getCoordinates(id_address.latitude, id_address.longitude);

  const getName = (isDriver, isUser) => {
    if (isDriver) {
      return id_driver ? id_driver.name : '';
    } else if (isUser) {
      return id_user.name;
    }
    return partner.name;
  };

  const Marker = ({isDriver, isUser}) => (
    <div className={cx('marker', {driver: isDriver, user: isUser})}>
      <div className="wrap">
        {isUser ? (
          <div className="user-wrap">
            <PersonPlusFill />
          </div>
        ) : (
          <div
            className="image"
            style={{
              backgroundImage: `url(${isDriver ? (id_driver ? id_driver.photo : '') : partner.logo})`,
            }}
          />
        )}
      </div>
      <div className="point" />
      <div className="info">
        {getName(isDriver, isUser)}
      </div>
    </div>
  );

  const GetMarker = ({ubication, isPartner, isUser, isDriver}) => (
    <OverlayView
      position={ubication}
      getPixelPositionOffset={() => ({x: -20, y: -52})}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <Marker isPartner={isPartner} isDriver={isDriver} isUser={isUser} />
    </OverlayView>
  );

  return (
    <div className="map-wrapper">
      <LoadScript
      googleMapsApiKey="AIzaSyCYkhtnCIFD6yWm3v1V78OKIwmUaPQqNvk"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={id_driver ? position : ubicationUser}
          zoom={15}
          options={{
            disableDefaultUI: true,
          }}
        >
          {branch.latitude && branch.longitude && (
            <GetMarker ubication={ubicationPartner} isPartner />
          )}
          <GetMarker ubication={ubicationUser} isUser />
          {id_user && (
            <GetMarker ubication={position} isDriver />
          )}
        </GoogleMap>
      </LoadScript>
      <div className="filter" />
    </div>
  );
};

export default Map;